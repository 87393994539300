import { BE_URL } from '../../Common/Constant';

import React, { useState, useEffect } from 'react';

import { useLocation } from "react-router-dom";

import { fetchApplicationNames, getAppsData } from '../../Services/assessmentGridService';

import moment from "moment";

import { useHistory } from 'react-router-dom';

import Loader from "../Common/Loader";

import { decodeSessionDetails } from "../../Services/CommonServices";

import objCryptoJS from "crypto-js";

import { DataType, AppSpecificGridProps, SelectedAppValue, SelectedAppValues, sessionResponse } from '../../Common/Interface'

//import { w3cwebsocket as WebSocket } from 'websocket';
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';

// Assuming a placeholder definition of DataType, modify as needed


// Define other attributes (PS_12 to PS_18) potentially as a separate type or within the component itself

const AppSpecificGrid: React.FC<AppSpecificGridProps> = ({ setLoaderFalse, taskId, applicationName, searchQuery,setInitialLoading }) => {
  // Initialize useState (PS_2 to PS_9, SQ_EX_10)
  const defaultPageSize = 10; // Placeholder value

  ///const location: any = useLocation().state

  const history = useHistory();

  const location: any = useLocation().state

  // Initialize selectedappValues with selectedAppInterface configuration (PS_11)
  const selectedappValues: SelectedAppValues = {
    outlook: [{
      header: "User Name",
      value: "UserName",
      sort: "m.UserName",
      className: "text-left",
      isSize: false
    },
    {
      header: "Mails",
      value: "TotalMailCount",
      sort: "m.TotalMailCount",
      className: "text-end",
      isSize: false
    },
    {
      header: "Mailbox",
      value: "TotalStorageUsedGB",
      sort: "m.TotalStorageUsedGB",
      className: "text-end",
      isSize: true
    }, {
      header: "Contacts",
      value: "TotalContactCount",
      sort: "m.TotalCalendarCount",
      className: "text-end",
      isSize: false
    }, {
      header: "Todo",
      value: "TotalTaskCount",
      sort: "m.TotalTaskCount",
      className: "text-end",
      isSize: false
    }, {
      header: "Calendar",
      value: "TotalCalendarCount",
      sort: "m.TotalCalendarCount",
      className: "text-end",
      isSize: false
    },
    {
      header: "Status",
      value: "InventoryStatus",
      sort: "",
      className: "text-end",
      isSize: false,
      isStatus: true
    }],
    shared: [{
      header: "User Name",
      value: "UserName",
      sort: "m.UserName",
      className: "text-left",
      isSize: false
    },
    {
      header: "Mails",
      value: "TotalMailCount",
      sort: "m.TotalMailCount",
      className: "text-end",
      isSize: false
    },
    {
      header: "Mailbox",
      value: "TotalStorageUsedGB",
      sort: "m.TotalStorageUsedGB",
      className: "text-end",
      isSize: true
    }, {
      header: "Contacts",
      value: "TotalContactCount",
      sort: "m.TotalCalendarCount",
      className: "text-end",
      isSize: false
    }, {
      header: "Todo",
      value: "TotalTaskCount",
      sort: "m.TotalTaskCount",
      className: "text-end",
      isSize: false
    }, {
      header: "Calendar",
      value: "TotalCalendarCount",
      sort: "m.TotalCalendarCount",
      className: "text-end",
      isSize: false
    },
    {
      header: "Status",
      value: "InventoryStatus",
      sort: "",
      className: "text-end",
      isSize: false,
      isStatus: true
    }],
    sharepoint: [
      { header: "Site Name", value: "SiteName", sort: "s.SourceSPSiteName", className: "text-left", isSize: false },
      { header: "Site URL", value: "SiteURL", sort: "s.SourceSPSiteURL", className: "text-left", isSize: false },
      { header: "Users", value: "userCount", sort: "o.SourceSPPeopleAndGroupsCount", className: "text-end", isSize: false },
      { header: "Document Library", value: "DocumentLibrary", sort: "DocumentLibrary", className: "text-end", isSize: false },
      { header: "Lists", value: "Lists", sort: "Lists", className: "text-end", isSize: false },
      { header: "Pages", value: "Pages", sort: "Pages", className: "text-end", isSize: false },
      { header: "Permission Level", value: "permissionLevelCount", sort: "permissionLevelCount", className: "text-end", isSize: false },
      { header: "People and Groups", value: "PeopleAndGroups", sort: "PeopleAndGroups", className: "text-end", isSize: false },
      { header: "Size", value: "siteSize", sort: "siteSize", className: "text-end", isSize: true },
      { header: "Status", value: "status", sort: "status", className: "text-center", isStatus: true, isSize: false }
    ], teamschannel: [
      { header: "Team Name", value: "SourceTeamName", sort: "s.SourceTeamName", className: "text-left", isSize: false },
      { header: "Team Email", value: "SourceTeamEmailID", sort: "s.SourceTeamEmailID", className: "text-left", isSize: false },
      { header: "Users", value: "Users", sort: "Users", className: "text-end", isSize: false },
      { header: "Channels", value: "Channels", sort: "Channels", className: "text-end", isSize: false },
      { header: "Messages", value: "TotalMessages", sort: "TotalMessages", className: "text-end", isSize: false },
      { header: "Human Messages", value: "HumanMessages", sort: "HumanMessages", className: "text-end", isSize: false },
      { header: "Bot Messages", value: "BotMessages", sort: "BotMessages", className: "text-end", isSize: false },
      { header: "Teams Apps", value: "Apps", sort: "Apps", className: "text-end", isSize: false },
      { header: "Files", value: "Files", sort: "Files", className: "text-end", isSize: false },
      { header: "Status", value: "status", sort: "status", className: "text-center", isStatus: true, isSize: false }
    ], gmail: [
      { header: "User Name", value: "UserName", sort: "UserName", className: "text-left", isSize: false },
      { header: "Mails", value: "TotalMailCount", sort: "TotalMailCount", className: "text-end", isSize: false },
      { header: "Mailbox", value: "TotalStorageUsedGB", sort: "TotalStorageUsedGB", className: "text-end", isSize: true },
      { header: "Contacts", value: "TotalContactCount", sort: "TotalContactCount", className: "text-end", isSize: false },
      { header: "Tasks", value: "TotalTaskCount", sort: "TotalTaskCount", className: "text-end", isSize: false },
      { header: "Calendar", value: "TotalCalendarCount", sort: "TotalCalendarCount", className: "text-end", isSize: false },
      { header: "Status", value: "InventoryStatus", sort: "", className: "text-center", isStatus: true, isSize: false }
    ], mydrive: [
      { header: "User Name", value: "UserName", sort: "ud.displayName", className: "text-left", isSize: false },
      { header: "User Email", value: "UserEmail", sort: "ud.mailId", className: "text-left", isSize: false },
      { header: "Total Files", value: "TotalFiles", sort: "di.fileCount", className: "text-end", isSize: false },
      { header: "Total Folders", value: "TotalFolders", sort: "di.folderCount", className: "text-end", isSize: false },
      { header: "Drive", value: "DriveSize", sort: "di.totalSize", className: "text-end", isSize: true },
      { header: "Status", value: "Status", sort: "di.inventoryStatus", className: "text-center", isStatus: true, isSize: false }
    ],

    onedrive: [
      { header: "User Name", value: "UserName", sort: "ud.displayName", className: "text-left", isSize: false },
      { header: "User Email", value: "UserEmail", sort: "ud.mailId", className: "text-left", isSize: false },
      { header: "Total Files", value: "TotalFiles", sort: "di.fileCount", className: "text-end", isSize: false },
      { header: "Total Folders", value: "TotalFolders", sort: "di.folderCount", className: "text-end", isSize: false },
      { header: "Drive", value: "DriveSize", sort: "di.totalSize", className: "text-end", isSize: true },
      { header: "Status", value: "Status", sort: "di.inventoryStatus", className: "text-center", isStatus: true, isSize: false }
    ],
  }

  const [data, setData] = useState<DataType[]>([]);
  const [renderapplicationName, setRenderApplicationName] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [sortBy, setSortBy] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [loading, setLoading] = useState<boolean>(false);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [totalCountinAssessment, setTotalCountinAssessment] = useState<number>(0);
  const [sortHide, setSortHide] = useState<string>('');

  // Initialize token (PS_10)
  //   PS_1 PS_2 PS_3 PS_4 PS_5 PS_6 PS_7 PS_8 PS_9 PS_10 
  // PS_11 PS_12 PS_13 PS_14 PS_15 PS_16 PS_17 PS_18 PS_19 
  //const [token, setToken] = useState({"clientToken":"U2FsdGVkX19MvwmIUmulsSQUii83m/ICs7Mg/0qD/0WAi4Qi0PvP2g8nV3zz41Ha+9qx/inRTQ0MuOv3N708dclk86udu0QKmXSPLEDlonrBQMNCUH+gi0358kH4XP2Ap7wb9R0/BPwCXIMJpS5/VmH76XPx3l4Mc8oMCk7DlT+Qhdek8Z17JWTCmHhikhzTtiiQfpuXmrUwaBKegiLYLD81i04h930XrM/4LQZ5u95wOVZLSwf9B51AQB0ABg0JUzT0k4D4H18WwBLHy4kEdTxr63awpVrPEoJzeoWbMMyCYpDnd8p7h9g9EkCCkKeMtdZ6iIfVyE8/hFo9Qi3zwwcfbrSWkMGK3Y5u+dA6iIKZmdUbxemwpmxOAoo1/JWjgoniJDatndOUfLuNcBCy60KvClIGxEC+oFei5qxe2wEt79/t89q+uE3xNyc5Vzf5vvd+0Fu9WJkyf8pysxNVpJE1kJir0jm4tz/HFRhOMa4TtwBy7HE/ELbUgD6AckeQV26GlJtzrEYVuBw2blWJdzeuguMUorYtUtmn9zfG3RIvMvujzEgypcehlHhn+XJ2SpZ4bniylQXLtQfrNSAZRaflhiCBJ+K26Up471sfKPh0nqmhT/ycCqu5wKrZtxi74jWa8hSqkXb6yaa33KyBSDzLNaGp3E0Bfbctip3uLlF5e8+Kk7yJU+ThKSnaXbYF08gqvV6EsziAJHky3W410Kn2xUjzyOCvCs9VBsiEee4GQULZzEgq4jsxeabz6KXNtrsVD0k7IdwZDYTQWugcYwPhsXj3aNlTlu4was1KHFsjXJL3isiC9IxXVLR4v41iEbDJqBa+bguKed8z189EshPvuwbrfCL5x2r1NzySILk="}); // Gets token from session assuming there's some getSessionToken method

  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})

  let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
  let [clientName, setclientName] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');
  let [isAdmin, setIsAdmin] = useState(false);
  let [adminName, setAdminName] = useState('');
  let [adminEemailAddress, setAdminEmailAddress] = useState('');
  let [adminrole, setAdminRole] = useState('');
  const [role, setRole] = useState<string>('');
  let client: any = ''
  const [alert, setAlert] = useState<string>('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let InActiveTimeout: NodeJS.Timeout;
  const [isFetching, setIsFetching] = useState(false);
  const [fetchNextData,setFetchNextData]=useState<boolean>(false);
  const [searchPerformed,setSearchPerformed]=useState<boolean>(false);
  // useEffect(() => {


  //   // Cleanup function
  // return () => {
  //   client.close();
  // };
  // }, []);


  // PS_21 PS_22 PS_23 PS_24 PS_25 PS_26 PS_27 PS_28 PS_29 PS_30
  useEffect(() => {
    // Fetch user data when component mounts 
    // if(client){
    //   client.close();
    // }
    
    
    sessionTokenProperties();
    resetTimer();
    setSortOrder("asc")
    setTotalDataCount(0)
    setPageSize(defaultPageSize)
    
    setRenderApplicationName(applicationName)
   
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };

    // return () => {
    //   client.close();
    // };
  }, []);

  useEffect(() => {
    
    

    // Fetch user data when component mounts
    // if(client){
    //   client.close();
    // }
    if (!isFetching) {
      const intervalId = setInterval(() => {
        //  fetchDataToBind().catch(console.error); // Handle any errors from the async call
      }, 5000);
      //const intervalId = setInterval(fetchDataToBind("initiaload"), 5000); // Call every 5 seconds
      fetchDataToBind()
      return () => clearInterval(intervalId); // Cleanup on unmount

    }

  }, [pageSize, sortBy, sortHide, searchQuery, renderapplicationName, isFetching,applicationName]);

  useEffect(() => {
    // Reset pageSize to default when searchQuery changes
    if (!searchQuery) {
      setPageSize(defaultPageSize);
    }
    setSearchPerformed(true);
  }, [searchQuery]);


  let resetTimer = async () => {
    try {
      // debugger;
      ////console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      ////console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        //  debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        //   debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      ////console.log("Reset Timer End");
    }
    catch (error) {
      ////console.log("Reset Timer Catch");
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoading(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      ////console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        ////console.log("Reset Session Timer Inside");
        clearTimeout(InActiveTimeout);
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout = setTimeout(() => {
          ////console.log("Expired");
          sessionStorage.removeItem("c_at");
          ////console.log("removed session");
          setisSessionEpired(true)
          setAlert('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        ////console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        ////console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          ////console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        ////console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      ////console.log("Reset Session Timer Catch");
      throw ex
    }
  };


  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      //  debugger
      if (tokenResponse.clientDetails) {
        setObjClient(tokenResponse.clientDetails)
        setclientName(tokenResponse.clientDetails.Name);
        setCompanyName(tokenResponse.clientDetails.CompanyName)
        emailAddress = tokenResponse.clientDetails.Email;
        setEmailAddress(emailAddress);
        setRole(tokenResponse.clientDetails.Role);
        setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
      }
      else {
        history.push("/Login")
      }
      //#region only for required Modules
      if (tokenResponse.adminDetails) {
        setObjAdmin(tokenResponse.adminDetails)
        setAdminName(tokenResponse.adminDetails.Name);
        setAdminEmailAddress(tokenResponse.adminDetails.Email);
        setRole(tokenResponse.adminDetails.Role);
      }
      // else {
      //     history.push("/Admin/Login")
      // }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };

  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    //   debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }


  // Component rendering logic and interactions would be defined here.
  // PS_32 PS_33 PS_34 PS_35 PS_36 PS_37 PS_38 PS_39 PS_40 PS_41 
  // PS_42 PS_43 PS_44 PS_45 PS_46 PS_47 PS_48 PS_49 PS_50 PS_51 
  // PS_52 PS_53 PS_54 PS_55  and 	Ps56 
  const getPageSize=():number=>{
    try{
      let payloadPageSize =defaultPageSize;
      const currentPageSize:number =(Math.ceil((data?.length) / 10) * 10);
      
      
      if(!searchPerformed){

        if(fetchNextData && (!(data?.length%defaultPageSize))){
          payloadPageSize= data?.length + 10
        }
        else if(currentPageSize % defaultPageSize ===0 && currentPageSize != 0){
          payloadPageSize= currentPageSize
        }

      }

      return payloadPageSize
    }catch(err){
      console.error('Error in getPageSize', err);
      return defaultPageSize;
    }
  }

  //PS_27 to PS_59
  const fetchDataToBind = async () => {
    // setSortBy(applicationName.toLocaleLowerCase().trim() == 'outlook' ? 'm.UserName' : applicationName.toLocaleLowerCase().trim() == 'gmail' ? 'UserName' : applicationName.toLocaleLowerCase().trim() == 'onedrive' ? 'ud.displayName' : applicationName.toLocaleLowerCase().trim() == 'sharepoint' ? 's.SourceSPSiteName' : applicationName.toLocaleLowerCase().trim() == 'teamschannel' ? 's.SourceTeamName' : '')
    try {
      setInitialLoading(true)
      
      setFetchNextData(false);
      setSearchPerformed(false);

      let token = await getToken()
      let payload = {
        searchValue: searchQuery.trim().toLocaleLowerCase(),
        pageSize: getPageSize(),
        sortBy: sortBy,
        sortOrder: sortOrder,
        taskId: taskId,
        token: token,
        applicationName: applicationName=='shared' ? 'outlook' : applicationName,
        resourceType : applicationName=='shared' ? 'shared' : 'user'
      }
      //console.log("this function is invoked", payload);
      let response: any = await getAppsData(payload);
      if (response.data.statusCode !== 200) {
        setAlert(response.data.statusMessage);
        setLoading(false);
        return;
      }
      //console.log("the response", response);
      // Transform the data based on the selectedappValues configuration
      const responseData = response.data.data.data;
      //console.log("the ressponse for the view sessment", response.data.data.data);
      //console.log("the response for outlook", responseData.detailedInfoResult);

      // Set common data
      debugger
      // Set application-specific data
      let transformedData: any;
      switch (applicationName?.toLowerCase().trim()) {
        case 'sharepoint':
          transformedData = responseData.detailedInfoResult;
          break;
        case 'outlook':
          transformedData = responseData.detailedInfoResult
          break;
        case 'shared':
          transformedData = responseData.detailedInfoResult
          break;
        case 'teamschannel':
          transformedData = responseData.detailedInfoResult;
          break;

        case 'gmail':
          transformedData = responseData.detailedInfoResult;
          break;

        case 'onedrive':
          setTotalDataCount(responseData.Count);
          
          // Assuming these applications have a similar structure to SharePoint
          // If they have different structures, you'll need to handle them separately
          transformedData = responseData.detailedInfoResult || [];
          break;
        default:
          throw new Error('Unsupported application');
      }

      // Transform the data based on the selectedappValues configuration
      const filteredData = transformedData.map((item: any) => {
        //console.log("the transformed data", transformedData);

        const newItem: any = {}
        selectedappValues[applicationName?.toLowerCase().trim()].forEach(({ value }) => {
          value == 'DriveSize' ? newItem[value] = Number(item[value]) + Number(item['versionSize']) : newItem[value] = item[value]
        });
        return newItem;
      });

      setData(filteredData);
      
      setTotalDataCount(responseData?.detailedInfoResult?.length);
      setTotalCountinAssessment(response?.data?.data?.data?.TotalCount)

      if (setLoaderFalse) {
        setLoaderFalse()
      }
      setInitialLoading(false);
    }
    catch (error: any) {
      //console.log("Error in assessment", error.message);
      setAlert("An error occurred during data fetching");
    }
    finally {
      setLoading(false);
    }
  };
  // PS_57
  const handleLoadMore = () => {
    setPageSize(pageSize + 10);
    setFetchNextData(true)
  };

  // PS_58 PS_59 PS_60 PS_61 PS_62 PS_63 
  const handleSort = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    
    try {
      let sortID: string = e.currentTarget.id;
      let sortval: Array<string> = sortID.split("@");
      if (sortval[0] !== 'Status') {
        setSortBy(sortval[0]);
        setSortOrder(sortval[1]);
        setSortHide(sortID);
        //console.log("the sortOrder and sortBY", sortval);
      }
    } catch (error) {
      ////console.log(error);
    }
  };


  const getStatusCSS = (status: string) => {
    switch (status) {
      case "failed":
        return "danger";
      case "queued":
        return "draft";
      case "draft":
        return "draft";
      case "cancelled":
        return "draft";
      case "scheduled":
        return "scheduled";
      case "inprogress":
        return "inprogress";
      case "in progress":
        return "inprogress";
      case "completed":
        return "success";
      case "paused":
        return "paused";
      default:
        return "";
    }
  };

  // PS_64 PS_65 PS_66 PS_67 PS_68 PS_69 PS_70 PS_71 PS_72 PS_73 PS_74 PS_75 
  function formatBytes(size: number): string {
    const units: string[] = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex: number = 0;

    while (size >= 1000 && unitIndex < units.length - 1) {
      size /= 1000;
      unitIndex++;
    }

    return size?.toFixed(2) + ' ' + units[unitIndex];
  }
  const renderCellContent = (item: any, value: string, isSize?: boolean, isStatus?: boolean) => {
    
    if (isSize) {
     
      // Number(item[value].DriveSize)

      return formatBytes(Number(item[value]));
    }
    if (isStatus) {
      return (
        <span className="text-nowrap text-start">
          <span
            className={`table-status ${getStatusCSS(
              item[value]?.toLowerCase()
            )} d-inline-block me-2 `}
          ></span>
          {item[value]}
        </span>
      );
    }
    if (item[value]?.length > 21) {
      return (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-title={item[value]}
          title={item[value]}
        >
          {item[value]?.slice(0, 21) + '...'}
        </span>
      );
    }
    return item[value]?.toString() || '-';
  };

  //console.log(applicationName, ": ", data)


  ////console.log(pageSize, "  ", totalDataCount)
  return (
    <div>
      <div className="table-responsive theme-table bg-white d-inline-block w-100 table-y-scroll">
        <table className="table table-borderless mb-0">
          <thead className="sticky-top">
            <tr>
              {


                selectedappValues[applicationName.toLocaleLowerCase().trim()]?.map(({ header, value, sort, className }, index) => {

                  return (<>
                    <th
                      className={`text-nowrap ${className}`}
                    >
                      {header}
                      {sort !== 'Status' && (
                      <span
                        className="ms-1 cursor-pointer"

                      >
                        <img
                          src={`/images/sort-arrow-up.svg`}
                          alt="sort-arrow"
                          style={{ cursor: 'pointer' }}
                          id={`${sort}@DESC`} hidden={
                            sortHide == `${sort}@ASC` || ""
                              ? false
                              : true
                          }
                          onClick={handleSort}
                        />
                        <img
                          src={`/images/sort-arrow-down.svg`}
                          alt="sort-arrow"
                          id={`${sort}@ASC`}
                          style={{ cursor: 'pointer' }}
                          hidden={
                            sortHide == `${sort}@ASC` || ""
                              ? true
                              : false
                          }
                          onClick={handleSort}
                        />
                      </span>
                      )}
                    </th>
                  </>)
                })
              }

            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((item, index) => (
                <tr key={index}>
                  {selectedappValues[applicationName?.toLowerCase().trim()].map(({ value, className, isSize, isStatus }, cellIndex) => (
                    <td key={cellIndex} className={className}>
                      {renderCellContent(item, value, isSize, isStatus)}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={selectedappValues[applicationName?.toLowerCase().trim()]?.length || 1}>
                  <div className="text-center">
                    <div className="pt-4 pb-3 text-center">
                      <img
                        src="/images/no-data-found-img.svg"
                        alt="no-data-found-img"
                        className="mb-3"
                      />
                      <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                      <p className="font-10 font-regular color-no-rec">
                        There is no data to show you right now
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
     
      <div className="d-flex justify-content-center mt-5">
      { totalCountinAssessment == totalDataCount ||pageSize <10 || totalDataCount < 10 ? null:
        <button
          className="btn primary-outline-btn font-14 font-semibold"
          onClick={() => handleLoadMore()}
          
        >
          Load More
        </button>
}
        {loading && (
          <div className="container-fluid">
            <div className="overlay">
              <div className="position-absolute top-50 start-50 translate-middle">
                <div
                  className="spinner-border Loader loader-color align-center "
                  role="status"
                >
                  <span>
                    <span className="loader-inner-circle"></span>
                  </span>
                </div>
                <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                  Loading..
                </p>
              </div>
            </div>
          </div>
        )}

        {/*Failure Popup starts here */}
        {alert ? <div
          className="modal fade show"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5
                  className="modal-title mb-3 primary-header"
                  id="Failure-popupLabel"
                >
                  { }
                </h5>
                <p className="primary-data">
                  {alert}
                </p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button
                  type="button"
                  className="btn primary-btn font-14 px-4 font-semibold"
                  onClick={() => {
                    if (isSessionEpired) {
                      history.push('/Login')
                    }
                    setAlert('')
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div> : null}
        {/*Failure Popup ends here */}
      </div>
    </div>
  );
};

export default AppSpecificGrid;