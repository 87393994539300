// PS_01, PS_02, PS_03, PS_04, PS_05, PS_06
import objCryptoJS from "crypto-js";
import React, { useEffect, useState, useRef } from 'react';
// import commonService from '/commonServices';
import { useHistory, useLocation } from 'react-router-dom';
import { sessionResponse } from '../../Common/Interface';
import { decodeSessionDetails } from '../../Services/CommonServices';
import { insertLastVisitedPageValueApi } from '../../Services/MigrationAndManageMigrationServices';
import { checkServiceAccount, deletePsservicedata, fetchPsDataApi } from "../../Services/psConfigServices"; //PS_AD-04
import { getSession } from '../../Services/manageClientAPI';
import jwt from 'jwt-decode';

interface PsServiceConfigurationProps {
    roleCheck: string;
    setRoleCheck: React.Dispatch<React.SetStateAction<string>>;
    psSourceSAC: boolean;
    setPsSourceSAC: React.Dispatch<React.SetStateAction<boolean>>;
    emailValid: boolean;
    setEmailValid: React.Dispatch<React.SetStateAction<boolean>>;
    emailTouched: boolean;
    setEmailTouched: React.Dispatch<React.SetStateAction<boolean>>;
    sourceDataError: {
        notification: string;
        sourceAccountEmail: string;
        sourceAccountPassword: string;
        targetAccountEmail: string;
        targetAccountPassword: string;
        sourceServiceAccount: string;
        targetServiceAccount: string;
        sourceServiceAppId: string;
        sourceServiceAppSecret: string;
        targetServiceAppId: string;
        targetServiceAppSecret: string;
        sourceServiceAccountLength: string,
        targetServiceAccountLength: string,
        sourceServiceAppLength: string;
        targetServiceAppLength: string;
        showPopupMessage: string;
        invalidEmailAddress: string;
        SourceAzureAppId: string;
        SourceAzureSecretId: string;
        emailMaxLengthError: string;
    };
    setsourceDataError: React.Dispatch<React.SetStateAction<{
        notification: string;
        sourceAccountEmail: string;
        sourceAccountPassword: string;
        targetAccountEmail: string;
        targetAccountPassword: string;
        sourceServiceAccount: string;
        targetServiceAccount: string;
        sourceServiceAppId: string;
        sourceServiceAppSecret: string;
        targetServiceAppId: string;
        targetServiceAppSecret: string;
        sourceServiceAccountLength: string,
        targetServiceAccountLength: string,
        sourceServiceAppLength: string;
        targetServiceAppLength: string;
        showPopupMessage: string;
        invalidEmailAddress: string;
        SourceAzureAppId: string;
        SourceAzureSecretId: string;
        emailMaxLengthError: string;
    }>>;
    sourceAccount: { email: string; password: string };
    setSourceAccount: React.Dispatch<React.SetStateAction<{ email: string; password: string }>>;
    sourceAccounts: { email: string; password: string; isNew: boolean; }[];
    setSourceAccounts: React.Dispatch<React.SetStateAction<any[]>>;
    targetAccounts: { email: string; password: string; isNew: boolean; }[];
    setTargetAccounts: React.Dispatch<React.SetStateAction<any[]>>;
    psTargetSAC: boolean;
    setPsTargetSAC: React.Dispatch<React.SetStateAction<boolean>>;
    handleSaveAndContinueAndInsert: (data: any) => Promise<void>;
    handleSaveAndContinueAndInsertTarget: () => void;
    nestedTab: string;
    mainNewTab: string;
    taskId : any;
    setAccountSaved?: React.Dispatch<React.SetStateAction<boolean>>
    optionalField?:boolean
}

interface InterfaceA {
    email: string;
    password: string;
    // ... any other properties
}
interface Props {
    isEdit: boolean;
}
const PsServiceConfiguration: React.FC<PsServiceConfigurationProps> = ({
    taskId,
    roleCheck,
    setRoleCheck,
    psSourceSAC,
    setPsSourceSAC,
    emailValid,
    setEmailValid,
    emailTouched,
    setEmailTouched,
    sourceDataError,
    setsourceDataError,
    sourceAccount,
    setSourceAccount,
    sourceAccounts,
    setSourceAccounts,
    targetAccounts,
    setTargetAccounts,
    psTargetSAC,
    setPsTargetSAC,
    handleSaveAndContinueAndInsert,
    handleSaveAndContinueAndInsertTarget,
    nestedTab,
    mainNewTab,
    setAccountSaved,
    optionalField
}) => {
    const location:any = useLocation()?.state;
    // PS_07, PS_08, PS_09, PS_10, PS_11, PS_12, PS_13, PS_14, PS_15, PS_16, PS_17, PS_18, PS_19, PS_20, 
    // PS_21, PS_22, PS_23, PS_24, PS_25, PS_26, PS_27, PS_28, PS_29, PS_30, PS_31, PS_32, PS_33, PS_34
    ////console.log('nestedTab:', nestedTab); // should log 'Source' or 'Target'
    ////console.log('mainNewTab:', mainNewTab); // should log 'PSservice'
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [alert, setAlert]= useState<boolean>(false);
    const [deletePsAccount, setdeletePsAccount] = useState<any[]>([]);
    const [deletePsTarAccount, setdeletePsTarAccount] = useState<any[]>([]);
    const [targetAccount, setTargetAccount] = useState<any>({ email: '', password: '' });
    const [deleteEmail, setDeleteEmail] = useState<string>('');
    const [deleteTab, setDeleteTab] = useState<string>('');
    const [psSource, setpsSource] = useState<boolean>(true);
    // const clientId = "2"
    const [index, setIndex] = useState<number>();
    const [DeletePopup, setDeletePopup] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false)
    const [hide, setHide] = useState<boolean>(false);

    // const isOpen = true;
    let history = useHistory()



    const [sourceDataErrorMessage, setsourceDataErrorMessage] = useState({
        notification: 'Email Address cannot be empty',
        sourceAccountEmail: 'Email Address cannot be empty',
        sourceAccountPassword: 'Password cannot be empty',
        targetAccountEmail: 'Email Address cannot be empty',
        targetAccountPassword: 'Password cannot be empty',
        sourceServiceAccount: 'Email Address cannot be empty',
        targetServiceAccount: 'Email Address cannot be empty',
        sourceServiceAppId: 'App client ID cannot be empty',
        sourceServiceAppSecret: 'Azure App Secret Value cannot be empty',
        targetServiceAppId: 'App client ID cannot be empty',
        targetServiceAppSecret: 'Azure App Secret Value cannot be empty'
    })
    const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
    const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
    const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
    const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [role, setRole] = useState('');

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());  
    const [isSessionEpired, setisSessionEpired] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string>('')
  
    let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);

    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    let applicationName1:any = location?.state?.applicationName;
    let environmentType2:any = location?.state?.environment
    const [applicationName, setApplicationName] = useState<string>(applicationName1);
    const [environmentType, setEnvironmentType] = useState<string>(environmentType2);

    // PS_35, PS_36
    
    useEffect(() => {
        sessionTokenProperties()
        // resetTimer();
        // window.addEventListener('keydown', resetTimer);
        // window.addEventListener('click', resetTimer);

        fetchDataPs()
        // return () => {
        //     window.removeEventListener('click', resetTimer);
        //     window.removeEventListener('keydown', resetTimer);
        //   }
    }, []);

    let resetTimer = async () => {
        try {
          debugger
          let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
          if (sessionResponse == 'expired') {
            debugger
            sessionStorage.removeItem("c_at");
            setisSessionEpired(true)
            setAlert(true)
            setAlertMessage('Your Session has Expired please login again to continue');
          }
          else if (sessionResponse == 'updateToken') {
            if (emailAddress == "" || emailAddress == undefined) {
              await sessionTokenProperties();
            }
            debugger
            let token = await getToken()
    
            let payload = {
              mailId: emailAddress,
              isAdmin: false,
              token: token
            };
            let getsessionToken = await getSession(payload);
            const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
            const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    
            const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
            const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
            let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
            await sessionStorage.removeItem("c_at");
            await sessionStorage.setItem('c_at', updatedToken);
            let updatedobjClient: any = jwt(c_decryptedValue);
            SessionEpiredDateTime = updatedobjClient.CreatedAt;
            setSessionEpiredDateTime(SessionEpiredDateTime);
          }
        }
        catch (error) {
          setLoader(false);
          setAlert(true)
          setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
        }
      }
    
      const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
          debugger
          if (typeof window !== 'undefined') {
            if (InActiveTimeout.current) {
              clearTimeout(InActiveTimeout.current);
            }
    
            const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);
    
            InActiveTimeout.current = setTimeout(() => {
              // console.log("Expired");          
              sessionStorage.removeItem("c_at");
              setisSessionEpired(true)
              setAlert(true)
              setAlertMessage('Your Session has Expired please login again to continue');
            }, tokenExpiryCheckInterval);
    
            const currentDateTime = new Date().toISOString();
            const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
            const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            console.log("minutes ps service : ", minutes);

            const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
    
            if (minutes > sessionUpdateTimeGap) {
              return 'updateToken'
            }
    
            return 'NoActionRequired'
          }
          return 'NoActionRequired'
        }
        catch (ex) {
          throw ex
        }
      };    

// PS_37, PS_38, PS_39, PS_40, PS_41, PS_42, PS_43, PS_44
    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: sessionResponse = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                else {
                    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
                        history.push("/Admin/Login")
                    }
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            setAlert(true);
            setLoader(false)

            console.error('Failed in main method', error);
        }
    };


    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin !== undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            setAlert(true);
            setLoader(false)

            throw error
        }
    }

    // Function to validate if an email is valid
    const isValidEmail = (email: string): boolean => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const maskPassword: any = (password: any) => {
        if (!password) return null;

        return '*'.repeat(password.length);
    }

    // PS_137, PS_138, PS_139, PS_140, PS_141, PS_142, PS_143
    const handleClick = (index: any, tab: string, email: string) => {
        try {
            ////console.log("handleclick inside");

            setIndex(index)
            setDeleteTab(tab)
            setDeleteEmail(email)
            setDeletePopup(true)
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    };

    // PS_144, PS_145, PS_146, PS_147, PS_148, PS_163, PS_164, PS_165, PS_166, PS_167, PS_168, 
    // PS_169, PS_170, PS_171, PS_186, PS_187, PS_188, PS_189, PS_190, PS_191, PS_192, PS_193
    const handleDeleteAccount = async (index: any, tab: string, email: string, mainNewTab: any) => {
        try {
            let token = await getToken()
if(mainNewTab=== 'assessment'){
    setDeletePopup(false);
    setLoader(true)
    const payload = {
        token: token,
        taskId:taskId,
        connectionType: "source",
        isPSAccount: 1,
        mailId: email
    };
    const response = await deletePsservicedata(payload);
    fetchDataPs();
    setLoader(false);
}
           else if (tab === 'Source') {
                setDeletePopup(false)
                if (sourceAccounts.length === 1) {
                    setDeletePopup(false)
                    setLoader(true)
                    const payload = {
                        token: token,
                        taskId:taskId,
                        connectionType: "source",
                        isPSAccount: 1,
                        mailId: email
                    };
                    const response = await deletePsservicedata(payload);
                    setpsSource(true)
                    setLoader(false)
                }
                const updatedAccounts = [...sourceAccounts];
                updatedAccounts.splice(index, 1);
                setSourceAccounts(updatedAccounts);
                setdeletePsAccount([...deletePsAccount, { email, connectionType: tab }])
            } else if (tab === 'Target') {
                setDeletePopup(false)
                if (targetAccounts.length === 1) {
                    setDeletePopup(false)
                    setLoader(true)
                    const payload = {
                        token : token,
                        taskId: taskId,
                        connectionType: "target",
                        isPSAccount: 1,
                        mailId: email
                    };
                    const response = await deletePsservicedata(payload);
                    setLoader(false)
                }
                const updatedAccounts = [...targetAccounts];
                updatedAccounts.splice(index, 1);
                setTargetAccounts(updatedAccounts);
                setdeletePsTarAccount([...deletePsTarAccount, { email, connectionType: tab }])

            }
        } catch (error) { 
            setAlert(true);
            setLoader(false)

        }

    };

    // PS_73, PS_74, PS_75, PS_76, PS_77, PS_78, PS_79, PS_102, PS_103, PS_104, PS_105, PS_106, PS_107, PS_108, PS_109, PS_110, PS_111, 
    // PS_112, PS_113, PS_129, PS_130, PS_131, PS_132, PS_133, PS_134, PS_135, PS_136
    const handleAddAccount = async (tab: string, mainNewTab: string) => {
        debugger
        try {
            let token = await getToken()
debugger
            if (tab === 'Source') {
                const trimmedEmail = sourceAccount?.email.trim();
                const trimmedPassword = sourceAccount?.password;
 
                if (emailValid && trimmedEmail && trimmedPassword) {
                    if (sourceAccounts.some(account => account.email === trimmedEmail) || targetAccounts.some(account => account.email === trimmedEmail)) {
                        setsourceDataError({
                            ...sourceDataError,
                            sourceAccountEmail: 'The entered Email Id is already exist',
                        });
                    } else {
                        setLoader(true);
 
                        let payload: any = {
                            taskId: taskId,
                            token : token,
                            username: trimmedEmail,
                            password: trimmedPassword,
                            type: "source",
                            application:applicationName
 
                        };
 
                        const result: any = await checkServiceAccount(payload);
                        setLoader(false);
                        //console.log(result,"result of service acc");
                       
                        // if(result.data.statusCode===200){
                            // if (mainNewTab === 'assessment') {
                            //     handleSaveAndContinueAndInsert(payload);
                            // }
                        // }
                       
 
                        if (result?.data?.statusCode === 210 || result?.data?.statusCode === 211) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Enter valid credentials',
                            });
                            setShowPopup(true);
                            setSourceAccount({ email: '', password: '' });
                            return;
                        }  else  if ( result?.data?.statusCode === 220) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Account already exists',
                            });
                            setShowPopup(true);
                            setSourceAccount({ email: '', password: '' });
                            return;
                        } else
                         if (result?.data?.statusCode === 201) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Enter valid admin credentials',
                            });
                            setShowPopup(true);
                            setSourceAccount({ email: '', password: '' });
                            return;
                        } else if (result?.data?.statusCode === 213 || result?.data?.statusCode === 205 || result?.data?.statusCode === 206) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Please provide valid scopes',
                            });
                            setShowPopup(true);
                            setSourceAccount({ email: '', password: '' });
                            return;
                        } else{
                            if (mainNewTab === 'assessment') {
                                handleSaveAndContinueAndInsert(payload);
                            }
                        }
 
                        setSourceAccounts([...sourceAccounts, { email: trimmedEmail, password: trimmedPassword, isNew: true }]);
                        setSourceAccount({ email: '', password: '' });
                        setsourceDataError({ ...sourceDataError, sourceAccountEmail: '', sourceAccountPassword: '', showPopupMessage: '' });
                        setEmailValid(false);
                        setShowPopup(false);
                        if(setAccountSaved){
                            setAccountSaved(false);
                        }
                        
                        ////console.log(sourceAccounts,"sourceAccountsLogggggggggggggggggggg");
                       
 
                    }
                    ////console.log(sourceAccounts,"sourceAccountsLogggggggggggggggggggg");
 
                } else {
                    setsourceDataError({
                        ...sourceDataError,
                        sourceAccountEmail: trimmedEmail ? '' : 'Email Address cannot be empty',
                        sourceAccountPassword: trimmedPassword ? '' : 'Password cannot be empty'
                    });
                }
 
 
 
            } else if (tab === 'Target') {
                const trimmedEmail = targetAccount?.email.trim();
                const trimmedPassword = targetAccount?.password;
 
                if (emailValid && trimmedEmail && trimmedPassword) {
                    if (targetAccounts.some(account => account.email === trimmedEmail) || sourceAccounts.some(account => account.email === trimmedEmail)) {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: 'The entered Email Id is already exist',
                        });
                    } else {
                        setLoader(true);
 
                        let payload: any = {
                            taskId: taskId,
                            token : token,
                            username: trimmedEmail,
                            password: trimmedPassword,
                            type: "targetPSAccount",
                            application:applicationName
                        };
 
                        const result = await checkServiceAccount(payload);
                        setLoader(false);
 
                        if (result?.data?.statusCode === 210 || result?.data?.statusCode === 211) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Enter valid credentials',
                            });
                            setShowPopup(true);
                            setTargetAccount({ email: '', password: '' });
                            return;
                        }  else  if ( result?.data?.statusCode === 220) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Account already exists',
                            });
                            setShowPopup(true);
                            setTargetAccount({ email: '', password: '' })
                            return;
                        } else if (result?.data?.statusCode === 201) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Enter valid admin credentials',
                            });
                            setShowPopup(true);
                            setTargetAccount({ email: '', password: '' });
                            return;
                        } else if (result?.data?.statusCode === 213 || result?.data?.statusCode === 205 || result?.data?.statusCode === 206) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Please provide the required scopes',
                            });
                            setShowPopup(true);
                            setTargetAccount({ email: '', password: '' });
                            return;
                        }
 
                        setTargetAccounts([...targetAccounts, { email: trimmedEmail, password: trimmedPassword, isNew: true }]);
                        setTargetAccount({ email: '', password: '' });
                        setsourceDataError({ ...sourceDataError, targetAccountEmail: '', targetAccountPassword: '' });
                        setEmailValid(false);
                        setShowPopup(false);
                        // debugger;
                        // if (mainNewTab === 'assessment') {
                        //     handleSaveAndContinueAndInsert(payload);
                        // }
                    }
                } else {
                    setsourceDataError({
                        ...sourceDataError,
                        targetAccountEmail: trimmedEmail ? '' : sourceDataErrorMessage.targetAccountEmail,
                        targetAccountPassword: trimmedPassword ? '' : sourceDataErrorMessage.targetAccountPassword
                    });
                }
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)
 
         }
    };

    const insertLastVisitedPageValue = async (data: any) => {
        try {
            setLoader(true)
            let token = await getToken()

            const payload = {
                token: token,
                taskId: taskId,
                lastVisitedValue: data
            };

            // Insert only newly entered records into the database
            const response: any = await insertLastVisitedPageValueApi(payload);
setLoader(false)
            if (!response) {
                ////console.log('The current page value failed to insert');
            } else {
                ////console.log('The current page value inserted successfully');

            }
        } catch (error) {
            console.error('Error inserting records:', error);
        }
    };
    // PS_45, PS_46, PS_47, PS_48, PS_49, PS_50, PS_51, PS_52, PS_53, PS_54, PS_55, PS_56, PS_57, PS_58, PS_59, PS_60, PS_61, PS_62, PS_63, 
    // PS_64, PS_65, PS_66, PS_67, PS_68, PS_69, PS_70, PS_71, PS_72
    const handleInputChange = (e: any, tab: string) => {
        try {
            
            const { name, value } = e.target;
            if (tab === 'Source') {
                if (name === 'email') {

                    setEmailValid(isValidEmail(value.trim())); // Validate email
                    if (value.trim() === '') {
                        setsourceDataError({
                            ...sourceDataError,
                            sourceAccountEmail: 'Email Address cannot be empty',
                        });
                    } else if (value.trim().length > 320) {
                        setsourceDataError({
                            ...sourceDataError,
                            sourceAccountEmail: 'Maximum allowed charcters reached',
                        });
                    }
                    else if(targetAccounts.some(account => account.email.toLowerCase() === value.trim().toLowerCase())){
                        setsourceDataError({
                            ...sourceDataError,
                            sourceAccountEmail: 'Source and Target PS service account domain name should not be same',
                        });
                    }
                    else if (sourceAccounts.some(account => account.email.toLowerCase() === value.trim().toLowerCase())) {

                        setsourceDataError({
                            ...sourceDataError,
                            sourceAccountEmail: 'The entered Email Id is already exist',
                        });
                    }
                    else {
                        setsourceDataError({
                            ...sourceDataError,
                            sourceAccountEmail: '',
                        });
                    }
                } else if (name === 'password') {
                    if (value === '') {
                        setsourceDataError({
                            ...sourceDataError,
                            sourceAccountPassword: 'Password cannot be empty',
                        });
                    }
                    else if (value.length < 8) {
                        setsourceDataError({
                            ...sourceDataError,
                            sourceAccountPassword: 'Minimum eight characters are required',
                        });
                    } else {
                        setsourceDataError({
                            ...sourceDataError,
                            sourceAccountPassword: '',
                        });
                    }
                }
                setSourceAccount({ ...sourceAccount, [name]: value });
            }
            else if (tab === 'Target') {

                if (name == 'email') {
                    setEmailValid(isValidEmail(value.trim())); // Validate email
                    if (value.trim() === '') {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: 'Email Address cannot be empty',
                        });
                    }
                    else if (value.trim().length > 320) {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: 'Maximum allowed charcters exceeded',
                        });
                    }
                    else if(targetAccounts.some(account => account.email.toLowerCase() === value.trim().toLowerCase())){
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: 'The entered Email Id is already exist',
                        });
                    }
                    else if (sourceAccounts.some(account => account.email.toLowerCase() === value.trim().toLowerCase())) {

                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: 'Source and Target PS service account domain name should not be same',
                        });
                    }
                    else {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: '',
                        });
                    }
                } else if (name == 'password') {
                    // You can add additional validation logic for password if needed
                    if (value === '') {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountPassword: 'Password cannot be empty',
                        });
                    }

                    else if (value.length < 8) {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountPassword: 'Minimum eight characters are required',
                        });
                    } else {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountPassword: '',
                        });
                    }
                }


                setTargetAccount({ ...targetAccount, [name]: value });
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

         }
    };


    const fetchDataPs = async () => {
        try {
            let token = await getToken()

            setLoader(true)
            const payload = {
                token : token,
                taskId: taskId,
            }
            const response = await fetchPsDataApi(payload);
            let result = response.data.data;
            ////console.log(result, "result of ps service fetch");
            
            setLoader(false)
            if (result) {
                ////console.log("inside if");

                // Separate accounts based on connectionType
                const sourceData = result.filter((account: { connectionType: string; }) => account.connectionType === 'source');
                ////console.log(sourceData, "sourcedata");

                const sourceAccountsData = sourceData.map((account: { serviceAccountMailId: any; password: any; }) => ({
                    email: account.serviceAccountMailId,
                    password: account.password,
                    isNew: false
                }));
                ////console.log(sourceAccountsData, "sourceAccountsData");

                setSourceAccounts(sourceAccountsData);

                const targetData = result.filter((account: { connectionType: string; }) => account.connectionType === 'target');
                const targetAccountsData = targetData.map((account: { serviceAccountMailId: any; password: any; }) => ({
                    email: account.serviceAccountMailId,
                    password: account.password,
                    isNew: false
                }));
                setTargetAccounts(targetAccountsData);

            }
            return result;
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

            ////console.log("error in fetch ps");

        }
    };


    return (
        <div className="col-md-12 mt-4 mt-md-5">
            <div className="tab-content" id="pills-tabContent">
                {nestedTab === 'Source' && (mainNewTab === 'psservice' || mainNewTab === 'assessment') ? (
                    <div className="tab-pane fade show active" id="Source" role="tabpanel">
                        <h5 className="font-20 font-bold mb-2">
                            PS Service Account
                            {optionalField ? null : <span className="mandatory-color ms-1"> * </span>}
                        </h5>
                        <p className="font-14 font-regular">
                            The PowerShell Service Account field necessitates secure credentials for executing elevated PowerShell commands within the system.
                            
                        </p>
                        <div className="mt-4 table-responsive theme-table bg-white">
                            <table className="table table-borderless table-shadow mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap" style={{boxSizing:'border-box', width:'40%',maxWidth:'40%'}}>Email Address</th>
                                        <th className="text-nowrap">Password</th>
                                        <th className="text-nowrap text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='align-top'>
                                            <input
                                                disabled={roleCheck.toLowerCase().includes('viewer')}
                                                type="text"
                                                className="form-control custom-form font-14 font-regular"
                                                placeholder="Enter Email Address"
                                                name="email"
                                                value={sourceAccount.email}
                                                onChange={(e) => handleInputChange(e, 'Source')}
                                                onBlur={() => setEmailTouched(true)}
                                                maxLength={320}
                                            />
                                            {sourceDataError.sourceAccountEmail !== "" && (
                                                <span className="mandatory-color fixedError">{sourceDataError.sourceAccountEmail}</span>
                                            )}                                            
                                            {emailTouched && !emailValid && sourceAccount.email && (sourceDataError.sourceAccountEmail === '') && (
                                                <span className="mandatory-color fixedError">{sourceDataError.invalidEmailAddress}</span>
                                            )}
                                            {sourceDataError.invalidEmailAddress === "" && sourceDataError.emailMaxLengthError !== "" && (
                                                <span className="mandatory-color fixedError">{sourceDataError.emailMaxLengthError}</span>
                                            )}
                                        </td>
                                        <td className='align-top'>
                                        <div className="position-relative">
                                            <input
                                                disabled={roleCheck.toLowerCase().includes('viewer')}
                                                type={hide ? "text" : "password"}
                                                className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                                placeholder="Enter Password"
                                                name="password"
                                                value={sourceAccount.password}
                                                onChange={(e) => handleInputChange(e, 'Source')}
                                            />
                                            
                                            <img
                                                        className={`position-absolute ${hide ? 'custom-open-eye-postion' : 'custom-open-eye-postion'}`}
                                                        src={`/images/${hide ? `password-eye-open.svg` : `password-icon.svg`}`}
                                                        alt="password-icon"
                                                        onClick={() => { setHide(!hide); }}

                                                    />
                                            {/* {sourceDataError.sourceAccountPassword !== "" && ( */}
                                                <span className="mandatory-color fixedError passExpansion">{sourceDataError.sourceAccountPassword}</span>
                                            {/* )}                                                */}
                                        </div>   
                                        </td>
                                        <td className="text-center" style={{verticalAlign:'top', paddingTop:'1.3rem'}}>
                                                <span className="d-inline-block">
                                                <button 
    disabled={
        roleCheck.toLowerCase().includes('viewer') ||
        !sourceAccount?.email?.trim() ||
        !sourceAccount?.password?.trim() ||
        !emailValid ||
        !!sourceDataError.sourceAccountEmail ||
        !!sourceDataError.sourceAccountPassword
    }
    onClick={(e) => { handleAddAccount('Source', mainNewTab); setPsSourceSAC(false) }} 
    className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center"
>
    <img src="/images/blue-plus-icon.svg" alt="blue-plus-icon" />
</button>

                                                </span>
                                        </td>
                                    </tr>
                                    {sourceAccounts.map((acc, index) => (
                                        <tr key={index}>
                                            <td>
                                                <span data-bs-toggle="tooltip" data-bs-placement="right" title={acc.email} style={{ cursor: 'pointer' }} className="text-truncate d-inline-block custom-text-width">
                                                    {/* {acc.email.length > 22 ? `${acc.email.slice(0, 22)}...` : acc.email} */}
                                                    {acc.email}
                                                </span>
                                            </td>
                                            <td>{maskPassword(acc.password)}</td>
                                            <td className="text-center">
                                                <span className='d-inline-block'>
                                                    <button onClick={() => handleClick(index, 'Source', acc.email)} disabled={roleCheck.toLowerCase().includes('viewer')} className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                                        <img src="/images/red-delete-icon.svg" alt="delete-icon" />
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {mainNewTab.toLowerCase() !== 'assessment' && (
                            <div className="d-flex justify-content-end mt-3">
                                <button
                                    disabled={(sourceAccounts.length === 0 || psSourceSAC === true) || roleCheck.toLowerCase().includes('viewer')}
                                    onClick={handleSaveAndContinueAndInsert}
                                    className="btn primary-btn text-nowrap font-semibold text-white font-14 d-flex gap-2 justify-content-center align-items-center"
                                >
                                    Save & Continue
                                </button>
                            </div>
                        )}
                    </div>
                ) : null}
                {nestedTab === 'Target' && mainNewTab === 'psservice' ? (
                    <div className="tab-pane fade show active" id="Target" role="tabpanel">
                        <h5 className="font-20 font-bold mb-2">
                            {environmentType!='googleworkspace'?`PS Service Account`:`M365 - PS Service Account`}
                            <span className="mandatory-color ms-1">*</span>
                        </h5>
                        <p className="font-14 font-regular">
                            The PowerShell Service Account field necessitates secure credentials for executing elevated PowerShell commands within the system
                        </p>
                        <div className="mt-4 table-responsive theme-table bg-white">
                            <table className="table table-borderless table-shadow mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap" style={{boxSizing:'border-box', width:'40%',maxWidth:'40%'}}>Email Address</th>
                                        <th className="text-nowrap">Password</th>
                                        <th className="text-nowrap text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='align-top'>
                                            <input
                                                disabled={roleCheck.toLowerCase().includes('viewer')}
                                                type="text"
                                                className="form-control custom-form font-14 font-regular"
                                                placeholder="Enter Email Address"
                                                name="email"
                                                value={targetAccount.email}
                                                onChange={(e) => handleInputChange(e, 'Target')}
                                                onBlur={() => setEmailTouched(true)}
                                                maxLength={320}
                                            />
                                            <span className="mandatory-color fixedError">{sourceDataError.targetAccountEmail}</span>
                                            {emailTouched && !emailValid && targetAccount.email && (sourceDataError.targetAccountEmail === '') && (
                                                <span className="mandatory-color fixedError">{sourceDataError.invalidEmailAddress}</span>
                                            )}
                                        </td>
                                        <td className='align-top'>
                                        <div className="position-relative">
                                            <input
                                                disabled={roleCheck.toLowerCase().includes('viewer')}
                                                type={hide ? "text" : "password"}
                                                className="form-control password-eye-hide custom-form font-14 font-regular py-2"

                                                placeholder="Enter Password"
                                                name="password"
                                                value={targetAccount.password}
                                                onChange={(e) => handleInputChange(e, 'Target')}
                                            />
                                                     <img
                                                        className={`position-absolute ${hide ? 'custom-open-eye-postion' : 'custom-postion'}`}
                                                        src={`/images/${hide ? `password-eye-open.svg` : `password-icon.svg`}`}
                                                        alt="password-icon"
                                                        onClick={() => { setHide(!hide); }}

                                                    />
                                            <span className="mandatory-color fixedError passExpansion">{sourceDataError.targetAccountPassword}</span>
                                       </div>
                                        </td>
                                        <td className="text-center" style={{verticalAlign:'top', paddingTop:'1.3rem'}}>
                                            <span className="d-inline-block">
  <button 
  disabled={
      roleCheck.toLowerCase().includes('viewer') ||
      !targetAccount?.email?.trim() ||
      !targetAccount?.password?.trim() ||
      !emailValid ||
      targetAccount?.password?.length === 0 ||
      !!sourceDataError.targetAccountEmail ||
      !!sourceDataError.targetAccountPassword
  }
  onClick={(e) => { handleAddAccount('Target', mainNewTab); setPsTargetSAC(false) }} 
  className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center"
>
  <img src="/images/blue-plus-icon.svg" alt="blue-plus-icon" />
</button>
                                            </span>
                                        </td>
                                    </tr>
                                    {targetAccounts.map((acc, index) => (
                                        <tr key={index}>
                                            <td>
                                                <span data-bs-toggle="tooltip" data-bs-placement="right" title={acc.email} style={{ cursor: 'pointer' }}>
                                                    {acc.email.length > 22 ? `${acc.email.slice(0, 22)}...` : acc.email}
                                                </span>
                                            </td>
                                            <td>{maskPassword(acc.password)}</td>
                                            <td className="text-center">
                                                <span className='d-inline-block'>
                                                    <button onClick={() => handleClick(index, 'Target', acc.email)} disabled={roleCheck.toLowerCase().includes('viewer')} className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                                        <img src="/images/red-delete-icon.svg" alt="delete-icon" />
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                        <button
    disabled={targetAccounts.length === 0 || psTargetSAC === true}
    onClick={() => {
        handleSaveAndContinueAndInsertTarget();
        insertLastVisitedPageValue("GeneralConfigurations-Migration")
    
    }}
    className="btn primary-btn text-nowrap font-semibold text-white font-14 d-flex gap-2 justify-content-center align-items-center"
>
    Save & Continue
</button>
                        </div>
                    </div>
                ) : null}
            </div>
            {loader && (
    <div className="overlay overlay-front">
      <div className="position-absolute top-50 start-50 translate-middle">
        <div
          className="spinner-border Loader loader-color align-center "
          role="status"
        >
          <span>
            <span className="loader-inner-circle" />
          </span>
        </div>
        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
          Loading..
        </p>
      </div>
    </div>

  )}

            {showPopup === true ? (
                <div className="modal fade show" id="Failure-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Failure-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>

                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content popup-brd-radius">

                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                <span className="position-absolute">

                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                </span>

                            </div>

                            <div className="modal-body border-0 text-center">

                                <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Failure</h5>

                                <p className="primary-data">{isSessionEpired ? alertMessage : sourceDataError.showPopupMessage}</p>

                            </div>

                            <div className="modal-footer border-0 pb-4 justify-content-center">

                                <button type="button" onClick={() => 
                                    {
                                        if (isSessionEpired) {
                                            history.push('/Login')
                                        }
                                        setShowPopup(false)
                                    }} className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">{isSessionEpired ? "OK" : "Retry"}</button>

                            </div>

                        </div>

                    </div>

                </div>) : null}
            {DeletePopup == true ? (
                <div
                    className="modal fade show"
                    id="Failure-popup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1} // Change this line
                    aria-labelledby="Failure-popupLabel"
                    aria-modal="true"
                    role="dialog"
                    style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content popup-brd-radius">

                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                <span className="position-absolute">

                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                </span>

                            </div>

                            <div className="modal-body border-0 text-center">

                                <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Alert</h5>

                                <p className="primary-data">Are you sure you want to Delete?</p>

                            </div>

                            <div className="modal-footer border-0 pb-4 justify-content-center">

                                <button type="button" onClick={() => {
                                      handleDeleteAccount(index, deleteTab, deleteEmail, mainNewTab)
                                }}
                                    className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">Yes</button>
                                <button type="button" onClick={() => {
                                    setDeletePopup(false)

                                }}
                                    className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">No</button>

                            </div>

                        </div>

                    </div>

                </div>) : null}
                
        </div>

    )

};

export default PsServiceConfiguration;