import Tippy from '@tippyjs/react';
import objCryptoJS from "crypto-js";
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { followCursor } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import * as XLSX from 'xlsx';
import { commonErrorMsgs } from '../../Common/Constant';
import { ViewMigrationGridProps } from '../../Common/Interface';
import { getOutlookData, getViewMigrationData, updateMailUserAction } from '../../Services/MigrationReportServices';
import { getSession } from '../../Services/manageClientAPI';
import { decodeSessionDetails } from '../../Services/CommonServices';
import jwt from 'jwt-decode';
import Loader from '../Common/Loader';

const ViewMigrationGrid: React.FC<ViewMigrationGridProps> = ({
  filterAction,
  searchAction,
  exportAction,
  setShouldExport,
  taskId,
  taskName,
  updateItemCount,
  role,
  adminRole,
  applicationName,
  resourceType,
  render
}) => {
  /**PS_40 to PS_70 will be executed*/
  const history = useHistory()

  /**PS_01 to PS_21 will be executed*/
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})

  const [alert, setAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')

  const [sortBy, setSortBy] = useState(
    applicationName === 'outlook' ? 'uds.displayName' :
      applicationName === 'teamschannel' ? 'ud_source.SourceChannelName' :
        applicationName === 'sharepoint' ? 'ud_source.SourceSPSiteName' :
          'ud_source.displayName'
  );

  const [sortOrder, setSortOrder] = useState("desc");
  const [sortHide, setSortHide] = useState<string>('');

  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [isFetching, setIsFetching] = useState(false)

  const [page, setPage] = useState<number>(10);
  const [loader, setLoader] = useState<boolean>(false);

  const [getData, setGetData] = useState<any[]>([]);
  const [noRecords, setNoRecords] = useState<string>('No Records Found');

  const [lastExportTimestamp, setLastExportTimestamp] = useState(0);

  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let [clientName, setclientName] = useState<string>('');
  let [companyName, setCompanyName] = useState<string>('');
  let [emailAddress, setEmailAddress] = useState<string>('');
  let [isAdmin, setIsAdmin] = useState<boolean>(false);
  let [adminName, setAdminName] = useState<string>('');
  let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
  let [adminrole, setAdminRole] = useState<string>('');

  let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);

  const [disableActions, setDisableActions] = useState(false);

  let bindingObject: any = {
    outlook: [
      resourceType.toLowerCase() !== 'shared' ? {
        header: "Source User Name",
        value: "SourceDisplayName",
        sort: "uds.displayName"
      } : {
        header: "Source Shared Mailbox Name",
        value: "SourceDisplayName",
        sort: "uds.displayName"
      },
      resourceType.toLowerCase() !== 'shared' ? {
        header: "Target User Name",
        value: "TargetDisplayName",
        sort: "udt.displayName"
      } :
        {
          header: "Target Shared Mailbox Name",
          value: "TargetDisplayName",
          sort: "udt.displayName"
        },
      {
        header: "Overall Progress",
        value: "totalCount",
        sort: "os.totalCount",
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "umm.statusText",
      },
      {
        header: "Action",
        value: "Status",
        sort: null,
      },
    ],
    sharepoint: [
      {
        header: "Source Site Name",
        value: "SourceDisplayName",
        sort: "ud_source.SourceSPSiteName"
      },
      {
        header: "Source Site URL",
        value: "sourceSiteURL",
        sort: "ud_source.SourceSPSiteURL"
      },
      {
        header: "Target Site Name",
        value: "TargetDisplayName",
        sort: "ud_target.targetSPSiteName"
      },
      {
        header: "Target Site URL",
        value: "targetSiteURL",
        sort: "ud_target.TargetSPSiteURL"
      },
      {
        header: "Overall Progress",
        value: "totalCount",
        sort: "ms.migratedCount"
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "ms.statusText",
      },
      {
        header: "Action",
        value: "Status",
        sort: null,
      },
    ],
    teamschannel: [
      {
        header: "Source Team Name",
        value: "sourceTeamName",
        sort: "ud_source.SourceTeamName"
      },
      {
        header: "Source Channel Name",
        value: "sourceChannelName",
        sort: "ud_source.SourceChannelName"
      },
      {
        header: "Target Team Name",
        value: "targetTeamName",
        sort: "ud_target.TargetTeamName"
      },
      {
        header: "Target Channel Name",
        value: "targetChannelName",
        sort: "ud_target.TargetChannelName"
      },
      {
        header: "Overall Progress",
        value: "totalCount",
        sort: "ms.migratedCount"
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "umm.StatusText",
      },
      {
        header: "Action",
        value: "Status",
        sort: null,
      },
    ],
    gmail: [
      {
        header: "Source User Name",
        value: "sourceDisplayName",
        sort: "ud_source.displayName"
      },
      {
        header: "Target User Name",
        value: "targetDisplayName",
        sort: "ud_target.displayName"
      },
      {
        header: "Overall Progress",
        value: "migratedCount",
        sort: "ms.migratedCount",
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "ms.statusText",
      },
      {
        header: "Action",
        value: "Status",
        sort: null,
      },
    ],
    mydrive: [
      {
        header: "Source User Name",
        value: "SourceDisplayName",
        sort: "ud_source.displayName"
      },
      {
        header: "Target User Name",
        value: "TargetDisplayName",
        sort: "ud_target.displayName"
      },
      {
        header: "Overall Progress",
        value: "migratedCount",
        sort: "ms.migratedCount",
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "ms.statusText",
      },
      {
        header: "Action",
        value: "Status",
        sort: null,
      },
    ],
    onedrive: [
      {
        header: "Source User Name",
        value: "SourceDisplayName",
        sort: "ud_source.displayName"
      },
      {
        header: "Target User Name",
        value: "TargetDisplayName",
        sort: "ud_target.displayName"
      },
      {
        header: "Overall Progress",
        value: "migratedCount",
        sort: "ms.migratedCount",
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "ms.statusText",
      },
      {
        header: "Action",
        value: "Status",
        sort: null,
      },
    ],
    groups: [
      {
        header: "source Group Name",
        value: "sourceGroupName",
        sort: "sourceGroupName",
      },
      {
        header: "target Group Name",
        value: "targetGroupName",
        sort: "targetGroupName",
      },
      {
        header: "Overall Progress",
        value: "overAllProgress",
        sort: "overAllProgress",
      },
      {
        header: "Overall Status",
        value: "userMappingActions",
        sort: "userMappingActions",
      },
      {
        header: "Action",
        value: "Status",
        sort: null,
      },
    ],
  };

  const getApplicationDisplayName = (applicationName: string): string => {
    const nameMap: { [key: string]: string } = {
      outlook: "User Mailbox",
      groups: "Groups",
      sharedmailbox: "Shared Mailbox",
      roomsandequipments: "Rooms & Equipment's",
      teamschannel: "Teams Channel",
      teamschat: "Teams Chat",
      onedrive: "One Drive",
      sharepoint: "SharePoint",
      gmail: "Gmail",
      mydrive: "My Drive",
      shareddrive: "Shared Drive",
      ggroups: "Groups",
      sites: "Sites",
      chats: "Chats",
      sharedDrive: "Shared Drive"
    };

    return nameMap[applicationName.toLowerCase()] || applicationName;
  };

  useEffect(() => {
    sessionTokenProperties();
    resetTimer();
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    if (searchAction.value.length === 0) {
      //console.log("enter 1");

      fetchData()
    }
    else if (searchAction.value.length > 0 && searchAction?.value.trim() != '') {
      //console.log("enter 2");

      fetchData()
    }

    if (!isFetching) {
      const intervalId = setInterval(() => {
        fetchData()
      },
        100000000
      );

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
    return () => {
      if (InActiveTimeout.current) {
        console.log("View Migration Child Unmount Time Clear");
        clearTimeout(InActiveTimeout.current);
      }
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    }
  }, [sortBy, sortOrder, sortHide, page, filterAction, searchAction, render]);

  useEffect(() => {
    if (exportAction) {
      setShouldExport(false)
      handleExport();
    }
  }, [exportAction]);

  let resetTimer = async () => {
    try {
      debugger
      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert(true)
        setAlertMessage('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()

        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        // const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        // const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        // let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', getsessionToken?.data?.data.sessionToken);
        await sessionTokenProperties();
        // let updatedobjClient: any = jwt(c_decryptedValue);
        // SessionEpiredDateTime = updatedobjClient.CreatedAt;
        // setSessionEpiredDateTime(SessionEpiredDateTime);
      }
    }
    catch (error) {
      setLoader(false);
      setAlert(true)
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      debugger
      if (typeof window !== 'undefined') {
        if (InActiveTimeout.current) {
          clearTimeout(InActiveTimeout.current);
        }

        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout.current = setTimeout(() => {
          // console.log("Expired");          
          sessionStorage.removeItem("c_at");
          setisSessionEpired(true)
          setAlert(true)
          setAlertMessage('Your Session has Expired please login again to continue');
        }, tokenExpiryCheckInterval);

        const currentDateTime = new Date().toISOString();
        //const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        const diffInMilliseconds = Math.abs(new Date(currentDateTime).getTime() - new Date(SessionEpiredDateTime).getTime());
        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);

        if (minutes > sessionUpdateTimeGap) {
          return 'updateToken'
        }

        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      throw ex
    }
  };

  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.adminDetails) {
        setObjAdmin(tokenResponse.adminDetails)
        setAdminName(tokenResponse.adminDetails.Name);
        setAdminEmailAddress(tokenResponse.adminDetails.Email);
        setAdminRole(tokenResponse.adminDetails.Role);

        if (tokenResponse?.adminDetails?.Role.toLowerCase().includes('viewer')) {
          setDisableActions(true)
        }
      }

      if (tokenResponse.clientDetails) {
        setObjClient(tokenResponse.clientDetails)
        setclientName(tokenResponse.clientDetails.Name);
        setCompanyName(tokenResponse.clientDetails.CompanyName)
        emailAddress = tokenResponse.clientDetails.Email;
        setEmailAddress(emailAddress);
        // setRole(tokenResponse.clientDetails.Role);

        if (tokenResponse?.clientDetails?.Role.toLowerCase().includes('viewer')) {
          setDisableActions(true)
        }

        SessionEpiredDateTime = tokenResponse.clientDetails.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      else {
        history.push("/Login")
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };
  // See steps 1.22 to 1.38 for the actual logic to be run on page load and component rendering
  const getStatusLabel = (action: any) => {
    switch (action) {
      case "0":
        return "Warning";
      case "1":
        return "Paused";
      case "2":
        return "Cancelled";
      case "3":
        return "Scheduled";
      case "4":
        return "Queue";
      case "5":
        return "In Progress";
      case "6":
        return "Completed";
      case "7":
        return "Failed";
      default:
        return "Unknown";
    }
  };


  /**PS_80 to PS_88 will be executed */
  function formatSize(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = Math.floor(Math.log(bytes) / Math.log(k));

    // Limit the index to 8 if it exceeds YB
    if (i > 8) i = 8;

    // Calculate the size
    const size = bytes / Math.pow(k, i);

    return `${size.toFixed(dm)} ${sizes[i]}`;
  }

  /*PS_135 to PS_139 will be executed*/
  const handleSort = (e: any) => {
    try {
      debugger;
      let sortID: string = (e.target as HTMLElement).id;
      let sortval: string[] = sortID.split("@");
      setSortBy(sortval[0]);
      setSortOrder(sortval[1]);
      setSortHide(sortID);
      //setSearchValue("");
    } catch (e: any) {
      ////console.log(e.message);
    }
  };

  const handleExport = () => {
    try {
      setLoader(true);
      const application = resourceType?.toLowerCase() == 'onedrive' ? 'onedrive' : applicationName?.toLowerCase();
      const bindingConfig = bindingObject[application];
      if (!bindingConfig) {
        throw new Error(`No configuration found for application: ${applicationName}`);
      }

      const filteredData = getData.map((item: any) => {
        const rowData: { [key: string]: any } = {};
        bindingConfig.forEach((config: any) => {
          if (config.header !== "Action") {
            let value = item;
            config.value.split('.').forEach((key: string) => {
              value = value && value[key];
            });
            rowData[config.header] = value;
          }
        });
        return rowData;
      });

      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Migration Summary");

      const displayName = getApplicationDisplayName(resourceType == 'shared' ? 'sharedmailbox' : application);
      const fileName = `${displayName}_Migration_Report.xlsx`;
      XLSX.writeFile(workbook, fileName);
    } catch (error: any) {
      setAlert(true);
      setAlertMessage('Something went wrong while generating the report. Please try again or reach AVAMIGRATRON Support.');
      console.error("Error exporting data:", error.message);
    } finally {
      setLoader(false);
    }
  };

  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = sessionToken
        }
      }

      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = sessionToken
        }
      }

      return token
    } catch (error) {
      throw error
    }
  }

  async function fetchData() {
    try {
      let sessionToken = await getToken();
      setLoader(true)
      debugger
      if (applicationName === 'outlook' && (resourceType == 'user' || resourceType == 'shared')) {
        const payload = {
          token: sessionToken,
          taskId: taskId,
          status: filterAction.value != '' ? Number(filterAction.value) : 100,
          searchValue: searchAction.value.trim(),
          sortBy: sortBy,
          sortOrder: sortOrder,
          pageSize: page,
          resourceType: resourceType
        };
        console.log("the payload outlook 2", payload);

        const result: any = await getOutlookData(payload)

        if (result?.data?.statusCode == 200) {
          setLoader(false)
          setTotalDataCount(result?.data?.data?.totalDataCount)
          setGetData(result?.data?.data?.bindingData)
          console.log(getData, "getdata ra");

          updateItemCount(result?.data?.data?.totalDataCount);
        }
        else {
          setLoader(false)
          setAlert(true)
          setAlertMessage(commonErrorMsgs.generalError)
        }
      }
      else {
        debugger
        const payload = {
          token: sessionToken,
          taskId: taskId,
          applicationName: applicationName == 'outlook' ? resourceType : applicationName,
          searchValue: searchAction.value,
          sortBy: sortBy,
          sortOrder: sortOrder,
          filterValue: filterAction.value,
          pageSize: page,
          offset: 0
        };
        console.log("the payload outlook", payload);

        setLoader(true);

        const result: any = await getViewMigrationData(payload)
        setLoader(false);

        setLoader(false);

        console.log(result?.data?.data.slice(1), "this is the other resultttttt");

        if (result?.data?.statusCode == 200) {
          setLoader(false)
          setTotalDataCount(result?.data?.data[0].totalDataCount)
          setGetData(result?.data?.data.slice(1));
          console.log(getData, "nene ra nee yenkamma");

          updateItemCount(result?.data?.data[0].totalDataCount);

          if (result?.data?.statusMessage === 'No Result Found') {
            setNoRecords('No Result Found')
          }
        }
        else {
          setLoader(false)
          setAlert(true)
          setAlertMessage(commonErrorMsgs.generalError)
        }
      }
    }
    catch (error) {
      setLoader(false)
      //console.log(error, "Error in fetching data")
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    }
  }

  async function handleActionButtonClick(item: any, action: any, isAdmin = false) {
    // debugger
    try {
      if (!disableActions) {
        let token = await getToken();

        if (action === '2' && action === '3' && item?.scheduleMigrationDateTime && !isAdmin) {
          ////console.log("Hello there");
          let currentDateTimeUTC = moment.utc(); // Current UTC time
          ////console.log("🚀 ~ item?.scheduleMigrationDateTime:", item?.scheduleMigrationDateTime)
          ////console.log("🚀 ~ handleActionButtonClick ~ currentDateTimeUTC:", currentDateTimeUTC)

          // Convert scheduleMigrationDateTime to moment object if it's not already
          let scheduledTime = moment.utc(item.scheduleMigrationDateTime);

          // Check if the difference is within a tolerance (e.g., 5 seconds)
          if (scheduledTime.diff(currentDateTimeUTC, 'minutes') <= 5) {
            setLoader(false)
          }
          else {
            ////console.log('lets send api')
            debugger
            let mapping = {
              mappingId: item.userMigrationMappingId
            }
            let payload = {
              taskId: taskId,
              token: token,
              data: mapping,
              applicationName: `${applicationName}`,
              Action: action,
              // isAdmin: isAdmin,
              statusText: getStatusLabel(action)
            }
            console.log("the payload", payload);

            setLoader(true)
            const response: any = await updateMailUserAction(payload);
            ////console.log("mappedMailboxes", response);
            if (response?.data?.statusCode === 409) {
              // setAlert(true)
              setAlertMessage(response?.data?.statusMessage)
              fetchData()
              setLoader(false)
            }
            else {
              fetchData()
              setLoader(false)
            }
          }
        }
        else {
          ////console.log('lets send api')
          let mapping = {
            mappingId: item.userMigrationMappingId
          }
          let payload = {
            taskId: taskId,
            token: token,
            data: mapping,
            applicationName: `${applicationName}`,
            Action: action,
            // isAdmin: isAdmin,
            statusText: getStatusLabel(action)
          }
          setLoader(true)
          const response: any = await updateMailUserAction(payload);
          ////console.log("mappedMailboxes", response);
          if (response?.data?.statusCode === 409) {
            setLoader(false)
            //   setAlert(true)
            setAlertMessage(response?.data?.statusMessage)
            fetchData()
          }
          else {
            fetchData()
            setLoader(false)
          }
        }

      }
    }
    catch (error) {
      console.error("Error updating user Action data:", error);
    } finally {
      // setLoading(false)
    }
  }

  const getStatusCSS = (status: string) => {
    switch (status) {
      case "failed":
        return "danger";
      case "warning":
        return "warning";
      case "queued":
        return "draft";
      case "draft":
        return "draft";
      case "cancelled":
        return "danger";
      case "scheduled":
        return "scheduled";
      case "inprogress":
        return "inprogress";
      case "in progress":
        return "inprogress";
      case "completed":
        return "success";
      case "paused":
        return "paused";
      default:
        return "draft";
    }
  };

  return (
    <>
      <div className="table-responsive theme-table bg-white ">
        <table className="table table-borderless mb-0">
          <thead>
            <tr>
              {bindingObject[applicationName.toLowerCase().trim()].map(
                ({ header, sort }: { header: string; sort: string }, index: number) => (
                  <th
                    key={index}
                    className={`text-nowrap ${header === "Action" ? "text-center" : ""
                      }`}
                  >
                    {header}
                    {header !== "Action" && (
                      <span className="ms-1 cursor-pointer">
                        <img
                          src={`/images/sort-arrow-up.svg`}
                          alt="sort-arrow"
                          style={{ cursor: "pointer" }}
                          id={`${sort}@DESC`}
                          hidden={
                            sortHide === `${sort}@ASC` || "" ? false : true
                          }
                          onClick={(e) => {
                            handleSort(e);
                          }}
                        />
                        <img
                          src={`/images/sort-arrow-down.svg`}
                          alt="sort-arrow"
                          id={`${sort}@ASC`}
                          style={{ cursor: "pointer" }}
                          hidden={
                            sortHide === `${sort}@ASC` || "" ? true : false
                          }
                          onClick={(e) => {
                            handleSort(e);
                          }}
                        />
                      </span>
                    )}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {getData?.length > 0 ? (
              getData?.map((item, index) => (
                <tr key={index}>
                  {bindingObject[applicationName?.toLowerCase().trim()].map(
                    ({ header, value }: { header: string; value: string }, index: number) => {
                      if (Object.is(value, "totalCount")) {
                        return (
                          <td className="text-nowrap"> {item["migratedCount"] !== undefined ? (
                            `${item["migratedCount"] !== null ? item["migratedCount"] : 0}/${item["totalCount"] !== null ? item["totalCount"] : 0}`
                          ) : (
                            `${item["totalSum"] ? item["totalSum"] : 0}/${item["totalCount"]}`
                          )}
                            <Tippy
                              delay={500}
                              arrow={true}
                              plugins={[followCursor]}
                              placement="right"
                              content={
                                <div
                                  style={{
                                    color: "white",
                                    padding: "5px",
                                  }}
                                >
                                  Count {item["migratedCount"] !== undefined && item["migratedCount"] !== null ? item["migratedCount"] : (item["totalSum"] || 0)}/
                                  {item["totalCount"] !== undefined && item["totalCount"] !== null ? item["totalCount"] : 0} <br />
                                  {applicationName?.toLowerCase() !== "teamschannel" &&
                                    applicationName?.toLowerCase() !== "sharepoint" && (
                                      <>
                                        Size{" "}
                                        {item["migratedSize"] !== undefined && item["migratedSize"] !== null ?
                                          formatSize(
                                            parseFloat(item["migratedSize"] || item["totalSizeAppDetails"])
                                          ) : "0 B"}
                                        /{item["totalSize"] !== undefined && item["totalSize"] !== null ?
                                          formatSize(parseFloat(item["totalSize"])) : "0 B"}{" "}
                                      </>
                                    )}
                                </div>
                              }
                            >
                              <img
                                src="/images/tooltip.svg"
                                alt="tooltip"
                                className="ms-1 mb-1"
                                data-bs-placement="right"
                                width={11}
                              />
                            </Tippy>
                          </td>
                        );
                      }
                      if (Object.is(value, "Status")) {
                        return (
                          <td className="d-flex align-items-center justify-content-center">
                            <span className="d-inline-flex gap-3">
                              <button
                                className="btn action-rounded-btn view-action d-flex align-items-center justify-content-center primary-action"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="View"
                                title="View"

                                onClick={() => {
                                  debugger;
                                  history.push("/MigrationReport", {
                                    state: {
                                      taskId: taskId,
                                      taskName: taskName,
                                      userMappingId: item.userMigrationMappingId,
                                      sourceDisplayName: item.SourceDisplayName || item.sourceChannelName || item.sourceDisplayName,
                                      targetDisplayName: item.TargetDisplayName || item.targetChannelName || item.targetDisplayName,
                                      sourceMailId: item.SourceMailID,
                                      targetMailId: item.TargetMailID,
                                      applicationName: applicationName,
                                      resourceType: resourceType
                                    },
                                  });
                                }}
                              >
                                <img
                                  src="/images/view-icon-filled.svg"
                                  alt="View"
                                />
                              </button>
                              {(item[value] == "1" || item[value] == "5") && adminRole.toLowerCase().includes("primary") ? (
                                <button
                                  className="btn action-rounded-btn cancel-action d-flex align-items-center justify-content-center"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  data-bs-title="Cancel"
                                  title="Cancel"
                                  disabled={
                                    disableActions
                                  }
                                  onClick={() =>
                                    handleActionButtonClick(item, "2")
                                  }
                                >
                                  <img
                                    src="/images/cancel-action-icon.svg"
                                    alt="Cancel"
                                  />
                                </button>
                              )
                                :
                                null}
                              {item[value] == "6"
                                ? (<div></div>)
                                : (
                                  <button
                                    disabled={
                                      disableActions
                                    }
                                    className="btn action-rounded-btn in-progress-action d-flex align-items-center justify-content-center"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    data-bs-title="Pause"
                                    title={
                                      item[value] == "3" ||
                                        item[value] == "4"
                                        ? "cancel"
                                        : item[value] == "1"
                                          ? "play"
                                          : item[value] == "2" ||
                                            item[value] == "7" ||
                                            item[value] == "0"
                                            ? "retry"
                                            : "pause"
                                    }
                                    onClick={() =>
                                      handleActionButtonClick(
                                        item,
                                        (item[value] == "3" || item[value] == "4") ? "2"
                                          : (item[value] == "1" || item[value] == "2" || item[value] == "7" || item[value] == "0" ? '5' : '1')
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        item[value] == "3" ||
                                          item[value] == "4"
                                          ? "/images/cancel-action-icon.svg"
                                          : item[value] == "1"
                                            ? "/images/play-icon-filled.svg"
                                            : item[value] == "2" ||
                                              item[value] == "7" ||
                                              item[value] == "0"
                                              ? "/images/refresh-icon-filled.svg"
                                              : "/images/pause-icon.svg"
                                      }
                                      alt={
                                        item[value] == "3" ||
                                          item[value] == "4"
                                          ? "cancel"
                                          : item[value] == "1"
                                            ? "pause"
                                            : item[value] == "2" ||
                                              item[value] == "7" ||
                                              item[value] == "0"
                                              ? "retry"
                                              : "pause"
                                      }
                                    />
                                  </button>
                                )}
                            </span>
                          </td>
                        );
                      }
                      if (Object.is(value, "statusText")) {
                        return (
                          <td>
                            <div className="d-flex align-items-center">
                              <span
                                className={`table-status ${getStatusCSS(item[value]?.toLowerCase())} d-inline-block me-2`}
                              ></span>
                              <span>
                                {item[value]
                                  ? item[value].charAt(0).toUpperCase() + item[value].slice(1)
                                  : ''}
                              </span>
                            </div>
                          </td>
                        );
                      }

                      return (
                        <td
                          style={{
                            textTransform:
                              header?.toLowerCase() === "resourcetype"
                                ? "capitalize"
                                : "none",
                          }}
                        >
                          {item[value]?.length > 21 ? (
                            <span
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title={item[value]}
                            >
                              {item[value].slice(0, 21) + "..."}
                            </span>
                          ) : (
                            item[value]
                          )}
                        </td>
                      );
                    }
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className="text-center">
                    <div className="pt-4 pb-3 text-center">
                      <img
                        src="/images/no-data-found-img.svg"
                        alt="no-data-found-img"
                        className="mb-3"
                      />
                      <h5 className="font-12 font-bold color-no-rec">
                        {noRecords}
                      </h5>
                      <p className="font-10 font-regular color-no-rec">
                        No Matching Results Found
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <p className="text-start mb-3" style={{ color: 'black' }}>
        # Records of :<span style={{ fontWeight: 'bold' }}>{getData.length}</span> out of <span style={{ fontWeight: 'bold' }}>{totalDataCount}</span>
      </p>
      <div className="d-flex justify-content-center mt-5">
        <button
          className="btn primary-outline-btn font-14 font-semibold"
          onClick={() => {
            setPage(page + 10);
          }}
          hidden={totalDataCount < page}>
          Load More
        </button>
      </div>

      {loader && <Loader />}

      {alert ?
        <div
          className="modal fade show"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5
                  className="modal-title mb-3 primary-header"
                  id="Failure-popupLabel"
                >
                  {alertMessage}
                </h5>
                <p className="primary-data">
                  {alert}
                </p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button
                  type="button"
                  className="btn primary-btn font-14 px-4 font-semibold"
                  onClick={() => {
                    if (isSessionEpired) {
                      history.push('/Login')
                    }
                    setAlert(false)
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        : null
      }
    </>
  );
};

export default ViewMigrationGrid;