import { useEffect, useState } from "react";
import { OutlookData, sessionResponse } from "../../Common/Interface";
import { decodeSessionDetails } from "../../Services/CommonServices";
import { getAssessmentSummary } from "../../Services/AssessmentSummaryServices";
import { useHistory, useLocation } from "react-router";
import objCryptoJS from "crypto-js";
import { commonErrorMsgs } from "../../Common/Constant";
import * as XLSX from 'xlsx';



export default function AssessmentSummary() {
    /**PS_01 to PS_14 will be executed
 */
    const [alert, setAlert] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>('')
    let location: any = useLocation().state;
    //console.log(location, "locaaaaa");
    
    let taskName = location?.state?.taskName;

    

    const history = useHistory()
    const [isCustomized, setIsCustomized] = useState<boolean>(true);
    // PS_03
    const [connectionName, setConnectionName] = useState<string>('');

    const [applicationName, setApplicationName] = useState<any>('SharePoint');

    // PS_04
    const [assessmentTimeTaken, setAssessmentTimeTaken] = useState<Date | null>(null);
    // PS_05
    const [assessmentStartDate, setAssessmentStartDate] = useState<Date | null>(null);
    // PS_06
    const [assessmentEndDate, setAssessmentEndDate] = useState<Date | null>(null);
    // PS_07
    const [customizedStartDate, setCustomizedStartDate] = useState<any>(null);
    // PS_08
    const [customizedEndDate, setCustomizedEndDate] = useState<any>(null);

    // PS_11
    const [status, setStatus] = useState<string>('');
    const [totalCount, settotalCount] = useState<number>(0);
    const [folderCount, setFolderCount] = useState<number>(0);
    const [fileCount, setFileCount] = useState<number>(0);

    const [progressCount, setProgressCount] = useState<number>(0);
    const [Completion, setCompletion] = useState<number>(0);
    const [Size, setSize] = useState<string>('0 GB');
    // PS_12

    // PS_13
    const [exportData, setExportData] = useState<any[]>([]);

    // PS_14
    const [applicationData, setApplicationData] = useState<{ [key: string]: any }>({});
    const [totalSize, setTotalSize] = useState('');
    const [overallStatus, setOverallStatus] = useState('');


    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [clientName, setclientName] = useState<string>('');
    let [companyName, setCompanyName] = useState<string>('');
    let [emailAddress, setEmailAddress] = useState<string>('');
    let [role, setRole] = useState<string>('');
    let [isAdmin, setIsAdmin] = useState<boolean>(false);
    let [adminName, setAdminName] = useState<string>('');
    let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
    let [adminrole, setAdminRole] = useState<string>('');

    useEffect(() => {
        sessionTokenProperties()
        fetchData()

    }, []);
    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: sessionResponse = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                else {
                    history.push("/Admin/Login")
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    setEmailAddress(tokenResponse.clientDetails.Email);
                    setRole(tokenResponse.clientDetails.Role);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            console.error('Failed in main method', error);
        }
    };

    const getStatusCSS = (status: string) => {
        switch (status) {
            case "failed":
                return "danger";
            case "queued":
                return "draft";
            case "draft":
                return "draft";
            case "cancelled":
                return "draft";
            case "scheduled":
                return "scheduled";
            case "inprogress":
                return "warning";
            case "in progress":
                return "warning";
            case "completed":
                return "success";
            case "paused":
                return "paused";
            default:
                return "";
        }
    };

    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin != undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            throw error
        }
    }

    async function formatBytes(size: number) {
        const units: string[] = ['B', 'KB', 'MB', 'GB', 'TB'];
        let unitIndex: number = 0;
    
        while (size >= 1000 && unitIndex < units.length - 1) {
          size /= 1000;
          unitIndex++;
        }
    
        return size?.toFixed(2) + ' ' + units[unitIndex];
      }

    /**PS_33 to PS_55 will be executed
     */
    const fetchData = async () => {
        try {
            setLoader(true);
            let token = await getToken();

            let payload = {
                token: token,
                taskId: location?.state?.taskId
            };

            debugger

            const response: any = await getAssessmentSummary(payload);
//console.log(response, "vanten da");

            if (response?.data?.statusCode === 200) {
                setLoader(false);
                const data = response.data.data;
                //console.log(data, 'dataaaa');
                
                //console.log("the response of the summary", response);

                // Set common data
                setConnectionName(data?.connectionName[0]?.connectionName|| data?.connectionName || '');
                setAssessmentStartDate(data?.AssessmentDateAndTime?.assessmentInitatedDate ||data?.AssessmentDateAndTime|| '');
                setAssessmentTimeTaken(data?.completeDetails?.assessmentDetails?.timeTakenFormatted || '');
                setAssessmentEndDate(data?.completeDetails?.assessmentDetails?.modifiedDate || '');
                setCustomizedStartDate(data?.completeDetails?.assessmentDetails?.customStartDate ? new Date(data.completeDetails?.assessmentDetails?.customStartDate).toLocaleDateString() : '');
                setCustomizedEndDate(data?.completeDetails?.assessmentDetails?.customEndDate ? new Date(data.completeDetails?.assessmentDetails?.customEndDate).toLocaleDateString() : '');
                setSize(data?.totalStats?.totalStorageFormatted)
                setFolderCount(data?.totalStats?.totalFolders)
                // Initialize application-specific data
                const appData: { [key: string]: any } = {};

                debugger

                // Process data for each application
                Object.entries(data.applications).forEach(async ([app, appInfo]: [string, any]) => {
                    if (data.applications.outlook) {
                        //console.log('outlook data : ', data.applications.outlook)
                        const outlookData = data.applications.outlook;
                        applicationData.outlook = {
                            totalCount: outlookData.sourceUserCount || 0,
                            progressCount: outlookData.masterInventoryCount || 0,
                            percentage: outlookData?.percentage || '0%',
                            status: outlookData.status,
                            size: appInfo?.totalItemSize ? await formatBytes(appInfo.totalItemSize) : '0KB',
                           // size: formatBytes(appInfo.totalItemSize),
                            detailedInfo: outlookData.detailedInfo || []
                        };
                        setApplicationName('outlook');
                    }
                    if (data.completeDetails.teamsData) {
                        applicationData.teamschannel = {
                            totalCount: appInfo.sourceTeamCount || 0,
                            totalFileCount: appInfo.totalFileCount || 0,
                            progressCount: appInfo.masterInventoryCount || 0,
                            percentage: appInfo.PercentageCompletion,
                            status: data.overallStatus,
                            size: `${(appInfo.TotalSize / (1024 * 1024)).toFixed(2)} MB`, // Convert bytes to MB
                            detailedInfo: appInfo.detailedInfo || []
                        };
                        setApplicationName('TeamsChannel');
                        setStatus(data.overallStatus);
                        setFileCount(appInfo.totalFileCount);
                        setOverallStatus(data.overallStatus);
                        setProgressCount(appInfo.masterInventoryCount);
                        settotalCount(appInfo.sourceTeamCount);
                        //console.log(appData, "vanten da chello");
                    }
                    if (data.applications.sharepoint) {
                        const spData = data.applications.sharepoint;
                        //console.log(spData, "spdataaa");
                        
                        applicationData.sharepoint = {
                            totalCount: spData.totalCount,
                            progressCount: spData.Count,
                            percentage: spData.PercentageCompletion,
                            status: spData.Status,
                            size: `${(spData.TotalSize / (1024 * 1024)).toFixed(2)} MB`,
                            detailedInfo: spData.detailedInfo || []
                        };
                        setStatus(spData.Status);
                        setProgressCount(spData.Count);
                        settotalCount(spData.totalCount);
                        //console.log(appData, "vanten da chello");
                        setApplicationName("SharePoint");
                        //console.log(applicationName, "appname");
                        
                    }
                    if (data.applications.mydrive) {
                        const myDriveData = data.applications.mydrive;
                        //console.log('mydrive data : ', data.applications.mydrive)
                        applicationData.mydrive = {
                            totalCount: myDriveData.TotalCount || 0,
                            progressCount: myDriveData.Count || 0,
                            percentage: myDriveData.PercentageCompletion || '0%',
                            status: myDriveData?.Status ? myDriveData?.Status : 'draft' ,
                            size: myDriveData?.TotalSize ? await formatBytes(myDriveData.TotalSize) : '0KB',
                            detailedInfo: myDriveData.detailedInfo || []
                        };
                        setApplicationName('mydrive');
                    }
                     else {
                        // Handle other application types here if needed
                        applicationData[app] = {
                            totalCount: appInfo.detailedInfo?.TotalMailCount || 0,
                            progressCount: appInfo.detailedInfo?.TotalMailCount || 0,
                            percentage: appInfo.percentage || 0,
                            status: appInfo.detailedInfo?.inventoryStatus || '',
                            size: `${appInfo.detailedInfo?.TotalItemSizeInGB || 0} GB`,
                            detailedInfo: appInfo.detailedInfo || {}
                        };
                    }
                });
    

                setApplicationData(applicationData);

                // Set overall data
                setTotalSize(data.totalStats.totalStorageFormatted);
                setOverallStatus(data.overallStatus);

                // For this response, isCustomized is not directly available
                // You might want to set it based on some other logic or remove it if not needed
                setIsCustomized(true); // or false, depending on your requirements

            } else {
                setLoader(false);
                setAlert(true);
                setAlertMessage(commonErrorMsgs.generalError);
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false);
            setAlertMessage(commonErrorMsgs.generalError);
        }
    };

   
   
//PS_57 to PS_73
    const exportExcel = async () => {
        try {
          setLoader(true);
          //console.log("Starting Excel export");
          
          // Initialize a new workbook
          const workbook = XLSX.utils.book_new();
      
          // Create an overall summary worksheet based on overall data
          const summaryData = {
            "Connection Name": connectionName,
            "Assessment Start Date & Time": assessmentStartDate,
            "Assessment End Date & Time": assessmentEndDate,
            "Customization Dates": `${customizedStartDate || ''} - ${customizedEndDate || ''}`,
            "Total Size": totalSize,
            "Folder Count": folderCount,
            "Total Time Taken": assessmentTimeTaken,
            "Overall Status": overallStatus
          };
          
          const summaryWorksheet = XLSX.utils.json_to_sheet([summaryData]);
          
          // Add an empty row
          XLSX.utils.sheet_add_json(summaryWorksheet, [{}], { origin: -1 });
          
          // Add the new headers
          const newHeaders = [
            {
                "Customized/Non-Customized": isCustomized ? 'Customized' : 'Non-Customized',
                "Applications": applicationName,
                "Size": Size,
                "Assessment Completed Count": progressCount,
                "TotalCount": totalCount,
                "status": status
            }
          ];
          XLSX.utils.sheet_add_json(summaryWorksheet, newHeaders, { origin: -1 });
          
          XLSX.utils.book_append_sheet(workbook, summaryWorksheet, 'Overall Summary');
          // List of applications to process
          const applications = ['outlook', 'sharepoint', 'teamschannel', 'gmail', 'mydrive'];
      
          // Process each application
          applications.forEach(appKey => {
            if (applicationData[appKey]) {
              try {
                const data = applicationData[appKey];
                //console.log(`${appKey} data:`, data);
      
                let appWorksheetData = [];
      
                // Process data based on application type
                switch(appKey) {
                  case 'outlook':
                    if (data.detailedInfo && typeof data.detailedInfo === 'object') {
                      appWorksheetData = [{
                        "User Name": data.detailedInfo.UserDisplayName,
                        "Mails- count": data.detailedInfo.TotalMailCount,
                        "Mailbox- Size": data.detailedInfo.TotalItemSizeInGB,
                        "Contacts-count": data.detailedInfo.TotalContactCount,
                        "Todo-count": data.detailedInfo.TotalTaskCount,
                        "Calendar-count": data.detailedInfo.TotalCalendarCount,
                        "Status": data.detailedInfo.inventoryStatus,
                        "Error Log": data.detailedInfo.customError || ""
                      }];
                    }
                    break;
                  case 'teamschannel':
                    if (Array.isArray(data.detailedInfo)) {
                      appWorksheetData = data.detailedInfo.map((team:any) => ({
                        "Team Name": team["SourceTeamName"],
                        "Team Email": team["SourceTeamEmailID"],
                        "Users": team["Users"],
                        "Channels": team["Channels"],
                        "Messages": team["TotalMessages"],
                        "Human Messages": team["HumanMessages"],
                        "Bot Messages": team["BotMessages"],
                        "Team Apps": team["Apps"],
                        "Files": team["Files"],
                        "Status": team["status"],
                        "Error Log": team["Error"]
                      }));
                    }
                    break;
                  case 'sharepoint':
                    // SharePoint processing logic
                    if (Array.isArray(data.detailedInfo)) {
                        appWorksheetData = data.detailedInfo.map((site:any)=> ({
                          "Site Name": site.SiteName,
                          "Site URL": site.SiteURL,
                          "Users": site.Users,
                          "Documents": site.Documents,
                          "Lists": site.Lists,
                          "Pages": site.Pages,
                          "Permission Levels": site.PermissionLevels,
                          "People and Groups": site.PeopleAndGroups,
                          "Status": site.Status,
                          "Error": site.Error,
                          "Site Size (KB)": (parseInt(site.siteSize) / 1024).toFixed(2)
                        }));
                      }
                    // You'll need to provide the specific data structure for SharePoint
                    break;
                  case 'gmail':
                    // Gmail processing logic
                    if (data.detailedInfo && typeof data.detailedInfo === 'object') {
                        appWorksheetData = [{
                          "User Name": data.detailedInfo.UserDisplayName,
                          "Mails- count": data.detailedInfo.TotalMailCount,
                          "Mailbox- Size": data.detailedInfo.TotalItemSizeInGB,
                          "Contacts-count": data.detailedInfo.TotalContactCount,
                          "Todo-count": data.detailedInfo.TotalTaskCount,
                          "Calendar-count": data.detailedInfo.TotalCalendarCount,
                          "Status": data.detailedInfo.inventoryStatus,
                          "Error Log": data.detailedInfo.customError || ""
                        }];
                      }
                    // You'll need to provide the specific data structure for Gmail
                    break;
                  case 'mydrive':
                    // MyDrive processing logic
                    if (Array.isArray(data.detailedInfo)) {
                        appWorksheetData = data.detailedInfo.map((user:any) => ({
                          "User Name": user.UserName,
                          "User Email": user.UserEmail,
                          "Total Files": user.TotalFiles,
                          "Total Folders": user.TotalFolders,
                          "Drive Size (GB)": (parseInt(user.DriveSize) / (1024 * 1024 * 1024)).toFixed(2),
                          "Status": user.Status,
                          "Error": user.Error
                        }));
                      }
                    // You'll need to provide the specific data structure for MyDrive
                    break;
                  default:
                    //console.log(`Unknown application type: ${appKey}`);
                }
      
                if (appWorksheetData.length > 0) {
                  const appWorksheet = XLSX.utils.json_to_sheet(appWorksheetData);
                  XLSX.utils.book_append_sheet(workbook, appWorksheet, `${appKey=='mydrive' ? 'OneDrive' : appKey} Details`);
                  //console.log(`${appKey} worksheet appended successfully`);
                } else {
                  //console.log(`No data to append for ${appKey}`);
                }
              } catch (error) {
                console.error(`Error processing ${appKey} data:`, error);
              }
            } else {
              //console.log(`${appKey} data not found in applicationData`);
            }
          });
      
          // Export the workbook as an Excel file
          XLSX.writeFile(workbook, 'OverallAssessmentSummary.xlsx');
          //console.log("Excel file written successfully");
          setLoader(false);
        } catch (error) {
          console.error('Error exporting Excel:', error);
          setLoader(false);
          setAlert(true);
          setAlertMessage(commonErrorMsgs?.generalError);
        }
      };
        
        
      //console.log("appliction data ",applicationData)

        return (<>
            {loader && (
                <div className="overlay">
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div
                            className="spinner-border Loader loader-color align-center "
                            role="status"
                        >
                            <span>
                                <span className="loader-inner-circle" />
                            </span>
                        </div>
                        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                            Loading..
                        </p>
                    </div>
                </div>
            )}
            <div className="container-fluid container-height">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                            {/* bread crumb starts here */}
                            <ul className="breadcrumb-item mb-4 ps-0">
                                <li>
                                    <a href="/Task" className="pe-0">
                                        Task
                                    </a>
                                </li>
                                <li className="pe-2">{taskName}</li>
                            </ul>
                            {/* bread crumb ends here */}
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
                                <h5 className="primary-header mb-0 d-flex align-items-center">
                                    <a href="/Task">
                                        <img
                                            src="/images/head-back-arrow.svg"
                                            alt="back-arrow"
                                            width={19}
                                            className="me-2"
                                        />
                                    </a>
                                    {taskName} - Assessment
                                </h5>
                                <button className="btn primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" onClick={() => exportExcel()}>
                                    <img src="/images/excel-icon-white.svg" alt="excel" width={13} />{" "}
                                    Export
                                </button>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card p-md-4 border-0 rounded-3">
                                        <div className="row justify-content-center">
                                            <div className="row float-start div-border">
                                                <div className="col-md-4 float-start mt-4 mb-2">
                                                    <div className="float-start">
                                                        <span className="float-start w-100 font-14 font-regular widget-title">
                                                            Connection Name
                                                        </span>
                                                        <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                            {connectionName}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 float-start mt-4 mb-2">
                                                    <div className="float-start">
                                                        <span className="float-start w-100 font-14 font-regular widget-title">
                                                            Assessment Start Date & Time
                                                        </span>
                                                        <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                            {assessmentStartDate}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 float-start mt-4 mb-2">
                                                    <div className="float-start">
                                                        <span className="float-start w-100 font-14 font-regular widget-title">
                                                            Assessment End Date & Time
                                                        </span>
                                                        <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                          {assessmentEndDate}  
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 float-start mt-4 mb-2">
                                                    <div className="float-start">
                                                        <span className="float-start w-100 font-14 font-regular widget-title">
                                                            Customization Dates
                                                        </span>
                                                        <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                            {customizedStartDate} -  {customizedEndDate}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    id="FileSize"
                                                    className="col-md-4 float-start mt-4 mb-2"
                                                >
                                                    <div className="float-start">
                                                        <span className="float-start w-100 font-14 font-regular widget-title ">
                                                            Total size
                                                        </span>
                                                        <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                            {Size}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    id="MessageCount"
                                                    className="col-md-4 float-start mt-4 mb-2"
                                                >
                                                    <div className="float-start">
                                                        <span className="float-start w-100 font-14 font-regular widget-title">
                                                            Folder Count
                                                        </span>
                                                        <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                            {folderCount}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    id="FileCount"
                                                    className="col-md-4 float-start mt-4 mb-2"
                                                >
                                                    <div className="float-start">
                                                        <span className="float-start w-100 font-14 font-regular widget-title ">
                                                            File Count{" "}
                                                        </span>
                                                        <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {fileCount}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    id="FileCount"
                                                    className="col-md-4 float-start mt-4 mb-2"
                                                >
                                                    <div className="float-start">
                                                        <span className="float-start w-100 font-14 font-regular widget-title ">
                                                            Total Time Taken
                                                        </span>
                                                        <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                            {assessmentTimeTaken}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-4 mt-md-5">
                                                <h3 className="mb-2 font-22 font-bold pb-2">
                                                    {isCustomized ? 'Customized Apps' : 'Non-Customized Apps'}
                                                </h3>
                                                <div className="row">
                                                    {Object.entries(applicationData).map(([appName, appData]) => (
                                                        <div key={appName} className="col-md-4 col-lg-4 col-xl-3 mb-4">
                                                            <div className="border-radius-8 border p-3 h-100">
                                                                <div className="d-flex justify-content-between">
                                                                    <span>
                                                                        <img
                                                                            src={`/images/${appName.toLowerCase()}.svg`}
                                                                            alt={`${appName} icon`}
                                                                            className="mb-2 me-2 cust-card-img"
                                                                        />
                                                                        <span className="font-semibold font-14 mb-3 color-black">
                                                                            {appName === 'outlook' ? 'Entity' :
                                                                                appName === 'sharepoint' ? 'SharePoint' :
                                                                                    appName === 'teamschannel' ? 'Teams Channel' :
                                                                                        appName === 'gmail' ? 'GMail' :
                                                                                            appName === 'mydrive' ? 'OneDrive' :
                                                                                                appName}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className="row mb-2 pb-2">
                                                                    <div className="col-5 cust-count-line position-relative pe-0">
                                                                        <p className="mb-0 font-10 font-regular cust-gray-color">
                                                                            Count
                                                                        </p>
                                                                        <span className="font-semibold font-10">
                                                                            {appData.progressCount} out of {appData.totalCount}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-6 pe-0">
                                                                        <p className="mb-0 font-10 font-regular cust-gray-color">
                                                                            Size
                                                                        </p>
                                                                        <span className="font-semibold font-20">
                                                                            {appData.size}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <p className="font-regular font-12 form-label-color mb-1">
                                                                    <span
                                                                        className={`table-status ${getStatusCSS(
                                                                            appData.status.toLowerCase()
                                                                        )} d-inline-block me-2 `}
                                                                    ></span>
                                                                    {appData.status} {appData.percentage}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {alert ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                {alertMessage}
                            </h5>
                            <p className="primary-data">
                                {alert}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => { setAlert(false) }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
        </>
        )

    }


