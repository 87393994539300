//PS_01, PS_02, PS_03, PS_04, PS_05, PS_06, PS_07, PS_08, PS_09, PS_10, PS_11, PS_12

import React, { useEffect, useState, useRef } from "react";
import {
  getClientData,
  handleSecondaryUser,
  sendInvitemail,
  updatePassword,
  updateProfile,
} from "../../Services/profileService";
import objCryptoJS from "crypto-js";
import { acc, sessionResponse } from "../../Common/Interface";
import { followCursor } from 'tippy.js';
import { useHistory } from "react-router-dom";
import { decodeSessionDetails, encryptHybidMessage } from "../../Services/CommonServices";
import Loader from "../Common/Loader";
import { Country } from "country-state-city";
import Select from "react-select"
import { countries, TCountries } from "countries-list";
import Tippy from "@tippyjs/react";
import jwt from 'jwt-decode'
import { getSession } from "../../Services/manageClientAPI";

import CryptoJS from 'crypto-js';
import { Onlymail } from "../../Common/Constant";
import { log } from "console";

// import Tippy from '@tippyjs/react';

const Profile: React.FC = () => {


  // PS_13, PS_14, PS_15, PS_16, PS_17, PS_18, PS_19, PS_20, PS_21, PS_22, PS_23, PS_24,
  // PS_25, PS_26, PS_27, PS_28, PS_29, PS_30, PS_31, PS_32, PS_33, PS_34, PS_35, 
  //PS_36, PS_37, PS_38, PS_39, PS_40, PS_41, PS_42, PS_43, PS_44, PS_45, PS_46, PS_47.
  // Initialization
  let checkId: any;
  const history = useHistory();
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [ctoken, setCToken] = useState<string | null>('');
  const [clientName, setClientName] = useState<string>("");
  const [issso, setissso] = useState(false)
  const [mailId, setMailId] = useState<string>("");
  const [deleteMailId, setDeleteMailId] = useState<string>("");
  let [role, setRole] = useState<string>('');
  const [tokenExpiration, setTokenExpiration] = useState<Date>(new Date());
  const [atoken, setAToken] = useState<string | null>(null);
  const [adminName, setAdminName] = useState<string>("");
  const [adminMailId, setAdminMailId] = useState<string>("");
  const [adminRole, setAdminRole] = useState<string>("");
  let [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [successPopupType, setSuccessPopupType] = useState('')

  // Complex object initialization
  const accountInfoObj = {
    name: "",
    mail: "",
    company: "",
    registrationType: "",
    phone: "",
    existPWD: "",
    phonecode: "",
  };
  const manageUserObj = {
    userName: "",
    email: "",
    authenticationType: "",
    role: "",
    accountStatus: "Invited",
    adminDetailsId: 0,
    manageUserId: 0,
    createdDate: ""
  };
  const passwordObj = {
    mail: "",
    newpassword: "",
    confirmpassword: "",
    oldpassword: "",
  };
  const erraccountInfoObj = {
    errname: "",
    errmail: "",
    errcompany: "",
    errregistrationType: "",
    errphone: "",
  };
  const errmanageUserObj = {
    erruserName: "",
    errmail: "",
    errauthenticationType: "",
    role: "",
  };
  const errpasswordObj = {
    errmail: "",
    errNewpassword: "",
    errconfirmpassword: "",
    erroldpassword: "",
  };
  const licenseDetails: any = {
    licenseExpiredAt: "",
    usedParallelInstance: "",
    purchasedParallelInstance: "",
    usedAssessmentLicense: "",
    purchasedAssessmentLicense: "",
    usedGroupsLicense: "",
    purchasedGroupsLicense: "",
    usedUsermailboxLicense: "",
    purchasedUserMailboxlicense: "",
    usedSharedMailboxLicense: "",
    purchasedSharedMailboxLicense: "",
    usedRoomAndEquipmentsLicense: "",
    purchasedRoomsAndEquipments: "",
    usedOneDriveLicense: "",
    purchasedOneDriveLicense: "",
    purchasedTeamChannelLicense: "",
    usedTeamChannelLicense: "",
    purchasedSharePointLicense: "",
    usedSharePointLicense: ""

  }
  const invoice = { Search: "", Sort: "", SortOrder: "" };
  // State variable initialization
  const [licenseData, setLicenseData] = useState([licenseDetails]);
  const [loader, setLoader] = useState(false)
  const [AccountInfodata, setAccountInfo] = useState(accountInfoObj);
  let [AccountInfo, setAccountInfoData] = useState(accountInfoObj);
  let [errAccountInfo, seterrAccountInfo] = useState(erraccountInfoObj);
  let [errmanageUser, seterrManageUser] = useState(errmanageUserObj);
  let [erreditmanageUser, seterreditManageUser] = useState(errmanageUserObj);
  let [errpassword, seterrpassword] = useState(errpasswordObj);
  const [editMode, seteditmode] = useState(false);
  const [noData, setNoData] = useState(false);
  const [countryOptions, setCountryOptions] = useState<{ value: string, label: string }[]>([]);
  const [secondaryUser, setSecondaryUser] = useState(manageUserObj);
  const [secondaryUsers, setSecondaryUsers] = useState([
    {
      userName: "",
      mailId: "",
      authenticationType: "",
      roleType: "",
      accountStatus: "Invited",
      manageUserDetailsId: 0,
      createdDate: '', adminDetailsId: 0
    },
  ]);
  const [Deletepopup, setDeletePopup] = useState(false);
  let [deleteId, setDeleteid] = useState('')
  const [editData, setEditData] = useState({
    userName: "",
    mailId: "",
    authenticationType: "",
    roleType: "",
    accountStatus: "Invited",
    manageUserDetailsId: 0,
    adminDetailsId: 0,
    adminRolesId: 0
  });
  const [searchValue, setSearchValue] = useState('');
  const [manageUsersSortHide, setManageUsersSortHide] =
    useState("userName@DESC");
  const [manageUsersSortBy, setManageUsersSortBy] = useState("userName");
  const [manageUsersSortOrder, setManageUsersSortOrder] = useState("DESC");;
  let [password, setPassword] = useState(passwordObj);
  const [roleData, setRoleData] = useState([]);
  const [authenticationType, setAuthenticationType] = useState([
    { authenticationType: "Email" },
    { authenticationType: "SSO" }]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [countrycode, setCountryCode] = useState([]);
  const [invoiceAction, setInvoiceAction] = useState(invoice);
  const tokenExpiryCheckInterval = 20 * 60 * 1000; // 20 minutes
  const [alertPopup, setAlertPopup] = useState(false);
  const [enable, setEnable] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [popupmessage, setpopupmessage] = useState("");
  const [existingMails, setExistingMails] = useState([]);
  const [adminEmailAddress, setAdminEmailAddress] = useState('');
  const [companyName, setCompanyName] = useState('')
  const [manageUserbutton, setAddEnableButton] = useState(false)
  const [basicCountryCodes, setBasicCountryCodes] = useState<{ value: string, label: string }[]>([]);
  const [Confirmhide, setconfirmHidee] = useState(false)
  const [hide, setHide] = useState(false)
  const [oldhide, setoldHide] = useState(false)
  const [showresend, setShowresend] = useState(false)
  const [countryCode, SetCountryCode] = useState('')
  const currentDate = new Date();
  const [alert, setAlert] = useState<string>('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let [emailAddress, setEmailAddress] = useState('');

  let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);
  // type UserDetail = [{
  //   userName: string;
  //   mailId?: string; // '?' indicates that the property is optional
  //   authenticationType: string;
  //   roleType: string;
  //   invitedStatus: string;
  //   manageUsersDetailsId: number;
  //   createdDate: string;
  // }];


  // Update the validation logic inside a useEffect hook
  useEffect(() => {
    const enableButton: any =
      !erreditmanageUser.errmail &&
      !erreditmanageUser.erruserName &&
      !erreditmanageUser.errauthenticationType &&
      !erreditmanageUser.role &&
      editData.mailId &&
      editData.userName &&
      editData.authenticationType &&
      editData.roleType;

    setAddEnableButton(enableButton);
  }, [editData, erreditmanageUser]);

  useEffect(() => {
    const enableButton =
      secondaryUser.userName !== '' &&
      secondaryUser.accountStatus !== '' &&
      secondaryUser.email !== '' &&
      secondaryUser.authenticationType !== '' &&
      !errmanageUser.erruserName &&
      !errmanageUser.errauthenticationType &&
      !errmanageUser.errmail &&
      !errmanageUser.role;

    setAddEnableButton(enableButton);
  }, [secondaryUser, errmanageUser]);

  // PS_48 PS_49
  useEffect(() => {
    fetchBasicCountryCodes()
    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
      // debugger
      isAdmin = true
      setIsAdmin(isAdmin)
      // resetSessionTimer();
      sessionTokenProperties();
      resetTimer();
      getClientDetails('initial');
      window.addEventListener('keydown', resetTimer);
      window.addEventListener('click', resetTimer);

      return () => {
        if (InActiveTimeout.current) {
          console.log("Profile Unmount Time Clear");
          clearTimeout(InActiveTimeout.current);
        }
        window.removeEventListener('click', resetTimer);
        window.removeEventListener('keydown', resetTimer);
      };

    }
    else {
      sessionTokenProperties();
      resetTimer();
      getClientDetails('initial')
      getToken()
      window.addEventListener('keydown', resetTimer);
      window.addEventListener('click', resetTimer);

      return () => {
        if (InActiveTimeout.current) {
          clearTimeout(InActiveTimeout.current);
        }
        window.removeEventListener('click', resetTimer);
        window.removeEventListener('keydown', resetTimer);
      };
    }

  }, [invoiceAction, manageUsersSortBy, manageUsersSortOrder]);


  let resetTimer = async () => {
    try {
      // debugger;
      ////console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      ////console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: isAdmin,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        // const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        // const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        // let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', getsessionToken?.data?.data.sessionToken);
        await sessionTokenProperties();
        // let updatedobjClient: any = jwt(c_decryptedValue);
        // SessionEpiredDateTime = updatedobjClient.CreatedAt;
        // setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      ////console.log("Reset Timer End");
    }
    catch (error) {
      ////console.log("Reset Timer Catch");
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoader(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      ////console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        ////console.log("Reset Session Timer Inside");
        if (InActiveTimeout.current) {
          clearTimeout(InActiveTimeout.current);
        }
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout.current = setTimeout(() => {
          if (!isAdmin) {
            ////console.log("Expired");
            sessionStorage.removeItem("c_at");
            ////console.log("removed session");
            setisSessionEpired(true)
            setAlert('Your Session has Expired please login again to continue');
            // return 'expired'
          }
        }, tokenExpiryCheckInterval);

        ////console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        //const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(currentDateTime).getTime() - new Date(SessionEpiredDateTime).getTime());
        ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        ////console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          ////console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        ////console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      ////console.log("Reset Session Timer Catch");
      throw ex
    }
  };


  //CommonFunctionS:


  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (window.location.pathname.toString().toLowerCase().includes("admin")) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
          setRole(tokenResponse.adminDetails.Role)
          role = tokenResponse.adminDetails.Role
          setAdminMailId(tokenResponse.adminDetails.Email)

        }
        else {
          if (window.location.pathname.toString().toLowerCase().includes("admin")) {
            history.push("/Admin/Login")
          }
        }
      }
      if (!window.location.pathname.toString().toLowerCase().includes("admin")) {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setClientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          emailAddress = tokenResponse.clientDetails.Email;
          setEmailAddress(emailAddress);
          setRole(tokenResponse.clientDetails.Role);
          role = tokenResponse.clientDetails.Role
          setMailId(tokenResponse.clientDetails.Email)

          SessionEpiredDateTime = tokenResponse.clientDetails.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
        }
        else {
          history.push("/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };


  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    const isAdminGeneratedClientSession = sessionStorage.getItem('isAdminGeneratedClientSession') === "true";

    // debugger
    try {
      if (isAdminGeneratedClientSession) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          setCToken(sessionToken)
          token = {
            clientToken: sessionToken
          }
          return token;  // Return early if it's an admin-generated client session
        }
      }
      if (objClient != undefined && objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          ////console.log(clientToken, 'ddddddddddddddddddddddddddd');
          setCToken(sessionToken)
          token = {
            clientToken: sessionToken
          }
        }


      }
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          ////console.log(clientToken, 'ddddddddddddddddddddddddddd');
          setCToken(sessionToken)
          token = {
            clientToken: sessionToken
          }
        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          ////console.log(adminToken, 'sssssssssssssssssss');
          setAToken(sessionToken)
          token = {
            adminToken: sessionToken
          }
        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  const fetchBasicCountryCodes = async () => {
    const allCountries = Object.keys(countries).map(key => ({
      name: countries[key as keyof TCountries].name,
      phonecode: countries[key as keyof TCountries].phone,
      isoCode: key,
    }));

    // Map country options with custom identifiers for US and Canada
    const options = allCountries.map(country => {
      if (country.isoCode === 'US') {
        return {
          value: `+${country.phonecode}-US`,
          label: `${country.name} (+${country.phonecode})`,
        };
      }
      if (country.isoCode === 'CA') {
        return {
          value: `+${country.phonecode}-CA`,
          label: `${country.name} (+${country.phonecode})`,
        };
      }
      return {
        value: `+${country.phonecode}`,
        label: `${country.name} (+${country.phonecode})`,
      };
    });

    options.sort((a, b) => a.label.localeCompare(b.label));

    setCountryOptions(options);
  };


  //PS_55, PS_56, PS_57, PS_58, PS_59, PS_60
  const getClientDetails = async (type: string) => {


    // Invoke the getClientData function from the profileService
    // This function call presumes the existence of a valid response format
    // and the correct implementation of the profile service and client service.
    try {

      setLoader(true)
      const payload = {
        token: await getToken(),
        invoiceSearch: invoiceAction?.Search.trim(),
        manageUserSearch: searchValue.trim(),
        invoiceSortValue: invoiceAction.Sort,
        invoiceSortOrder: invoiceAction.SortOrder,
        manageUserSortValue: manageUsersSortBy,
        manageUserSortOrder: manageUsersSortOrder,
      };
      const response = await getClientData(payload);
      //console.log(response?.data, "test");
      debugger
      setpopupmessage(response?.data?.statusMessage)
      if (response?.data?.statusCode !== 200) {
        setpopupmessage(response?.data?.data?.statusMessage);
        setAlertPopup(true)
      } else {
        //handleResend(response?.data?.data?.secondaryData[0].createdDate,response?.data?.data?.secondaryData[0].accountStatus)
        SetCountryCode(response?.data?.data?.ClientData[0]?.countryCode)
        // Update state with the received data
        setAddEnableButton(false)
        setAccountInfo((prevState) => ({
          ...prevState,
          name: response?.data?.data?.ClientData[0]?.userName,
          mail: response?.data?.data?.ClientData[0]?.mailId,
          company: response?.data?.data?.ClientData[0]?.companyName || "Avasoft",
          registrationType:
            response?.data?.data?.ClientData[0]?.isSSOAuthentication === true
              ? "SSO"
              : "Email",
          existPWD: response?.data?.data?.ClientData[0]?.permanentPassword,
          phone: ` ${response?.data?.data?.ClientData[0]?.countryCode} ${response?.data?.data?.ClientData[0]?.phoneNumber}`
        }));
        setpopupmessage(response?.data?.data?.statusMessage);
        setAccountInfoData((prevState) => ({
          ...prevState,
          name: response?.data?.data?.ClientData[0]?.userName,
          mail: response?.data?.data?.ClientData[0]?.mailId,
          company: response?.data?.data?.ClientData[0]?.companyName || "Avasoft",
          registrationType:
            response?.data?.data?.ClientData[0]?.isSSOAuthentication === true
              ? "SSO"
              : "Email",
          existPWD: response?.data?.data?.permanentPassword[0]?.permanentPassword,
          phone: response?.data?.data?.ClientData[0]?.phoneNumber,
          phonecode: response?.data?.data?.ClientData[0]?.countryCode
        }));
        setInvoiceData(response?.data?.data?.invoiceData);
        setLicenseData(response?.data?.data?.licenseData);
        setRoleData(response?.data?.data?.AdminRoles);
        //  await handleResend(response?.data?.data?.secondaryData)
        if (role.toLowerCase().includes('primary')) {
          setSecondaryUsers(response?.data?.data?.secondaryData)
          let id = response?.data?.data?.secondaryData
          if (response?.data?.data?.secondaryData.length == 0) {
            setNoData(true)
          }
          else {
            setNoData(false)
          }
        }
      }
      if (type == 'initial') {
        setLoader(false)
      }
    } catch (error: any) {
      console.error(error);
      setLoader(false)
      setAlertPopup(true);
    }
  };

  //handleResend :
  // const handleResend = (data:any) => {
  //   debugger
  //   ////console.log('Resend')
  //   try{
  //     data.forEach((item:any) => {
  //     if(item?.accountStatus.toLowerCase() === 'invited'){
  //       const createdDateObj = new Date(item?.createdDate);
  //       const currentDate = new Date();
  //       const diffInMilliseconds = (currentDate.getTime() - createdDateObj.getTime());
  //       const diffInDays = Math.floor((currentDate.getTime() - createdDateObj.getTime()) / (1000 * 60 * 60 * 24));
  //      if( diffInDays >= 3){
  //       ////console.log('showresend');
  //       setShowresend(true)
  //      }
  //      else{
  //       setShowresend(false)
  //      }} 
  //     })
  //   }
  //   catch (e: any) {
  //     ////console.log(e.message);
  //   }
  // }

  //PS_58, PS_59, PS_60
  const handleSort = (e: any) => {
    debugger
    try {
      ////console.log(handleSort, "sorttttttttttttttttttttsssssss");
      let sortID = e.target.id;
      let sortval = sortID.split("@");
      setManageUsersSortBy(sortval[0]);
      ////console.log(manageUsersSortBy, "jknxxaxx");
      setManageUsersSortOrder(sortval[1]);
      setManageUsersSortHide(sortID);
      ////console.log(manageUsersSortHide, "hidesssssssss");
    } catch (e: any) {
      ////console.log(e.message);
    }
  };
  const handleCountryChange = (selectedOption: any) => {
    debugger
    AccountInfo = ({ ...AccountInfo, phonecode: selectedOption?.value })
    setAccountInfoData(AccountInfo);
  };

  const onSearch = async () => {
    // setLoader(true)
    // if (!searchValue) {
    //   await getClientDetails('initial')
    //   ////console.log('Please enter values to search.');
    //   return; // Prevent further execution
    // }
    if (!searchValue?.length || searchValue?.trim() != "") {
      const result = await getClientDetails('initial');
      // setLoader(false)
      return result;
    }

  }
  const handleSearch = (event: any) => {
    ////console.log(event.target.value, "praveen");

    const value = event.target.value;
    setSearchValue(value);

  };



  ///PS_119, PS_120, PS_121, PS_122, PS_123, PS_124, PS_125, PS_126, PS_127, PS_128, PS_129, PS_130, PS_131, PS_132, 
  //PS_133, PS_134, PS_135, PS_136, PS_137, PS_138, PS_139, PS_140, PS_141, PS_142, PS_143, PS_144, PS_145, PS_146, PS_147,
  // PS_148, PS_149, PS_150, PS_151, PS_152, PS_153, PS_154, PS_155, PS_156, PS_157, PS_158, PS_159, PS_160, PS_161, PS_162, PS_163,
  // PS_164, PS_165, PS_166, PS_167, PS_168, PS_169, PS_170, PS_171, PS_172, PS_173, PS_174, PS_175, PS_176, PS_177, PS_178, PS_179, PS_180, 
  //PS_181, PS_182, PS_183, PS_184, PS_185, PS_186, PS_187, PS_188, PS_189, PS_190, PS_191, PS_192, PS_193, PS_194, PS_195, PS_196, PS_197, PS_198, PS_199,
  // PS_200, PS_201, PS_202, PS_203, PS_204, PS_205, PS_206, PS_207, PS_208, PS_209, PS_210, PS_211, PS_212, PS_213, PS_214, PS_215, PS_216, PS_217, PS_218, 
  //PS_219, PS_220, PS_221, PS_222, PS_223, PS_224, PS_225, PS_226, PS_227, PS_228, PS_229, PS_230, PS_231, PS_232, PS_233, PS_234, PS_235, PS_236, PS_237, PS_238,
  // PS_239, PS_240, PS_241, PS_242, PS_243, PS_244, PS_245, PS_246, PS_247, PS_248, PS_249
  const handleActions = async (e: any, tab: string, useraction: string) => {

    try {

      if (tab === "accountInfo" && useraction === "update") {
        handleFieldValidation(e.target.id, e.target.value, "accountInfo");
        if (
          errAccountInfo.errcompany === "" &&
          errAccountInfo.errmail === "" &&
          errAccountInfo.errname === "" &&
          errAccountInfo.errphone === "" &&
          errAccountInfo.errregistrationType === ""
        ) {
          // return if form is invalid
          let payload: object = {
            token: await getToken(),
            mail: AccountInfo.mail,
            userName: AccountInfo.name,
            phone: AccountInfo.phone,
            company: AccountInfo.company,
            regType: AccountInfo.registrationType,
            phncode: AccountInfo.phonecode,
          };
          //console.log(payload, "payload for the admin profile update");

          setEditPopup(false)
          setLoader(true)
          const response: any = await updateProfile(payload);

          setpopupmessage(response?.data?.statusMessage)
          //console.log("the response from the Backend ", response);
          setEnable(false);
          if (response?.data?.statusCode === 200) {
            debugger
            if (role.toLowerCase() == 'primaryadmin' || role.toLowerCase() == 'secondaryadmin' || role.toLowerCase() == 'adminviewer') {
              // const decryptedBytes = CryptoJS.AES.decrypt(response?.data?.data, rn_secretKey);
              // const decrptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
              // let encryptedToken: any = CryptoJS.AES.encrypt(decrptedValue, a_secretKey);
              sessionStorage.removeItem('a_at');
              //console.log("the encryptToken", encryptedToken);

              sessionStorage.setItem('a_at', response?.data?.data);
              setSuccessPopupType('accountInfoupdate')
              await getClientDetails('update')
              setLoader(false)
              setEditPopup(false);
              setSuccessPopup(true);
              setEnable(false);

            }
            else {
              let passphrase = `${process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY}`;
              // const decryptedBytes = CryptoJS.AES.decrypt(response?.data?.data, passphrase);
              // const decrptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
              // let encryptedToken: any = CryptoJS.AES.encrypt(decrptedValue, c_secretKey);
              sessionStorage.removeItem('c_at');
              sessionStorage.setItem("c_at", response?.data?.data);
              await getClientDetails('update')
              setLoader(false)
              setSuccessPopupType('accountInfoupdate')
              setEditPopup(false);
              setSuccessPopup(true);
            }

          } else {
            setEditPopup(false);
            setLoader(false)
            setAlertPopup(true);
          }
        }
      }
      if (tab === "password" && useraction === "submit") {
        setLoader(true)
        handleFieldValidation(e.target.id, e.target.value, "password");
        if (
          errpassword.erroldpassword === "" &&
          errpassword.errNewpassword === "" &&
          errpassword.errconfirmpassword === ""
        ) {
          // return if form is invalid
          let payload = {
            token: await getToken(),
            oldPassword: await encryptHybidMessage(password.oldpassword?.toString()),
            newpassword: await encryptHybidMessage(password.newpassword?.toString()),
            confirmpassword: await encryptHybidMessage(password.confirmpassword?.toString())
          };
          //console.log("the payload for updatePassword", payload);

          const response: any = await updatePassword(payload);
          // setpopupmessage(response?.data?.statusMessage==="Invalid payload provided"?"Please enter valid password":response?.data?.statusMessage)
          if (response?.data?.statusCode === 200) {
            setSuccessPopupType('passwordUpdate')
            setLoader(false)
            setSuccessPopup(true);
          }
          else if (response?.data?.statusMessage === "Invalid payload provided") {
            setLoader(false)
            errpassword = ({ ...errpassword, erroldpassword: 'Please enter valid password' })
            seterrpassword(errpassword);
          } else {

            setLoader(false)
            setAlertPopup(true);
          }
        }
      }
      if (tab === "manageUser" && useraction === "addAccount") {
        setLoader(true)
        handleFieldValidation(e.target.id, e.target.value, "manageUser");
        if (
          errmanageUser.erruserName === "" &&
          errmanageUser.errmail === "" &&
          errmanageUser.errauthenticationType === "" &&
          errmanageUser.role === ""
        ) {
          // return if form is invalid
          let payload: object = {
            token: await getToken(),
            useraction: useraction,
            mail: secondaryUser.email,
            userName: secondaryUser.userName,
            roleType: secondaryUser.role,
            authenticationType: secondaryUser.authenticationType,
            accountStatus: "Invited"
          };
          //console.log("the payload", payload);

          const response: any = await handleSecondaryUser(payload);
          setpopupmessage(response?.data?.statusMessage)
          //console.log(response, "the response for the secondary data");
          if (response?.data?.data?.recordset[0].ErrorMessage) {
            setLoader(false)
            seterrManageUser((prevState) => ({
              ...prevState,
              errmail: "The entered user already exist",
            }));
          }
          else if (response?.data?.statusCode === 200) {
            debugger
            setSuccessPopupType('insertuser')
            setSecondaryUsers([...secondaryUsers, { userName: secondaryUser.userName, mailId: secondaryUser.email, authenticationType: secondaryUser.authenticationType, roleType: secondaryUser.role, accountStatus: secondaryUser.accountStatus, adminDetailsId: secondaryUser.adminDetailsId, createdDate: secondaryUser.createdDate, manageUserDetailsId: 0 }]);
            getClientDetails('update')
            seteditmode(false)
            await sendInvite(secondaryUser.userName, secondaryUser.email, secondaryUser.role, secondaryUser.authenticationType)
            setSecondaryUser({ userName: '', email: '', authenticationType: '', role: '', accountStatus: '', adminDetailsId: 0, manageUserId: 0, createdDate: "" })
            setLoader(false)
            setSuccessPopup(true);
          }
          else {
            setEditPopup(false);
            setLoader(false)
            setAlertPopup(true);

          }
        }
      }
      if (tab === "manageUser" && useraction === "editAccount") {
        debugger
        //console.log("the edit for manageUsers 1");
        handleFieldValidation(e.target.id, e.target.value, "manageUser");
        if (
          errmanageUser.erruserName === "" &&
          errmanageUser.errmail === "" &&
          errmanageUser.errauthenticationType === "" &&
          errmanageUser.role === ""
        ) {
          //console.log("the edit for manageUsers 2");
          // return if form is invalid
          setLoader(true)
          //console.log("the manageUserDetailsId", secondaryUser);

          let payload: object = {
            token: await getToken(),

            useraction: useraction,
            mail: editData.mailId,
            userName: editData.userName,
            roleType: editData.roleType,
            authenticationType: editData.authenticationType,
            id: editData?.adminDetailsId ? editData?.adminDetailsId : editData.manageUserDetailsId,
            accountStatus: editData.accountStatus

          };

          //console.log("the edit for manageUsers", payload);

          const response: any = await handleSecondaryUser(payload);

          setpopupmessage(response?.data?.statusMessage)
          if (response?.data?.statusCode == 200) {
            ////console.log("i'm batman");
            getClientDetails('update')
            seteditmode(false)
            setEditPopup(false);
            setSuccessPopupType('userUpdate')
            setSuccessPopup(true);
            setLoader(false)

          }
          else if (response?.data?.data?.recordset[0]?.ErrorMessage) {
            setLoader(false)
            seterreditManageUser((prevState) => ({
              ...prevState,
              errmail: "user doesn't Exist",
            }));
          } else {
            ////console.log("i'm superman");
            setEditPopup(false);
            seteditmode(false)
            setLoader(false)
            setAlertPopup(true);
          }
        }
      }
      if (tab === "manageUser" && useraction === "deleteAccount") {
        ////console.log(deleteId, 'dddddddddddddddddddd');
        //console.log(editData, "the edit data");
        //console.log(secondaryUser, "the secondaryUser");
        //console.log(secondaryUsers, "the secondaryUsers ");
        //console.log(deleteId, "the deleteId is")




        setDeletePopup(false)
        setLoader(true)
        if (
          errmanageUser.erruserName === "" &&
          errmanageUser.errmail === "" &&
          errmanageUser.errauthenticationType === "" &&
          errmanageUser.role === ""
        ) {
          // return if form is invalid

          let payload: object = {
            token: await getToken(),
            useraction: useraction,
            id: deleteId,
            mail: deleteMailId

          };
          //console.log("the adminDetailsId 11", deleteId);

          const response: any = await handleSecondaryUser(payload);
          setpopupmessage(response?.data?.statusMessage)
          if (response?.data?.statusCode === 200) {
            getClientDetails('update')
            setLoader(false)
            setSuccessPopupType('userDelete')
            setSuccessPopup(true);
            setSecondaryUser({
              userName: '', email: '', authenticationType: '', role: '', accountStatus: '', adminDetailsId: 0, manageUserId: 0, createdDate: ""
            });


          } else {
            setDeletePopup(false);
            setLoader(false)
            setAlertPopup(true);
          }
        }
      }
    } catch (error: any) {
      ////console.log(error);
      setLoader(false)
      setAlertPopup(true);
    }
  };


  //PS_71, PS_72, PS_73, PS_74, PS_75, PS_76, PS_77, PS_78, PS_79, PS_80, PS_81, PS_82, PS_83, 
  //PS_84, PS_85, PS_86, PS_87, PS_88, PS_89, PS_90, PS_91, PS_92, PS_93, PS_94, PS_95, PS_96, 
  //PS_97, PS_98, PS_99, PS_100, PS_101, PS_102, PS_103, PS_104, PS_105, PS_106, PS_107, PS_108,
  // PS_109, PS_110, PS_111, PS_112, PS_113, PS_114, PS_115, PS_116, PS_117, PS_118
  const handleFieldValidation = (id: string, value: string, tab: string) => {
    if (tab === 'manageUser') {
      if (id === 'userName') {
        setSecondaryUser(prevState => ({ ...prevState, userName: value, accountStatus: "Invited" }));

        let errMessage = '';
        if (!value) {
          errMessage = "User Name cannot be empty";
        } else if (!/^[a-zA-Z]+$/.test(value)) {
          errMessage = "Invalid Name provided";
        } else if (value.length >= 80) {
          errMessage = "Maximum allowed characters is exceeded";
        }

        seterrManageUser(prevState => ({ ...prevState, erruserName: errMessage }));
      }

      else if (id === 'email') {
        setSecondaryUser(prevState => ({ ...prevState, email: value, accountStatus: "Invited" }));

        let errMessage = '';
        if (!value) {
          errMessage = "Email address cannot be empty";
        } else if (value.length > 320) {
          errMessage = "Maximum allowed characters is exceeded";
        } else if (!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          errMessage = "The Entered Email ID is invalid";
        }

        seterrManageUser(prevState => ({ ...prevState, errmail: errMessage }));
      }

      else if (id === 'authenticationType') {
        setSecondaryUser(prevState => ({ ...prevState, authenticationType: value, accountStatus: "Invited" }));

        let errMessage = '';
        if (!value) {
          errMessage = "Registration type cannot be empty";
        }

        seterrManageUser(prevState => ({ ...prevState, errauthenticationType: errMessage }));
      }

      else if (id === 'role') {
        setSecondaryUser(prevState => ({ ...prevState, role: value, accountStatus: "Invited" }));

        let errMessage = '';
        if (!value) {
          errMessage = "Role cannot be empty";
        }

        seterrManageUser(prevState => ({ ...prevState, role: errMessage }));
      }
    }
    else if (tab == 'manageUserEdit') {
      let result2 = true
      if (id === "userName") {
        setEditData((prevState) => ({ ...prevState, userName: value }));
        if (!value) {
          erreditmanageUser = ({ ...erreditmanageUser, erruserName: "User Name cannot be empty" });
          seterreditManageUser(erreditmanageUser);
          result2 = false
        }
        else if (value.length > 80) {
          erreditmanageUser = ({ ...erreditmanageUser, erruserName: "Maximum allowed characters is exceeded" });
          seterreditManageUser(erreditmanageUser);
          result2 = false
        }
        else if (!/^[a-zA-Z]+$/.test(value)) {
          erreditmanageUser = ({ ...erreditmanageUser, erruserName: "Invalid Name provided" });
          seterreditManageUser(erreditmanageUser);
          result2 = false;
        }
        else if (value == undefined) {
          result2 = false;
        }
        else {
          erreditmanageUser = ({ ...erreditmanageUser, erruserName: "" });
          seterreditManageUser(erreditmanageUser);
        }
      }
      else if (id === "email") {
        setEditData((prevState) => ({ ...prevState, mailId: value }));
        if (!value) {
          erreditmanageUser = ({ ...erreditmanageUser, errmail: "Email Address cannot be empty" });
          seterreditManageUser(erreditmanageUser);
          result2 = false
        }
        else if (value.length > 320) {
          erreditmanageUser = ({ ...erreditmanageUser, errmail: "Maximum allowed characters is exceeded" });
          seterreditManageUser(erreditmanageUser);
          result2 = false
        }
        else if (value == undefined) {
          result2 = false;
        }
        else if (!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          erreditmanageUser = ({ ...erreditmanageUser, errmail: "The Entered Email ID is invalid" });
          seterreditManageUser(erreditmanageUser);
          result2 = false
        }
        else {
          erreditmanageUser = ({ ...erreditmanageUser, errmail: "" });
          seterreditManageUser(erreditmanageUser);
        }
      }
      else if (id === "authenticationType") {
        setEditData((prevState) => ({
          ...prevState,
          authenticationType: value,
        }));
        if (!value) {
          erreditmanageUser = ({ ...erreditmanageUser, errauthenticationType: "Registration type is cannot be empty" });
          seterreditManageUser(erreditmanageUser);
          result2 = false
        }
        else if (value == undefined) {
          result2 = false;
        }
        else {
          erreditmanageUser = ({ ...erreditmanageUser, errauthenticationType: "" });
          seterreditManageUser(erreditmanageUser);
        }
      }
      else if (id === "role") {
        setEditData((prevState) => ({
          ...prevState,
          roleType: value,
        }));
        if (!value) {
          erreditmanageUser = ({ ...erreditmanageUser, role: "Role cannot be empty" });
          seterreditManageUser(erreditmanageUser);
          result2 = false
        }
        else if (value == undefined) {
          result2 = false;
        }
        else {
          erreditmanageUser = ({ ...erreditmanageUser, role: "" });
          seterreditManageUser(erreditmanageUser);
        }
      }
      if (result2 && erreditmanageUser.errauthenticationType == '' && erreditmanageUser.errmail == '' && erreditmanageUser.erruserName == '' && erreditmanageUser.role == '' && editData.roleType != '' && editData.authenticationType != '' && editData.mailId != '' && editData.userName != '') {
        setAddEnableButton(true)
      }
      else {
        setAddEnableButton(false)
      }
    }
    else if (tab == 'accountInfo') {
      let result = true
      if (id === "userName") {
        setAccountInfoData((prevState) => ({ ...prevState, name: value }));
        if (value === AccountInfo.name) {
          result = false
          return
        }
        if (!value) {
          errAccountInfo = ({ ...errAccountInfo, errname: "User Name cannot be empty" });
          seterrAccountInfo(errAccountInfo);
          result = false

        }
        else if (value.length > 80) {
          errAccountInfo = ({ ...errAccountInfo, errname: "Maximum allowed characters is exceeded" });
          seterrAccountInfo(errAccountInfo);
          result = false
        }
        else if (!/^[a-zA-Z]+$/.test(value)) {
          errAccountInfo = ({ ...errAccountInfo, errname: "Invalid User Name provided" });
          seterrAccountInfo(errAccountInfo);
          result = false;
        }
        else if (value == undefined) {
          result = false;
        }
        else {
          errAccountInfo = ({ ...errAccountInfo, errname: "" });
          seterrAccountInfo(errAccountInfo);
        }
      }
      else if (id === "userComp") {
        setAccountInfoData((prevState) => ({ ...prevState, company: value }));
        if (value === AccountInfo.company) {
          result = false
          return;
        }
        if (!value) {
          errAccountInfo = ({ ...errAccountInfo, errcompany: "Company name cannot be empty" });
          seterrAccountInfo(errAccountInfo);
          result = false

        }
        else if (value.length > 80) {
          errAccountInfo = {
            ...errAccountInfo,
            errcompany: "Invalid company name provided",
          };
          seterrAccountInfo(errAccountInfo);
          result = false
        }
        else {
          errAccountInfo = ({ ...errAccountInfo, errcompany: "" });
          seterrAccountInfo(errAccountInfo);
        }
      }
      else if (id === "userRegType") {
        setAccountInfoData((prevState) => ({
          ...prevState,
          registrationType: value,
        }));
        if (value === AccountInfo.registrationType) {
          result = false
          return;
        }
        if (!value) {
          errAccountInfo = ({ ...errAccountInfo, errregistrationType: "Registration type cannot be empty" });
          seterrAccountInfo(errAccountInfo);
          result = false

        }
        else {
          errAccountInfo = ({ ...errAccountInfo, errregistrationType: "" });
          seterrAccountInfo(errAccountInfo);
          result = false
        }
      }
      else if (id === "userMail") {
        setAccountInfoData((prevState) => ({ ...prevState, email: value }));
        if (value === AccountInfo.mail) {
          result = false
          return;
        }
        if (!value) {
          errAccountInfo = ({ ...errAccountInfo, errmail: "Email Address cannot be empty" });
          seterrAccountInfo(errAccountInfo);
          result = false
        }
        else if (value.length > 320) {
          errAccountInfo = ({ ...errAccountInfo, errmail: "Maximum allowed characters is exceeded" });
          seterrAccountInfo(errAccountInfo);
          result = false
        }
        else if (
          !value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ) {
          errAccountInfo = ({ ...errAccountInfo, errmail: "The Entered Email ID is invalid" });
          seterrAccountInfo(errAccountInfo);
          result = false
        }
        else {
          errAccountInfo = ({ ...errAccountInfo, errmail: "" });
          seterrAccountInfo(errAccountInfo);
        }
      }
      else if (id === "ph-no") {
        setAccountInfoData((prevState) => ({ ...prevState, phone: value }));
        if (value === AccountInfo.phone) {
          result = false
          return;
        }
        if (!value) {
          errAccountInfo = ({ ...errAccountInfo, errphone: "Phone number cannot be empty" });
          seterrAccountInfo(errAccountInfo);
          result = false

        }
        else if (value.length > 15) {
          errAccountInfo = ({ ...errAccountInfo, errphone: "Maximum allowed characters is exceeded" });
          seterrAccountInfo(errAccountInfo);
          result = false
        }
        else if (value.length < 5) {
          errAccountInfo = ({ ...errAccountInfo, errphone: "Invalid phone number provided" });
          seterrAccountInfo(errAccountInfo);
          result = false
        }
        else if (!value.match(/^\+?\d+$/)) {
          errAccountInfo = ({ ...errAccountInfo, errphone: "Invalid phone number provided" });
          seterrAccountInfo(errAccountInfo);
          result = false
        }
        else {
          errAccountInfo = ({ ...errAccountInfo, errphone: "" });
          seterrAccountInfo(errAccountInfo);
        }
      }
      debugger
      if (result && errAccountInfo.errcompany == '' && errAccountInfo.errmail == '' && errAccountInfo.errname == '' && errAccountInfo.errphone == '' && errAccountInfo.errregistrationType == '' && AccountInfo.name != '' && AccountInfo.company != '' && AccountInfo.mail != '' && AccountInfo.phone != '' && AccountInfo.phonecode != '' && AccountInfo.registrationType != '') {
        setEnable(true)
      }
      else {
        setEnable(false)
      }
    }
    else if (tab == 'password') {
      let result = true
      if (id === "old-password") {
        debugger
        setPassword((prevState) => ({ ...prevState, oldpassword: value }));
        if (!value) {
          errpassword = ({ ...errpassword, erroldpassword: 'This field cannot be empty' })
          seterrpassword(errpassword);
          result = false

        }
        else if ((/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/).test(value) === false) {
          errpassword = ({ ...errpassword, erroldpassword: 'Please the enter valid password' })
          seterrpassword(errpassword);

          result = false

        }

        else if (value.length > 20) {
          errpassword = ({ ...errpassword, erroldpassword: 'Maximum allowed charcters exceeded' })
          seterrpassword(errpassword);
          result = false
        }
        else if (password.newpassword != '' && value === password.newpassword) {
          errpassword = ({ ...errpassword, errNewpassword: "New Password cannot be Same as the Old password" })
          seterrpassword(errpassword);
          result = false

        }
        else {
          errpassword = ({ ...errpassword, erroldpassword: '' })
          seterrpassword(errpassword);
        }

        if (value !== password.newpassword && errpassword.errNewpassword === "") {
          errpassword = ({ ...errpassword, errNewpassword: errpassword.errNewpassword })
          seterrpassword(errpassword);
          result = false
        }
        else if (errpassword.errNewpassword === "New Password cannot be Same as the Old password" && value !== password.newpassword) {
          errpassword = ({ ...errpassword, errNewpassword: "" })
          seterrpassword(errpassword);
        }


      }
      else if (id === "new-password") {
        debugger
        setPassword((prevState) => ({ ...prevState, newpassword: value }));

        if (!value) {
          errpassword = ({ ...errpassword, errNewpassword: 'This field cannot be empty' })
          seterrpassword(errpassword);
          result = false

        }
        else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
          errpassword = ({ ...errpassword, errNewpassword: 'Please the enter valid password' })
          seterrpassword(errpassword);
          result = false
        }
        else if (value.length > 20) {
          errpassword = ({ ...errpassword, errNewpassword: 'Maximum allowed charcters exceeded' })
          seterrpassword(errpassword);
          result = false
        }

        else if (value === password.oldpassword) {
          seterrpassword(errpassword => ({ ...errpassword, errNewpassword: 'New Password cannot be Same as the Old password' }))
          result = false
        }
        else if (password.confirmpassword != '' && value != password.confirmpassword && value != password.oldpassword) {

          seterrpassword(errpassword => ({ ...errpassword, errconfirmpassword: 'Passwords does not match', errNewpassword: '' }))

          result = false
        }

        else if (value == password.confirmpassword && value !== password.oldpassword) {
          errpassword = ({ ...errpassword, errconfirmpassword: '', errNewpassword: '' })
          seterrpassword(errpassword);

        }
        else if (value == password.confirmpassword) {
          seterrpassword(errpassword => ({ ...errpassword, errconfirmpassword: '' }))
        }
        else {
          errpassword = ({ ...errpassword, errNewpassword: '' })
          seterrpassword(errpassword);
        }



      }
      else if (id === "confirm-password") {
        ////console.log("password : ", password);
        debugger
        password = ({ ...password, confirmpassword: value })
        setPassword(password);
        if (password.newpassword === password.oldpassword && (password.newpassword === value || password.newpassword !== value) && (password.newpassword !== "" && password.oldpassword !== "")) {
          errpassword = ({ ...errpassword, errconfirmpassword: '', errNewpassword: 'New Password cannot be Same as the Old password' })
          seterrpassword(errpassword);
        }
        // else {
        //   errpassword = ({ ...errpassword, errNewpassword: '' })
        //   seterrpassword(errpassword);
        // }
        if (!value) {
          errpassword = ({ ...errpassword, errconfirmpassword: 'This field cannot be empty' })
          seterrpassword(errpassword);
          result = false
        }
        else if (value.length > 20) {
          errpassword = ({ ...errpassword, errconfirmpassword: 'Maximum allowed charcters exceeded' })
          seterrpassword(errpassword);
          result = false
        }
        else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
          errpassword = ({ ...errpassword, errconfirmpassword: 'Please enter a valid password' })
          seterrpassword(errpassword);
          result = false
        }
        else if (value !== password.newpassword) {
          errpassword = ({ ...errpassword, errconfirmpassword: 'Passwords does not match' })
          seterrpassword(errpassword);
          result = false
        }
        else if (value == password.confirmpassword) {
          errpassword = ({ ...errpassword, errconfirmpassword: '' })
          seterrpassword(errpassword);
        }

        else {
          errpassword = ({ ...errpassword, errconfirmpassword: '' })
          seterrpassword(errpassword);
        }
      }
      debugger;
      if (result && errpassword.errNewpassword == '' && errpassword.errconfirmpassword == '' && errpassword.errmail == '' && errpassword.erroldpassword == '' && password.confirmpassword != '' && password.newpassword != '' && password.oldpassword != '') {
        setEnable(true)
      }
      else {
        setEnable(false)
      }
      // setAddEnableButton(result)
    }
  }

  //PS_294, PS_295, PS_296, PS_297, PS_298, PS_299, PS_300, PS_301
  async function handleEdit(rowData: any) {
    setEditData({
      userName: rowData.userName,
      mailId: rowData.mailId,
      authenticationType: rowData.authenticationType,
      roleType: rowData.roleType,
      accountStatus: rowData.accountStatus,
      adminRolesId: rowData.adminRolesId,
      manageUserDetailsId: rowData.manageUserDetailsId,
      adminDetailsId: rowData.adminDetailsId
    });
    //console.log("the id", rowData);

    seteditmode(true);
  }
  //PS_250, PS_251, PS_252, PS_253, PS_254, PS_255, PS_256, PS_257, PS_258, PS_259, PS_260, PS_261,
  // PS_262, PS_263, PS_264, PS_265, PS_266, PS_267, PS_268, PS_269, PS_270, PS_271, PS_272, PS_273,
  // PS_274, PS_275, PS_276, PS_277, PS_278, PS_279, PS_280, PS_281
  const sendInvite = async (user: string, mailId: any, roleType: any, authenticationType: any) => {
    debugger
    try {
      // setLoader(true)
      const payload = {
        token: await getToken(),
        user: user,
        mail: mailId,
        role: roleType,
        authtype: authenticationType
      }
      const response = await sendInvitemail(payload)
      setpopupmessage(response?.data?.statusCode)
      if (response?.data?.statusCode === 200) {
        // setLoader(false)
        setSuccessPopup(true);
      }
      else {
        setLoader(false)
        setAlertPopup(true);
      }
    }
    catch {
      setLoader(false)
      setAlertPopup(true);
    }
  }

  let passwordCancelClick = async () => {
    try {
      password = { ...password, mail: '', newpassword: '', confirmpassword: '', oldpassword: '' }
      setPassword(password)
      errpassword = { ...errpassword, errconfirmpassword: '', errmail: '', errNewpassword: '', erroldpassword: '' }
      seterrpassword(errpassword)
      setEnable(false);
    }
    catch (error) {
      //console.log('error : ', error);
    }
  }

  //Return Statement:
  return (
    <>
      <div className="container-fluid container-height">
        <div className="row">
          <div className="col-md-12">
            <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
              <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 ">
                <h5 className="font-20 font-bold mb-0 d-flex align-items-center">
                  Profile
                </h5>
              </div>
              <div className="col-md-12 d-lg-flex d-sm-block gap-3">
                <div className="col-md-12 col-lg-3 mb-5">
                  <div className="user-details py-3 px-4 grey-border">
                    <div className="text-end mb-4">
                      <img
                        src="/images/edit-icon.svg"
                        alt="icon-icon"
                        className="profile-edit-icon cursor-pointer"
                        onClick={() => setEditPopup(true)}
                      />
                    </div>
                    <div className="d-sm-flex d-lg-block justify-content-around">
                      <div className="text-center mb-5">
                        <span className="user-profile mb-1 d-flex align-items-center justify-content-center font-14 font-semibold profile-legend-name success m-auto">
                          {/* {role.toLowerCase() == 'primaryadmin' || role.toLowerCase() == 'secondaryadmin' || role.toLowerCase() == 'adminviewer' ? adminName[0] : clientName[0]} */}
                          {AccountInfodata.name[0]}
                        </span>
                        <h5 className="font-24 font-semibold mb-2">
                          {/* {role.toLowerCase() == 'primaryadmin' || role.toLowerCase() == 'secondaryadmin' || role.toLowerCase() == 'adminviewer' ? adminName : clientName} */}
                          {AccountInfodata.name}
                        </h5>
                        <p className="font-16 font-regular profile-grey">
                          {/* {role.toLowerCase() == 'primaryadmin' || role.toLowerCase() == 'secondaryadmin' || role.toLowerCase() == 'adminviewer' ? adminMailId : mailId} */}
                          {AccountInfodata.mail}
                        </p>
                      </div>
                      <div>
                        <p className="font-14 font-regular profile-grey mb-2 text-nowrap">
                          Company Name
                        </p>
                        <p className="font-13 font-semibold mb-4">
                          {AccountInfodata.company}
                        </p>
                        {role.toLowerCase() == "primaryadmin" ||
                          role.toLowerCase() == "secondaryadmin" ||
                          role.toLowerCase() == "adminviewer" ||
                          role.toLowerCase() == "secondaryuser" ||
                          role.toLowerCase() == "viewer" ||
                          role.toLowerCase() == "migrationmanager" ? null : (
                          <>
                            <p className="font-14 font-regular profile-grey mb-2 text-nowrap">
                              Phone
                            </p>
                            <p className="font-13 font-semibold mb-4">
                              {AccountInfodata.phone}
                            </p>
                          </>
                        )}
                        <p className="font-14 font-regular profile-grey mb-2 text-nowrap">
                          Registration Type
                        </p>
                        <p className="font-13 font-semibold mb-4">
                          {AccountInfodata.registrationType}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {role.toLowerCase() == "primaryadmin" ||
                  role.toLowerCase() == "secondaryadmin" ||
                  role.toLowerCase() == "adminviewer" ? (
                  <div className="col-md-12 col-lg-9">
                    {/*Tabs Starts here*/}
                    <ul
                      className="nav nav-pills mb-4 cust-padding-20"
                      id="pills-tab"
                      role="tablist"
                    >
                      {AccountInfo.registrationType.toLowerCase() === "email" ? (
                        <li className="nav-item custom-bg" role="presentation">
                          <button
                            className={AccountInfo.registrationType.toLowerCase() === "email" ? "nav-link theme-tab active font-16" : "nav-link theme-tab font-16"}
                            data-bs-toggle="pill"
                            data-bs-target="#password-reset"
                            type="button"
                            role="tab"
                            aria-selected="false"
                          >
                            Password Reset
                          </button>
                        </li>
                      ) : null}
                      {role.toLowerCase().includes('primary') ?
                        <li className="nav-item custom-bg" role="presentation">
                          <button
                            className={AccountInfo.registrationType.toLowerCase() === "email" ? "nav-link theme-tab font-16" : "nav-link theme-tab active font-16"}
                            data-bs-toggle="pill"
                            data-bs-target="#manage-users"
                            type="button"
                            role="tab"
                            aria-selected="false"
                          >
                            Manage Users
                          </button>
                        </li> : null}
                    </ul>
                    {/*Tabs ends here*/}
                    {/*Tab Inner starts here*/}
                    <div className="tab-content" id="pills-tabContent">
                      {/*Password Reset Tab starts here*/}
                      {AccountInfo.registrationType.toLowerCase() === "email" ?
                        <div
                          className={AccountInfo.registrationType.toLowerCase() === "email" ? "tab-pane fade show active cust-padding-20" : "tab-pane fade cust-padding-20"}
                          id="password-reset"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="col-md-12 mb-2">
                              <h5 className="font-bold font-18 color-black">
                                Password Reset
                              </h5>
                            </div>
                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="email"
                              >
                                Email Address{" "}
                                <span className="mandatory-color">*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control custom-form font-14 font-regular py-2"
                                id="email"
                                placeholder="Enter Email Address"
                                defaultValue={AccountInfo.mail}
                                onChange={(e) =>
                                  handleFieldValidation(
                                    e.target.id,
                                    e.target.value,
                                    "password"
                                  )
                                }
                                onPaste={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="old-password"
                              >
                                Old Password{" "}
                                <span className="mandatory-color">*</span>
                              </label>
                              <div className="position-relative">
                                <input
                                  type={oldhide ? "text" : "password"} // Changed variable name and simplified conditional statement
                                  className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                  id="old-password"
                                  placeholder="Enter Old Password"
                                  value={password.oldpassword}
                                  onChange={(e) => {
                                    ////console.log("Old Password Input Changed:", e.target.value);
                                    handleFieldValidation(
                                      e.target.id,
                                      e.target.value,
                                      "password"
                                    ); // Changed to pass value directly
                                  }}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                {oldhide ? ( // Simplified conditional rendering
                                  <img
                                    className="position-absolute custom-open-eye-postion"
                                    src="/images/password-eye-open.svg"
                                    alt="password-icon"
                                    onClick={() => {
                                      ////console.log("Hide password clicked");
                                      setoldHide(false); // Changed variable name
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="position-absolute custom-postion"
                                    src="/images/password-icon.svg"
                                    style={{ top: "50%" }}
                                    alt="password-icon"
                                    onClick={() => {
                                      ////console.log("Show password clicked");
                                      setoldHide(true); // Changed variable name
                                    }}
                                  />
                                )}
                              </div>
                              {errpassword.erroldpassword && (
                                <div
                                  className="font-12 font-semibold mandatory-color mt-2 mb-0 "
                                  style={{ position: "absolute" }}
                                >
                                  {errpassword.erroldpassword}
                                </div>
                              )}
                            </div>

                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="new-password"
                              >
                                New Password{" "}
                                <span className="mandatory-color">*</span>{" "}
                                <Tippy
                                  arrow={true}
                                  // followCursor={false}
                                  // plugins={[followCursor]}
                                  placement="top"
                                  content="Password should contain atleast 8-20 characters atleast 1 Special character, atleast 1 Number, atleast 1 Uppercase, atleast 1 Lowercase"
                                >
                                  <img
                                    src="/images/tooltip.svg"
                                    alt="tooltip"
                                    width={11}
                                  />
                                </Tippy>
                              </label>
                              <div className="position-relative">
                                <input
                                  type={hide == true ? "text" : "password"}
                                  className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                  id="new-password"
                                  placeholder="Enter New Password"
                                  value={password.newpassword}
                                  onChange={(e) =>
                                    handleFieldValidation(
                                      e.target.id,
                                      e.target.value,
                                      "password"
                                    )
                                  }
                                  onPaste={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                {hide == true ? (
                                  <img
                                    className="position-absolute custom-open-eye-postion"
                                    src="/images/password-eye-open.svg"
                                    alt="password-icon"
                                    onClick={() => setHide(false)}
                                  />
                                ) : (
                                  <img
                                    className="position-absolute custom-postion"
                                    src="/images/password-icon.svg"
                                    alt="password-icon"
                                    style={{ top: "50%" }}
                                    onClick={() => setHide(true)}
                                  />
                                )}
                              </div>

                              {errpassword.errNewpassword && (
                                <div
                                  className="font-12 font-semibold mandatory-color mt-2 mb-0 "
                                  style={{ position: "absolute" }}
                                >
                                  {errpassword.errNewpassword}
                                </div>
                              )}
                            </div>

                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="confirm-password"
                              >
                                Confirm Password{" "}
                                <span className="mandatory-color">*</span>{" "}
                                <Tippy
                                  arrow={true}
                                  followCursor={"horizontal"}
                                  plugins={[followCursor]}
                                  placement="top"
                                  content="Please re-enter your password to confirm. Ensure it matches the password you entered above."
                                >
                                  <img
                                    src="/images/tooltip.svg"
                                    alt="tooltip"
                                    width={11}
                                  />
                                </Tippy>
                              </label>
                              <div className="position-relative">
                                <input
                                  type={Confirmhide ? "text" : "password"}
                                  className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                  id="confirm-password"
                                  placeholder="Confirm New Password"
                                  value={password.confirmpassword}
                                  onChange={(e) =>
                                    handleFieldValidation(
                                      e.target.id,
                                      e.target.value,
                                      "password"
                                    )
                                  }
                                  onPaste={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                {Confirmhide == true ? (
                                  <img
                                    className="position-absolute custom-open-eye-postion"
                                    src="/images/password-eye-open.svg"
                                    alt="password-icon"
                                    onClick={() => setconfirmHidee(false)}
                                  />
                                ) : (
                                  <img
                                    className="position-absolute custom-postion"
                                    src="/images/password-icon.svg"
                                    alt="password-icon"
                                    style={{ top: "50%" }}
                                    onClick={() => setconfirmHidee(true)}
                                  />
                                )}
                              </div>
                              {errpassword.errconfirmpassword && (
                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                  {errpassword.errconfirmpassword}
                                </div>
                              )}
                            </div>

                            <div className="col-md-12 mt-3">
                              <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 py-4">
                                <button
                                  className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 px-4"
                                  onClick={() => passwordCancelClick()}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                                  onClick={(e) =>
                                    handleActions(e, "password", "submit")
                                  }
                                  disabled={!enable}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> : null}
                      {/*Password Reset Tab ends here*/}
                      {/*Manage Users Tab starts here*/}
                      {role.toLowerCase().includes('primary') ?
                        <div
                          className={AccountInfo.registrationType.toLowerCase() === "email" ? "tab-pane fade cust-padding-20" : "tab-pane fade show active cust-padding-20"}
                          id="manage-users"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="col-md-12 mb-2">
                              <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                <h5 className="font-bold font-18 color-black">
                                  Manage Users (
                                  {secondaryUsers?.length
                                    ? secondaryUsers?.length
                                    : 0}
                                  ){" "}
                                  <Tippy
                                    delay={500}
                                    arrow={true}
                                    // followCursor={false}
                                    // plugins={[followCursor]}
                                    placement="top"
                                    content={
                                      <>
                                        Admins with multiple roles can be added:
                                        <br />
                                        • Secondary admin can make changes in the
                                        application except for adding admins.
                                        <br />
                                        • Admin viewer can only access the application.
                                      </>
                                    }
                                    allowHTML={true}
                                  >
                                    <img
                                      src="/images/tooltip.svg"
                                      alt="tooltip"
                                      width={11}
                                    />
                                  </Tippy>
                                </h5>
                                <div className="d-flex gap-3 flex-wrap align-items-center">
                                  <div className="d-flex gap-3 align-items-center">
                                    <div className="input-group box-shadow-filter search-container float-start">
                                      <input
                                        type="search"
                                        className="form-control custom-form font-14 font-regular"
                                        placeholder="Search"
                                        onKeyDown={(event) => {
                                          if (event.key == "Enter") {
                                            onSearch()
                                          }
                                        }}
                                        onChange={handleSearch}
                                      />
                                      <button
                                        className="btn border d-flex align-items-center"
                                        type="button"
                                        onClick={onSearch}
                                      >
                                        <img
                                          src="/images/search.svg"
                                          alt="search"
                                          className="d-block"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*Grid starts here*/}
                              <div className="table-responsive theme-table bg-white ">
                                <table className="table table-borderless table-shadow mb-0">
                                  <thead>
                                    <tr>
                                      <th className="text-nowrap">
                                        User Name
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={`/images/sort-arrow-up.svg`}
                                            alt="sort-arrow"
                                            style={{ cursor: "pointer" }}
                                            id={`userName@DESC`}
                                            hidden={
                                              manageUsersSortHide ==
                                                `userName@ASC` || ""
                                                ? false
                                                : true
                                            }
                                            onClick={(e) => {
                                              handleSort(e);
                                            }}
                                          />
                                          <img
                                            src={`/images/sort-arrow-down.svg`}
                                            alt="sort-arrow"
                                            id={`userName@ASC`}
                                            style={{ cursor: "pointer" }}
                                            hidden={
                                              manageUsersSortHide ==
                                                `userName@ASC` || ""
                                                ? true
                                                : false
                                            }
                                            onClick={(e: any) => {
                                              handleSort(e);
                                            }}
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap">
                                        Email Address
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={`/images/sort-arrow-up.svg`}
                                            alt="sort-arrow"
                                            style={{ cursor: "pointer" }}
                                            id={`mailId@DESC`}
                                            hidden={
                                              manageUsersSortHide ==
                                                `mailId@ASC` || ""
                                                ? false
                                                : true
                                            }
                                            onClick={(e: any) => {
                                              handleSort(e);
                                            }}
                                          />
                                          <img
                                            src={`/images/sort-arrow-down.svg`}
                                            alt="sort-arrow"
                                            id={`mailId@ASC`}
                                            style={{ cursor: "pointer" }}
                                            hidden={
                                              manageUsersSortHide ==
                                                `mailId@ASC` || ""
                                                ? true
                                                : false
                                            }
                                            onClick={(e: any) => {
                                              handleSort(e);
                                            }}
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap">
                                        Authentication Type
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={`/images/sort-arrow-up.svg`}
                                            alt="sort-arrow"
                                            style={{ cursor: "pointer" }}
                                            id={`authenticationType@DESC`}
                                            hidden={
                                              manageUsersSortHide ==
                                                `authenticationType@ASC` || ""
                                                ? false
                                                : true
                                            }
                                            onClick={(e: any) => {
                                              handleSort(e);
                                            }}
                                          />
                                          <img
                                            src={`/images/sort-arrow-down.svg`}
                                            alt="sort-arrow"
                                            id={`authenticationType@ASC`}
                                            style={{ cursor: "pointer" }}
                                            hidden={
                                              manageUsersSortHide ==
                                                `authenticationType@ASC` || ""
                                                ? true
                                                : false
                                            }
                                            onClick={(e: any) => {
                                              handleSort(e);
                                            }}
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap">
                                        Role
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={`/images/sort-arrow-up.svg`}
                                            alt="sort-arrow"
                                            style={{ cursor: "pointer" }}
                                            id={`roleType@DESC`}
                                            hidden={
                                              manageUsersSortHide ==
                                                `roleType@ASC` || ""
                                                ? false
                                                : true
                                            }
                                            onClick={(e) => {
                                              handleSort(e);
                                            }}
                                          />
                                          <img
                                            src={`/images/sort-arrow-down.svg`}
                                            alt="sort-arrow"
                                            id={`roleType@ASC`}
                                            style={{ cursor: "pointer" }}
                                            hidden={
                                              manageUsersSortHide ==
                                                `roleType@ASC` || ""
                                                ? true
                                                : false
                                            }
                                            onClick={(e) => {
                                              handleSort(e);
                                            }}
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap">
                                        Account Status
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={`/images/sort-arrow-up.svg`}
                                            alt="sort-arrow"
                                            style={{ cursor: "pointer" }}
                                            id={`accountStatus@DESC`}
                                            hidden={
                                              manageUsersSortHide ==
                                                `accountStatus@ASC` || ""
                                                ? false
                                                : true
                                            }
                                            onClick={(e) => {
                                              handleSort(e);
                                            }}
                                          />
                                          <img
                                            src={`/images/sort-arrow-down.svg`}
                                            alt="sort-arrow"
                                            id={`accountStatus@ASC`}
                                            style={{ cursor: "pointer" }}
                                            hidden={
                                              manageUsersSortHide ==
                                                `accountStatus@ASC` || ""
                                                ? true
                                                : false
                                            }
                                            onClick={(e) => {
                                              handleSort(e);
                                            }}
                                          />
                                        </span>
                                      </th>
                                      {role.toLowerCase() !== "viewer" ||
                                        role.toLowerCase() !== "adminviewer" ? (
                                        <th className="text-nowrap text-center ">
                                          Action
                                        </th>
                                      ) : null}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control custom-form font-regular font-14"
                                          placeholder="Enter User Name"
                                          name="userName"
                                          value={
                                            !editMode
                                              ? secondaryUser.userName
                                              : ""
                                          }
                                          onChange={(e) => {
                                            {
                                              ////console.log(e.target.value);
                                            }

                                            handleFieldValidation(
                                              "userName",
                                              e.target.value,
                                              "manageUser"
                                            );
                                          }}
                                        />

                                        {errmanageUser.erruserName && (
                                          <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                            {" "}
                                            {errmanageUser.erruserName}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          // className={`form-control custom-form font-regular font-14 ${(!secondaryUser.email && formSubmitted) || (erremail && formSubmitted) ? 'is-invalid' : ''}`}
                                          className="form-control custom-form font-regular font-14"
                                          placeholder="Enter Email Address"
                                          name="email"
                                          value={
                                            !editMode ? secondaryUser.email : ""
                                          }
                                          onChange={(e) => {
                                            handleFieldValidation(
                                              "email",
                                              e.target.value,
                                              "manageUser"
                                            );
                                          }}
                                        />
                                        {errmanageUser.errmail && (
                                          <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                            {" "}
                                            {errmanageUser.errmail}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div>
                                          <select
                                            className="form-select font-regular font-14 custom-form py-2"
                                            id="type"
                                            aria-label="Default select example"
                                            name="authenticationType"
                                            value={
                                              !editMode
                                                ? secondaryUser.authenticationType
                                                : ""
                                            }
                                            onChange={(e) => {
                                              handleFieldValidation(
                                                "authenticationType",
                                                e.target.value,
                                                "manageUser"
                                              );
                                            }}
                                          >
                                            <option value="" disabled>
                                              Select
                                            </option>
                                            {authenticationType.map(
                                              (option: any, index: any) => (
                                                <option
                                                  key={index}
                                                  value={
                                                    option.authenticationType
                                                  }
                                                >
                                                  {option.authenticationType}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                        {/* {formSubmitted && invalidAuthType && (
                                                                    <div className="invalid-feedback">Authentication Type is required.</div>
                                                                )} */}

                                        <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                          {errmanageUser.errauthenticationType}
                                        </div>
                                      </td>
                                      <td>
                                        <select
                                          className="form-select font-regular font-14 custom-form py-2"
                                          id="type2"
                                          aria-label="Default select example"
                                          name="role"
                                          value={
                                            !editMode ? secondaryUser.role : ""
                                          }
                                          onChange={(e) => {
                                            handleFieldValidation(
                                              "role",
                                              e.target.value,
                                              "manageUser"
                                            );
                                          }}
                                        >
                                          <option value="" disabled>
                                            Select
                                          </option>
                                          {roleData.map(
                                            (option: any, index: any) => (
                                              <option
                                                key={index}
                                                id={option.adminRolesId}
                                                value={option.roleType}
                                              >
                                                {option.roleType}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        {/* {formSubmitted && invalidRole && !editMode(
                                                                    <div className="invalid-feedback">Role is required.</div>
                                                                )} */}

                                        <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                          {errmanageUser.role}
                                        </div>
                                      </td>
                                      <td>
                                        <span className="text-nowrap">
                                          <span className="table-status draft d-inline-block me-2 " />
                                          Queue
                                        </span>
                                      </td>
                                      {role.toLowerCase() !== "viewer" ||
                                        role.toLowerCase() !== "adminviewer" ? (
                                        <td className="text-center">
                                          <span className="d-inline-block">
                                            <button
                                              className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center"
                                              onClick={(e) =>
                                                handleActions(
                                                  e,
                                                  "manageUser",
                                                  "addAccount"
                                                )
                                              }
                                              disabled={!manageUserbutton}
                                            >
                                              <img
                                                src="/images/blue-plus-icon.svg"
                                                alt="blue-plus-icon"
                                              />
                                            </button>
                                          </span>
                                        </td>
                                      ) : null}
                                    </tr>
                                    {secondaryUsers
                                      ?.filter((acc) =>
                                        Object.values(acc)?.some(
                                          (value) => value !== ""
                                        )
                                      )
                                      ?.map((acc: any, index) => (
                                        <React.Fragment key={index}>
                                          {editMode &&
                                            editData.adminDetailsId ===
                                            acc.adminDetailsId ? (
                                            // Render input fields for the row being edited
                                            <tr>
                                              <td>
                                                <input
                                                  type="text"
                                                  className="form-control custom-form font-regular font-14"
                                                  placeholder="Enter User Name"
                                                  name="userName"
                                                  defaultValue={editData.userName}
                                                  onChange={(e) => {
                                                    handleFieldValidation(
                                                      "userName",
                                                      e.target.value,
                                                      "manageUserEdit"
                                                    );
                                                  }}
                                                />
                                                {erreditmanageUser.erruserName && (
                                                  <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                    {
                                                      erreditmanageUser.erruserName
                                                    }
                                                  </div>
                                                )}
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  className="form-control custom-form font-regular font-14"
                                                  placeholder="Enter Email Address"
                                                  name="mailId"
                                                  defaultValue={editData.mailId}
                                                  onChange={(e) => {
                                                    handleFieldValidation(
                                                      "email",
                                                      e.target.value,
                                                      "manageUserEdit"
                                                    );
                                                  }}
                                                />
                                                {erreditmanageUser.errmail && (
                                                  <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                    {erreditmanageUser.errmail}
                                                  </div>
                                                )}
                                              </td>
                                              <td>
                                                <select
                                                  className="form-select font-regular font-14 custom-form py-2"
                                                  id="authenticationType"
                                                  aria-label="Default select example"
                                                  name="authenticationType"
                                                  defaultValue={
                                                    editData.authenticationType
                                                  }
                                                  onChange={(e) => {
                                                    handleFieldValidation(
                                                      "authenticationType",
                                                      e.target.value,
                                                      "manageUserEdit"
                                                    );
                                                  }}
                                                >
                                                  <option value="" disabled>
                                                    Select
                                                  </option>
                                                  {authenticationType.map(
                                                    (option, index) => (
                                                      <option
                                                        key={index}
                                                        value={
                                                          option.authenticationType
                                                        }
                                                      >
                                                        {
                                                          option.authenticationType
                                                        }
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                                {/* {erreditmanageUser.authenticationType && (
                                              <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                {erreditmanageUser.authenticationType}
                                              </div>
                                            )} */}
                                              </td>
                                              <td>
                                                <select
                                                  className="form-select font-regular font-14 custom-form py-2"
                                                  id="role"
                                                  aria-label="Default select example"
                                                  name="role"
                                                  defaultValue={editData.roleType}
                                                  onChange={(e) => {
                                                    handleFieldValidation(
                                                      "role",
                                                      e.target.value,
                                                      "manageUserEdit"
                                                    );
                                                  }}
                                                >
                                                  <option value="" disabled>
                                                    Select
                                                  </option>
                                                  {roleData.map(
                                                    (
                                                      option: {
                                                        roleType: string;
                                                      },
                                                      index
                                                    ) => (
                                                      <option
                                                        key={index}
                                                        value={option.roleType}
                                                      >
                                                        {option.roleType}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                                {erreditmanageUser.role && (
                                                  <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                    {erreditmanageUser.role}
                                                  </div>
                                                )}
                                              </td>
                                              <td>
                                                <span className="text-nowrap">
                                                  {editData.accountStatus}
                                                </span>
                                              </td>
                                              <td className="text-center">
                                                <span style={{ display: "flex" }}>
                                                  <button
                                                    className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center"
                                                    onClick={(e) =>
                                                      handleActions(
                                                        e,
                                                        "manageUser",
                                                        "editAccount"
                                                      )
                                                    }
                                                    disabled={!manageUserbutton}
                                                  >
                                                    <img
                                                      src="/images/tick-action-icon.svg"
                                                      alt="tick-icon"
                                                    />
                                                  </button>
                                                  <button
                                                    className="btn action-rounded-btn success-action d-flex align-items-center justify-content-center"
                                                    onClick={() => {
                                                      seteditmode(false);
                                                      setEditData({
                                                        ...editData,
                                                        accountStatus: "",
                                                        authenticationType: "",
                                                        userName: "",
                                                        manageUserDetailsId: 0,
                                                        roleType: "",
                                                        mailId: "",
                                                      });
                                                    }}
                                                  >
                                                    <img
                                                      src="/images/cancel-icon.svg"
                                                      alt="cancel"
                                                    />
                                                  </button>
                                                </span>
                                              </td>
                                            </tr>
                                          ) : (
                                            // Render normal row data
                                            <tr>
                                              <td>{acc.userName}</td>
                                              <td>{acc.mailId}</td>
                                              <td>{acc.authenticationType}</td>
                                              <td>{acc.roleType}</td>
                                              <td>
                                                <span className="text-nowrap">
                                                  <span
                                                    className={
                                                      acc?.accountStatus?.toLowerCase() ===
                                                        "invited"
                                                        ? "table-status inprogress d-inline-block me-2"
                                                        : acc?.accountStatus?.toLowerCase() ===
                                                          "failed"
                                                          ? "table-status danger d-inline-block me-2"
                                                          : acc?.accountStatus?.toLowerCase() ===
                                                            "active"
                                                            ? "table-status success d-inline-block me-2"
                                                            : ""
                                                    }
                                                  />
                                                  {acc.accountStatus}
                                                </span>
                                              </td>
                                              <td className="text-center">
                                                <span className="action-drop-down">
                                                  <img
                                                    src="/images/keb-menu-icon.svg"
                                                    alt="keb-menu-icon"
                                                    data-bs-toggle="dropdown"
                                                    className="p-2 action-icon rounded-3"
                                                    aria-expanded="false"
                                                  />
                                                  <span className="dropdown-menu font-regular font-14 p-1">
                                                    <span className="d-flex gap-2 px-3 py-2 list" onClick={() => handleEdit(acc)} aria-disabled={!role.toLowerCase().includes('primary')}>
                                                      <img src="/images/edit-icon.svg" alt="edit" />
                                                      Edit
                                                    </span>
                                                    <span className="d-flex gap-2 px-3 py-2 list" onClick={() => {
                                                      setDeleteid(acc.adminDetailsId);
                                                      setDeleteMailId(acc.mailId);
                                                      setDeletePopup(true);
                                                    }}>
                                                      <img src="/images/red-delete-icon.svg" alt="delete" />
                                                      Delete
                                                    </span>

                                                    {Math.floor((currentDate.getTime() - new Date(acc.createdDate).getTime()) / (1000 * 60 * 60)) >= 72 ||
                                                      acc.accountStatus === "Expired" ? (
                                                      <span className="d-flex gap-2 px-3 py-2 list" onClick={() => sendInvite(acc.userName, acc.mailId, acc.roleType, acc.authenticationType)}>
                                                        <img src="/images/resend-icon.svg" alt="resend" />
                                                        Resend
                                                      </span>
                                                    ) : null}

                                                  </span>
                                                </span>
                                              </td>
                                            </tr>
                                          )}
                                        </React.Fragment>
                                      ))}
                                    {noData && (
                                      <tr>
                                        <td colSpan={100}>
                                          <div className="pt-4 pb-3 text-center">
                                            <img
                                              src="/images/no-data-found-img.svg"
                                              alt="no-data-found-img"
                                              className="mb-5 mx-auto"
                                            />
                                            <h5 className="font-12 font-bold color-no-rec">
                                              No Record
                                            </h5>
                                            <p className="font-10 font-regular color-no-rec">
                                              There is no Record to show you right
                                              now
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {/*Grid ends here*/}
                            </div>
                          </div>
                        </div> : null
                      }
                      {(AccountInfo.registrationType.toLowerCase() !== "email" && !role.toLowerCase().includes('primary'))?
                        <div className="container-fluid p-2 bg-white card-bg-img">
                        <div className='centerallign'>
                            <h4 className="text-center contact-us font-bold font-24 mb-4">No Data to Display</h4>
                            <p className="text-center contact-us-para font-18 font-regular">Login with the primary admin role to manage the secondary users.</p>
                            <p className="text-center contact-us-para font-18 font-regular">Cannot reset password due to SSO authentication type.</p>
                        </div>
                      </div> :null}
                      {/*Manage Users Tab ends here*/}
                    </div>
                    {/*Tab Inner ends here*/}
                  </div>
                ) : (
                  <div className="col-md-12 col-lg-9">
                    <ul
                      className="nav nav-pills mb-5 cust-padding-20 cust-nav"
                      id="pills-tab"
                      role="tablist"
                    >
                        <li
                          className="nav-item custom-bg rounded-start"
                          role="presentation"
                        >
                          <button
                            className="nav-link theme-tab active font-16 text-nowrap"
                            data-bs-toggle="pill"
                            data-bs-target="#accountInformation"
                            type="button"
                            role="tab"
                            aria-selected="true"
                          >
                            License
                          </button>
                        </li>
                      {AccountInfo.registrationType.toLowerCase() === "email" ? (
                        <li className="nav-item custom-bg" role="presentation">
                          <button
                            className="nav-link theme-tab font-16 text-nowrap"
                            data-bs-toggle="pill"
                            data-bs-target="#password-reset"
                            type="button"
                            role="tab"
                            aria-selected="false"
                          >
                            Password Reset
                          </button>
                        </li>
                      ) : null}
                      {role.toLowerCase() === "primaryuser" ? (
                        <li className="nav-item custom-bg" role="presentation">
                          <button
                            className="nav-link theme-tab font-16 text-nowrap"
                            data-bs-toggle="pill"
                            data-bs-target="#manage-users"
                            type="button"
                            role="tab"
                            aria-selected="false"
                          >
                            Manage Users
                          </button>
                        </li>
                      ) : null}
                      {/*
                      <li
                        className="nav-item custom-bg rounded-end"
                        role="presentation"
                      >
                        <button
                          className="nav-link theme-tab font-16 text-nowrap"
                          data-bs-toggle="pill"
                          data-bs-target="#invoice-history"
                          type="button"
                          role="tab"
                          aria-selected="false"
                        >
                          Invoice History
                        </button>
                      </li>
                      */}
                    </ul>
                    {/*Tabs ends here*/}
                    {/*Tab Inner starts here*/}
                    <div className="tab-content" id="pills-tabContent">
                      {/*License Information Tab starts here*/}
                      <div
                        className="tab-pane fade show active cust-padding-20"
                        id="accountInformation"
                        role="tabpanel"
                      >
                        <div className="col-md-12 mb-4">
                          <h5 className="font-bold font-18 color-black">
                            Available License
                          </h5>
                        </div>
                        {/*Slack License Information Details Starts here
                        <div className="col-md-12 mb-3 grey-border license-container px-4 py-3 ">
                          <div className="d-flex gap-2 align-items-center pb-3 bottom-border">
                            <img
                              src="/images/slack-license.svg"
                              alt="slack-logo"
                              className=""
                            />
                            <h5 className="font-16 font-bold m-0">
                              Slack License
                            </h5>
                            
                          </div>

                          <div className="announcement-carousel mb-0 mt-3 d-flex">
                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  Plan Expires On
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                  May 15, 2024
                                </p>
                              </div>
                            </div>

                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  Parallel Instance
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                  Assessment
                                </p>
                              </div>
                            </div>

                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  Conversation Migration
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                  Channel Migration
                                </p>
                              </div>
                            </div>

                          </div>
                        </div>
                        */}
                        {licenseData.map((data: any, index: any) => (
                          <div className="col-md-12 mb-3 grey-border license-container px-4 py-3 ">
                            <div className="d-flex gap-2 align-items-center pb-3 bottom-border">
                              <img
                                src="/images/tenant-license.svg"
                                alt="slack-logo"
                                className=""
                              />
                              <h5 className="font-16 font-bold m-0">
                                Tenant License
                              </h5>
                              {/* <div className="carousel-nav ">
                                <button className="next-arrow scroll-btn" id="next-arrow1">
                                  <img
                                    src="/images/right-arrow.svg"
                                    alt="right-arrow"
                                  />
                                </button>
                              </div> */}
                            </div>

                            <div className="overflow-auto mb-0 mt-3 d-flex" >
                              <div className="announcement-item">
                                <div>
                                  <p className="font-14 font-regular profile-grey mb-2 text-nowrap">
                                    Plan Expires On
                                  </p>
                                  <p className="font-13 font-semibold mb-0 text-nowrap">
                                    {data?.licenseExpiredAt
                                      ? new Date(
                                        data.licenseExpiredAt
                                      ).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      })
                                      : ""}
                                  </p>
                                </div>
                              </div>

                              <div className="announcement-item">
                                <div>
                                  <p className="font-14 font-regular profile-grey mb-2 text-nowrap">
                                    Parallel Instance
                                  </p>
                                  <p className="font-13 font-semibold mb-0 text-nowrap">
                                    {(data?.purchasedParallelinstance == null
                                      ? 0 : data?.purchasedParallelinstance) -
                                      (data?.usedParallelinstanceCount == null
                                        ? 0 : data?.usedParallelinstanceCount)}
                                    /{(data?.purchasedParallelinstance == null
                                      ? 0 : data?.purchasedParallelinstance)}
                                  </p>
                                </div>
                              </div>

                              <div className="announcement-item">
                                <div>
                                  <p className="font-14 font-regular profile-grey mb-2 text-nowrap">
                                    Assessment
                                  </p>
                                  <p className="font-13 font-semibold mb-0 text-nowrap">
                                    {(data?.purchasedAssessmentLicense == null
                                      ? 0 : data?.purchasedAssessmentLicense) -
                                      (data?.usedAssessmentLicense == null
                                        ? 0 : data?.usedAssessmentLicense)}
                                    /{(data?.purchasedAssessmentLicense == null
                                      ? 0 : data?.purchasedAssessmentLicense)}
                                  </p>
                                </div>
                              </div>

                              <div className="announcement-item">
                                <div>
                                  <p className="font-14 font-regular profile-grey mb-2 text-nowrap">
                                    SharePoint License
                                  </p>
                                  <p className="font-13 font-semibold mb-0 text-nowrap">
                                    {(data?.purchasedSharePointLicense == null
                                      ? 0
                                      : data?.purchasedSharePointLicense) -
                                      (data?.usedSharePointLicense == null
                                        ? 0
                                        : data?.usedSharePointLicense)}
                                    /
                                    {data?.purchasedSharePointLicense == null
                                      ? 0
                                      : data?.purchasedSharePointLicense}
                                  </p>
                                </div>
                              </div>
                              <div className="announcement-item">
                                <div>
                                  <p className="font-14 font-regular profile-grey mb-2 text-nowrap">
                                    User Mailbox
                                  </p>
                                  <p className="font-13 font-semibold mb-0 text-nowrap">
                                    {(data?.purchasedUserMailboxLicense == null
                                      ? 0 : data?.purchasedUserMailboxLicense) -
                                      (data?.usedUsermailboxLicense == null
                                        ? 0 : data?.usedUsermailboxLicense)}
                                    /{(data?.purchasedUserMailboxLicense == null
                                      ? 0 : data?.purchasedUserMailboxLicense)}
                                  </p>
                                </div>
                              </div>
                              <div className="announcement-item">
                                <div>
                                  <p className="font-14 font-regular profile-grey mb-2 text-nowrap">
                                    Teams Channel License
                                  </p>
                                  <p className="font-13 font-semibold mb-0 text-nowrap">
                                    {(data?.purchasedTeamChannelLicense ==
                                      null
                                      ? 0
                                      : data?.purchasedTeamChannelLicense) -
                                      (data?.usedTeamChannelLicense ==
                                        null
                                        ? 0
                                        : data?.usedTeamChannelLicense)}
                                    /
                                    {(data?.purchasedTeamChannelLicense ==
                                      null
                                      ? 0
                                      : data?.purchasedTeamChannelLicense)}
                                  </p>
                                </div>
                              </div>
                              <div className="announcement-item">
                                <div>
                                  <p className="font-14 font-regular profile-grey mb-2 text-nowrap">
                                    One Drive License
                                  </p>
                                  <p className="font-13 font-semibold mb-0 text-nowrap">
                                    {(data?.purchasedOneDriveLicense == null
                                      ? 0 : data?.purchasedOneDriveLicense) -
                                      (data?.usedOneDriveLicense == null
                                        ? 0 : data?.usedOneDriveLicense)}
                                    /{(data?.purchasedOneDriveLicense == null
                                      ? 0 : data?.purchasedOneDriveLicense)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {/*Google License Information Details Starts here
                        {licenseData.map((data: any, index: any) => (
                          <div className="col-md-12 mb-3 grey-border license-container px-4 py-3 ">
                          <div className="d-flex gap-2 align-items-center pb-3 bottom-border">
                            <img
                              src="/images/gsuit-license.svg"
                              alt="slack-logo"
                              className=""
                            />
                            <h5 className="font-16 font-bold m-0">
                              G-suite License
                            </h5>
                            <div className="carousel-nav ">
                              <button className="next-arrow scroll-btn" id="next-arrow2">
                                <img
                                  src="images/right-arrow.svg"
                                  alt="right-arrow"
                                />
                              </button>
                            </div>
                          </div>

                          <div className="announcement-carousel mb-0 mt-3 d-flex" id="carousel2">
                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  Plan Expires On
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                {data?.licenseExpiredAt
                                  ? new Date(
                                      data.licenseExpiredAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })
                                  : ""}
                                </p>
                              </div>
                            </div>

                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  Parallel Instance
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                {data?.purchasedParallelinstance -
                                  data?.usedParallelinstanceCount}
                                /{data?.purchasedParallelinstance}
                                </p>
                              </div>
                            </div>

                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  Assessment
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                {data?.purchasedAssessmentLicense -
                                  data?.usedAssessmentLicense}
                                /{data?.purchasedAssessmentLicense}
                                </p>
                              </div>
                            </div>

                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  Gmail
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                {data?.purchasedUserMailboxLicense -
                                  data?.usedUsermailboxLicense}
                                /{data?.purchasedUserMailboxLicense}
                                </p>
                              </div>
                            </div>

                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  My Drive
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                {data?.purchasedUserMailboxLicense -
                                  data?.usedOneDriveLicense}
                                /{data?.purchasedUserMailboxLicense}
                                </p>
                              </div>
                            </div>

                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  Shared Drive
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                {data?.purchasedSharedMailboxLicense -
                                  data?.usedSharedmailboxLicense}
                                /{data?.purchasedSharedMailboxLicense}
                                </p>
                              </div>
                            </div>
                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  Groups
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                {data?.usedGroupsLicense == null
                                  ? 0
                                  : data?.usedGroupsLicense}
                                /
                                {data?.purchasedGroupsLicense == null
                                  ? 0
                                  : data?.purchasedGroupsLicense}
                                </p>
                              </div>
                            </div>
                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  Sites
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                  7/30
                                </p>
                              </div>
                            </div>
                            <div className="announcement-item">
                              <div>
                                <p className="font-14 font-regular profile-grey mb-2">
                                  Chats
                                </p>
                                <p className="font-13 font-semibold mb-0 text-nowrap">
                                  7/30
                                </p>
                              </div>
                            </div>

                          </div>
                        </div>
                        ))}
                        */}

                      </div>
                      {/*License Information Tab ends here*/}
                      {/*Password Reset Tab starts here*/}
                      {(AccountInfo.registrationType.toLowerCase() === 'email') ?
                        (<div
                          className="tab-pane fade  cust-padding-20"
                          id="password-reset"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="col-md-12 mb-2">
                              <h5 className="font-bold font-18 color-black">
                                Password Reset
                              </h5>
                            </div>
                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="email"
                              >
                                Email Address{" "}
                                <span className="mandatory-color">*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control custom-form font-14 font-regular py-2"
                                id="email"
                                placeholder="Enter Email Address"
                                defaultValue={AccountInfo.mail}
                                onChange={(e) =>
                                  handleFieldValidation(
                                    e.target.id,
                                    e.target.value,
                                    "password"
                                  )
                                }
                                onPaste={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="old-password"
                              >
                                Old Password{" "}
                                <span className="mandatory-color">*</span>
                              </label>
                              <div className="position-relative">
                                <input
                                  type={oldhide ? "text" : "password"} // Changed variable name and simplified conditional statement
                                  className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                  id="old-password"
                                  placeholder="Enter Old Password"
                                  value={password.oldpassword}
                                  onChange={(e) => {
                                    ////console.log("Old Password Input Changed:", e.target.value);
                                    handleFieldValidation(
                                      e.target.id,
                                      e.target.value,
                                      "password"
                                    ); // Changed to pass value directly
                                  }}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                {oldhide ? ( // Simplified conditional rendering
                                  <img
                                    className="position-absolute custom-open-eye-postion"
                                    src="/images/password-eye-open.svg"
                                    alt="password-icon"
                                    onClick={() => {
                                      ////console.log("Hide password clicked");
                                      setoldHide(false); // Changed variable name
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="position-absolute custom-postion top-50 cursor-pointer"
                                    src="/images/password-icon.svg"
                                    alt="password-icon"
                                    onClick={() => {
                                      ////console.log("Show password clicked");
                                      setoldHide(true); // Changed variable name
                                    }}
                                  />
                                )}
                              </div>
                              {errpassword.erroldpassword && (
                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                  {errpassword.erroldpassword}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="new-password"
                              >
                                New Password{" "}
                                <span className="mandatory-color">*</span>{" "}
                                <Tippy
                                  arrow={true}
                                  followCursor={"horizontal"}
                                  plugins={[followCursor]}
                                  placement="top"
                                  content="Password should contain atleast 8-20 characters atleast 1 Special character, atleast 1 Number, atleast 1 Uppercase, atleast 1 Lowercase"
                                >
                                  <img
                                    src="/images/tooltip.svg"
                                    alt="tooltip"
                                    width={11}
                                  />
                                </Tippy>
                              </label>
                              <div className="position-relative">
                                <input
                                  type={hide == true ? "text" : "password"}
                                  className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                  id="new-password"
                                  placeholder="Enter New Password"
                                  value={password.newpassword}
                                  onChange={(e) =>
                                    handleFieldValidation(
                                      e.target.id,
                                      e.target.value,
                                      "password"
                                    )
                                  }
                                  onPaste={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                {hide == true ? (
                                  <img
                                    className="position-absolute custom-open-eye-postion"
                                    src="/images/password-eye-open.svg"
                                    alt="password-icon"
                                    onClick={() => setHide(false)}
                                  />
                                ) : (
                                  <img
                                    className="position-absolute custom-postion top-50 cursor-pointer"
                                    src="/images/password-icon.svg"
                                    alt="password-icon"
                                    onClick={() => setHide(true)}
                                  />
                                )}
                              </div>
                              {errpassword.errNewpassword && (
                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                  {errpassword.errNewpassword}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="confirm-password"
                              >
                                Confirm Password{" "}
                                <span className="mandatory-color">*</span>
                                <Tippy
                                  arrow={true}
                                  followCursor={"horizontal"}
                                  plugins={[followCursor]}
                                  placement="top"
                                  content="Please re-enter your password to confirm. Ensure it matches the password you entered above."
                                >
                                  <img
                                    src="/images/tooltip.svg"
                                    alt="tooltip"
                                    width={11}
                                  />
                                </Tippy>
                              </label>
                              <div className="position-relative">
                                <input
                                  type={Confirmhide ? "text" : "password"}
                                  className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                  id="confirm-password"
                                  placeholder="Confirm New Password"
                                  value={password.confirmpassword}
                                  onChange={(e) =>
                                    handleFieldValidation(
                                      e.target.id,
                                      e.target.value,
                                      "password"
                                    )
                                  }
                                  onPaste={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                {Confirmhide == true ? (
                                  <img
                                    className="position-absolute custom-open-eye-postion"
                                    src="/images/password-eye-open.svg"
                                    alt="password-icon"
                                    onClick={() => setconfirmHidee(false)}
                                  />
                                ) : (
                                  <img
                                    className="position-absolute custom-postion top-50 cursor-pointer"
                                    src="/images/password-icon.svg"
                                    alt="password-icon"
                                    onClick={() => setconfirmHidee(true)}
                                  />
                                )}
                              </div>
                              {errpassword.errconfirmpassword && (
                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                  {errpassword.errconfirmpassword}
                                </div>
                              )}
                            </div>

                            <div className="col-md-12 mt-3">
                              <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 py-4">
                                <button
                                  className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 px-4"
                                  onClick={() => passwordCancelClick()}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                                  onClick={(e) =>
                                    handleActions(e, "password", "submit")
                                  }
                                  disabled={!enable}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>):null}
                      {/*Password Reset Tab ends here*/}
                      {/*Manage Users Tab starts here*/}
                      {role.toLowerCase().includes('primary')?
                      <div
                        className="tab-pane fade cust-padding-20"
                        id="manage-users"
                        role="tabpanel"
                      >
                        <div className="row">
                          <div className="col-md-12 mb-2">
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                              <h5 className="font-bold font-18 color-black">
                                Manage Users ({secondaryUsers?.length}) {" "}
                                <Tippy
                                  delay={500}
                                  arrow={true}
                                  followCursor={false}
                                  plugins={[followCursor]}
                                  placement="top"
                                  content={
                                    <>
                                      Users with multiple roles can be added:
                                      <br />
                                      • Secondary users can make changes in the
                                      application except for adding users.
                                      <br />
                                      • Migration managers can support the
                                      migration progress.
                                      <br />• Viewers can only access the
                                      application.
                                    </>
                                  }
                                  allowHTML={true}
                                >
                                  <img
                                    src="/images/tooltip.svg"
                                    alt="tooltip"
                                    width={11}
                                  />
                                </Tippy>
                              </h5>
                              <div className="d-md-flex d-block gap-3 flex-wrap align-items-center w-md-100">
                                <div className="d-flex gap-3 mb-md-0 mb-3 align-items-center">
                                  <div className="input-group input-search flex-nowrap">
                                    <input
                                      type="search"
                                      className="form-control border-end-0 font-14 font-regular w-auto"
                                      placeholder="Search"
                                      onKeyDown={(event) => {
                                        if (event.key == "Enter") {
                                          onSearch()
                                        }
                                      }}
                                      onChange={handleSearch}
                                    />
                                    <button
                                      className="btn border-start-0"
                                      type="button"
                                      onClick={onSearch}
                                    >
                                      <img
                                        src="/images/search.svg"
                                        alt="search"
                                        className="d-block "
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*Grid starts here*/}
                            <div className="table-responsive theme-table bg-white ">
                              <table className="table table-borderless table-shadow mb-0">
                                <thead>
                                  <tr>
                                    <th className="text-nowrap">
                                      User Name
                                      <span className="ms-1 cursor-pointer">
                                        <img
                                          src={`/images/sort-arrow-up.svg`}
                                          alt="sort-arrow"
                                          style={{ cursor: "pointer" }}
                                          id={`userName@DESC`}
                                          hidden={
                                            manageUsersSortHide ==
                                              `userName@ASC` || ""
                                              ? false
                                              : true
                                          }
                                          onClick={(e) => {
                                            handleSort(e);
                                          }}
                                        />
                                        <img
                                          src={`/images/sort-arrow-down.svg`}
                                          alt="sort-arrow"
                                          id={`userName@ASC`}
                                          style={{ cursor: "pointer" }}
                                          hidden={
                                            manageUsersSortHide ==
                                              `userName@ASC` || ""
                                              ? true
                                              : false
                                          }
                                          onClick={(e: any) => {
                                            handleSort(e);
                                          }}
                                        />
                                      </span>
                                    </th>
                                    <th className="text-nowrap">
                                      Email Address
                                      <span className="ms-1 cursor-pointer">
                                        <img
                                          src={`/images/sort-arrow-up.svg`}
                                          alt="sort-arrow"
                                          style={{ cursor: "pointer" }}
                                          id={`mailId@DESC`}
                                          hidden={
                                            manageUsersSortHide ==
                                              `mailId@ASC` || ""
                                              ? false
                                              : true
                                          }
                                          onClick={(e: any) => {
                                            handleSort(e);
                                          }}
                                        />
                                        <img
                                          src={`/images/sort-arrow-down.svg`}
                                          alt="sort-arrow"
                                          id={`mailId@ASC`}
                                          style={{ cursor: "pointer" }}
                                          hidden={
                                            manageUsersSortHide ==
                                              `mailId@ASC` || ""
                                              ? true
                                              : false
                                          }
                                          onClick={(e: any) => {
                                            handleSort(e);
                                          }}
                                        />
                                      </span>
                                    </th>
                                    <th className="text-nowrap">
                                      Authentication Type
                                      <span className="ms-1 cursor-pointer">
                                        <img
                                          src={`/images/sort-arrow-up.svg`}
                                          alt="sort-arrow"
                                          style={{ cursor: "pointer" }}
                                          id={`isSSOAuthentication@DESC`}
                                          hidden={
                                            manageUsersSortHide ==
                                              `isSSOAuthentication@ASC` || ""
                                              ? false
                                              : true
                                          }
                                          onClick={(e: any) => {
                                            handleSort(e);
                                          }}
                                        />
                                        <img
                                          src={`/images/sort-arrow-down.svg`}
                                          alt="sort-arrow"
                                          id={`isSSOAuthentication@ASC`}
                                          style={{ cursor: "pointer" }}
                                          hidden={
                                            manageUsersSortHide ==
                                              `isSSOAuthentication@ASC` || ""
                                              ? true
                                              : false
                                          }
                                          onClick={(e: any) => {
                                            handleSort(e);
                                          }}
                                        />
                                      </span>
                                    </th>
                                    <th className="text-nowrap">
                                      Role
                                      <span className="ms-1 cursor-pointer">
                                        <img
                                          src={`/images/sort-arrow-up.svg`}
                                          alt="sort-arrow"
                                          style={{ cursor: "pointer" }}
                                          id={`roleType@DESC`}
                                          hidden={
                                            manageUsersSortHide ==
                                              `roleType@ASC` || ""
                                              ? false
                                              : true
                                          }
                                          onClick={(e) => {
                                            handleSort(e);
                                          }}
                                        />
                                        <img
                                          src={`/images/sort-arrow-down.svg`}
                                          alt="sort-arrow"
                                          id={`roleType@ASC`}
                                          style={{ cursor: "pointer" }}
                                          hidden={
                                            manageUsersSortHide ==
                                              `roleType@ASC` || ""
                                              ? true
                                              : false
                                          }
                                          onClick={(e) => {
                                            handleSort(e);
                                          }}
                                        />
                                      </span>
                                    </th>
                                    <th className="text-nowrap">
                                      Account Status
                                      <span className="ms-1 cursor-pointer">
                                        <img
                                          src={`/images/sort-arrow-up.svg`}
                                          alt="sort-arrow"
                                          style={{ cursor: "pointer" }}
                                          id={`accountStatus@DESC`}
                                          hidden={
                                            manageUsersSortHide ==
                                              `accountStatus@ASC` || ""
                                              ? false
                                              : true
                                          }
                                          onClick={(e) => {
                                            handleSort(e);
                                          }}
                                        />
                                        <img
                                          src={`/images/sort-arrow-down.svg`}
                                          alt="sort-arrow"
                                          id={`accountStatus@ASC`}
                                          style={{ cursor: "pointer" }}
                                          hidden={
                                            manageUsersSortHide ==
                                              `accountStatus@ASC` || ""
                                              ? true
                                              : false
                                          }
                                          onClick={(e) => {
                                            handleSort(e);
                                          }}
                                        />
                                      </span>
                                    </th>
                                    {role.toLowerCase() !== "viewer" ||
                                      role.toLowerCase() !== "adminviewer" ? (
                                      <th className="text-nowrap text-center ">
                                        Action
                                      </th>
                                    ) : null}
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control custom-form font-regular font-14"
                                        placeholder="Enter User Name"
                                        name="userName"
                                        value={
                                          !editMode
                                            ? secondaryUser.userName
                                            : ""
                                        }
                                        onChange={(e) => {
                                          {
                                            ////console.log(e.target.value);
                                          }

                                          handleFieldValidation(
                                            "userName",
                                            e.target.value,
                                            "manageUser"
                                          );
                                        }}
                                      />

                                      <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                        {errmanageUser.erruserName}
                                      </span>
                                    </td>

                                    <td>
                                      <input
                                        type="text"
                                        // className={`form-control custom-form font-regular font-14 ${(!secondaryUser.email && formSubmitted) || (erremail && formSubmitted) ? 'is-invalid' : ''}`}
                                        className="form-control custom-form font-regular font-14"
                                        placeholder="Enter Email Address"
                                        name="email"
                                        value={
                                          !editMode ? secondaryUser.email : ""
                                        }
                                        onChange={(e) => {
                                          handleFieldValidation(
                                            "email",
                                            e.target.value,
                                            "manageUser"
                                          );
                                        }}
                                      />
                                      <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                        {errmanageUser.errmail}
                                      </span>
                                    </td>
                                    <td>
                                      <select
                                        className="form-select font-regular font-14 custom-form py-2"
                                        id="type"
                                        aria-label="Default select example"
                                        name="authenticationType"
                                        value={
                                          !editMode
                                            ? secondaryUser.authenticationType
                                            : ""
                                        }
                                        onChange={(e) => {
                                          handleFieldValidation(
                                            "authenticationType",
                                            e.target.value,
                                            "manageUser"
                                          );
                                        }}
                                      >
                                        <option value="" disabled>
                                          Select
                                        </option>
                                        {authenticationType.map(
                                          (option: any, index: any) => (
                                            <option
                                              key={index}
                                              value={option.authenticationType}
                                            >
                                              {option.authenticationType}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      {/* {formSubmitted && invalidAuthType && (
                                                                    <div className="invalid-feedback">Authentication Type is required.</div>
                                                                )} */}

                                      <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                        {errmanageUser.errauthenticationType}
                                      </div>
                                    </td>
                                    <td>
                                      <select
                                        className="form-select font-regular font-14 custom-form py-2"
                                        id="type2"
                                        aria-label="Default select example"
                                        name="role"
                                        value={
                                          !editMode ? secondaryUser.role : ""
                                        }
                                        onChange={(e) => {
                                          handleFieldValidation(
                                            "role",
                                            e.target.value,
                                            "manageUser"
                                          );
                                        }}
                                      >
                                        <option value="" disabled>
                                          Select
                                        </option>
                                        {roleData.map(
                                          (option: any, index: any) => (
                                            <option
                                              key={index}
                                              value={option.roleType}
                                            >
                                              {option.roleType}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      {/* {formSubmitted && invalidRole && !editMode(
                                                                    <div className="invalid-feedback">Role is required.</div>
                                                                )} */}

                                      <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                        {errmanageUser.role}
                                      </div>
                                    </td>
                                    <td>
                                      <span className="text-nowrap">
                                        <span className="table-status draft d-inline-block me-2 " />
                                        Queue
                                      </span>
                                    </td>
                                    {role.toLowerCase() !== "viewer" ||
                                      role.toLowerCase() !== "adminviewer" ? (
                                      <td className="text-center">
                                        <span className="d-inline-block">
                                          <button
                                            className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center"
                                            onClick={(e) =>
                                              handleActions(
                                                e,
                                                "manageUser",
                                                "addAccount"
                                              )
                                            }
                                            disabled={!manageUserbutton}
                                          >
                                            <img
                                              src="/images/blue-plus-icon.svg"
                                              alt="blue-plus-icon"
                                            />
                                          </button>
                                        </span>
                                      </td>
                                    ) : null}
                                  </tr>
                                  {secondaryUsers
                                    ?.filter((acc) =>
                                      Object.values(acc).some(
                                        (value) => value !== ""
                                      )
                                    )
                                    .map((acc: any, index) => (
                                      <React.Fragment key={index}>
                                        {editMode &&
                                          editData.manageUserDetailsId ===
                                          acc.manageUserDetailsId ? (
                                          // Render input fields for the row being edited
                                          <tr>
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control custom-form font-regular font-14"
                                                placeholder="Enter User  Name"
                                                name="userName"
                                                defaultValue={editData.userName}
                                                onChange={(e) => {
                                                  handleFieldValidation(
                                                    "userName",
                                                    e.target.value,
                                                    "manageUserEdit"
                                                  );
                                                }}
                                              />
                                              {erreditmanageUser.erruserName && (
                                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                  {
                                                    erreditmanageUser.erruserName
                                                  }
                                                </div>
                                              )}
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                disabled
                                                className="form-control custom-form font-regular font-14"
                                                placeholder="Enter Email Address"
                                                name="mailId"
                                                defaultValue={editData.mailId}
                                                onChange={(e) => {
                                                  handleFieldValidation(
                                                    "email",
                                                    e.target.value,
                                                    "manageUserEdit"
                                                  );
                                                }}
                                              />
                                              {erreditmanageUser.errmail && (
                                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                  {erreditmanageUser.errmail}
                                                </div>
                                              )}
                                            </td>
                                            <td>
                                              <select
                                                className="form-select font-regular font-14 custom-form py-2"
                                                id="authenticationType"
                                                aria-label="Default select example"
                                                name="authenticationType"
                                                defaultValue={
                                                  editData.authenticationType
                                                }
                                                onChange={(e) => {
                                                  handleFieldValidation(
                                                    "authenticationType",
                                                    e.target.value,
                                                    "manageUserEdit"
                                                  );
                                                }}
                                              >
                                                <option value="" disabled>
                                                  Select
                                                </option>
                                                {authenticationType.map(
                                                  (option, index) => (
                                                    <option
                                                      key={index}
                                                      value={
                                                        option.authenticationType
                                                      }
                                                    >
                                                      {
                                                        option.authenticationType
                                                      }
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              {erreditmanageUser.errauthenticationType && (
                                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                  {
                                                    erreditmanageUser.errauthenticationType
                                                  }
                                                </div>
                                              )}
                                            </td>
                                            <td>
                                              <select
                                                className="form-select font-regular font-14 custom-form py-2"
                                                id="role"
                                                aria-label="Default select example"
                                                name="role"
                                                defaultValue={editData.roleType}
                                                onChange={(e) => {
                                                  handleFieldValidation(
                                                    "role",
                                                    e.target.value,
                                                    "manageUserEdit"
                                                  );
                                                }}
                                              >
                                                <option value="" disabled>
                                                  Select
                                                </option>
                                                {roleData.map(
                                                  (
                                                    option: {
                                                      roleType: string;
                                                    },
                                                    index
                                                  ) => (
                                                    <option
                                                      key={index}
                                                      value={option.roleType}
                                                    >
                                                      {option.roleType}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              {erreditmanageUser.role && (
                                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                  {erreditmanageUser.role}
                                                </div>
                                              )}
                                            </td>
                                            <td>
                                              <span className="text-nowrap">
                                                {editData.accountStatus}
                                              </span>
                                            </td>
                                            <td className="text-center">
                                              <span style={{ display: "flex" }}>
                                                <div>
                                                  <button
                                                    className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center"
                                                    onClick={(e) =>
                                                      handleActions(
                                                        e,
                                                        "manageUser",
                                                        "editAccount"
                                                      )
                                                    }
                                                    disabled={!manageUserbutton}
                                                  >
                                                    <img
                                                      src="/images/tick-action-icon.svg"
                                                      alt="tick-icon"
                                                    />
                                                  </button>
                                                </div>
                                                <div>
                                                  <button
                                                    className="btn action-rounded-btn success-action d-flex align-items-center justify-content-center"
                                                    onClick={() => {
                                                      seteditmode(false);
                                                      setEditData({
                                                        ...editData,
                                                        accountStatus: "",
                                                        authenticationType: "",
                                                        userName: "",
                                                        manageUserDetailsId: 0,
                                                        roleType: "",
                                                        mailId: "",
                                                      });
                                                    }}
                                                  >
                                                    <img
                                                      src="/images/cancel-icon.svg"
                                                      alt="cancel"
                                                    />
                                                  </button>
                                                </div>
                                              </span>
                                            </td>
                                          </tr>
                                        ) : (
                                          // Render normal row data
                                          <tr>
                                            <td>{acc.userName}</td>
                                            <td>{acc.mailId}</td>
                                            <td>{acc.authenticationType}</td>
                                            <td>{acc.roleType}</td>
                                            <td>
                                              <span className="text-nowrap">
                                                <span
                                                  className={
                                                    acc?.accountStatus?.toLowerCase() ===
                                                      "invited"
                                                      ? "table-status inprogress d-inline-block me-2"
                                                      : acc?.accountStatus?.toLowerCase() ===
                                                        "failed"
                                                        ? "table-status danger d-inline-block me-2"
                                                        : acc?.accountStatus?.toLowerCase() ===
                                                          "active"
                                                          ? "table-status success d-inline-block me-2"
                                                          : ""
                                                  }
                                                />
                                                {acc.accountStatus}
                                              </span>
                                            </td>
                                            {role.toLowerCase() !== "viewer" &&
                                              role.toLowerCase() !==
                                              "adminviewer" &&
                                              acc?.accountStatus?.toLowerCase() !==
                                              "active" ? (
                                              <td className="text-center">
                                                <span className="action-drop-down">
                                                  <img
                                                    src="/images/keb-menu-icon.svg"
                                                    alt="keb-menu-icon"
                                                    data-bs-toggle="dropdown"
                                                    className="p-2 action-icon rounded-3"
                                                    aria-expanded="false"
                                                  />
                                                  <span className="dropdown-menu font-regular font-14 p-1">
                                                    <span
                                                      className="d-flex gap-2 px-3 py-2 list"
                                                      onClick={() =>
                                                        handleEdit(acc)
                                                      }
                                                    >
                                                      <img
                                                        src="/images/edit-icon.svg"
                                                        alt="edit"
                                                      />
                                                      Edit
                                                    </span>
                                                    <span
                                                      className="d-flex gap-2 px-3 py-2 list"
                                                      onClick={() => {
                                                        setDeleteid(
                                                          acc.manageUserDetailsId
                                                        );
                                                        setDeleteMailId(acc.mailId)
                                                        setDeletePopup(true);
                                                      }}
                                                    >
                                                      <img
                                                        src="/images/red-delete-icon.svg"
                                                        alt="delete"
                                                      />
                                                      Delete
                                                    </span>
                                                    {Math.floor(
                                                      (currentDate.getTime() -
                                                        new Date(
                                                          acc.createdDate
                                                        ).getTime()) /
                                                      (1000 * 60 * 60 * 24)
                                                    ) < 3 ? null : (
                                                      <span
                                                        className="d-flex gap-2 px-3 py-2 list"
                                                        onClick={() =>
                                                          sendInvite(
                                                            acc.userName,
                                                            acc.mailId,
                                                            acc.roleType,
                                                            acc.authenticationType
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src="/images/resend-icon.svg"
                                                          alt="resend"
                                                        />
                                                        Resend
                                                      </span>
                                                    )}
                                                    {/* <span className="d-flex gap-2 px-3 py-2 list" onClick={() => sendInvite(acc.userName, acc.mailId, acc.roleType, acc.authenticationType)}>
                                                      <img src="/images/resend-icon.svg" alt="resend" />
                                                      Resend
                                                    </span> */}
                                                  </span>
                                                </span>
                                              </td>
                                            ) : (
                                              <td className="text-center">
                                                <span className="action-drop-down">
                                                  <img
                                                    src="/images/keb-menu-icon.svg"
                                                    alt="keb-menu-icon"
                                                    data-bs-toggle="dropdown"
                                                    className="p-2 action-icon rounded-3"
                                                    aria-expanded="false"
                                                  />
                                                  <span className="dropdown-menu font-regular font-14 p-1">
                                                    <span
                                                      className="d-flex gap-2 px-3 py-2 list"
                                                      onClick={() =>
                                                        handleEdit(acc)
                                                      }
                                                    >
                                                      <img
                                                        src="/images/edit-icon.svg"
                                                        alt="edit"
                                                      />
                                                      Edit
                                                    </span>
                                                    <span
                                                      className="d-flex gap-2 px-3 py-2 list"
                                                      onClick={() => {
                                                        setDeleteid(
                                                          acc.manageUserDetailsId
                                                        );
                                                        setDeleteMailId(acc.mailId)
                                                        setDeletePopup(true);
                                                      }}
                                                    >
                                                      <img
                                                        src="/images/red-delete-icon.svg"
                                                        alt="delete"
                                                      />
                                                      Delete
                                                    </span>
                                                  </span>
                                                </span>
                                              </td>
                                            )}
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    ))}
                                  {noData && (
                                    <tr>
                                      <td colSpan={100}>
                                        <div className="pt-4 pb-3 text-center">
                                          <img
                                            src="/images/no-data-found-img.svg"
                                            alt="no-data-found-img"
                                            className="mb-5 mx-auto"
                                          />
                                          <h5 className="font-12 font-bold color-no-rec">
                                            No Record
                                          </h5>
                                          <p className="font-10 font-regular color-no-rec">
                                            There is no Record to show you right
                                            now
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            {/*Grid ends here*/}
                          </div>
                        </div>
                      </div>:null}
                      {/*Manage Users Tab ends here*/}
                      {/*Invoice History Tab starts here*/}
                      {/*
                      <div
                        className="tab-pane fade cust-padding-20"
                        id="invoice-history"
                        role="tabpanel"
                      >
                        <div className="row">
                          <div className="col-md-12 mb-2">
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                              <h5 className="font-bold font-18 color-black">
                                Invoice History (5)
                              </h5>
                              <div className="d-md-flex d-block gap-3 flex-wrap align-items-center w-md-100">
                                <div className="d-flex gap-3 mb-md-0 mb-2 align-items-center">
                                  <div className="input-group input-search flex-nowrap">
                                    <input
                                      type="search"
                                      className="form-control border-end-0 font-14 font-regular w-auto"
                                      placeholder="Search"
                                    />
                                    <button
                                      className="btn border-start-0"
                                      type="button"
                                    >
                                      <img
                                        src="/images/search.svg"
                                        alt="search"
                                        className="d-block "
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="table-responsive theme-table bg-white ">
                              <table className="table table-borderless table-shadow mb-0">
                                <thead>
                                  <tr>
                                    <th className="text-nowrap">
                                      Invoice #
                                      <span className="ms-1 cursor-pointer">
                                        <img
                                          src="/images/sort-arrow-down.svg"
                                          alt="sort-arrow-down"
                                        />
                                      </span>
                                    </th>
                                    <th className="text-nowrap">
                                      Payment Status
                                      <span className="ms-1 cursor-pointer">
                                        <img
                                          src="/images/sort-arrow-down.svg"
                                          alt="sort-arrow-down"
                                        />
                                      </span>
                                    </th>
                                    <th className="text-nowrap">
                                      Payment Date
                                      <span className="ms-1 cursor-pointer">
                                        <img
                                          src="/images/sort-arrow-down.svg"
                                          alt="sort-arrow-down"
                                        />
                                      </span>
                                    </th>
                                    <th className="text-nowrap text-center">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    className="accordion-table-row font-semibold font-14"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#invoice-acc-one"
                                  >
                                    <td className="text-nowrap p-3">
                                      <span className="d-flex align-items-center gap-3">
                                        <img
                                          src="/images/table-accordion-arrow.svg"
                                          alt="table-accordion-arrow"
                                          className="cursor-pointer table-accordion-arrow"
                                        />
                                        <span className="ac-heading">
                                          20220810-023-024
                                        </span>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="text-nowrap">
                                        <span className="table-status success d-inline-block me-2"></span>
                                        Success
                                      </span>
                                    </td>
                                    <td>Jan 12, 2024</td>
                                    <td className="text-center">
                                      <span className="d-inline-block">
                                        <button className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                          <img
                                            src="/images/dowload-circle-icon.svg"
                                            alt="dowload-circle-icon"
                                            title="Download"
                                          />
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr
                                    className="collapse show table-accordion-body"
                                    id="invoice-acc-one"
                                  >
                                    <td colSpan={4}>
                                      <span className="table-responsive theme-table bg-white d-inline-block w-100">
                                        <table className="table table-borderless mb-0 align-middle">
                                          <thead>
                                            <tr>
                                              <th className="text-nowrap">
                                                Product Type
                                              </th>
                                              <th className="text-nowrap">
                                                Plan Type
                                              </th>
                                              <th className="text-nowrap">
                                                Purchase Type
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Slack</td>
                                              <td>Premium</td>
                                              <td>Channel &amp; Chat Based</td>
                                            </tr>
                                            <tr>
                                              <td>Tenant</td>
                                              <td>Pro</td>
                                              <td>
                                                Resource Utilization &amp;
                                                Channel &amp; Chat Based
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>G Suite</td>
                                              <td>Elite</td>
                                              <td>Channel Based</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr
                                    className="accordion-table-row font-semibold font-14 collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#invoice-acc-two"
                                  >
                                    <td className="text-nowrap p-3">
                                      <span className="d-flex align-items-center gap-3">
                                        <img
                                          src="/images/table-accordion-arrow.svg"
                                          alt="table-accordion-arrow"
                                          className="cursor-pointer table-accordion-arrow"
                                        />
                                        <span className="ac-heading">
                                          20220810-023-024
                                        </span>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="text-nowrap">
                                        <span className="table-status success d-inline-block me-2"></span>
                                        Success
                                      </span>
                                    </td>
                                    <td>Jan 12, 2024</td>
                                    <td className="text-center">
                                      <span className="d-inline-block">
                                        <button className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                          <img
                                            src="/images/dowload-circle-icon.svg"
                                            alt="dowload-circle-icon"
                                            title="Download"
                                          />
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr
                                    className="collapse table-accordion-body"
                                    id="invoice-acc-two"
                                  >
                                    <td colSpan={4}>
                                      <span className="table-responsive theme-table bg-white d-inline-block w-100">
                                        <table className="table table-borderless mb-0 align-middle">
                                          <thead>
                                            <tr>
                                              <th className="text-nowrap">
                                                Product Type
                                              </th>
                                              <th className="text-nowrap">
                                                Plan Type
                                              </th>
                                              <th className="text-nowrap">
                                                Purchase Type
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Slack</td>
                                              <td>Premium</td>
                                              <td>Channel &amp; Chat Based</td>
                                            </tr>
                                            <tr>
                                              <td>Tenant</td>
                                              <td>Pro</td>
                                              <td>
                                                Resource Utilization &amp;
                                                Channel &amp; Chat Based
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>G Suite</td>
                                              <td>Elite</td>
                                              <td>Channel Based</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr
                                    className="accordion-table-row font-semibold font-14 collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#invoice-acc-three"
                                  >
                                    <td className="text-nowrap p-3">
                                      <span className="d-flex align-items-center gap-3">
                                        <img
                                          src="/images/table-accordion-arrow.svg"
                                          alt="table-accordion-arrow"
                                          className="cursor-pointer table-accordion-arrow"
                                        />
                                        <span className="ac-heading">
                                          20220810-023-024
                                        </span>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="text-nowrap">
                                        <span className="table-status danger d-inline-block me-2"></span>
                                        Fail
                                      </span>
                                    </td>
                                    <td>Jan 08, 2024</td>
                                    <td className="text-center">
                                      <span className="d-inline-block">
                                        <button className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                          <img
                                            src="/images/dowload-circle-icon.svg"
                                            alt="dowload-circle-icon"
                                            title="Download"
                                          />
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr
                                    className="collapse table-accordion-body"
                                    id="invoice-acc-three"
                                  >
                                    <td colSpan={4}>
                                      <span className="table-responsive theme-table bg-white d-inline-block w-100">
                                        <table className="table table-borderless mb-0 align-middle">
                                          <thead>
                                            <tr>
                                              <th className="text-nowrap">
                                                Product Type
                                              </th>
                                              <th className="text-nowrap">
                                                Plan Type
                                              </th>
                                              <th className="text-nowrap">
                                                Purchase Type
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Slack</td>
                                              <td>Premium</td>
                                              <td>Channel &amp; Chat Based</td>
                                            </tr>
                                            <tr>
                                              <td>Tenant</td>
                                              <td>Pro</td>
                                              <td>
                                                Resource Utilization &amp;
                                                Channel &amp; Chat Based
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>G Suite</td>
                                              <td>Elite</td>
                                              <td>Channel Based</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      */}
                      {/*Invoice History Tab ends here*/}
                    </div>
                    {/*Tab Inner ends here*/}
                  </div>
                )}

                {alertPopup === true ? (
                  <div
                    className="modal fade show"
                    id="Alert-popup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="Alert-popupLabel"
                    aria-hidden="true"
                    style={{
                      display: "block",
                      backgroundColor: "rgba(0,0,0,0.6)",
                    }}
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                          <span className="position-absolute">
                            <img
                              src="/images/failure-popup-icon.svg"
                              alt="Alert-popup-icon"
                            />
                          </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                          <h5
                            className="modal-title mb-3 primary-header"
                            id="Alert-popupLabel"
                          >
                            Alert
                          </h5>
                          <p className="primary-data font-16">
                            {popupmessage ||
                              "Something went wrong , Please try again later !"}
                          </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                          <button
                            type="button"
                            className="btn primary-btn font-14 px-4 font-semibold"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              if (isSessionEpired) {
                                history.push("/Login");
                              }
                              setAlertPopup(false);
                              setLoader(false);
                            }}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {editPopup == true ? (
                  <div
                    className="modal fade show"
                    id="EditProfilePopup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="MailPopupLabel"
                    aria-hidden="true"
                    style={{
                      display: "block",
                      backgroundColor: "rgba(0,0,0,0.6)",
                    }}
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content p-4">
                        <div className="modal-header border-0 p-0">
                          <h1
                            className="font-20 font-bold custom-header-color m-0"
                            id="MailPopupLabel"
                          >
                            Edit Profile
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              setEditPopup(false);
                              getClientDetails("initial");
                              seterrAccountInfo((prevState) => ({
                                ...prevState,
                                errname: "",
                                errmail: "",
                                errcompany: "",
                                errregistrationType: "",
                                errphone: "",
                              }));
                            }}
                          />
                        </div>
                        <div className="modal-body border-0 p-0 pt-2 pb-5">
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                htmlFor="userName"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control custom-form font-14 font-regular"
                                id="userName"
                                placeholder="Enter User Name "
                                defaultValue={AccountInfo.name}
                                onChange={(e) =>
                                  handleFieldValidation(
                                    e.target.id,
                                    e.target.value,
                                    "accountInfo"
                                  )
                                }
                              />
                              {errAccountInfo.errname && (
                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                  {errAccountInfo.errname}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6">
                              <label
                                className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                htmlFor="userMail"
                              >
                                Mail
                              </label>
                              <input
                                type="text"
                                className="form-control custom-form font-14 font-regular"
                                id="userMail"
                                placeholder="Enter Mail Address"
                                defaultValue={AccountInfo.mail}
                                disabled
                                onChange={(e) =>
                                  handleFieldValidation(
                                    e.target.id,
                                    e.target.value,
                                    "accountInfo"
                                  )
                                }
                              />
                              {errAccountInfo.errmail && (
                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                  {errAccountInfo.errmail}
                                </div>
                              )}
                            </div>
                            {role.toLowerCase() == "secondaryuser" ||
                              role.toLowerCase() == "migrationmanager" ||
                              role.toLowerCase() == "viewer" || role.toLowerCase() == "adminviewer"
                              || role.toLowerCase() == "secondaryadmin" || role.toLowerCase() == "primaryadmin" ? null : (
                              <div className="col-md-6">
                                <label
                                  className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                  htmlFor="userComp"
                                >
                                  Company
                                </label>
                                <input
                                  type="text"
                                  className="form-control custom-form font-14 font-regular"
                                  id="userComp"
                                  placeholder="Enter Company Name"
                                  value={AccountInfo.company}
                                  onChange={(e) =>
                                    handleFieldValidation(
                                      e.target.id,
                                      e.target.value,
                                      "accountInfo"
                                    )
                                  }
                                />
                                {errAccountInfo.errcompany && (
                                  <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                    {errAccountInfo.errcompany}
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="col-md-6">
                              <label
                                className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                htmlFor="userComp"
                              >
                                Registration Type
                              </label>
                              <input
                                type="text"
                                className="form-control custom-form font-14 font-regular"
                                id="userRegType"
                                placeholder="Enter RegistrationType"
                                defaultValue={AccountInfo.registrationType}
                                disabled
                                onChange={(e) =>
                                  handleFieldValidation(
                                    e.target.id,
                                    e.target.value,
                                    "accountInfo"
                                  )
                                }
                              />
                              {errAccountInfo.errregistrationType && (
                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                  {errAccountInfo.errregistrationType}
                                </div>
                              )}
                            </div>
                          </div>

                          {role.toLowerCase() == "primaryadmin" ||
                            role.toLowerCase() == "secondaryadmin" ||
                            role.toLowerCase() == "adminviewer" ||
                            role.toLowerCase() == "secondaryuser" ||
                            role.toLowerCase() == "viewer" ||
                            role.toLowerCase() == "migrationmanager" ? null : (
                            <div className="row">
                              <label
                                className="form-label font-14 font-semibold label-color my-2 pt-3"
                                htmlFor="ph-no"
                              >
                                Phone
                              </label>
                              <div className="input-group flex-nowrap">
                                <Select
                                  className="country-dd"
                                  options={countryOptions}
                                  onChange={handleCountryChange}
                                  value={countryOptions.find(
                                    (option: any) =>
                                      option.value === AccountInfo.phonecode
                                  )}
                                />
                                <input
                                  type="tel"
                                  className="form-control custom-form font-14 font-regular"
                                  placeholder="Enter Phone Number"
                                  id="ph-no"
                                  defaultValue={AccountInfo.phone}
                                  maxLength={15}
                                  onInput={(e: any) => {
                                    const value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ); // Remove non-numeric characters
                                    e.target.value = value; // Update the input value
                                    handleFieldValidation(
                                      e.target.id,
                                      value,
                                      "accountInfo"
                                    );
                                  }}
                                />
                              </div>
                              {errAccountInfo.errphone && (
                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                  {errAccountInfo.errphone}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="modal-footer border-0 p-0">
                          <button
                            type="button"
                            className="btn secondary-btn"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              setEditPopup(false);
                              getClientDetails("initial");
                              seterrAccountInfo((prevState) => ({
                                ...prevState,
                                errname: "",
                                errmail: "",
                                errcompany: "",
                                errregistrationType: "",
                                errphone: "",
                              }));
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn primary-btn"
                            disabled={!enable}
                            onClick={(e) =>
                              handleActions(e, "accountInfo", "update")
                            }
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {Deletepopup === true ? (
                  <div
                    className="modal fade show"
                    id="Failure-popup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="Failure-popupLabel"
                    aria-modal="true"
                    role="dialog"
                    style={{
                      display: "block",
                      backgroundColor: "rgba(0,0,0,0.8)",
                    }}
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                          <span className="position-absolute">
                            <img
                              src="/images/failure-popup-icon.svg"
                              alt="failure-popup-icon"
                            />
                          </span>
                        </div>

                        <div className="modal-body border-0 text-center">
                          <h5
                            className="modal-title mb-3 primary-header"
                            id="Failure-popupLabel"
                          >
                            Alert
                          </h5>

                          <p className="primary-data">
                            Are you sure want to delete the user?
                          </p>
                        </div>

                        <div className="modal-footer border-0 pb-4 justify-content-center">
                          <button
                            type="button"
                            onClick={() => {
                              handleActions(
                                deleteId,
                                "manageUser",
                                "deleteAccount"
                              );
                              setDeleteid("");
                            }}
                            className="btn primary-btn font-14 px-4 font-semibold"
                            data-bs-dismiss="modal"
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setDeletePopup(false);
                              setDeleteid("");
                            }}
                            className="btn primary-btn font-14 px-4 font-semibold"
                            data-bs-dismiss="modal"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {successPopup === true ? (
                  <div
                    className="modal fade show "
                    id="Success-popup"
                    aria-labelledby="Success-popupLabel"
                    aria-modal="true"
                    role="dialog"
                    style={{
                      display: "block",
                      backgroundColor: "rgba(0,0,0,0.8)",
                    }}
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                          <span className="position-absolute">
                            <img
                              src="/images/success-popup-icon.svg"
                              alt="success-popup-icon"
                            />
                          </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                          <h5
                            className="modal-title mb-3 primary-header"
                            id="Success-popupLabel"
                          >
                            Success !
                          </h5>
                          <p className="primary-data">
                            {successPopupType == "passwordUpdate"
                              ? "Password Updated Successfully"
                              : successPopupType == "insertuser"
                                ? "User Added Successfully"
                                : successPopupType == "accountInfoupdate"
                                  ? "Account Information Updated Successfully"
                                  : successPopupType == "userInsert"
                                    ? "User Added Successfully"
                                    : successPopupType == "userUpdate"
                                      ? "User Updated Successfully"
                                      : successPopupType == "userDelete"
                                        ? "User Deleted Successfully"
                                        : ""}
                          </p>
                        </div>
                        <div className="modal-footer border-0 justify-content-center pb-4">
                          <button
                            type="button"
                            className="btn primary-btn font-14 px-4 font-semibold"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              if (successPopupType == "passwordUpdate") {
                                sessionStorage.clear();
                                if (role.toLowerCase().includes("admin")) {
                                  history.push("/Admin/Login");
                                } else {
                                  history.push("/Login");
                                }
                                setSuccessPopup(false);
                              }
                              setSuccessPopup(false);
                            }}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {loader && <Loader />}
      </div>
    </>
  );
};
export default Profile;


