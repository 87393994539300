import "./App.css";
import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useState, useEffect } from "react";
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'


import ServerDown from "./Components/Common/ServerDown";
import PageNotFound from "./Components/Common/PageNotFound";
import Header from "./Components/Common/Header";
import Footer from "./Components/Common/Footer";
import SignUp from "./Components/RegistrationComponent/Registration";
import Login from "./Components/RegistrationComponent/Login";
import Feedback from "./Components/OtherModules/feedback";
import Dashboard from "./Components/Dashboard/Dashboard";
import PsServiceConfiguration from "./Components/ConfigurationsComponents/psServiceConfigurations";
import LicenseRenewal from "./Components/Dashboard/LicenseRenewal";
import ForgotPassword from "./Components/RegistrationComponent/ForgotPassword";

import { HelpAndDocumentation } from "./Components/OtherModules/HelpAndDocumentation";
import { SelectApps } from "./Components/OtherModules/SelectApps";
import Profile from "./Components/Profile/Profile";
import Contactus from "./Components/OtherModules/ContactUs";
import TaskGridComponent from "./Components/OtherModules/Task";
import AssessmentSummary from "./Components/Assessment/AssessmentSummary";
import Configurations from "./Components/ConfigurationsComponents/Configurations";
import { MappedUsers } from "./Components/ConfigurationsComponents/MappedUsers";
import UserMapping from "./Components/ConfigurationsComponents/UserMapping";
import ConnectionComponent from "./Components/OtherModules/Connection";

import MasterAssConfiguration from './Components/ConfigurationsComponents/AssessmentMasterComponent'
import MigrationManageMigration from "./Components/MigrationScreens/MigrationAndManageMigration";

import ManageClientProfile from "./Components/Admin/ManageClientGrid";
import ViewMigrationMaster from "./Components/ViewMigrationComponents/ViewMigrationMaster";
import MigrationTypeMain from "./Components/ViewMigrationComponents/IndividualMigrationReportMaster";
import ViewAssessmentMaster from "./Components/Assessment/ViewAssessmentMaster";
import CustomCreationandMapping from "./Components/CreationandMapping/CustomCreationandMapping";

import AssessmentInprogressComponent from "./Components/Assessment/AssessmentInprogress";
import { RedirectPage } from "./Components/OtherModules/RedirectPage";
import AssignOrRevokeLicense from "./Components/Admin/AssignorRevokeLicense";
import ClientProfile from "./Components/Admin/adminClient";

import ClientPlantInfoComponent from "./Components/RegistrationComponent/Plan";

export function App(): JSX.Element {
  const rn_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;

  let c_local: any = sessionStorage.getItem("c_at");
  let cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;

  let a_local: any = sessionStorage.getItem("a_at");
  let as_secretKey: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
  let objAdmin: any;

  let [serverDown, setServerDown] = useState<string>("");

  useEffect(() => {
    getServerStatus()
  }, [])

  async function getServerStatus() {
    try {
      // let config = {
      //   data: { "type": "getServer" }
      // }
      // let data: any = await getServerdownvalue(config)
      // if (data.data.statusCode == 200) {
      //   if (data.data.data.serverStatus == 'true' && objAdmin == undefined) {
      //     localStorage.removeItem("c_at");
      //   }
      setServerDown("false")
      // }
    }
    catch (error) {
      console.log(error);
    }
  }

  if (c_local != undefined) {
    let c_byteValue = objCryptoJS.AES.decrypt(c_local, cs_secretKey);
    var c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(c_decryptedValue);

  }
  if (a_local != undefined) {
    let a_byteValue = objCryptoJS.AES.decrypt(a_local, as_secretKey);
    var a_decryptedValue = a_byteValue?.toString(objCryptoJS.enc.Utf8);
    objAdmin = jwt(a_decryptedValue);
  }
  let pagesWithoutHeader: string[] = ['registration', 'signUp', 'verifyemail', 'Login', 'Forgotpassword', 'resetpassword']
  const shouldDisplayHeader = !pagesWithoutHeader.some(page =>
    window.location.pathname.toLowerCase().includes(page)
  );

  let adminPages: any = ["admin/manageclients", "admin/feedback", "admin/profile", "admin/faq", "admin/help&documentation"]

  if (window.location.pathname.toString().toLowerCase().includes("admin")) {
    document.title = `AVAMIGRATRON - Admin`
  }
  else {
    const path = window.location.pathname.toString().split('/')
    document.title = `AVAMIGRATRON - ${path[1].charAt(0).toUpperCase() + path[1].slice(1)}`
  }

  const getClientSessionDetails = async () => {
    try {
      // if (objClient.CMail != undefined) {
      //   let config = {
      //     token: objCryptoJS.AES.encrypt(c_decryptedValue, rn_Key).toString()
      //   }

      //   let data: any = await sessiondetails(config)
      //   if (data.data.statusCode == 200) {
      //     //#region Update and Frame Client Session
      //     localStorage.removeItem("c_at");
      //     let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, rn_Key);
      //     var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
      //     let enPass = objCryptoJS.AES.encrypt(dePass, cs_secretKey).toString();
      //     localStorage.setItem("c_at", enPass);
      //     //#endregion

      //     // var nodeToken = dePass.toString(objCryptoJS.enc.Utf8);
      //     let nodeSession: any = jwt(dePass);
      //     if (nodeSession.isClientExpired == true) {
      //       window.location.pathname = "/UpgradePlan";
      //     }
      //   }
      //   else {
      //   }
      // }
    }
    catch (error) {
      console.log(error);
    }
  }

  if (window.location.pathname.toString().toLowerCase().includes("/dashboard") || window.location.pathname.toString().toLowerCase().includes("/task") || window.location.pathname.toString().toLowerCase().includes("/selectapp") ||
    window.location.pathname.toString().toLowerCase().includes("/Connectors") || window.location.pathname.toString().toLowerCase().includes("/configurations") || window.location.pathname.toString().toLowerCase().includes("/usermapping")
    || window.location.pathname.toString().toLowerCase().includes("/migration") || window.location.pathname.toString().toLowerCase().includes("/assessment")) {
    getClientSessionDetails();
  }

  const navigateToLogin = async () => {
    try {
      window.location.pathname = "/Login";
    } catch (error) {
    }
  }

  return (
    <Router>
      {
        (serverDown === "true" && objAdmin == undefined && window.location.href.includes("/Admin") != true) ?
          <Switch>
            <Route exact path="/ServerDown" component={ServerDown} ></Route>
            <Route path="*" >
              <Redirect to="/ServerDown"></Redirect>
              <Route path="*" component={ServerDown}></Route>
            </Route>
          </Switch>
          :
          (objAdmin != undefined || objClient != undefined || window.location.pathname.toString().toLowerCase().includes("/login") || window.location.pathname.toString().toLowerCase() == "/registration" || window.location.pathname.toString().toLowerCase() == "/admin/login" || window.location.pathname.toString().toLowerCase() == "/forgotpassword" || window.location.pathname.toString().toLowerCase() == "/resetpassword" || window.location.pathname.toString().toLowerCase() == "/admin/resetpassword" || window.location.pathname.toString().toLowerCase() == "/admin/forgotpassword") ?
            <>{shouldDisplayHeader ? <Header /> : null}
              <Switch>
                {/* Registration Module route Starts Here */}
                <Route exact path="/Registration" component={SignUp} />
                <Route exact path="/Login" component={Login} />
                <Route exact path="/NewPlan" component={ClientPlantInfoComponent} />
                <Route exact path="/Admin/login" component={Login} />
                <Route exact path="/Forgotpassword" component={ForgotPassword} />
                <Route exact path="/Admin/Forgotpassword" component={ForgotPassword} />
                {/* <Route exact path={"/Emailverification"} component={Emailverification} /> */}
                <Route exact path={"/ResetPassword"} component={Login} />
                <Route exact path={"/Admin/ResetPassword"} component={Login} />




                {/* <Route exact path="/VerifyEmail" component={Dashboard} />
                <Route exact path="/Login" component={Dashboard} />
                <Route exact path="/Admin/Login" component={Dashboard} />
                <Route exact path="/ForgotPassword" component={Dashboard} />
                <Route exact path="/ResetPassword" component={Dashboard} />
                <Route exact path="/Admin/ForgotPassword" component={Dashboard} />
                <Route exact path="/Admin/ResetPassword" component={Dashboard} /> */}
                {/* Registration Module route Ends Here */}

                {/* Other Routings Start */}

                {/* Dashboard Module route Starts Here */}
                <Route exact path="/Dashboard" component={Dashboard} />
                <Route exact path="/Admin/Dashboard" component={Dashboard} />
                <Route exact path="/Admin/LicenseRenewal" component={LicenseRenewal} />
                {/* Dashboard Module route Ends Here */}

                {/* Task Module route Starts Here */}
                <Route exact path="/Task" component={TaskGridComponent} />
                <Route exact path="/Configurations" component={Configurations} />
                <Route exact path="/ViewMigration" component={ViewMigrationMaster}/>
                <Route exact path="/MigrationReport" component={MigrationTypeMain}/>
                
                <Route exact path="/userMapping" component={UserMapping}/>
                <Route exact path="/MappedUsers" component={MappedUsers}/>

                {/* Task Module route Ends Here */}


                {/* Migration Module route Starts Here */}
                <Route exact path="/Connectors" component={ConnectionComponent} />
                <Route exact path="/Connectors/Redirect" component={RedirectPage}/>
                <Route exact path="/SelectApps" component={SelectApps} />
                <Route exact path="/Migration" component={MigrationManageMigration} />
                <Route exact path="/CustomCreationandMapping" component={CustomCreationandMapping} />

                {/* 
                <Route exact path="/Configurations" component={Dashboard} />
                <Route exact path="/UserMapping" component={Dashboard} />
                <Route exact path="/Migration" component={Dashboard} />
                <Route exact path="/ViewMigration" component={Dashboard} />
                <Route exact path="/MigrationReport" component={Dashboard} /> */}
                {/* Migration Module route Ends Here */}

                {/* Assessment Module route Starts Here */}
                <Route exact path="/AssessmentConfigurations" component={MasterAssConfiguration} />
                <Route exact path="/Assessment" component={SelectApps} />
                <Route exact path="/AssessmentSummary" component={AssessmentSummary} />
                <Route exact path="/ViewAssessment" component={ViewAssessmentMaster} />
                {/* Assessment Module route Ends Here */}

                {/* ProfileUpdate Contact-Us Choose/Upgrade-Plan Module route Starts Here */}
                {/* { {<Route exact path="/ChoosePlan" component={Dashboard} />
                <Route exact path="/UpgradePlan" component={Dashboard} /> */}
                <Route exact path="/ContactUs" component={Contactus} />
                <Route exact path="/FAQ" component={Contactus} />
                <Route exact path="/Profile" component={Profile} />
                <Route exact path="/Feedback" component={Feedback} />
                <Route exact path="/HelpandDocumentation" component={HelpAndDocumentation} />
                {/* Registration Module route Ends Here */}

                {/* Admin Module route Starts Here */}
                <Route exact path="/Admin/ManageClients" component={ManageClientProfile} />
              <Route exact path="/Admin/ManageClients/NewClient" component={ClientProfile} /> 
                <Route exact path="/Admin/ManageClients/AdminClient" component={ClientProfile} />

                <Route exact path="/Admin/ManageClients/AssignRevokeLicense" component={AssignOrRevokeLicense} />
                <Route exact path="/Admin/Feedback" component={Feedback} />
                <Route exact path="/Admin/Profile" component={Profile} />
                <Route exact path="/Admin/ContactUs" component={Contactus} />
                <Route exact path="/Admin/HelpandDocumentation" component={HelpAndDocumentation} />
                <Route exact path="/Admin">
                  <Redirect to="/Admin/ManageClients"></Redirect>
                </Route>
                {/* Admin Module route Ends Here */}
                <Route exact path="/Inprogress" component={AssessmentInprogressComponent} />

                <Route path="*">
                  {/* <Redirect to="/Task"></Redirect> */}
                  <Route path="*" component={Dashboard}></Route>
                </Route>

                {/* Other Routings End */}
              </Switch>
              {shouldDisplayHeader ? <Footer /> : null}
            </>
            :
            (serverDown == "false" || window.location.href.includes("/Admin") == true) ?
              <Switch>
                <Route path="*" render={() => navigateToLogin()}>
                </Route>
              </Switch>
              : null
      }
    </Router >
  );
}

export default App;

