import { useState, useEffect, ChangeEvent, useRef } from "react";
import {
    VideoData,
    GuideData,
    DeleteGuide,
    DeleteVideo,
    CategoryData,
    UploadGuide,
    AddVideo,
    EditVideo,
    SearchText,
    Popup,
    videoLabel,
    sessionResponse,
    Client,
    Admin,
    count
} from '../../Common/Interface';

import { useHistory } from "react-router-dom";
import { decodeSessionDetails } from "../../Services/CommonServices";
import { getGuideAndVideoService, uploadGuideToBlobService, addOrEditVideoService, deleteGuideOrVideoService, getBlobAccessTokenService } from "../../Services/HelpAndDocumentationService";
import { commonErrorMsgs, fileErrors, videoErrors } from "../../Common/Constant";
import { getSession } from "../../Services/manageClientAPI";
import moment from "moment";
import { saveAs } from 'file-saver';
import { AxiosResponse } from "axios";
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'


export function HelpAndDocumentation() {
    //PS_EX_26 to PS_EX_42 wil cover all the state variable initializations and declrations
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [role, setRole] = useState('');
    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let history = useHistory()
    const searchInputRef = useRef<HTMLInputElement>(null);
    const searchInputRef1 = useRef<HTMLInputElement>(null);
    const [totalCount, setTotalCount] = useState<count>({ videoCount: 0, guideCount: 0 })
    const [showConfigurations, setShowConfigurations] = useState(false)
    const tokenExpiryCheckInterval = 20 * 60 * 1000;  // 20 minutes
    const [guideData, setGuideData] = useState<GuideData[] | undefined>();
    const [videoData, setVideoData] = useState<VideoData[] | undefined>();
    const [search, setSearch] = useState<SearchText>({ videoSearch: '', guideSearch: '' });
    const [searchBuffer, setSearchBuffer] = useState<SearchText>({ videoSearch: '', guideSearch: '' });
    const [loader, setLoader] = useState<boolean>(true);
    const [showGuide, setShowGuide] = useState<boolean>(true);
    const [showApp, setShowApp] = useState<string>('slack');
    const [category, setCategory] = useState<CategoryData[] | undefined>();
    const [videoDataArray, setVideoDataArray] = useState<VideoData[]>([]);
    const [popup, setPopup] = useState<Popup>({ errorMessage: '', successMessage: '' });
    const [label, setLabel] = useState<string>("")
    const [videoLabel, setVideoLabel] = useState<videoLabel>({ youtube: "", description: "", category: "" })
    const [form, setForm] = useState<string>("")
    const [singleVideoData, setSingleVideoData] = useState<VideoData>({ categoryId: '', tutorialVideoUrl: '', description: '' } as VideoData);
    const [editVideoData, setEditVideoData] = useState<VideoData>({} as VideoData);
    let [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    const [alert, setAlert] = useState<string>('');
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);

    const [disableActions, setDisableActions] = useState(false)

    // //debugger
    let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (window.location.pathname.toString().toLowerCase().includes("admin")) {
            isAdmin = true
            setIsAdmin(isAdmin)
        }
        mainmethod()
        sessionTokenProperties()
        //     if(adminrole!=  'PrimaryAdmin'){
        //     resetTimer();
        //     }
        getGuideAndVideo(true)
        //console.log(adminrole, "adminrole");

        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);

        // Return cleanup function
        return () => {
            if (InActiveTimeout.current) {
                console.log("Hemp & Documentation Unmount Time Clear");
                clearTimeout(InActiveTimeout.current);
            }
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };
    }, []);


    let mainmethod = async () => {
        const sessiontoken: any = await decodeSessionDetails();
        //console.log(sessiontoken, "juhuhuhu");

        if (typeof sessiontoken?.adminDetails?.Role !== 'string' ||
            (sessiontoken?.adminDetails?.Role && !sessiontoken.adminDetails.Role.toLowerCase().includes('admin'))) {
            resetTimer();
        }
    }

    let resetTimer = async () => {
        try {
            // debugger;
            //console.log("Reset Timer Start");

            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            ////console.log("sessionResponse value :", sessionResponse);

            // //debugger
            if (sessionResponse == 'expired') {
                debugger
                sessionStorage.removeItem("c_at");
                setisSessionEpired(true)
                setAlert('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {

                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }
                debugger
                let token = await getToken()

                let payload = {
                    mailId: emailAddress,
                    isAdmin: isAdmin ? true : false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                // const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                // const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                // let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', getsessionToken?.data?.data.sessionToken);
                await sessionTokenProperties();
                // let updatedobjClient: any = jwt(c_decryptedValue);
                // SessionEpiredDateTime = updatedobjClient?.CreatedAt;
                // setSessionEpiredDateTime(SessionEpiredDateTime);
            }
            ////console.log("Reset Timer End");
        }
        catch (error) {
            ////console.log("Reset Timer Catch");
            ////console.log(error);

            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            setLoader(false);
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            //debugger
            ////console.log("Reset Session Timer Start");
            // Assuming InActiveTimeout is a number representing the ID of the current timeout

            if (typeof window !== 'undefined') {
                ////console.log("Reset Session Timer Inside");
                if (InActiveTimeout.current) {
                    clearTimeout(InActiveTimeout.current);
                }
                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout.current = setTimeout(() => {
                    if (!isAdmin) {
                        ////console.log("Expired");
                        sessionStorage.removeItem("c_at");
                        ////console.log("removed session");
                        setisSessionEpired(true)
                        setAlert('Your Session has Expired please login again to continue');
                        // return 'expired'
                    }
                }, tokenExpiryCheckInterval);

                ////console.log("Reset Session Timer checking");

                const currentDateTime = new Date().toISOString();
                //const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

                const diffInMilliseconds = Math.abs(new Date(currentDateTime).getTime() - new Date(SessionEpiredDateTime).getTime());
                ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                ////console.log("minutes : ", minutes);

                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
                ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

                if (minutes > sessionUpdateTimeGap) {

                    ////console.log("Reset Session Timer need to update");
                    return 'updateToken'
                }

                ////console.log("Reset Session Timer End");
                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            ////console.log(ex,"Reset Session Timer Catch");       
            throw ex
        }
    };

    let sessionTokenProperties = async () => {
        try {
            debugger
            const tokenResponse: any = await decodeSessionDetails();
            //console.log(tokenResponse, "tokenresponse daw deiiii");

            if (tokenResponse.adminDetails) {
                //console.log(tokenResponse.adminDetails.Role, "idhe ra role");

                setObjAdmin(tokenResponse.adminDetails)
                setAdminRole(tokenResponse.adminDetails.Role);
                setAdminName(tokenResponse.adminDetails.Name);
                setAdminEmailAddress(tokenResponse.adminDetails.Email);
                // emailAddress = tokenResponse.adminDetails.Email;
                // setEmailAddress(emailAddress);
                // setSessionEpiredDateTime(tokenResponse.adminDetails.CreatedAt);

                if (tokenResponse?.adminDetails?.Role.toLowerCase().includes('viewer')) {
                    setDisableActions(true)
                }
            }
            else {
                if (window.location.pathname.toString().toLowerCase().includes("admin")) {
                    history.push("/Admin/Login")
                }
            }
            //console.log(adminrole, "adminnnn");

            if (!window.location.pathname.toString().toLowerCase().includes("admin")) {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    
                SessionEpiredDateTime = tokenResponse.clientDetails.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);

                    if (tokenResponse?.clientDetails?.Role.toLowerCase().includes('viewer')) {
                        setDisableActions(true)
                    }

                }
                else {
                    history.push("/Login")
                }
            }

            //#endregion
        } catch (error) {
            ////console.log("Failed in sesssionTokenProps",error);

            console.error('Failed in main method', error);
        }
    };

    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = sessionToken

                }


            }
            if (objAdmin != undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    // sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    // adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = sessionToken

                }
            }
            return token
        } catch (error) {
            ////console.log("getToken Catch",error);

            throw error
        }
    }


    // PS_EX_87 - PS_EX_99 
    // This is the method to call the bind the guides and videos table/grid
    function bindTable() {
        if (showGuide) {
            let filteredData = guideData?.filter((item: any) => item?.environmentType?.toLowerCase() == showApp.toLowerCase())
           

            if (showConfigurations) {
             
                  
                    return (
                        <>
                            <div className="col-md-12">
                                <div className="row gx-5">
                                    {filteredData?.map((element1: any, index: any) => {
                                        return (
                                            <div className="col-md-3     mt-4">
                                                <div className="card custom-height rounded-3 position-relative card-hover files-card">
                                                    {!disableActions && <img
                                                        aria-disabled={disableActions}
                                                        onClick={() => { if (!disableActions) handleGuideDelete(element1.documentationGuideId) }}
                                                        src="/images/cross-red-icon.svg"
                                                        alt="Delete"
                                                        className="position-absolute files-card-cancel-btn cursor-pointer"
                                                    />
                                                    }
                                                    <div className="d-flex align-items-center justify-content-center h-100">
                                                        <img
                                                            className="docs-custom-width"
                                                            src="/images/pdf-icon.svg"
                                                            alt="word-document"
                                                        />
                                                    </div>
                                                    <div className="card-body border-top py-2">
                                                        <p className="card-text font-14 font-semibold label-color mb-0">
                                                            {/* {element1.guideName.length > 25 ? element1.guideName.slice(0, 25) + "...." : element1.guideName} */}
                                                            {element1.guideName}
                                                        </p>
                                                        <span className="font-10 font-regular cust-role-color">
                                                            {moment(element1.modifiedDate).format('MMMM D, YYYY')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                           
                        </>
                    )
                
            }
            else {
             
                   
                    ////console.log(array1, array2);

                    return (
                        <>
                            <div className="col-md-12">
                                <div className="row gx-5">
                                    {filteredData?.map((element1: any, index: any) => {
                                        return (
                                            <>
                                                <div className="col-md-3     mt-4">
                                                    <div className="card custom-height rounded-3 position-relative hover-cont">
                                                        <div className="cust-hover">
                                                            <div className="d-flex align-items-center justify-content-center h-100">
                                                                <img
                                                                    className="docs-custom-width image"
                                                                    src="/images/pdf-icon.svg"
                                                                    alt="pdf"
                                                                />
                                                            </div>
                                                            <div className="card-body border-top py-2">
                                                                <p className="card-text font-14 font-semibold label-color mb-0">
                                                                    {element1.guideName}
                                                                    {/* {element1.guideName.length > 25 ? element1.guideName.slice(0, 25) + "...." : element1.guideName} */}
                                                                </p>
                                                                <span className="font-10 font-regular cust-role-color">
                                                                    {moment(element1.modifiedDate).format('MMMM D, YYYY')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="position-absolute middle rounded-3">
                                                            <button
                                                                type="button"
                                                                className="btn btn-download-circle position-absolute"
                                                            >
                                                                <img
                                                                    src="/images/download-icon-hover.svg"
                                                                    alt="download"
                                                                    onClick={() => handleGuideDownload(element1)}

                                                                />
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}

                                </div>
                            </div>
                         


                        </>
                    )


              
            }
        }
        else {
            if (showConfigurations) {
                return videoData?.filter((element) => element?.environmentType?.toLowerCase() == showApp)?.map((item, _) => {
                    return (
                        <div className="col-md-4 mt-4">
                            <div className="card border-radius-14 custom-vid-card-height vid-card border-0 mb-1 hover-cont">
                                <div className="cust-hover position-relative">
                                    <iframe
                                        className="border-radius-14 h-100 border-0 w-100"
                                        src={item.tutorialVideoUrl.includes('embed') ? item.tutorialVideoUrl : item.tutorialVideoUrl.startsWith('https://youtu.be') ? item.tutorialVideoUrl.replace('https://youtu.be', 'https://www.youtube.com/embed') : `https://www.youtube.com/embed/${item.tutorialVideoUrl.split('/watch?v=')[1]}`}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"

                                    />
                                    <div className="position-absolute cust-bg-color rounded-3 custom-play-position px-2 py-1">
                                        <span className="font-14 font-regular ">
                                            <img
                                                className="me-2 mb-1"
                                                src="/images/play-icon.svg"
                                                alt="play"
                                            />
                                        </span>
                                    </div>
                                    <div className="card-body py-2 px-0">
                                        <span className="font-14 font-semibold label-color mb-0 d-block">
                                            {(item.description?.length > 40) ? (
                                                <span
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="right"
                                                    data-bs-title="Type Team Site"
                                                    data-bs-original-title=""
                                                    title={item.description}
                                                >
                                                    {item.description?.slice(0, 40) + '...'}
                                                </span>
                                            ) : item.description}
                                        </span>
                                    </div>
                                </div>
                                {!disableActions &&
                                    <div className="position-absolute middle absolute-icon-container border-radius-14 gap-2">
                                        <button
                                            type="button"
                                            disabled={disableActions}
                                            onClick={() => {
                                                if (!disableActions) {
                                                    setForm("edit");
                                                    handleVideoEditChange(item);
                                                }
                                            }}
                                            className="btn primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center bg-white me-2"
                                        >
                                            <img
                                                src="/images/edit-icon.svg"
                                                alt="edit"
                                                width={13}
                                            />
                                            {/* <span className="primary-color" onClick={() => {  }}>Edit</span> */}
                                        </button>
                                        <button
                                            disabled={disableActions}
                                            onClick={() => {
                                                if (!disableActions) {
                                                    handleVideoDelete(item.tutorialVideoId as number)
                                                }
                                            }}
                                            type="button"
                                            className="btn primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center bg-red-light"
                                        >
                                            <img
                                                src="/images/red-delete-icon.svg"
                                                alt="cancel"
                                                width={13}
                                            />

                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                })
            }
            else {
                return category?.filter((element) => element.environmentType.toLowerCase() == showApp).map((item: CategoryData) => {
                    return (
                        <>
                            {videoData?.some((element) => element.categoryId == item.categoryId) && <div className="col-md-12 mt-3">
                                <h5 className="font-18 font-bold">{item.category}</h5>
                            </div>}
                            {videoData?.filter((element) => element.categoryId == item.categoryId && element.environmentType.toLowerCase() == showApp.toLowerCase()).map((element, index) => {
                                return (
                                    <>
                                        <div className="col-md-4 mt-4">
                                            <div className="card border-radius-14 custom-vid-card-height border-0 mb-1 position-relative">
                                                <iframe
                                                    className="border-radius-14 h-100 border-0"
                                                    src={element.tutorialVideoUrl.includes('embed') ? element.tutorialVideoUrl : element.tutorialVideoUrl.startsWith('https://youtu.be') ? element.tutorialVideoUrl.replace('https://youtu.be', 'https://www.youtube.com/embed') : `https://www.youtube.com/embed/${element.tutorialVideoUrl.split('/watch?v=')[1]}`}
                                                    title="YouTube video player"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"

                                                />
                                                <div className="position-absolute cust-bg-color rounded-3 custom-play-position px-2 py-1">
                                                    <span className="font-14 font-regular ">
                                                        {/* <img
                                                            className="me-2 mb-1"
                                                            src="/images/play-icon.svg"
                                                            alt="play"
                                                        /> */}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-body py-2 px-0">
                                                <span className="font-14 font-semibold label-color mb-0 d-block">
                                                {(element.description?.length > 40) ? (
                                                    <span
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="right"
                                                    data-bs-title="Type Team Site"
                                                    data-bs-original-title=""
                                                    title={element.description}
                                                    >
                                                    {element.description?.slice(0, 40) + '...'}
                                                    </span>
                                                ) : element.description}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </>
                    )
                })

            }


        }
    }


    //PS_EX_49 to PS_EX_72
    //This is the method to call the get Guide Video Category data 
    const getGuideAndVideo = async (flag = false) => {
        debugger
        if((!searchBuffer?.videoSearch?.length && !searchBuffer?.guideSearch?.length) || searchBuffer?.guideSearch?.trim()!='' ||searchBuffer?.videoSearch?.trim()!=''){
            setLoader(true);
        try {
            let token = await getToken()

            const guideAndVideoData = await getGuideAndVideoService({
                guideSearchText: searchBuffer.guideSearch.trim(),
                videoSearchText: searchBuffer.videoSearch.trim(),
                token: token
            });

            // Mock response structure, replace with actual interface implementation
            if (guideAndVideoData && guideAndVideoData?.data.statusCode === 200) {
                setGuideData(guideAndVideoData?.data.data[0]);
                setVideoData(guideAndVideoData?.data.data[1]);
                setCategory(guideAndVideoData?.data.data[2]);
                // setSearchBuffer({ guideSearch: "", videoSearch: "" })
                // setSearch({ guideSearch: "", videoSearch: "" })
                if (flag) {
                    setTotalCount({ ...totalCount, guideCount: guideAndVideoData?.data.data[0].length })
                    setTotalCount({ ...totalCount, videoCount: guideAndVideoData?.data.data[1].length })
                }
            } else {
                setPopup({ ...popup, errorMessage: guideAndVideoData?.data.statusMessage });
            }
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        } finally {
            setLoader(false);
        }
        }
        
    };

    // PS_EX_192 - PS_EX_201
    // This handler method is used to handle input fields when A user fills the inputs
    const handleFieldInput = (e: any) => {
        try {
            debugger
            let YT_URL_PATTERN: RegExp = /^(https:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/)[\w-]{11}(\?.*)?$/
            ////console.log(e.target.type);
            let label: videoLabel = {
                youtube: "",
                category: "",
                description: ""
            }
            const { id, value } = e.target;
            if (e.target?.type === "text" || e.target?.type === "textarea") {
                switch (id) {
                    case "youtubeUrl":
                        if (!value) {
                            label.youtube = videoErrors.mandatory
                            setSingleVideoData({ ...singleVideoData, tutorialVideoUrl: value.trim() });
                            setVideoLabel({ ...videoLabel, youtube: label.youtube })
                            return; // Mandatory validation failed for tutorialVideoURL
                        }
                        if (!YT_URL_PATTERN.test(value.trim())) {
                            setSingleVideoData({ ...singleVideoData, tutorialVideoUrl: value.trim() });
                            setVideoLabel({ ...videoLabel, youtube: videoErrors.invalidUrlFormat })
                            return;
                        }
                        setVideoLabel({ ...videoLabel, youtube: "" })
                        setSingleVideoData({ ...singleVideoData, tutorialVideoUrl: value.trim() });
                        break;
                    case "description":
                        if (!value) {
                            label.description = videoErrors.mandatory
                            setSingleVideoData({ ...singleVideoData, description: value.trim() });
                            setVideoLabel({ ...videoLabel, description: videoErrors.mandatory })
                            return
                        }
                        if (value.length > 255) {
                            setSingleVideoData({ ...singleVideoData, description: value.trim() });
                            setVideoLabel({ ...videoLabel, description: videoErrors.maxLength })
                            return; // Failed description length validation
                        }
                        setVideoLabel({ ...videoLabel, description: "" })
                        setSingleVideoData({ ...singleVideoData, description: value });
                        break;
                }

            }
            else {
                if (!value) {
                    label.category = videoErrors.mandatory
                    setVideoLabel({ ...videoLabel, category: label.category })
                    return
                }
                setVideoLabel({ ...videoLabel, category: "" })
                setSingleVideoData({ ...singleVideoData, categoryId: value });
                // setSingleVideoData({ ...singleVideoData, categoryId: e.target.value });
            }
        } catch (error) {
            setLoader(false);
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    }


    // PS_EX_202 - PS_EX_208
    // This handler method is used to handle the addition of video urls to the grid of video data that is about to be inserted to DB
    const handleAdd = () => {
        try {
            if (videoLabel.category == "" && videoLabel.youtube == "" && videoLabel.description == "") {
                if (videoDataArray.some((element) => element.tutorialVideoUrl == singleVideoData.tutorialVideoUrl)) {
                    setVideoLabel({ ...videoLabel, youtube: videoErrors.duplicateError })
                    return
                }
                setVideoLabel({ youtube: "", description: "", category: "" })
                // Assuming this adds to an array state - replace with actual state update logic
                setVideoDataArray(prevArray => [singleVideoData, ...prevArray]);
                setSingleVideoData({ categoryId: '', tutorialVideoUrl: '', description: '' } as VideoData)
            }
            else {
                setVideoLabel({ youtube: '', category: '', description: '' })
            }
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    };

    // PS_EX_73 - PS_EX_79
    // This handler method is used to handle when A user switches tabs
    const handleTabSwitch = (e: React.MouseEvent<HTMLButtonElement>) => {
        try {

            if (e.currentTarget.id.includes("guides")) {
                setShowGuide(true)
                setShowApp("slack")
            }
            else {
                setShowGuide(false)
                setShowApp("slack")
            }
            setSearch({ guideSearch: "", videoSearch: "" })
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    };

    // PS_EX_80 - PS_EX_86
    // This handler method is used to handle when A user switches App tabs
    const handleAppTabSwitch = (e: any) => {
        try {
            debugger
            const appTabId = e.target.id;
            setLabel("")
            // setSearch({guideSearch : "", videoSearch : ""})
            if (appTabId.includes("slack")) {
                setShowApp("slack");
                setSearchBuffer({ guideSearch: "", videoSearch: "" })
                setSearch({ guideSearch: "", videoSearch: "" })
            } else if (appTabId.includes("tenant")) {
                setShowApp("tenant");
                setSearchBuffer({ guideSearch: "", videoSearch: "" })
                setSearch({ guideSearch: "", videoSearch: "" })
            } else {
                setShowApp("google");
                setSearchBuffer({ guideSearch: "", videoSearch: "" })
                setSearch({ guideSearch: "", videoSearch: "" })
            }
            if (searchInputRef.current?.value != null) {
                searchInputRef.current.value = ""
            }
            if (searchInputRef1.current?.value != null) {
                searchInputRef1.current.value = ""
            }
            getGuideAndVideo()
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    };

    // PS_EX_107 - PS_EX_127
    // This handler method is used to delete a guide when A user clicks the delete button of a guide
    const handleGuideDelete = async (id: number) => {
        try {
            setLoader(true); // PS_EX_107 and PS_EX_120
            let token = await getToken()

            let deletion = await deleteGuideOrVideoService({ documentationGuideId: id, type: "guide", token: token }); // PS_EX_108
            if (deletion?.data.statusCode == 200) {
                setPopup({ ...popup, successMessage: deletion?.data.statusMessage }); // PS_EX_121
                getGuideAndVideo()
            }
            else {
                setPopup({ ...popup, errorMessage: deletion?.data.statusMessage }); // PS_EX_121
            }
        } catch (error: any) {
            setPopup({
                ...popup, errorMessage: error?.statusMessage || commonErrorMsgs.generalError, // PS_EX_123 and PS_EX_125
            });
            setLoader(false)
        }
    }

    // PS_EX_101 - PS_EX_106
    // This handler method is used to download a guide when A user clicks the download button of a guide
    const handleGuideDownload = async (value: GuideData) => {
        try {
            let token = await getToken();
            const sasToken: AxiosResponse | undefined = await getBlobAccessTokenService({ token: token });
            saveAs(value.guideURL + "?" + sasToken?.data.data, value.guideName);
        } catch (error) {
            setLoader(false); // PS_EX_103
            setPopup({
                ...popup,
                errorMessage: commonErrorMsgs.generalError, // PS_EX_104
            });
        }
    };

    // PS_EX_159 - PS_EX_191
    // This handler method is used to handle the guide upload scenario by the user
    async function handleGuideUpload(e: any) {
        try {
            let file
            if (e.type == "drop") {
                e.preventDefault();
                file = e.dataTransfer.files[0]
            }
            else {
                file = e.target.files[0]
            }
            let guideValidation: string = validateGuideUpload(file)
            switch (guideValidation) {
                case "size": {
                    setLabel(fileErrors.sizeError);
                    break
                }
                case "format": {
                    setLabel(fileErrors.formatError);
                    break
                }
                case "exist": {
                    setLabel(fileErrors.duplicateError);
                    break
                }
                case "true": {
                    setLoader(true)
                    let token = await getToken()

                    let formData = new FormData()
                    formData.append('file', file)
                    formData.append('token', JSON.stringify(token));
                    formData.append('environmentType', showApp.charAt(0).toUpperCase() + showApp.slice(1))
                    let uploadGuide = await uploadGuideToBlobService(formData)
                    if (uploadGuide?.data.statusCode == 200) {
                        getGuideAndVideo()
                        setLabel("")
                    }
                    else {
                        setLoader(false)
                        setPopup({ ...popup, errorMessage: uploadGuide?.data.statusMessage });
                    }
                    break
                }
            }
        } catch (error) {
            setLoader(false); // PS_EX_103
            setPopup({
                ...popup,
                errorMessage: commonErrorMsgs.generalError, // PS_EX_104
            });
        }
    }

    // PS_EX_164 - PS_EX_167
    // This handler method is used to handle the validatity of the data that is uploaded by the user and returns respective error
    function validateGuideUpload(file: any): string {
        try {
            if (file?.size >= 5000000) return "size"
            if (!file?.name.endsWith('.pdf')) return "format"
            if (guideData?.some((element) => element.guideName == file.name && element.environmentType.toLowerCase() == showApp.toLowerCase())) return "exist"
            return "true"
        } catch (error) {
            return "error"
        }
    }

    // PS_EX_268 - PS_EX_273
    // This handler method is used to handle the updating of video data in DB
    const handleVideoArrayDelete = (index: number) => {
        try {
            if (!disableActions) {
                debugger
                setVideoDataArray(videoDataArray.filter((_, ind) => ind != index))
            }
        } catch (error) {
            setLoader(false); // PS_EX_103
            setPopup({
                ...popup,
                errorMessage: commonErrorMsgs.generalError, // PS_EX_104
            });
        }
    }

    // PS_EX_209 - PS_EX_228
    // This handler method is used to trigger the api call to inserted the videos into DB
    const handleVideoAdd = async () => {
        setLoader(true);

        try {
            let token = await getToken()

            setForm("")
            let videoAdd = await addOrEditVideoService({
                videos: videoDataArray, // Replace with actual video data array
                type: "add",
                token: token
            });
            
            if (videoAdd?.data.statusCode === 200) {
                setVideoDataArray([])
                setSingleVideoData({ categoryId: '', tutorialVideoUrl: '', description: '' } as VideoData)
                getGuideAndVideo();
            } else if (videoAdd) {
                setLoader(false)
                setPopup({ ...popup, errorMessage: videoAdd?.data.statusMessage });
            }
           
        } catch (error) {
            setLoader(false); // PS_EX_103
            setPopup({
                ...popup,
                errorMessage: commonErrorMsgs.generalError, // PS_EX_104
            });
        }
    };

    // PS_EX_128 - PS_EX_147
    // This handler method is used to delete a video when A user clicks the delete button of a video
    const handleVideoDelete = async (id: number) => {
        try {
            setLoader(true);
            let token = await getToken()

            const deletion = await deleteGuideOrVideoService({ tutorialVideoId: id, type: "video", token: token });
            if (deletion?.data.statusCode == 200) {
                debugger
                setPopup({ ...popup, successMessage: "Video Successfully Deleted" });
                getGuideAndVideo()

            } else {
                setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
                setLoader(false)
            }

        } catch (error) {
            //   setPopup({ errorMessage: commonErrorMsgs.generalError });
        } finally {
            setLoader(false);
        }
    };

    // PS_EX_239 - PS_EX_260
    // This handler method is used to handle the updating of video data in DB 
    const handleVideoEdit = async (id: number) => {
        try {
            // Assuming editVideoData is part of the component's state
            setLoader(true)
            setForm("")
            let token = await getToken()

            let editVideo: any = await addOrEditVideoService({ ...singleVideoData, tutorialVideoId: id, type: "edit", token: token });

            if (editVideo.data.statusCode === 200) {
                // Videos updated successfully
                getGuideAndVideo();
            } else if (editVideo) {
                setLoader(false)
                setPopup({ ...popup, errorMessage: editVideo?.data.statusMessage });
            }
        } catch (error) {
            // Error occurred
            setLoader(false)
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    };

    // PS_EX_229 - PS_EX_238
    // This handler method is used to handle the edit form data binding in the video form 
    const handleVideoEditChange = (item: VideoData) => {
        try {
            let obj: VideoData = {
                tutorialVideoId: item.tutorialVideoId,
                description: item.description,
                categoryId: item.categoryId,
                tutorialVideoUrl: item.tutorialVideoUrl,
                environmentType: item.environmentType
            }
            setEditVideoData(obj)
            setSingleVideoData(obj)
        } catch (error) {

        }
    }

    // PS_EX_148 - PS_EX_158
    // This handler method is used to handle the search box when A user types in the search box
    const handleSearchInput = (e: any) => {
        try {
            e.target.focus()
                if (e.target.id.includes("guide")) {
                    setSearchBuffer({ ...search, guideSearch: e.target.value });
                } else {
                    setSearchBuffer({ ...search, videoSearch: e.target.value });
                }
        } catch (error) {
            setLoader(false);
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    };

    const bindVideoArray = () => {
        return videoDataArray?.reverse().map((item, index) => {
            return (
                <li className="list-group-item custom-list-group-item mt-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <label className="custom-list-label">
                            {item.tutorialVideoUrl}
                        </label>
                        <button
                            type="button"
                            onClick={() => {
                                if (!disableActions) {
                                    handleVideoArrayDelete(index)
                                }
                            }}
                            className="btn x-cross-btn d-flex justify-content-center align-items-center"
                        >
                            <img
                                src="/images/red-cross.svg"
                                alt="close"
                            />
                        </button>
                    </div>
                </li>
            )
        })
    }

    return (
        <>

            {showConfigurations ?
                <div className="container-fluid container-height">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                                {/* bread crumb starts here */}
                                <ul className="breadcrumb-item mb-4 ps-0">
                                    <li>
                                        <a className="pe-0" onClick={() => setShowConfigurations(false)}>
                                            Help &amp; Documentation
                                        </a>
                                    </li>
                                    <li className="pe-2">Configurations</li>
                                </ul>
                                {/* bread crumb ends here */}
                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                    <h5 className="primary-header font-20 font-bold mb-0 d-flex align-items-center">
                                        <a>
                                            <img
                                                src="/images/head-back-arrow.svg"
                                                alt="back-arrow"
                                                role="button"
                                                width={19}
                                                className="me-2"
                                                onClick={() => setShowConfigurations(false)}
                                            />
                                        </a>
                                        Configurations
                                    </h5>
                                </div>
                                {/*Tabs starts here*/}
                                <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                                    <li className="nav-item custom-bg rounded-start" role="presentation">
                                        <button
                                            className={showGuide ? "nav-link theme-tab active font-16" : "nav-link theme-tab font-16"}
                                            data-bs-toggle="pill"
                                            data-bs-target="#guides"
                                            type="button"
                                            role="tab"
                                            id="guides1"
                                            aria-selected="true"
                                            onClick={(e) => { handleTabSwitch(e) }}

                                        >
                                            Guides
                                        </button>
                                    </li>
                                    <li className="nav-item custom-bg rounded-end" role="presentation">
                                        <button
                                            className={!showGuide ? "nav-link theme-tab active font-16" : "nav-link theme-tab font-16"}
                                            data-bs-toggle="pill"
                                            data-bs-target="#videos"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            id="videos2"
                                            onClick={(e) => { handleTabSwitch(e) }}

                                        >
                                            Tutorial Video’s
                                        </button>
                                    </li>
                                </ul>
                                {/*Tabs ends here*/}
                                {/*Tab Inner content starts here*/}
                                <div className="tab-content" id="pills-tabContent">
                                    {/*Guides tab starts here*/}
                                    <div className={showGuide ? "tab-pane fade show active" : "tab-pane fade"}
                                        id="guides2"
                                        role="tabpanel"
                                    >
                                        <div className="row gx-5 mb-5">
                                            {/*guides*/}
                                            <div className="col-md-12">
                                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                                    <div>
                                                        <h5 className="font-20 font-bold mb-2">Guides</h5>
                                                        <p className="font-regular font-16 cust-role-color mb-1">
                                                            They are concise documents providing instructions,
                                                            information, or recommendations on specific topics, tasks,
                                                            or processes.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <ul
                                                    className="nav nav-pills mb-3 border p-1 border-radius-8 color-white-v1 d-inline-flex"
                                                    id="pills1-tab"
                                                    role="tablist"
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link active custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"
                                                            id="pills-slack2-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-slack2"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-slack2"
                                                            onClick={(e) => { handleAppTabSwitch(e) }}
                                                            aria-selected="true"
                                                        >
                                                            Slack
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"
                                                            id="pills-tenant2-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-tenant2"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-tenant2"
                                                            onClick={(e) => { handleAppTabSwitch(e) }}
                                                            aria-selected="false"
                                                        >
                                                            Tenant
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"
                                                            id="pills-gsuite2-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-gsuite2"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-gsuite2"
                                                            onClick={(e) => { handleAppTabSwitch(e) }}
                                                            aria-selected="false"
                                                        >
                                                            Google Workspace
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tab-content" id="pills1-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="pills-slack2"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-slack2-tab"
                                                >
                                                    {/*Upload*/}
                                                    {!disableActions && <div className="col-12 mb-3">
                                                        <div className=" border-radius-8 ava-wt-drag d-flex w-100 p-3" onDrop={(e) => { handleGuideUpload(e) }} onDragOver={(e) => e.preventDefault()}>
                                                            <div className="d-flex align-items-center my-auto justify-content-center flex-column">
                                                                <div className="input-group justify-content-center">
                                                                    <span className="btn position-relative primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center">
                                                                        <img src="/images/file-icon.svg" alt="file" />
                                                                        Choose Files
                                                                        <span>
                                                                            {" "}
                                                                            <input
                                                                                type="file"

                                                                                onChange={(e) => { handleGuideUpload(e) }}
                                                                                name="myfile"
                                                                                className="position-absolute"
                                                                            />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <p className="mt-2 mb-0 font-14 primary-color">
                                                                    {label == "" ? "Drag and upload files or can choose files" : label}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {/*Upload*/}
                                                    {/*documents*/}
                                                    <div className="row gx-5">
                                                        <div className="col-md-12 mb-6" >
                                                            <h5 className="font-20 font-bold mb-4 pb-md-1">
                                                                Documents
                                                            </h5>
                                                        </div>
                                                        {bindTable()}
                                                        {/*documents*/}
                                                    </div>
                                                    {/*documents*/}
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-tenant2"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-tenant2-tab"
                                                >
                                                    {!disableActions && <div className="col-12 mb-3">
                                                        <div className=" border-radius-8 ava-wt-drag d-flex w-100 p-3" onDrop={(e) => { handleGuideUpload(e) }} onDragOver={(e) => e.preventDefault()}>
                                                            <div className="d-flex align-items-center my-auto justify-content-center flex-column">
                                                                <div className="input-group justify-content-center">
                                                                    <span className="btn position-relative primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center">
                                                                        <img src="/images/file-icon.svg" alt="file" />
                                                                        Choose Files
                                                                        <span>
                                                                            {" "}
                                                                            <input
                                                                                type="file"
                                                                                onChange={(e) => { handleGuideUpload(e) }}
                                                                                name="myfile"
                                                                                className="position-absolute"
                                                                            />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <p className="mt-2 mb-0 font-14 primary-color">
                                                                    {label == "" ? "Drag and upload files or can choose files" : label}

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {/*Upload*/}
                                                    {/*documents*/}
                                                    <div className="col-md-12 mb-6" >
                                                    <div className="row gx-5">
                                                       
                                                            <h5 className="font-20 font-bold mb-4 pb-md-1">
                                                                Documents
                                                            </h5>
                                                        </div>
                                                        {bindTable()}
                                                        {/*documents*/}
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-gsuite2"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-gsuite2-tab"
                                                >
                                                    {!disableActions && <div className="col-12 mb-3">
                                                        <div className=" border-radius-8 ava-wt-drag d-flex w-100 p-3" onDrop={(e) => { handleGuideUpload(e) }} onDragOver={(e) => e.preventDefault()}>
                                                            <div className="d-flex align-items-center my-auto justify-content-center flex-column">
                                                                <div className="input-group justify-content-center">
                                                                    <span className="btn position-relative primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center">
                                                                        <img src="/images/file-icon.svg" alt="file" />
                                                                        Choose Files
                                                                        <span>
                                                                            {" "}
                                                                            <input
                                                                                type="file"
                                                                                name="myfile"
                                                                                onChange={(e) => { handleGuideUpload(e) }}
                                                                                className="position-absolute"
                                                                            />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <p className="mt-2 mb-0 font-14 primary-color">
                                                                    {label == "" ? "Drag and upload files or can choose files" : label}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {/*Upload*/}
                                                    {/*documents*/}
                                                    <div className="col-md-12 mb-6" >
                                                    <div className="row gx-5">
                                                            <h5 className="font-20 font-bold mb-4 pb-md-1">
                                                                Documents
                                                            </h5>
                                                        </div>
                                                        {bindTable()}
                                                        {/*documents*/}
                                                    </div>
                                                </div>
                                            </div>
                                            {/*guides*/}
                                        </div>
                                    </div>
                                    {/*Guides tab ends here*/}
                                    {/*Tutorial Video Starts here*/}
                                    <div className={!showGuide ? "tab-pane fade show active" : "tab-pane fade"}
                                        id="videos" role="tabpanel">
                                        <div className="row gx-5">
                                            <div className="col-md-12">
                                                <div className="mb-3 flex-wrap gap-3 ">
                                                    <div>
                                                        <h5 className="font-20 font-bold mb-2">Tutorial Video’s</h5>
                                                        <p className="font-regular font-16 cust-role-color ">
                                                            They provide practical demonstrations and instructions to
                                                            guide users through learning specific tasks or topics.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul
                                                            className="nav nav-pills mt-4 mb-3 border p-1 border-radius-8 color-white-v1 d-inline-flex"
                                                            id="pills2-tab"
                                                            role="tablist"
                                                        >
                                                            <li className="nav-item" role="presentation">
                                                                <button
                                                                    className="nav-link active custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"
                                                                    id="pills-slack-tab"
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target="#pills-slack"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="pills-slack"
                                                                    onClick={(e) => { handleAppTabSwitch(e) }}
                                                                    aria-selected="true"
                                                                >
                                                                    Slack
                                                                </button>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <button
                                                                    className="nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"
                                                                    id="pills-tenant-tab"
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target="#pills-tenant"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="pills-tenant"
                                                                    aria-selected="false"
                                                                    onClick={(e) => { handleAppTabSwitch(e) }}
                                                                >
                                                                    Tenant
                                                                </button>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <button
                                                                    className="nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"
                                                                    id="pills-gsuite-tab"
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target="#pills-gsuite"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="pills-gsuite"
                                                                    onClick={(e) => { handleAppTabSwitch(e) }}
                                                                    aria-selected="false"
                                                                >
                                                                    Google Workspace
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="tab-content" id="pills2-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="pills-slack"
                                                            role="tabpanel"
                                                            aria-labelledby="pills-slack-tab"
                                                        >
                                                            {/*Upload*/}
                                                            <div className="col-12 mb-3">
                                                                <div className=" border-radius-8 ava-wt-drag d-flex w-100 p-3">
                                                                    <div className="d-flex align-items-center my-auto justify-content-center flex-column">
                                                                        <div className="input-group justify-content-center">
                                                                            <span onClick={() => setForm("add")} className="btn position-relative primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" >
                                                                                <img src="/images/file-icon.svg" alt="file" />
                                                                                Upload URL
                                                                                <span>
                                                                                    {" "}
                                                                                    {/* <input
                                                                                        type="file"
                                                                                        name="myfile"
                                                                                        className="position-absolute"
                                                                                    /> */}
                                                                                </span>
                                                                            </span>

                                                                        </div>
                                                                        <p className="mt-2 mb-0 font-14 primary-color font-regular">
                                                                            Add a URL
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*Upload*/}
                                                            {/*documents*/}
                                                            <div className="row gx-3 gx-lg-5">
                                                                <div className="col-md-12">
                                                                    <h5 className="font-20 font-bold mb-4 pb-md-1">
                                                                        Video’s
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-md-4 mb-4"> */}
                                                                {bindTable()}
                                                                {videoData?.filter((element) => element.environmentType.toLowerCase() == "slack")?.length == 0 ? <div className="pt-4 pb-3 text-center">
                                                                    <img
                                                                        src="/images/no-data-found-img.svg"
                                                                        alt="no-data-found-img"
                                                                        className="mb-5"
                                                                    />
                                                                    <h5 className="font-12 font-bold color-no-rec">{totalCount.videoCount == 0 ? 'No Record' : 'No Data'}</h5>
                                                                    <p className="font-10 font-regular color-no-rec">
                                                                        {totalCount.videoCount == 0 ? 'There is no Record to show you right now' : 'There is no data to show you right now'}
                                                                    </p>
                                                                </div> : <></>}
                                                                {/*documents*/}
                                                            </div>
                                                            {/*documents*/}
                                                        </div>
                                                        <div
                                                            className="tab-pane fade"
                                                            id="pills-tenant"
                                                            role="tabpanel"
                                                            aria-labelledby="pills-tenant-tab"
                                                        >
                                                            <div className="col-12 mb-3">
                                                                <div className=" border-radius-8 ava-wt-drag d-flex w-100 p-3">
                                                                    <div className="d-flex align-items-center my-auto justify-content-center flex-column">
                                                                        <div className="input-group justify-content-center">
                                                                            <span onClick={() => setForm("add")} className="btn position-relative primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" >
                                                                                <img src="/images/file-icon.svg" alt="file" />
                                                                                Upload URL
                                                                                <span>
                                                                                    {" "}
                                                                                    {/* <input
                                                                                        type="file"
                                                                                        name="myfile"
                                                                                        className="position-absolute"
                                                                                    /> */}
                                                                                </span>
                                                                            </span>

                                                                        </div>
                                                                        <p className="mt-2 mb-0 font-14 primary-color font-regular">
                                                                            Add a URL
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*Upload*/}
                                                            {/*documents*/}
                                                            <div className="row gx-5">
                                                                <div className="col-md-12">
                                                                    <h5 className="font-20 font-bold mb-4 pb-md-1">
                                                                        Tutorial Videos
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-md-4 mb-4"> */}
                                                                {bindTable()}
                                                                {videoData?.filter((element) => element.environmentType.toLowerCase() == "tenant")?.length == 0 ? <div className="pt-4 pb-3 text-center">
                                                                    <img
                                                                        src="/images/no-data-found-img.svg"
                                                                        alt="no-data-found-img"
                                                                        className="mb-5"
                                                                    />
                                                                    <h5 className="font-12 font-bold color-no-rec">{totalCount.videoCount == 0 ? 'No Record' : 'No Data'}</h5>
                                                                    <p className="font-10 font-regular color-no-rec">
                                                                        {totalCount.videoCount == 0 ? 'There is no Record to show you right now' : 'There is no data to show you right now'}
                                                                    </p>
                                                                </div> : <></>}
                                                                {/*documents*/}
                                                            </div>
                                                            {/*documents*/}
                                                        </div>
                                                        <div
                                                            className="tab-pane fade"
                                                            id="pills-gsuite"
                                                            role="tabpanel"
                                                            aria-labelledby="pills-gsuite-tab"
                                                        >
                                                            <div className="col-12 mb-3">
                                                                <div className=" border-radius-8 ava-wt-drag d-flex w-100 p-3">
                                                                    <div className="d-flex align-items-center my-auto justify-content-center flex-column">
                                                                        <div className="input-group justify-content-center">
                                                                            <span onClick={() => setForm("add")} className="btn position-relative primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" >
                                                                                <img src="/images/file-icon.svg" alt="file" />
                                                                                Upload URL
                                                                                <span>
                                                                                    {" "}
                                                                                    {/* <input
                                                                                        type="file"
                                                                                        name="myfile"
                                                                                        className="position-absolute"
                                                                                    /> */}
                                                                                </span>
                                                                            </span>

                                                                        </div>
                                                                        <p className="mt-2 mb-0 font-14 primary-color font-regular">
                                                                            Add a URL
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*Upload*/}
                                                            {/*documents*/}
                                                            <div className="row gx-5">
                                                                <div className="col-md-12">
                                                                    <h5 className="font-20 font-bold mb-4 pb-md-1">
                                                                        Tutorial Videos
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-md-4 mb-4"> */}
                                                                {bindTable()}
                                                                {videoData?.filter((element) => element.environmentType.toLowerCase() == "google")?.length == 0 ? <div className="pt-4 pb-3 text-center">
                                                                    <img
                                                                        src="/images/no-data-found-img.svg"
                                                                        alt="no-data-found-img"
                                                                        className="mb-5"
                                                                    />
                                                                    <h5 className="font-12 font-bold color-no-rec">{totalCount.videoCount == 0 ? 'No Record' : 'No Data'}</h5>
                                                                    <p className="font-10 font-regular color-no-rec">
                                                                        {totalCount.videoCount == 0 ? 'There is no Record to show you right now' : 'There is no data to show you right now'}
                                                                    </p>
                                                                </div> : <></>}
                                                                {/*documents*/}
                                                            </div>
                                                            {/*documents*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Tab Inner content ends here*/}
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="container-fluid container-height">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                    <h5 className="font-20 font-semibold mb-0">
                                        Help &amp; Documentation
                                    </h5>
                                    {adminrole.toLowerCase().includes('admin') && <div className="d-flex gap-3 w-md-100 flex-wrap">
                                        <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" onClick={() => { setShowConfigurations(true); setShowApp("slack") }}>
                                            <img src="/images/configuration-icon.svg" alt="config-icon" />
                                            Configurations
                                        </button>
                                    </div>}
                                    {/* {adminrole && <div className="d-flex gap-3 w-md-100 flex-wrap">
                                        <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" onClick={() => { setShowConfigurations(true); setShowApp("slack") }}>
                                            <img src="images/configuration-icon.svg" alt="config-icon" />
                                            Configurations
                                        </button>
                                    </div>} */}
                                </div>
                                {/*Tabs starts here*/}
                                <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                                    <li className="nav-item custom-bg rounded-start" role="presentation">
                                        <button
                                            className={showGuide ? "nav-link theme-tab active font-16" : "nav-link theme-tab font-16"}
                                            data-bs-toggle="pill"
                                            data-bs-target="#guides"
                                            type="button"
                                            role="tab"
                                            id="guides2"
                                            aria-selected="true"
                                            onClick={(e) => { handleTabSwitch(e) }}

                                        >
                                            Guides
                                        </button>
                                    </li>
                                    <li className="nav-item custom-bg rounded-end" role="presentation">
                                        <button
                                            // className="nav-link theme-tab font-16"
                                            className={!showGuide ? "nav-link theme-tab active font-16" : "nav-link theme-tab font-16"}
                                            data-bs-toggle="pill"
                                            data-bs-target="#videos"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            id="videos1"
                                            onClick={(e) => { handleTabSwitch(e) }}
                                        >
                                            Tutorial Video’s
                                        </button>
                                    </li>
                                </ul>
                                {/*Tabs ends here*/}
                                {/*Tab Inner content starts here*/}
                                <div className="tab-content" id="pills-tabContent">
                                    {/*Guides tab starts here*/}
                                    <div
                                        className={showGuide ? "tab-pane fade show active" : "tab-pane fade"}
                                        id="guides"
                                        role="tabpanel"
                                    >
                                        <div className="row gx-5">
                                            <div className="col-md-12">
                                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                                    <div>
                                                        <h5 className="font-20 font-bold mb-2">Guides</h5>
                                                        <p className="font-regular font-16 cust-role-color">
                                                            They are concise documents providing instructions,
                                                            information, or recommendations on specific topics, tasks,
                                                            or processes.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between flex-column flex-md-row gap-2">
                                                <ul
                                                    className="nav nav-pills border p-1 border-radius-8 color-white-v1 d-inline-flex width-fit-content"
                                                    id="pills1-tab"
                                                    role="tablist"
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={showApp == "slack" ? "nav-link active custom-service-tabs font-regular font-14 tab-grey-color border-radius-8" : "nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"}
                                                            id="pills-slack1-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-slack1"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-slack1"
                                                            onClick={(e) => { handleAppTabSwitch(e) }}
                                                            aria-selected="true"
                                                        >
                                                            Slack
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={showApp == "tenant" ? "nav-link active custom-service-tabs font-regular font-14 tab-grey-color border-radius-8" : "nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"}
                                                            // className="nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"
                                                            id="pills-tenant1-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-tenant1"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-tenant1"
                                                            aria-selected="false"
                                                            onClick={(e) => { handleAppTabSwitch(e) }}
                                                        >
                                                            Tenant
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={showApp == "google" ? "nav-link active custom-service-tabs font-regular font-14 tab-grey-color border-radius-8" : "nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"}
                                                            // className="nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"
                                                            id="pills-gsuite1-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-gsuite1"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-gsuite1"
                                                            onClick={(e) => { handleAppTabSwitch(e) }}
                                                            aria-selected="false"
                                                        >
                                                            Google Workspace
                                                        </button>
                                                    </li>
                                                </ul>
                                                <div className="d-flex gap-3 align-items-center">
                                                    <div className="input-group box-shadow-filter search-container float-start">
                                                        <input
                                                            type="search"
                                                            id="guide23"
                                                            // value={search.guideSearch}
                                                            className="form-control custom-form font-14 font-regular"
                                                            placeholder="Search"
                                                            ref={searchInputRef}
                                                            onChange={(e) => { handleSearchInput(e) }}
                                                            onKeyDown={(e) => { e.key === "Enter" && getGuideAndVideo() }}
                                                        />
                                                        <button
                                                            className="btn border d-flex align-items-center"
                                                            type="button"
                                                            onClick={() => { getGuideAndVideo() }}
                                                        >
                                                            <img src="/images/search.svg" alt="search" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-content" id="pills1-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="pills-slack1"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-slack1-tab"
                                                >
                                                    <div className="row">
                                                        {/*cards starts here*/}
                                                        {bindTable()}
                                                        {guideData?.filter((element) => element.environmentType.toLowerCase() == "slack").length == 0 ? <div className="pt-4 pb-3 text-center">
                                                            <img
                                                                src="/images/no-data-found-img.svg"
                                                                alt="no-data-found-img"
                                                                className="mb-5"
                                                            />
                                                            <h5 className="font-12 font-bold color-no-rec">{totalCount.videoCount != 0 ? 'No Record' : 'No Data'}</h5>
                                                            <p className="font-10 font-regular color-no-rec">
                                                                {totalCount.videoCount != 0 ? 'There is no Record to show you right now' : 'There is no data to show you right now'}
                                                            </p>
                                                        </div> : <></>}

                                                    </div>
                                                    {/*cards ends here*/}
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-tenant1"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-tenant1-tab"
                                                >
                                                    <div className="row">
                                                        {/*cards starts here*/}
                                                        {bindTable()}
                                                        {guideData?.filter((element) => element.environmentType.toLowerCase() == "tenant").length == 0 ? <div className="pt-4 pb-3 text-center">
                                                            <img
                                                                src="/images/no-data-found-img.svg"
                                                                alt="no-data-found-img"
                                                                className="mb-5"
                                                            />
                                                            <h5 className="font-12 font-bold color-no-rec">{totalCount.videoCount != 0 ? 'No Record' : 'No Data'}</h5>
                                                            <p className="font-10 font-regular color-no-rec">
                                                                {totalCount.videoCount != 0 ? 'There is no Record to show you right now' : 'There is no data to show you right now'}
                                                            </p>
                                                        </div> : <></>}
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-gsuite1"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-gsuite1-tab"
                                                >
                                                    <div className="row">
                                                        {/*cards starts here*/}
                                                        {bindTable()}
                                                        {guideData?.filter((element) => element.environmentType.toLowerCase() == "google").length == 0 ? <div className="pt-4 pb-3 text-center">
                                                            <img
                                                                src="/images/no-data-found-img.svg"
                                                                alt="no-data-found-img"
                                                                className="mb-5"
                                                            />
                                                            <h5 className="font-12 font-bold color-no-rec">{totalCount.videoCount != 0 ? 'No Record' : 'No Data'}</h5>
                                                            <p className="font-10 font-regular color-no-rec">
                                                                {totalCount.videoCount != 0 ? 'There is no Record to show you right now' : 'There is no data to show you right now'}
                                                            </p>
                                                        </div> : <></>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Guides tab ends here*/}
                                    {/*Tutorial Video Starts here*/}
                                    <div className={!showGuide ? "tab-pane fade show active" : "tab-pane fade"} id="videos2" role="tabpanel">
                                        <div className="row gx-5">
                                            <div className="col-md-12">
                                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                                    <div>
                                                        <h5 className="font-20 font-bold mb-2">Tutorial Video’s</h5>
                                                        <p className="font-regular font-16 cust-role-color ">
                                                            They provide practical demonstrations and instructions to
                                                            guide users through learning specific tasks or topics.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between flex-column flex-md-row gap-2">
                                                <ul
                                                    className="nav nav-pills border p-1 border-radius-8 color-white-v1 d-inline-flex width-fit-content"
                                                    id="pills2-tab"
                                                    role="tablist"
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={showApp == "slack" ? "nav-link active custom-service-tabs font-regular font-14 tab-grey-color border-radius-8" : "nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"}
                                                            // className="nav-link active custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"
                                                            id="pills-slack2-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-slack2"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-slack2"
                                                            onClick={(e) => { handleAppTabSwitch(e) }}
                                                            aria-selected="true"
                                                        >
                                                            Slack
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={showApp == "tenant" ? "nav-link active custom-service-tabs font-regular font-14 tab-grey-color border-radius-8" : "nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"}
                                                            // className="nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"
                                                            id="pills-tenant2-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-tenant2"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-tenant2"
                                                            onClick={(e) => { handleAppTabSwitch(e) }}
                                                            aria-selected="false"
                                                        >
                                                            Tenant
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={showApp == "google" ? "nav-link active custom-service-tabs font-regular font-14 tab-grey-color border-radius-8" : "nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"}
                                                            // className="nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8"
                                                            id="pills-gsuite2-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-gsuite2"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-gsuite2"
                                                            onClick={(e) => { handleAppTabSwitch(e) }}
                                                            aria-selected="false"
                                                        >
                                                            Google Workspace
                                                        </button>
                                                    </li>
                                                </ul>
                                                <div className="d-flex gap-3 align-items-center">
                                                    <div className="input-group box-shadow-filter search-container float-start" >
                                                        <input
                                                            type="search"
                                                            ref={searchInputRef1}
                                                            className="form-control custom-form border-end-0 font-14 font-regular"
                                                            // value={search.videoSearch}
                                                            onChange={(e) => { handleSearchInput(e) }}
                                                            placeholder="Search"
                                                            id="video23"
                                                            onKeyDown={(e) => { e.key === "Enter" && getGuideAndVideo() }}
                                                        />
                                                        <button
                                                            className="btn border d-flex align-items-center"
                                                            onClick={() => { setSearch(searchBuffer); getGuideAndVideo() }}
                                                            type="button"
                                                        >
                                                            <img src="/images/search.svg" alt="search" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-content" id="pills2-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="pills-slack2"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-slack2-tab"
                                                >
                                                    {/*cards starts here*/}
                                                    <div className="row">
                                                        {bindTable()}
                                                        {videoData?.filter((element) => element.environmentType.toLowerCase() == "slack").length == 0 ? <div className="pt-4 pb-3 text-center">
                                                            <img
                                                                src="/images/no-data-found-img.svg"
                                                                alt="no-data-found-img"
                                                                className="mb-5"
                                                            />
                                                            <h5 className="font-12 font-bold color-no-rec">{totalCount.videoCount == 0 ? 'No Record' : 'No Data'}</h5>
                                                            <p className="font-10 font-regular color-no-rec">
                                                                {totalCount.videoCount == 0 ? 'There is no Record to show you right now' : 'There is no data to show you right now'}
                                                            </p>
                                                        </div> : <></>}
                                                    </div>
                                                    {/*cards ends here*/}
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-tenant2"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-tenant2-tab"
                                                >
                                                    <div className="row">
                                                        {bindTable()}
                                                        {videoData?.filter((element) => element.environmentType.toLowerCase() == "tenant").length == 0 ? <div className="pt-4 pb-3 text-center">
                                                            <img
                                                                src="/images/no-data-found-img.svg"
                                                                alt="no-data-found-img"
                                                                className="mb-5"
                                                            />
                                                            <h5 className="font-12 font-bold color-no-rec">{totalCount.videoCount == 0 ? 'No Record' : 'No Data'}</h5>
                                                            <p className="font-10 font-regular color-no-rec">
                                                                {totalCount.videoCount == 0 ? 'There is no Record to show you right now' : 'There is no data to show you right now'}
                                                            </p>
                                                        </div> : <></>}
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-gsuite2"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-gsuite2-tab"
                                                >
                                                    <div className="row">
                                                        {bindTable()}
                                                        {videoData?.filter((element) => element.environmentType.toLowerCase() == "google").length == 0 ? <div className="pt-4 pb-3 text-center">
                                                            <img
                                                                src="/images/no-data-found-img.svg"
                                                                alt="no-data-found-img"
                                                                className="mb-5"
                                                            />
                                                            <h5 className="font-12 font-bold color-no-rec">{totalCount.videoCount == 0 ? 'No Record' : 'No Data'}</h5>
                                                            <p className="font-10 font-regular color-no-rec">
                                                                {totalCount.videoCount == 0 ? 'There is no Record to show you right now' : 'There is no data to show you right now'}
                                                            </p>
                                                        </div> : <></>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Tab Inner content ends here*/}
                            </div>
                        </div>
                    </div>
                </div>}


            {loader && <div className="container-fluid">
                <div className="overlay">
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div
                            className="spinner-border Loader loader-color align-center "
                            role="status"
                        >
                            <span>
                                <span className="loader-inner-circle" />
                            </span>
                        </div>
                        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                            Loading..
                        </p>
                    </div>
                </div>
            </div>}
            {form == "add" && <div
                className="modal fade show"
                id="addFiles"
                role="tabpanel"
                aria-labelledby="addFiles-tab"
                tabIndex={0}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content p-4">
                        <div className="modal-header border-0 p-0">
                            <h1
                                className="font-20 font-bold custom-header-color m-0"
                                id="addFilesLabel"
                            >
                                Add Files by URL
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { setForm(""); setVideoLabel({ youtube: "", description: "", category: "" }); setVideoDataArray([]); setSingleVideoData({ categoryId: '', tutorialVideoUrl: '', description: '' } as VideoData) }}
                            />
                        </div>
                        <div className="modal-body border-0 p-0 pt-2 pb-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <label
                                        className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                        htmlFor="url"
                                    >
                                        URL
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form font-14 font-regular"
                                        id="youtubeUrl"
                                        name="Addurl"
                                        disabled={disableActions}
                                        placeholder="Enter URL"
                                        maxLength={256}
                                        value={singleVideoData.tutorialVideoUrl}
                                        onChange={(e) => { if (!disableActions) handleFieldInput(e) }}
                                    // value={saveUrl.Addurl}
                                    />
                                    {/* {submitErrorMessage && (
                                        <p className="mt-2 mb-0 font-14 text-danger" >
                                            {submitErrorMessage}
                                        </p>
                                    )} */}
                                    {videoLabel.youtube != "" && <p className="mt-2 mb-0 font-14 text-danger">{videoLabel.youtube}</p>}
                                    {/* {isYoutubeUrlRegex && (<p className="mt-2 mb-0 font-14 text-danger">Please Enter URL</p>)} */}
                                </div>
                                <div className="col-md-6">
                                    <label
                                        className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                        htmlFor="MailTo"
                                    >
                                        Category
                                    </label>
                                    <select
                                        className="form-select font-14 custom-form"
                                        aria-label="Default select example"
                                        name="category"
                                        disabled={disableActions}
                                        onChange={(e) => { if (!disableActions) handleFieldInput(e) }}
                                        id="category"
                                        value={singleVideoData.categoryId}
                                    // defaultValue={category}
                                    // value={saveUrl.category || ""}


                                    >
                                        <option value="" disabled>Select</option>

                                        {category?.filter((element) => element.environmentType.toLowerCase() == showApp).map((val: any) => {
                                            return (
                                                <option value={val.categoryId}>
                                                    {val?.category}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    {videoLabel.category != "" && <p className="mt-2 mb-0 font-14 text-danger">{videoLabel.category}</p>}

                                </div>
                                <div className="col-md-12">
                                    <label
                                        className="font-14 font-semibold label-color w-100 my-2 pt-2"
                                        htmlFor="desc"
                                    >
                                        Description
                                    </label>
                                    <textarea
                                        id="description"
                                        cols={5}
                                        disabled={disableActions}
                                        className="w-100 form-control custom-textarea custom-form resize-none txtarea-height"
                                        placeholder="Enter Description"
                                        value={singleVideoData.description}
                                        onChange={(e) => { if (!disableActions) handleFieldInput(e) }}

                                        name="Description"

                                        defaultValue={""}
                                    />
                                    {videoLabel.description != "" && <p className="mt-2 mb-0 font-14 text-danger">{videoLabel.description}</p>}

                                </div>
                                <div className="col-md-12 d-flex justify-content-end mt-4">
                                    <button
                                        type="button"
                                        className="btn primary-btn"
                                        onClick={() => { handleAdd() }}
                                        disabled={disableActions || (singleVideoData.description == '' || singleVideoData.categoryId == '' ? true : (videoLabel.category != '' || videoLabel.description != '' || videoLabel.youtube != '') ? true : false)}
                                    >
                                        Add
                                    </button>
                                </div>
                                <div className="col-md-12">
                                    <p className="font-14 font-semibold label-color">
                                        URL
                                    </p>
                                    <ul className="list-group custom-list-group">
                                        {videoDataArray?.map((item, index) => (
                                            <li key={index} className="list-group-item custom-list-group-item mt-2">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <label className="custom-list-label">
                                                        {item.tutorialVideoUrl}
                                                    </label>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleVideoArrayDelete(index)}
                                                        className="btn x-cross-btn d-flex justify-content-center align-items-center"
                                                    >
                                                        <img
                                                            src="/images/red-cross.svg"
                                                            alt="close"
                                                        />
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer border-0 p-0">
                            <button
                                type="button"
                                className="btn secondary-btn"
                                data-bs-dismiss="modal"
                                onClick={() => { setForm(""); setVideoLabel({ youtube: "", description: "", category: "" }); setVideoDataArray([]); setSingleVideoData({ categoryId: '', tutorialVideoUrl: '', description: '' } as VideoData) }}

                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn primary-btn"
                                disabled={disableActions || videoDataArray.length == 0}
                                onClick={() => { form == "add" ? handleVideoAdd() : handleVideoAdd() }}
                            >
                                {form == "add" ? "Submit" : "Update"}
                            </button>

                        </div>
                    </div>
                </div>
            </div>}

            {form == "edit" && <div
                className="modal fade show"
                id="addFiles"
                role="tabpanel"
                aria-labelledby="addFiles-tab"
                tabIndex={0}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content p-4">
                        <div className="modal-header border-0 p-0">
                            <h1
                                className="font-20 font-bold custom-header-color m-0"
                                id="addFilesLabel"
                            >
                                Add Files by URL
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { setForm(""); setVideoLabel({ youtube: "", description: "", category: "" }) }}
                            />
                        </div>
                        <div className="modal-body border-0 p-0 pt-2 pb-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <label
                                        className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                        htmlFor="url"
                                    >
                                        URL
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form font-14 font-regular"
                                        id="youtubeUrl"
                                        name="Addurl"
                                        placeholder="Enter URL"
                                        disabled={disableActions}
                                        defaultValue={editVideoData.tutorialVideoUrl}
                                        maxLength={256}
                                        onChange={(e) => { if (!disableActions) handleFieldInput(e) }}
                                    // value={saveUrl.Addurl}
                                    />
                                    {/* {submitErrorMessage && (
                                        <p className="mt-2 mb-0 font-14 text-danger" >
                                            {submitErrorMessage}
                                        </p>
                                    )} */}
                                    {videoLabel.youtube != "" && <p className="mt-2 mb-0 font-14 text-danger">{videoLabel.youtube}</p>}
                                    {/* {isYoutubeUrlRegex && (<p className="mt-2 mb-0 font-14 text-danger">Please Enter URL</p>)} */}
                                </div>
                                <div className="col-md-6">
                                    <label
                                        className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                        htmlFor="MailTo"
                                    >
                                        Category
                                    </label>
                                    <select
                                        className="form-select font-14 custom-form"
                                        aria-label="Default select example"
                                        name="category"
                                        disabled={disableActions}
                                        onChange={(e) => { if (!disableActions) handleFieldInput(e); }}
                                        id="category"
                                        defaultValue={editVideoData.categoryId}

                                    >
                                        <option value="" >Select</option>

                                        {category?.filter((element: any) => element.environmentType.toLowerCase() == showApp).map((val: any) => {
                                            return (
                                                <option value={val.categoryId}>
                                                    {val?.category}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    {videoLabel.category != "" && <p className="mt-2 mb-0 font-14 text-danger">{videoLabel.category}</p>}

                                </div>
                                <div className="col-md-12">
                                    <label
                                        className="font-14 font-semibold label-color w-100 my-2 pt-2"
                                        htmlFor="desc"
                                    >
                                        Description
                                    </label>
                                    <textarea
                                        id="description"
                                        cols={5}
                                        className="w-100 form-control custom-textarea custom-form resize-none txtarea-height"
                                        placeholder="Enter Description"
                                        disabled={disableActions}
                                        defaultValue={editVideoData.description}
                                        // value={editVideoData.description}
                                        onChange={(e) => { if (!disableActions) handleFieldInput(e) }}

                                        name="Description"

                                        // defaultValue={""}
                                        maxLength={256} />
                                    {videoLabel.description != "" && <p className="mt-2 mb-0 font-14 text-danger">{videoLabel.description}</p>}

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 p-0">
                            <button
                                type="button"
                                className="btn secondary-btn"
                                data-bs-dismiss="modal"
                                onClick={() => { setForm(""); setVideoDataArray([]) }}

                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn primary-btn"
                                onClick={() => { handleVideoEdit(editVideoData.tutorialVideoId as number); }}
                                disabled={
                                    (editVideoData?.description === singleVideoData?.description &&
                                        editVideoData?.tutorialVideoUrl === singleVideoData?.tutorialVideoUrl
                                        &&
                                        editVideoData?.categoryId === singleVideoData?.categoryId)
                                    ||
                                    (videoLabel.youtube !== ''
                                        ||
                                        videoLabel.category !== ''
                                        ||
                                        videoLabel.description !== ''
                                    )
                                }
                            >
                                Update
                            </button>

                        </div>
                    </div>
                </div>
            </div>}
            {(popup.errorMessage || popup.successMessage) && <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src={`/images/${popup.successMessage == '' ? "failure" : "success"}-popup-icon.svg`} alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                { }
                            </h5>
                            <p className="primary-data">
                                {popup.errorMessage || popup.successMessage}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => { setPopup({ successMessage: '', errorMessage: '' }) }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
            {alert ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                { }
                            </h5>
                            <p className="primary-data">
                                {alert}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => {
                                    if (isSessionEpired) {
                                        history.push('/Login')
                                    }
                                    setAlert('')
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
            {/*Failure Popup ends here */}

        </>
    );
}