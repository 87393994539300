// CC_PS_01 - CC_PS_04
import Tippy from "@tippyjs/react";
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode';
import Papa from 'papaparse';
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import * as XLSX from 'xlsx';
import { commonErrorMsgs } from "../../Common/Constant";
import { SortState } from "../../Common/Interface";
import { decodeSessionDetails } from "../../Services/CommonServices";
import { cancelMappingService, createChannel, createSites, createTeams, deleteMappingService, getSiteDetails, getSitesApi, getTeamChannelsDetails, getTeamsandChannelsApi, saveSiteMapping, saveTeamChannelMapping, SubmitSiteMapping, SubmitTeamChannelMapping } from "../../Services/CreationandMapping";
import { getSession } from "../../Services/manageClientAPI";

export default function CustomCreationandMapping() {

  // CC_PS_05 - CC_PS_13
  const [pageType, setPageType] = useState<string>("");
  const [getBtnType, setGetBtnType] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alert, setAlert] = useState<boolean>(false);
  const [actualAlert, setActualAlert] = useState<boolean>(false);
  const [actualAlertMessage, setActualAlertMessage] = useState<any>({ title: '', content: '' });

  const [popUp, setPopUp] = useState<string>("");
  const [migrationType, setMigrationType] = useState<string>("standard");
  const [fileName, setFileName] = useState<string>("");
  const [successPopUp, setSuccessPopup] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [siteCreationPopup, setSiteCreationPopup] = useState<boolean>(false);
  const [teamChannelCreationPopup, setTeamChannelCreationPopup] = useState<boolean>(true);
  const [downloadBtnType, setDownloadBtnType] = useState<string>("");
  const [successPopupMessage, setSuccessPopupMessage] = useState<string>("")
  const [gridData, setGridData] = useState<any>([]);


  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  const history = useHistory();
  const mappingFileInput: any = useRef(null);
  const siteCreationRef: any = useRef(null);
  const teamCreationRef: any = useRef(null);
  const ChannelCreationRef: any = useRef(null);
  const location: any = useLocation()?.state
  const [ctoken, setCToken] = useState<null | string>(null);
  const [clientName, setClientName] = useState("");
  const [mailId, setMailId] = useState("");
  const [role, setRole] = useState("");
  const [tokenExpiration, setTokenExpiration] = useState(new Date());
  const [action, setAction] = useState("");

  const [atoken, setAToken] = useState<null | string>(null);
  const [adminName, setAdminName] = useState("");
  const [adminMailId, setAdminMailId] = useState("");
  const [adminRole, setAdminRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [successPopupType, setSuccessPopupType] = useState('')
  const [createBtnType, setCreateBtnType] = useState<string>('');
  const [teamCreationbrowseFileName, setteamCreationBrowseFileName] = useState<string>("Choose a File")
  const [channelCreationFileName, setChannelCreationFileName] = useState<string>("Choose a File")
  const [teamChannelMapFileName, setTeamChannelMapFileName] = useState<string>("Choose a File")
  const [siteMapFileName, setSiteMapFileName] = useState<string>("Choose a File")
  const [siteCreationName, setSiteCreationName] = useState<string>("Choose a File")

  const [templateType, setTemplateType] = useState<string>('');
  const [popup, setPopup] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [taskID, setTaskID] = useState<number>(0) || location?.state?.taskId;
  const [taskName, setTaskName] = useState<string>("") || location?.state?.taskName;
  const [teamCreationData, setTeamCreationData] = useState<any[]>([]);
  const [channelCreationData, setChannelCreationData] = useState<any[]>([]);
  const [siteCreationData, setSiteCreationData] = useState<any[]>([]);
  const [teamChannelMappingData, setTeamChannelMappingData] = useState<any[]>([]);
  const [siteMappingData, setSiteMappingData] = useState<any[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [popupMessage, setPopupMessage] = useState<string | null>(null);

  const [mappings, setMappings] = useState<any>([]);
  const [sortState, setSortState] = useState<SortState>({
    column: '',
    direction: 'asc',
  });
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [originalData, setOriginalData] = useState<any>([]);
  const [cancelPopUp, setCancelPopUp] = useState<boolean>(false);

  let [navigateTo, setNavigateTo] = useState<string>('');
  const [openSiteCreation, setOpenSiteCreation] = useState<boolean>(false);
  const [openTeamCreation, setOpenTeamCreation] = useState<boolean>(false);
  const [searchOnFocus, setSearchOnFocus] = useState(false);

  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);
  let [adminEemailAddress, setAdminEmailAddress] = useState('');
  let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');

  const [disableActions, setDisableActions] = useState(false);

  useEffect(() => {
    sessionTokenProperties()
    resetTimer();
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    setTaskID(location?.state?.taskId)
    // Simulate an API call or some initialization process
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoader(false);
    }, 2000);

    setTaskName(`${location?.state?.taskName}`)



    // Handling the State to Render the Page based on the Type
    if (location?.state?.applicationName?.toLowerCase() == 'teamschannel') {
      setPageType('TeamChannel')
      if (location?.state?.MigrationMode?.toLowerCase() == 'migration') {
        setMigrationType('migration')
      }
      else {
        setMigrationType('standard')
      }
    }
    else {
      setPageType('Site')
    }

    return () => {
      if (InActiveTimeout.current) {
        console.log("Creation & Mapping Unmount Time Clear");
        clearTimeout(InActiveTimeout.current);
      }
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };

  }, [])

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      //
      //console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        //console.log("Reset Session Timer Inside");
        if (InActiveTimeout.current) {
          clearTimeout(InActiveTimeout.current);
        }
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout.current = setTimeout(() => {
          // console.log("Expired");
          sessionStorage.removeItem("c_at");
          //console.log("removed session");
          setisSessionEpired(true)
          setAlert(true)
          setAlertMessage('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        //console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        //const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(currentDateTime).getTime() - new Date(SessionEpiredDateTime).getTime());
        //console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        //console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          //console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        //console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      //console.log("Reset Session Timer Catch");
      throw ex
    }
  };



  // PS_14, PS_15, PS_16, PS_17, PS_18, PS_19, PS_20, PS_21, PS_22, PS_23, PS_24, PS_25, PS_26, PS_27, PS_28, PS_29, PS_30, PS_31
  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();

      //#region only for required Modules
      if (tokenResponse.adminDetails) {
        setObjAdmin(tokenResponse.adminDetails)
        setAdminName(tokenResponse.adminDetails.Name);
        setAdminEmailAddress(tokenResponse.adminDetails.Email);
        setRole(tokenResponse.adminDetails.Role);

        if (tokenResponse?.adminDetails?.Role.toLowerCase().includes('viewer')) {
          setDisableActions(true)
        }
      }

      if (tokenResponse.clientDetails) {
        setObjClient(tokenResponse.clientDetails)
        setCompanyName(tokenResponse.clientDetails.CompanyName)
        emailAddress = tokenResponse.clientDetails.Email;
        setEmailAddress(emailAddress);
        setRole(tokenResponse.clientDetails.Role);

        if (tokenResponse?.clientDetails?.Role.toLowerCase().includes('viewer')) {
          setDisableActions(true)
        }

        SessionEpiredDateTime = tokenResponse.clientDetails.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      else {
        history.push("/Login")
      }

      //#endregion
    } catch (error) {
      setAlert(true);

      // console.error('Failed in main method', error);
    }
  };

  let resetTimer = async () => {
    try {
      // ;
      //console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      //console.log("sessionResponse value :", sessionResponse);

      // //
      if (sessionResponse == 'expired') {

        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert(true)
        setAlertMessage('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }

        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        // const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        // const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        // let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', getsessionToken?.data?.data.sessionToken);
        await sessionTokenProperties();
        // let updatedobjClient: any = jwt(c_decryptedValue);
        // SessionEpiredDateTime = updatedobjClient.CreatedAt;
        // setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      //console.log("Reset Timer End");
    }
    catch (error) {
      //console.log("Reset Timer Catch");
      setAlert(true)
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoader(false);
    }
  }



  // CC_PS_123 - CC_PS_167
  const getTeamsandChannels = async (btnType: string, fileName: string) => {

    try {
      debugger
      let token: any = await getToken();
      let apiResponse: any;
      setLoader(true)
      if (btnType.toLowerCase() === "getsourceteamsandchannels" || btnType.toLowerCase() === "gettargetteamsandchannels") {
        apiResponse = await getTeamsandChannelsApi(btnType, token, taskID, migrationType);
      } else {
        apiResponse = await getSitesApi(btnType, token, taskID);
      }

      if (apiResponse.data.statusMessage === 'Success' && apiResponse.data.statusCode === 200) {
        setSuccessPopup(true)
        const successMessage: string = btnType.toLowerCase().includes('source') ? 'Source details downloaded successfully' : btnType.toLowerCase().includes('target') ? 'Target details downloaded successfully' : 'Success';
        setSuccessPopupMessage(successMessage);
        // Assuming `exportCsvDetail` is a function available in the scope
        exportCsvDetail(fileName, apiResponse.data.data, btnType);
      } else {
        setAlert(true);
        setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      }
    } catch (error) {
      setAlert(true);
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    }
    finally {
      setLoader(false)
    }
  };

  const handleSort = (column: string) => {
    setSortState((prevState) => {
      const newDirection = prevState.column === column && prevState.direction === 'asc' ? 'desc' : 'asc';

      const sortedMappings = [...gridData].sort((a, b) => {
        let compareA, compareB;

        switch (column) {
          case 'sourceTeam':
            compareA = pageType === 'TeamChannel' ? a.SourceTeamName?.toLowerCase() : a.SourceSPSiteName?.toLowerCase();
            compareB = pageType === 'TeamChannel' ? b.SourceTeamName?.toLowerCase() : b.SourceSPSiteName?.toLowerCase();
            break;
          case 'sourceChannel':
            compareA = pageType === 'TeamChannel' ? a.SourceChannelName?.toLowerCase() : a.SourceSPSiteURL?.toLowerCase();
            compareB = pageType === 'TeamChannel' ? b.SourceChannelName?.toLowerCase() : b.SourceSPSiteURL?.toLowerCase();
            break;
          case 'targetTeam':
            compareA = pageType === 'TeamChannel' ? a.TargetTeamName?.toLowerCase() : a.TargetSPSiteName?.toLowerCase();
            compareB = pageType === 'TeamChannel' ? b.TargetTeamName?.toLowerCase() : b.TargetSPSiteName?.toLowerCase();
            break;
          case 'targetChannel':
            compareA = pageType === 'TeamChannel' ? a.TargetChannelName?.toLowerCase() : a.TargetSPSiteURL?.toLowerCase();
            compareB = pageType === 'TeamChannel' ? b.TargetChannelName?.toLowerCase() : b.TargetSPSiteURL?.toLowerCase();
            break;
          default:
            return 0;
        }

        if (compareA < compareB) return newDirection === 'asc' ? -1 : 1;
        if (compareA > compareB) return newDirection === 'asc' ? 1 : -1;
        return 0;
      });

      setGridData(sortedMappings);

      return { column, direction: newDirection };
    });
  };



  const performSearch = () => {
    debugger
    if (searchTerm?.trim() != '' || !searchTerm?.length) { 
      const searchTermLower = searchTerm.toLowerCase()?.trim();
      const filtered = originalData.filter((item: { SourceChannelName: string; TargetChannelName: string; SourceTeamName: string; TargetTeamName: string; SourceSPSiteName: string; TargetSPSiteName: string; }) => {
        if (pageType === 'TeamChannel') {
          return (
            (item.SourceChannelName && item.SourceChannelName.toLowerCase().includes(searchTermLower)) ||
            (item.TargetChannelName && item.TargetChannelName.toLowerCase().includes(searchTermLower)) ||
            (item.SourceTeamName && item.SourceTeamName.toLowerCase().includes(searchTermLower)) ||
            (item.TargetTeamName && item.TargetTeamName.toLowerCase().includes(searchTermLower))
          );
        } else {
          return (
            (item.SourceSPSiteName && item.SourceSPSiteName.toLowerCase().includes(searchTermLower)) ||
            (item.TargetSPSiteName && item.TargetSPSiteName.toLowerCase().includes(searchTermLower))
          );
        }
      });
      setGridData(filtered);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      performSearch();
    }
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchOnFocus(true);
    setSearchTerm(event.target.value);
  };

  const handleSearchIconClick = () => {
    performSearch();
  };



  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          ////console.log(clientToken, 'ddddddddddddddddddddddddddd');
          setCToken(sessionToken)
          token.clientToken = sessionToken
        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          ////console.log(adminToken, 'sssssssssssssssssss');
          setAToken(sessionToken)
          token.adminToken = sessionToken
        }
      }
      return token
    } catch (error) {
      throw error
    }
  }


  const submitMapping = async (pageType: string) => { // CC_PS_172
    try {
      if (!disableActions) {
        setLoader(true)
        const token = await getToken(); // CC_PS_170
        let Details: any; // CC_PS_171
        let fileName: string = ""
        let localBtn: string = ""
        let isMappingTempSaved = originalData.length == 0 ? true : false
        debugger

        if (pageType.toLowerCase() === 'teamchannel') { // CC_PS_173
          const response: any = await SubmitTeamChannelMapping(pageType, token, taskID, teamChannelMappingData, migrationType, isMappingTempSaved); // CC_PS_174
          if (response.data.statusCode === 200 && response.data.statusMessage === "Success") { // CC_PS_184
            setGridData(response.data.data); // CC_PS_185
            setOriginalData(response.data.data)
          } else if (response.data.statusCode === 400 && response.data.statusMessage === "Invalid CSV Details") { // CC_PS_187
            setAlert(true); // CC_PS_188
            setAlertMessage("Imported sheet is invalid"); // CC_PS_188
            fileName = "Invalid TeamChannel Mapping"
            localBtn = "teamsubmit"
            exportCSVCreation(fileName, response.data.data, localBtn); // CC_PS_189
          } else {
            // handle other error scenarios // CC_PS_190
            setAlert(true);
            setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
          }

        } else if (pageType.toLowerCase() === 'site') { // CC_PS_191

          const response: any = await SubmitSiteMapping(pageType, token, taskID, siteMappingData, isMappingTempSaved); // CC_PS_192
          if (response.data.statusCode === 200 && response.data.statusMessage === "Success") { // CC_PS_200
            setGridData(response.data.data); // CC_PS_201
            setOriginalData(response.data.data)

          } else if (response.data.statusCode === 400 && response.data.statusMessage === "Invalid CSV Details") { // CC_PS_203
            setAlert(true); // CC_PS_204
            setAlertMessage("Please Provide Valid Details"); // CC_PS_204
            fileName = "Invalid_Mapping"
            localBtn = "sitesubmit"
            exportCsvDetail(fileName, response.data.data, localBtn); // CC_PS_205
          } else {
            // handle other error scenarios // CC_PS_206
            setAlert(true);
            setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
          }
        }
      }
    }
    catch (error) {
      setAlert(true);
      setAlertMessage(commonErrorMsgs.generalError);
    }
    finally {
      // File Emptying
      mappingFileInput.current.value = ''
      setSiteMapFileName('Choose a File')
      setTeamChannelMapFileName('Choose a File')

      setLoader(false)
    }
  };

  // Function to handle download based on button click
  // CC_PS_100 - CC_PS_118
  const downloadTeamsAndChannels = async (downloadBtnType: string, fileName: string) => {

    try {
      debugger
      setLoader(true)
      const token = await getToken();
      let apiResponse: any;

      if (downloadBtnType.toLowerCase() === "downloadsourceteamsandchannels" || downloadBtnType.toLowerCase() === "downloadtargetteamsandchannels") {
        apiResponse = await getTeamChannelsDetails(downloadBtnType, token, taskID, migrationType); // Pass actual taskID
      } else {
        apiResponse = await getSiteDetails(downloadBtnType, token, taskID)
      }

      if (apiResponse.data.statusCode == 200 && apiResponse.data.statusMessage == "Success") {

        setLoader(false)

        const headers = Object.keys(apiResponse.data.data[0]);
        const aoaData = [
          headers,
          ...apiResponse.data.data.map((obj: { [x: string]: any; }) => headers.map(key => obj[key]))
        ];

        // Create a new workbook and a new worksheet
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.aoa_to_sheet(aoaData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workBook, workSheet, fileName);

        // Write the workbook to a file
        XLSX.writeFile(workBook, `${fileName}.csv`);
      }
      else if (apiResponse.data.statusCode == 200 && (apiResponse.data.statusMessage == "It appears you have 0 Target Teams channels available for download")) {
        setActualAlert(true);
        setActualAlertMessage({
          title: "No Target Teams Channels Found",
          content: "It appears you have 0 Target Teams channels available for download.<br><br>To proceed further:<br>1. Create a new Teams and Channels using AVAMIGRATRON<br>2. Return to this screen to download your Target Teams and Channels."
        });
      }
      else if (apiResponse.data.statusCode == 200 && (apiResponse.data.statusMessage == "Click the Get Source Sites button to fetch Source Site details"
        || apiResponse.data.statusMessage == "Click the Get Target Sites button to fetch Target Site details"
        || apiResponse.data.statusMessage == "Click the Get Source Teams and Channels button to fetch Source Team Channel details"
        || apiResponse.data.statusMessage == "Click the Get Target Teams and Channels button to fetch Target Team Channel details")) {
        setAlert(true);
        setAlertMessage(apiResponse.data.statusMessage);
      }
      else {
        setAlert(true);
        setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');

      }
    }
    catch (error) {
      setAlert(true);
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    }
    finally {
      setLoader(false)
    }


  };


  // CC_PS_236 - CC_PS_257
  const deleteMapping = async (e: React.MouseEvent<HTMLButtonElement>, pageType: string) => {
    try {
      if (!disableActions) {
        debugger
        setLoader(true)
        const token = await getToken();

        let Details: any;
        let ID: any = (e.target as HTMLButtonElement).id;

        const response: any = await deleteMappingService(pageType, token, ID, Number(taskID));

        if ((response.data.statusMessage === 'Team Channel Mapping Deleted Successfully' || response.data.statusMessage == 'Site Mapping Deleted Successfully') && response.data.statusCode === 200) {
          setSuccessPopup(true)
          setSuccessPopupMessage(response.data.statusMessage)
          setGridData(response.data.data);
          setOriginalData(response.data.data)

        } else {
          setAlert(true);
          setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
        }
      }
    } catch (err) {
      // Default behaviour or error handling in Techstack React TS
      setAlert(true);
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    }
    finally {
      setLoader(false)
    }
  };

  // CC_PS_214 - CC_PS_235
  const onSaveBtnClick = async (PageType: string) => {
    try {
      debugger
      setLoader(true)
      let token = await getToken();

      let apiResponse: any;

      if (PageType === "TeamChannel") {
        apiResponse = await saveTeamChannelMapping(PageType, token, taskID, teamChannelMappingData, migrationType);
      } else {
        apiResponse = await saveSiteMapping(PageType, token, taskID, siteMappingData);
      }

      if ((apiResponse.data.statusMessage === 'Imported source and target Teams Channel mapped successfully' || apiResponse.data.statusMessage == "Imported source and target Sites mapped successfully") && apiResponse.data.statusCode === 200) {
        setNavigateTo('migration');
        setSuccessPopup(true)
        setSuccessPopupMessage(apiResponse.data.statusMessage)
        setGridData([])
      } else {
        setAlert(true);
        setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      }
    } catch (error) {
      setAlert(true);
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    }
    finally {
      setLoader(false)
    }
  };

  const cancelMapping = async () => {
    // User clicks Yes on popup to confirm the cancel action
    try {
      setLoader(true); // Start loading indication
      setCancelPopUp(false)
      const token = await getToken();
      debugger
      const apiResponse: any = await cancelMappingService(pageType, token, taskID);

      // Check the response from the server
      if (apiResponse.data.statusCode == 200 && apiResponse.data.statusMessage == 'Success') {
        // Navigate to previous page
        history.push("/Migration", {
          state: {
            ...location?.state,
          },
        });
      } else {
        // Handle error response
        setAlert(true);
        setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      }
    } catch (error) {
      // Handle exceptions
      setAlert(true);
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    } finally {
      setLoader(false); // End loading indication regardless of the outcome
    }
  };


  function exportCsvDetail(fileName: string, data: any, btnType: string) {
    let columns: any
    if (btnType.toLowerCase() == "getsourceteamsandchannels") {
      columns = [
        { header: 'Source Team Name', key: 'SourceTeamName' },
        { header: 'Source Team ID', key: 'SourceTeamID' },
        { header: 'Source Team Type', key: 'SourceTeamType' },
        { header: 'Source Channel Name', key: 'SourceChannelName' },
        { header: 'Source Channel ID', key: 'SourceChannelID' },
        { header: 'Source Channel Type', key: 'SourceChannelType' }
      ];

    }
    else if (btnType.toLowerCase() == "gettargetteamsandchannels") {
      columns = [
        { header: 'Target Team Name', key: 'TargetTeamName' },
        { header: 'Target Team ID', key: 'TargetTeamID' },
        { header: 'Target Team Type', key: 'TargetTeamType' },
        { header: 'Target Channel Name', key: 'TargetChannelName' },
        { header: 'Target Channel ID', key: 'TargetChannelID' },
        { header: 'Target Channel Type', key: 'TargetChannelType' }

      ];
    }
    else if (btnType.toLowerCase() == "getsourcesites") {
      columns = [
        { header: 'Source Site Name', key: 'SPSiteName' },
        { header: 'Source Site Type', key: 'SPSiteType' },
        { header: 'Source Site URL', key: 'SPSiteURL' },
      ];
    }
    else if (btnType.toLowerCase() == "gettargetsites") {
      columns = [
        { header: 'Target Site Name', key: 'SPSiteName' },
        { header: 'Target Site Type', key: 'SPSiteType' },
        { header: 'Target Site URL', key: 'SPSiteURL' },
      ];
    }
    else if (btnType.toLowerCase() == "sitesubmit") {
      columns = [
        { header: 'Source Site Name', key: 'SourceSiteName' },
        { header: 'Source Site Type', key: 'SourceSiteType' },
        { header: 'Source Site URL', key: 'SourceSiteURL' },
        { header: 'Target Site Name', key: 'TargetSiteName' },
        { header: 'Target Site Type', key: 'TargetSiteType' },
        { header: 'Target Site URL', key: 'TargetSiteURL' },
        { header: 'Status', key: 'status' },
        { header: 'Comments', key: 'comments' }
      ];
    }

    // Prepare the data with custom headers
    const worksheetData = [
      columns.map((col: { header: any; }) => col.header), // First row is headers
      ...data.map((item: { [x: string]: any; }) => columns.map((col: { key: string | number; }) => item[col.key] || '')) // Map data to columns
    ];

    const workSheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Teams Channel Details");
    XLSX.writeFile(workBook, `${fileName}.csv`);
  }

  // CC_PS_26 - CC_PS_45
  const downloadTemplate = (templateType: string) => {
    try {
      debugger
      let exportData: any[] = [];
      let fileName: string = '';

      if (templateType === 'teamCreation') {
        exportData = [
          { 'TargetTeam Name': 'ExampleTeam1', 'TargetTeam Type': 'Public' },
        ];
        fileName = 'Team Creation Template';
      } else if (templateType === 'ChannelCreation') {
        exportData = [
          {
            "TargetTeam Name": "Dummy Team",
            "TargetTeam ID": "24sadf454fsdv5t365yv",
            "TargetTeam Type": "Public",
            "TargetChannel Name": "DummyChannel",
            "TargetChannel Type": "Public"
          }
        ]
        fileName = 'Channel Creation Template';
      } else if (templateType === 'siteCreation') {
        exportData = [
          { 'Site Name': 'ExampleSite1', 'Site Type': 'Communication Site' },
          { 'Site Name': 'ExampleSite2', 'Site Type': 'Team Site' },
          { 'Site Name': 'ExampleSite3', 'Site Type': 'Team Site without M365 groups' },
        ];
        fileName = 'Site_Creation_Template';
      } else if (templateType.toLowerCase() === 'sitemappingtemplate') {
        exportData = [
          { 'Source Site Name': 'SourceSite1', 'Source Site Type': 'Team Site', 'Source Site URL': 'https://examplesite.com', 'Target Site Name': 'TargetSite1', 'Target Site Type': 'Team Site', 'Target Site URL': 'https://examplesite.com', }
        ];
        fileName = 'Site Mapping Template';
      } else if (templateType === 'TeamChannelMappingTemplate') {
        exportData = [
          {
            'Source Team Name': 'SourceTeam1',
            'Source Team ID': 'ST1ID',
            'Source Team Type': 'Public',
            'Source Channel Name': 'SourceChannel1',
            'Source Channel ID': 'SC1ID',
            'Source Channel Type': 'Standard',
            'Target Team Name': 'TargetTeam1',
            'Target Team ID': 'TT1ID',
            'Target Team Type': 'Public',
            'Target Channel Name': 'TargetChannel1',
            'Target Channel ID': 'TC1ID',
            'Target Channel Type': 'Standard'
          }
        ];
        fileName = 'Team Channel Mapping Template';
      }

      // Now, use PapaParse to stringify the CSV content
      const csv = Papa.unparse(exportData);

      // Create a Blob for the CSV content
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      // Trigger file download using a hidden anchor element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      setAlert(true);
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    }
  };

  // CC_PS_186, CC_PS_202
  const GridBodyData: React.FC = () => {
    try {
      if (gridData && gridData.length > 0) {
        if (pageType.toLowerCase() === "teamchannel") {
          return (
            <>
              {gridData.map((obj: any, index: number) => (
                <tr key={index} style={{ cursor: 'pointer' }}>
                  <td >
                    {obj?.SourceTeamType ? <Tippy
                      delay={[500, 0]}
                      arrow={true}
                      placement='right'
                      content={`Type : ${obj?.SourceTeamType}`}
                    >
                      <span>{obj.SourceTeamName || "-"}</span>
                    </Tippy> : <span title={obj.SourceTeamName}>{obj.SourceTeamName.length > 21 ? obj.SourceTeamName.slice(0, 21) + '...' : obj.SourceTeamName || "-"} </span>}
                  </td>
                  <td>
                    {obj?.SourceChannelType ? <Tippy
                      delay={[500, 0]}
                      arrow={true}
                      placement='right'
                      content={`Type : ${obj?.SourceChannelType}`}
                    >
                      <span>{obj.SourceChannelName || "-"}</span>
                    </Tippy> : <span title={obj.SourceChannelName}>{obj.SourceChannelName.length > 21 ? obj.SourceChannelName.slice(0, 21) + '...' : obj.SourceChannelName || "-"} </span>}
                  </td>
                  <td>
                    {obj?.TargetTeamType ? <Tippy
                      delay={[500, 0]}
                      arrow={true}
                      placement='right'
                      content={`Type : ${obj?.TargetTeamType}`}
                    >
                      <span>{obj.TargetTeamName || "-"}</span>
                    </Tippy> : <span title={obj.TargetTeamName}>{obj.TargetTeamName.length > 21 ? obj.TargetTeamName.slice(0, 21) + '...' : obj.TargetTeamName || "-"} </span>}
                  </td>
                  <td>
                    {obj?.TargetChannelType ? <Tippy
                      delay={[500, 0]}
                      arrow={true}
                      placement='right'
                      content={`Type : ${obj?.TargetChannelType}`}
                    >
                      <span>{obj.TargetChannelName || "-"}</span>
                    </Tippy> : <span title={obj.TargetChannelName}>{obj.TargetChannelName.length > 21 ? obj.TargetChannelName.slice(0, 21) + '...' : obj.TargetChannelName || "-"} </span>}
                  </td>
                  <td className="text-center">
                    <span className="d-inline-block">
                      <button
                        className="btn action-rounded-btn danger-action d-flex align-items-center justify-content-center"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Delete"
                        title=""
                        id={obj.TeamChannelMigrationMappingID}
                        disabled={disableActions}
                        onClick={(e) => deleteMapping(e, "TeamChannel")}
                      >
                        <img
                          src="/images/red-delete-icon.svg"
                          alt="blue-plus-icon"
                          id={obj.TeamChannelMigrationMappingID}
                        />
                      </button>
                    </span>
                  </td>
                </tr>
              ))}
            </>
          );
        }
        else {
          return (
            <>
              {gridData.map((obj: any, index: number) => (
                <tr key={index}>
                  <td style={{ cursor: 'pointer' }}>
                    {obj?.sourceSiteType ? <Tippy
                      delay={[500, 0]}
                      arrow={true}
                      placement='right'
                      content={`Type : ${obj?.sourceSiteType}`}
                    >
                      <span>{obj.SourceSPSiteName || "-"}</span>
                    </Tippy> : <span title={obj.SourceSPSiteName}>{obj.SourceSPSiteName.length > 21 ? obj.SourceSPSiteName.slice(0, 21) + '...' : obj.SourceSPSiteName || "-"} </span>}
                  </td>
                  <td>
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      data-bs-title="Type Team Site"
                      title={obj.SourceSPSiteURL}
                    >
                      {obj.SourceSPSiteURL.length > 21 ? obj.SourceSPSiteURL.slice(0, 21) + '...' : obj.SourceSPSiteURL || "-"}
                    </span>
                  </td>
                  <td style={{ cursor: 'pointer' }}>
                    {obj?.targetSiteType ? <Tippy
                      delay={[500, 0]}
                      arrow={true}
                      placement='right'
                      content={`Type : ${obj?.targetSiteType}`}
                    >
                      <span>{obj.TargetSPSiteName || "-"}</span>
                    </Tippy> : <span title={obj.TargetSPSiteName}>{obj.TargetSPSiteName.length > 21 ? obj.TargetSPSiteName.slice(0, 21) + '...' : obj.TargetSPSiteName || "-"} </span>}
                  </td>
                  <td>
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      data-bs-title="Type Team Site"
                      title={obj.TargetSPSiteURL}
                    >
                      {obj.TargetSPSiteURL.length > 21 ? obj.TargetSPSiteURL.slice(0, 21) + '...' : obj.TargetSPSiteURL || "-"}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="d-inline-block">
                      <button
                        className="btn action-rounded-btn danger-action d-flex align-items-center justify-content-center"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Delete"
                        title=""
                        id={obj.SPSiteMigrationMappingID}
                        disabled={disableActions}
                        onClick={(e) => deleteMapping(e, "Site")}
                      >
                        <img
                          src="/images/red-delete-icon.svg"
                          alt="blue-plus-icon"
                          id={obj.SPSiteMigrationMappingID}
                        />
                      </button>
                    </span>
                  </td>
                </tr>
              ))}
            </>
          );
        }
      }
      return (
        <tr>
          <td colSpan={5}><div className="text-center">
            <img
              src="/images/no-data-found-img.svg"
              alt="no-data-found-img"
              className="mb-3"
            />
            <h5 className="font-12 font-bold color-no-rec">No records</h5>
            <p className="font-10 font-regular color-no-rec">
              There is no records to show you right now
            </p>
          </div></td>
        </tr>
      );
    } catch (error) {
      console.error("Error in GridBodyData:", error);
      return (
        <tr>
          <td colSpan={5}>Error loading data</td>
        </tr>
      );
    }
  };


  //   const createTeamsAndChannels = (createButtonType: string) => {
  //     try {
  //         debugger
  //       let localCreateBtnType = createButtonType;

  //       if (localCreateBtnType.toLowerCase() === 'sitecreation') {
  //         // Render Site Creation Popup
  //         setSiteCreationPopup(true);
  //       } else if (localCreateBtnType.toLowerCase() === 'teamchannelcreation') {
  //         // Render Team Channel Creation Popup
  //         setTeamChannelCreationPopup(true);
  //       }
  //     } catch (error) {
  //       // Handle error
  //       console.error(commonErrorMsgs.generalError);
  //     }
  //   };

  // CC_PS_46 - CC_PS_77
  const onChangeBrowse = (e: React.ChangeEvent<HTMLInputElement>) => {
    debugger
    if (!disableActions) {
      if (e.target.files && e.target.files[0]) {
        debugger
        const file = e.target.files[0];
        let mappedData: any[] = [];
        let jsonData: any
        let fileReader = new FileReader();
        let relevantKeys: any

        fileReader.readAsBinaryString(file);

        fileReader.onload = (event: ProgressEvent<FileReader>) => {
          try {
            debugger
            if (file.name.includes('.csv')) {
              const parsedData = XLSX.read(event.target?.result as string, { type: 'binary' });
              const sheetName = parsedData.SheetNames[0];
              jsonData = XLSX.utils.sheet_to_json(parsedData.Sheets[sheetName], {
                defval: '' // Set default value for missing cells to an empty string
              });
            }
            else {
              setAlert(true)
              setOpenSiteCreation(false);
              setOpenTeamCreation(false);
              setAlertMessage("Please upload valid template")
              return;
            }


            switch (e.target.id) {
              case 'TeamCreation':
                const requiredTeamColumns = ['TargetTeam Name', 'TargetTeam Type'];
                mappedData = jsonData.map((row: any) => ({
                  'TargetTeamName': row['TargetTeam Name'],
                  'TargetTeamType': row['TargetTeam Type']?.trim(),
                }));
                const teamColumnsPresent = requiredTeamColumns.every(column => Object.keys(jsonData[0]).includes(column));


                if (file.name.includes('.csv') && mappedData.length > 0 &&
                  mappedData[0]['TargetTeamName'] && mappedData[0]['TargetTeamType'] && teamColumnsPresent) {
                  setteamCreationBrowseFileName(file.name)
                  setTeamCreationData(mappedData);
                } else {
                  setAlert(true)
                  setAlertMessage("Please upload valid template")
                }
                break;

              case 'ChannelCreation':
                const requiredChannelColumns = ['TargetTeam Name', 'TargetTeam Type', 'TargetTeam ID', 'TargetChannel Name', 'TargetChannel Type'];
                const channelColumnsPresent = requiredChannelColumns.every(column => Object.keys(jsonData[0]).includes(column));

                mappedData = jsonData.map((row: any) => ({
                  'TargetTeamName': row['TargetTeam Name'],
                  'TargetTeamType': row['TargetTeam Type']?.trim(),
                  'TargetTeamID': row['TargetTeam ID'],
                  'TargetChannelName': row['TargetChannel Name'],
                  'TargetChannelType': row['TargetChannel Type']?.trim(),
                }));
                if (file.name.includes('.csv') && mappedData.length > 0 &&
                  mappedData[0]['TargetTeamName'] && mappedData[0]['TargetTeamType'] && channelColumnsPresent) {
                  setChannelCreationData(mappedData);
                  setChannelCreationFileName(file.name)
                } else {
                  setAlert(true)
                  setAlertMessage("Please upload valid template")
                }
                break;

              case 'SiteCreation':
                if (jsonData.length == 0) {
                  setAlert(true)
                  setAlertMessage("Please upload valid template");
                  setSiteCreationData(mappedData);
                }
                else {
                  const requiredSiteColumns = ['Site Name', 'Site Type'];
                  const siteColumnsPresent = requiredSiteColumns.every(column => Object.keys(jsonData[0]).includes(column));
                  mappedData = jsonData.map((row: any) => ({
                    'SiteName': row['Site Name'],
                    'SiteType': row['Site Type'],
                  }));
                  if (file.name.includes('.csv') && mappedData.length > 0 &&
                    mappedData[0]['SiteName'] != undefined && mappedData[0]['SiteType'] != undefined && siteColumnsPresent) {
                    setSiteCreationData(mappedData);
                    setSiteCreationName(file.name)
                  } else {
                    setAlert(true)
                    setAlertMessage("Please upload valid template")
                  }
                }

                break;

              case 'SiteMapping':
                const requiredSiteMappingColumns = [
                  'Source Site Name',
                  'Source Site Type',
                  'Source Site URL',
                  'Target Site Name',
                  'Target Site Type',
                  'Target Site URL'
                ];
                const channelSiteMappingPresent = requiredSiteMappingColumns.every(column => Object.keys(jsonData[0]).includes(column));
                mappedData = jsonData.map((row: any) => ({
                  'SourceSiteName': row['Source Site Name'],
                  'SourceSiteType': row['Source Site Type'],
                  'SourceSiteURL': row['Source Site URL'],
                  'TargetSiteName': row['Target Site Name'],
                  'TargetSiteType': row['Target Site Type'],
                  'TargetSiteURL': row['Target Site URL'],
                }));

                relevantKeys = [
                  { mapped: "SourceSiteName", grid: "SourceSPSiteName" },
                  { mapped: "SourceSiteURL", grid: "SourceSPSiteURL" },
                  { mapped: "TargetSiteName", grid: "TargetSPSiteName" },
                  { mapped: "TargetSiteURL", grid: "TargetSPSiteURL" }
                ];

                if (gridData.length !== 0) {
                  mappedData = mappedData.filter((mappedItem: { [x: string]: any }) => {
                    // Check if this mappedItem matches any item in gridData
                    return !gridData.some((gridItem: { [x: string]: any }) =>
                      relevantKeys.every((key: { mapped: string | number; grid: string | number; }) => mappedItem[key.mapped] === gridItem[key.grid])
                    );
                  });
                }

                if (file.name.includes('.csv') && mappedData.length > 0 &&
                  mappedData[0]['SourceSiteName'] && mappedData[0]['SourceSiteType'] && mappedData[0]['SourceSiteURL']
                  && mappedData[0]['TargetSiteName'] && mappedData[0]['TargetSiteType'] && mappedData[0]['TargetSiteURL'] &&
                  channelSiteMappingPresent) {
                  setSiteMappingData(mappedData);
                  setSiteMapFileName(file.name)
                } else {
                  setAlert(true);
                  setAlertMessage('Please upload valid template!');
                }
                break;

              case 'TeamChannelMapping':
                const requiredColumns = [
                  'Source Team Name', 'Source Team ID', 'Source Team Type',
                  'Source Channel Name', 'Source Channel ID', 'Source Channel Type',
                  'Target Team Name', 'Target Team ID', 'Target Team Type',
                  'Target Channel Name', 'Target Channel ID', 'Target Channel Type'
                ];

                mappedData = jsonData.map((row: any) => ({
                  'SourceTeamName': row['Source Team Name'],
                  'SourceTeamID': row['Source Team ID'],
                  'SourceTeamType': row['Source Team Type'],
                  'SourceChannelName': row['Source Channel Name'],
                  'SourceChannelID': row['Source Channel ID'],
                  'SourceChannelType': row['Source Channel Type'],
                  'TargetTeamName': row['Target Team Name'],
                  'TargetTeamID': row['Target Team ID'],
                  'TargetTeamType': row['Target Team Type'],
                  'TargetChannelName': row['Target Channel Name'],
                  'TargetChannelID': row['Target Channel ID'],
                  'TargetChannelType': row['Target Channel Type'],
                }));

                relevantKeys = [
                  "SourceTeamName", "SourceTeamID", "SourceTeamType",
                  "SourceChannelName", "SourceChannelID", "SourceChannelType",
                  "TargetTeamName", "TargetTeamID", "TargetTeamType",
                  "TargetChannelName", "TargetChannelID", "TargetChannelType"
                ];

                if (gridData.length != 0) {
                  mappedData = mappedData.filter((mappedItem: { [x: string]: any; }) => {
                    // Check if this mappedItem matches any item in gridData
                    return !gridData.some((gridItem: { [x: string]: any; }) =>
                      relevantKeys.every((key: string | number) => mappedItem[key] === gridItem[key])
                    );
                  });
                }


                const allColumnsPresent = requiredColumns.every(column =>
                  Object.keys(jsonData[0]).includes(column)
                );

                if (file.name.includes('.csv') && mappedData.length > 0 &&
                  mappedData[0]['SourceTeamName'] && mappedData[0]['SourceTeamID'] &&
                  mappedData[0]['SourceTeamType'] && mappedData[0]['SourceChannelName'] &&
                  mappedData[0]['SourceChannelID'] && mappedData[0]['SourceChannelType'] &&
                  mappedData[0]['TargetTeamName'] && mappedData[0]['TargetTeamID'] &&
                  mappedData[0]['TargetTeamType'] && mappedData[0]['TargetChannelName'] &&
                  mappedData[0]['TargetChannelID'] && mappedData[0]['TargetChannelType'] &&
                  allColumnsPresent) {
                  setTeamChannelMappingData(mappedData);
                  setTeamChannelMapFileName(file.name)
                } else {
                  setAlert(true);
                  setAlertMessage('Please upload valid template!');
                }
                break;

              default:
                break;
            }

            setCsvData(jsonData);
          } catch (error) {
            setAlert(true);
            setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
          }
          finally {
            debugger
            e.target.value = ''
          }
        };

        fileReader.onerror = () => {
          setPopupMessage(commonErrorMsgs.generalError);
        };
      } else {
        setPopupMessage('No file selected!');
      }
    }
  };

  async function exportCSVCreation(fileName: string, originalData: any, createBtnType: string) {
    try {
      let exportData: any
      if (createBtnType.toLowerCase() === 'createteams' && fileName === 'Invalid Team Creation') {
        exportData = originalData?.map((item: { Comments: any; Status: any; TargetChannelID: any; TargetChannelName: any; TargetChannelType: any; TargetTeamID: any; TargetTeamName: any; TargetTeamType: any; }) => ({
          "Target Team Name": item.TargetTeamName,
          "Target Team Type": item.TargetTeamType,
          "Comments": item.Comments
        }));
      }
      else if (createBtnType.toLowerCase() === 'createteams' || createBtnType.toLowerCase() === 'createchannels') {
        exportData = originalData?.map((item: { Comments: any; Status: any; TargetChannelID: any; TargetChannelName: any; TargetChannelType: any; TargetTeamID: any; TargetTeamName: any; TargetTeamType: any; }) => ({
          "Target Team Name": item.TargetTeamName,
          "Target Team ID": item.TargetTeamID,
          "Target Team Type": item.TargetTeamType,
          "Target Channel Name": item.TargetChannelName,
          "Target Channel ID": item.TargetChannelID,
          "Target Channel Type": item.TargetChannelType,
          "Comments": item.Comments,
          "Status": item.Status,
        }));

      }
      else if (createBtnType.toLowerCase() === 'createsite') {
        exportData = originalData?.map((item: { SiteName: any; SiteType: any; SiteURL: any; status: any; comments: any; }) => ({
          "Site Name": item.SiteName,
          "Site Type": item.SiteType,
          "Site URL": item.SiteURL,
          "Status": item.status,
          "Comments": item.comments,
        }));

      }
      else if (createBtnType.toLowerCase() == "teamsubmit") {
        exportData = originalData?.map((item: {
          SourceTeamName: string;
          SourceTeamID: string;
          SourceTeamType: string;
          SourceChannelName: string;
          SourceChannelID: string;
          SourceChannelType: string;
          TargetTeamName: string;
          TargetTeamID: string;
          TargetTeamType: string;
          TargetChannelName: string;
          TargetChannelID: string;
          TargetChannelType: string;
          status: string;
          comments: string;
        }) => ({
          "Source Team Name": item.SourceTeamName,
          "Source Team ID": item.SourceTeamID,
          "Source Team Type": item.SourceTeamType,
          "Source Channel Name": item.SourceChannelName,
          "Source Channel ID": item.SourceChannelID,
          "Source Channel Type": item.SourceChannelType,
          "Target Team Name": item.TargetTeamName,
          "Target Team ID": item.TargetTeamID,
          "Target Team Type": item.TargetTeamType,
          "Target Channel Name": item.TargetChannelName,
          "Target Channel ID": item.TargetChannelID,
          "Target Channel Type": item.TargetChannelType,
          "Status": item.status,
          "Comments": item.comments
        }));

      }

      // Now, use PapaParse to stringify the CSV content
      const csv = Papa.unparse(exportData);

      // Create a Blob for the CSV content
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      // Trigger file download using a hidden anchor element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (error) {
      setAlert(true);
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    }

  }

  // CC_PS_83 - CC_PS_95
  const createTeamsAndChannelsDetails = async (createBtnType: string) => {
    try {
      if (!disableActions) {
        debugger
        const token = await getToken(); // Obtain token
        setLoader(true)
        let response: any;
        let invalidfileName: string = ""
        let successFileName: string = ""
        let isDirectMigration: string = "false"

        // Based on the type, call the corresponding service function
        if (createBtnType.toLowerCase() === 'createteams') {
          response = await createTeams(token, migrationType, teamCreationData, taskID, isDirectMigration);
          invalidfileName = "Invalid Team Creation"
          successFileName = "Created Team Details"
        } else if (createBtnType.toLowerCase() === 'createchannels') {
          response = await createChannel(token, migrationType, channelCreationData, taskID, isDirectMigration);
          invalidfileName = "Invalid Channel Creation"
          successFileName = "Created Channel Details"
        } else if (createBtnType.toLowerCase() === 'createsite') {
          response = await createSites(token, siteCreationData, createBtnType, taskID);
          invalidfileName = "Invalid Site Creation"
          successFileName = "Created_site_details"
        }

        // Handle the response after the API call
        if (response.data.statusCode == 200 && (response.data.statusMessage == "Team Channel Created Successfully" || response.data.statusMessage == "Site Created Successfully")) {
          setSuccessPopup(true)
          setSuccessPopupMessage(response.data.statusMessage)
          exportCSVCreation(successFileName, response.data.data, createBtnType)

        } else if (response.data.statusMessage == 'Teams Channel Creation Failed' || response.data.statusMessage == 'Invalid CSV Details' || response.data.statusMessage == 'Site Creation Failed') {
          setAlert(true);
          setAlertMessage('Sheet contains invalid records');
          exportCSVCreation(invalidfileName, response.data.data, createBtnType)
          // Logic for exporting CSV

        } else {
          setAlert(true);
          setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
        }
      }
    } catch (error) {
      setAlert(true);
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    }
    finally {
      //Clean up activities below
      setLoader(false)
      setOpenSiteCreation(false);
      setOpenTeamCreation(false);

      teamCreationRef?.current && teamCreationRef.current?.value ? teamCreationRef.current.value = '' : null;
      ChannelCreationRef?.current && ChannelCreationRef.current?.value ? ChannelCreationRef.current.value = '' : null;
      siteCreationRef?.current && siteCreationRef.current?.value ? siteCreationRef.current.value = '' : null;

      setteamCreationBrowseFileName('Choose a File')
      setChannelCreationFileName('Choose a File')
      setSiteCreationName('Choose a File')

    }
  };

  const getSortArrow = (column: string) => {
    if (sortState.column === column) {
      return sortState.direction === 'asc'
        ? "/images/sort-arrow-up.svg"
        : "/images/sort-arrow-down.svg";
    }
    return "/images/sort-arrow-down.svg";
  };



  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{pageType == "TeamChannel" ? (migrationType.toLowerCase() == "standard" && pageType.toLowerCase() == "teamchannel" ? "Service Account Migration" : "Actual User Migration") : "SharePoint"}</title>
      <link rel="stylesheet" type="text/css" href="./scss/commontheme.css" />
      <link rel="stylesheet" type="text/css" href="./scss/default.css" />
      <link rel="stylesheet" type="text/css" href="./scss/fonts.css" />
      <link rel="stylesheet" type="text/css" href="./scss/table.css" />
      <link rel="stylesheet" type="text/css" href="./scss/forms.css" />
      <link rel="stylesheet" type="text/css" href="./scss/colors.css" />
      <link rel="stylesheet" type="text/css" href="./scss/popup.css" />
      <link rel="stylesheet" type="text/css" href="./scss/top-nav.css" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n        body {\n            overflow: overlay\n        }\n\n        ::-webkit-scrollbar {\n            width: 4px;\n            height: 4px;\n            display: block\n        }\n\n        ::-webkit-scrollbar-button {\n            display: none\n        }\n\n        ::-webkit-scrollbar-track {\n            border-radius: 5px;\n            background-color: #00000000\n        }\n\n        ::-webkit-scrollbar-track-piece {\n            background-color: #00000000\n        }\n\n        ::-webkit-scrollbar-thumb {\n            background: #9f9f9f;\n            opacity: .2;\n            border-radius: 5px\n        }\n    "
        }}
      />
      <div className="container-fluid container-height">
        <div className="row">
          <div className="col-md-12">
            <div className="card px-4 py-4 mt-3 mb-5 border-0 rounded-3">
              {/* bread crumb starts here */}
              <ul className="breadcrumb-item mb-4 ps-0">
                <li>
                  <a className="pe-0" onClick={() => {
                    history.push("/Task")
                  }}>
                    Task
                  </a>
                </li>
                <li>
                  <a className="pe-0" onClick={() => {
                    history.push("/Migration", {
                      state: {
                        ...location?.state,
                      },
                    });
                  }}>
                    {location?.state?.taskName || ""}
                  </a>
                </li>
                <li className="pe-2">{pageType == "TeamChannel" ? (migrationType.toLowerCase() == "standard" && pageType.toLowerCase() == "teamchannel" ? "Teams Channel - Service Account" : "Teams Channel - Actual User Migration") : 'SharePoint'}</li>
              </ul>
              {/* bread crumb ends here */}
              <h5 className="font-20 font-semibold mb-4">
                <a href="">
                  <img
                    src="/images/head-back-arrow.svg"
                    alt="back-arrow"
                    className="me-2"
                    onClick={() => {
                      history.push("/Migration", {
                        state: {
                          ...location?.state,
                        },
                      });
                    }}
                  />
                </a>
                {pageType == "TeamChannel" ? (migrationType.toLowerCase() == "standard" && pageType.toLowerCase() == "teamchannel" ? "Teams Channel - Service Account" : "Teams Channel - Actual User Migration") : 'SharePoint'}
              </h5>
              <ul className="ps-0 list-unstyled">
                <li className="my-2">
                  <span className="d-flex align-items-center gap-3">
                    <img
                      src="/images/table-accordion-arrow.svg"
                      alt="table-accordion-arrow"
                      className="cursor-pointer table-accordion-arrow"
                      data-bs-toggle="collapse"
                      data-bs-target="#step1"
                    />
                    <span className="acc-heading">
                      Step 1 :{" "}
                      <span className="font-semibold color-gray-v5">
                        {pageType == "TeamChannel" ? "Team Channel Creation" : "SharePoint Site Creation"}
                      </span>
                    </span>
                  </span>
                </li>
                <li className="collapse show" id="step1">
                  <div className="px-0 px-md-4 mx-3">
                    <p className="font-14 font-regular text-gray">
                      {pageType == "TeamChannel" ? (migrationType.toLowerCase() == "standard" ? "Create new MS Teams or channels, if you wish to migrate to new Teams or channels. Teams & channels created will be in normal mode." : "Create new MS Teams or channels, if you wish to migrate to new Teams or channels. Teams & channels created will be in migration mode.")
                        : "Create new sites, if you wish to migrate to new sites. Sites created will be in normal mode."}

                    </p>
                    {pageType == "TeamChannel" ?
                      <button
                        type="button"
                        className="primary-btn btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                        disabled={disableActions}
                        onClick={() => {
                          if (!disableActions) {
                            debugger;
                            setOpenTeamCreation(true);
                            ChannelCreationRef.current && ChannelCreationRef.current.value ? ChannelCreationRef.current.value = '' : null;
                            teamCreationRef.current && teamCreationRef.current.value ? teamCreationRef.current.value = '' : null;
                            setChannelCreationFileName('Choose a File')
                            setteamCreationBrowseFileName('Choose a File')

                            let localCreateBtnType = "";
                            if (pageType.toLowerCase() === 'teamchannel') {
                              setCreateBtnType('TeamChannelCreation');
                              localCreateBtnType = "TeamChannelCreation";
                            } else {
                              setCreateBtnType('SiteCreation');
                              localCreateBtnType = "SiteCreation";
                            }
                            // createTeamsAndChannels(localCreateBtnType);

                          }
                        }}
                      >
                        Create Teams & Channels
                      </button>
                      :
                      <button
                        type="button"
                        className="primary-btn btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                        onClick={() => {

                          debugger;
                          setOpenSiteCreation(true)
                          siteCreationRef?.current && siteCreationRef?.current?.value ? siteCreationRef.current.value = '' : null;
                          setSiteCreationName("Choose a File");



                          let localCreateBtnType = "";
                          if (pageType.toLowerCase() === 'teamchannel') {
                            setCreateBtnType('TeamChannelCreation');
                            localCreateBtnType = "TeamChannelCreation";
                          } else {
                            setCreateBtnType('SiteCreation');
                            localCreateBtnType = "SiteCreation";
                          }
                          // createTeamsAndChannels(localCreateBtnType);
                        }}

                      >
                        Create Sites
                      </button>

                    }

                  </div>
                </li>
                <li className="mt-4 pt-2 mb-2">
                  <span className="d-flex align-items-center gap-3">
                    <img
                      src="/images/table-accordion-arrow.svg"
                      alt="table-accordion-arrow"
                      className="cursor-pointer table-accordion-arrow"
                      data-bs-toggle="collapse"
                      data-bs-target="#step2"
                    />
                    <span className="acc-heading">
                      Step 2 :{" "}
                      <span className="font-semibold color-gray-v5">
                        {pageType == "TeamChannel" ? "Import Teams & Channel" : "Import Source & Target sites"}
                      </span>
                    </span>
                  </span>
                </li>
                <li className="collapse show" id="step2">
                  <div className="px-0 px-md-4 mx-3">
                    <p className="font-14 font-regular text-gray mb-4">
                      {pageType == "TeamChannel" ? "Export the existing MS Teams channel details and customize the channel mapping template. Import and “Submit” to verify mapping."
                        : "Export the existing sites from source and target and create mapping in site mapping template. Import and “Submit” to create mapping."}

                    </p>
                    <div className="col-xxl-7 col-xl-9 col-lg-10 col-md-12 col-sm-12">
                      <div className="row ">
                        <div className="col-lg-5 col-md-6 col-sm-12">
                          <button
                            type="button"
                            className="primary-btn btn mb-3 w-100 font-semibold font-14 d-flex justify-content-center align-items-center"
                            onClick={() => {
                              // CC_PS_119 - CC_PS_122
                              let btnType: string = ""
                              let fileName = ""
                              if (pageType === "TeamChannel") {
                                btnType = "GetSourceTeamsandChannels"
                                setGetBtnType("GetSourceTeamsandChannels");
                                fileName = "Source Teams & Channels details"
                                setFileName("Source Teams & Channels details")
                              } else {
                                btnType = "GetSourceSites"
                                fileName = "Source Site details"
                                setGetBtnType("GetSourceSites");
                              }

                              getTeamsandChannels(btnType, fileName)

                            }}
                          >
                            <img
                              src="/images/download-icon.svg"
                              alt="download-icon"
                              className="me-1"
                            />
                            {pageType == "TeamChannel" ? `Get Source Teams & Channel` : "Get Source Sites"}
                          </button>
                        </div>
                        {migrationType == "standard" && pageType == "TeamChannel" || pageType == "Site" ?
                          <div className="col-lg-5 col-md-6 col-sm-12">
                            <button
                              type="button"
                              className="primary-btn btn mb-3 w-100 font-semibold font-14 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                let btnType: string = ""
                                let fileName = ""
                                if (pageType === "TeamChannel") {
                                  btnType = "GetTargetTeamsandChannels"
                                  setGetBtnType("GetTargetTeamsandChannels");
                                  fileName = "Target Teams & Channel Details"
                                  setFileName("Target Teams & Channel Details")
                                } else {
                                  fileName = "Target Site details"
                                  btnType = "GetTargetSites"
                                  setGetBtnType("GetTargetSites");
                                }

                                getTeamsandChannels(btnType, fileName)

                              }}
                            >
                              <img
                                src="/images/download-icon.svg"
                                alt="download-icon"
                                className="me-1"
                              />
                              {pageType == "TeamChannel" ? `Get Target Teams & Channel` : "Get Target Sites"}
                            </button>
                          </div>
                          : <></>
                        }
                      </div>
                      <div className="row ">
                        <div className="col-lg-5 col-md-6 col-sm-12">
                          <button
                            type="button"
                            className="primary-outline-btn btn mb-3 w-100 font-semibold font-14 d-flex justify-content-center align-items-center"
                            onClick={() => {
                              let localDownloadBtnType: string
                              let fileName: string
                              if (pageType.toLowerCase() == "teamchannel") {
                                setDownloadBtnType("downloadsourceteamsandchannels")
                                localDownloadBtnType = "downloadsourceteamsandchannels"
                                fileName = "Source Teams & Channels details"
                              }
                              else {
                                localDownloadBtnType = "downloadsourcesites"
                                fileName = "Source Site details"
                              }

                              downloadTeamsAndChannels(localDownloadBtnType, fileName)
                            }}
                          >
                            <img
                              src="/images/download-icon.svg"
                              alt="download-icon"
                              className="me-1"
                            />
                            {pageType == "TeamChannel" ? `Download Source Teams & Channel` : "Download Source Sites"}

                          </button>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                          <button
                            type="button"
                            className="primary-outline-btn btn mb-3 w-100 font-semibold font-14 d-flex justify-content-center align-items-center"
                            onClick={() => {
                              let localDownloadBtnType: string
                              let fileName: string
                              if (pageType.toLowerCase() == "teamchannel") {
                                setDownloadBtnType("downloadtargetteamsandchannels")
                                localDownloadBtnType = "downloadtargetteamsandchannels"
                                fileName = "Target Teams & Channels details"
                              }
                              else {
                                localDownloadBtnType = "downloadtargetsites"
                                fileName = "Target Site details"
                              }

                              downloadTeamsAndChannels(localDownloadBtnType, fileName)
                            }}
                          >
                            <img
                              src="/images/download-icon.svg"
                              alt="download-icon"
                              className="me-1"
                            />
                            {pageType == "TeamChannel" ? ` Download Target Teams & Channel` : "Download Target Sites"}
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-5 col-md-6 col-sm-12">
                          <button
                            type="button"
                            className="primary-outline-btn btn mb-3 w-100 font-semibold font-14 d-flex justify-content-center align-items-center"
                            onClick={() => {
                              let localTemplateType = ""
                              if (pageType.toLowerCase() == "teamchannel") {
                                setTemplateType("TeamChannelMappingTemplate")
                                localTemplateType = "TeamChannelMappingTemplate"
                              }
                              else {
                                localTemplateType = "SiteMappingTemplate"
                              }
                              downloadTemplate(localTemplateType)

                            }}
                          >
                            <img
                              src="/images/download-icon.svg"
                              alt="download-icon"
                              className="me-1"
                            />
                            {pageType == "TeamChannel" ? `Team & Channel Mapping Template` : "Site Mapping Template"}
                          </button>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                          <div className="upload-btn-wrapper mb-3 d-block cursor-pointer">
                            <div className="input-group">
                              <span className="cust-filter-btn font-14 py-2 font-medium primary-color upload-btn">
                                Browse
                                <input
                                  onChange={onChangeBrowse}
                                  ref={mappingFileInput}
                                  className="form-control"
                                  type="file"
                                  id={pageType.toLowerCase() === 'teamchannel' ? 'TeamChannelMapping' : 'SiteMapping'}
                                />
                              </span>
                              <input
                                type="text"
                                className="form-control file-input-field"
                                placeholder={pageType.toLowerCase() == "teamchannel" ? teamChannelMapFileName : siteMapFileName}
                                aria-label="Example text with button addon"
                                aria-describedby="upload-btn"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-12 col-sm-12">
                          <button
                            type="button"
                            className="primary-btn btn mb-3 me-0 me-md-3 w-md-100 font-semibold font-14 d-flex justify-content-center align-items-center"
                            onClick={() => {
                              submitMapping(pageType)
                            }}
                            disabled={disableActions ? true :
                              pageType.toLowerCase() == "teamchannel"
                                ? teamChannelMappingData.length > 0 ? false : true
                                : siteMappingData.length > 0 ? false : true
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="mt-4 pt-2 mb-2">
                  <span className="d-flex align-items-center gap-3">
                    <img
                      src="/images/table-accordion-arrow.svg"
                      alt="table-accordion-arrow"
                      className="cursor-pointer table-accordion-arrow"
                      data-bs-toggle="collapse"
                      data-bs-target="#step3"
                    />
                    <span className="acc-heading">
                      Step 3 :{" "}
                      <span className="font-semibold color-gray-v5">
                        Create Mapping
                      </span>
                    </span>
                  </span>
                </li>
                <li className="collapse show" id="step3">
                  <div className="px-0 px-md-4 mx-3">
                    <p className="font-14 font-regular text-gray">
                      {pageType == "TeamChannel" ? "Select the required mappings to create mapping." : "Select the required mappings to create site mapping."}

                    </p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <h5 className="tertiary-header">
                        {pageType == "TeamChannel" ? "Teams & Channel Mapping " : "Site Mapping "}
                        <span>({gridData.length})
                          <Tippy
                            delay={100}
                            arrow={true}
                            placement='top'
                            content={`Displays mapped source and target ${pageType == "TeamChannel" ? `Teams & Channels` : `sites`} for accurate migration alignment.`}
                          >
                            <img className="ms-1"
                              src="/images/tooltip.svg"
                              alt="tooltip"
                              width={11}
                            />
                          </Tippy>
                        </span>
                      </h5>

                      {originalData?.length ?
                        <div className="input-group input-search " style={searchOnFocus ? { boxShadow: '0 0 0 0.15rem rgba(13, 110, 253, 0.25)', width: '20%', borderRadius: '5px' } : { width: '20%' }}>
                          <input
                            type="search"
                            className="form-control custom-form font-14 font-regular"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearchInputChange}
                            onKeyDown={handleKeyDown}
                            onFocus={() => setSearchOnFocus(true)}
                            onBlur={() => setSearchOnFocus(false)}
                            onKeyPress={(e) => {
                              if (e && e.key === "Enter") {
                                setSearchOnFocus(false)
                              }
                            }}
                          />
                          <button
                            className="btn border-start-0 d-flex align-items-center"
                            type="button"
                            onClick={handleSearchIconClick}
                          >
                            <img src="/images/search.svg" alt="search" />
                          </button>
                        </div> : null}
                    </div>
                    <div className="table-responsive theme-table bg-white d-inline-block w-100 mt-3">
                      <table className="table table-borderless mb-0">
                        <thead style={{ boxShadow: '0 0 4px #ccc' }}>
                          <tr>
                            <th className="text-nowrap">
                              {pageType == "TeamChannel" ? "Source Team Name" : "Source Site Name"}
                              {gridData?.length ? <span
                                className="ms-1 cursor-pointer"
                                onClick={() => handleSort('sourceTeam')}
                              >
                                <img
                                  src={
                                    sortState.column === 'sourceTeam'
                                      ? sortState.direction === 'asc'
                                        ? "/images/sort-arrow-up.svg"
                                        : "/images/sort-arrow-down.svg"
                                      : "/images/sort-arrow-down.svg"
                                  }
                                  alt="sort-arrow"
                                />
                              </span> : null}
                            </th>
                            <th className="text-nowrap">
                              {pageType == "TeamChannel" ? "Source Channel Name" : "Source Site URL"}
                              {gridData?.length ? <span
                                className="ms-1 cursor-pointer"
                                onClick={() => handleSort('sourceChannel')}
                              >
                                <img
                                  src={
                                    sortState.column === 'sourceChannel'
                                      ? sortState.direction === 'asc'
                                        ? "/images/sort-arrow-up.svg"
                                        : "/images/sort-arrow-down.svg"
                                      : "/images/sort-arrow-down.svg"
                                  }
                                  alt="sort-arrow"
                                />
                              </span> : null}
                            </th>
                            <th className="text-nowrap">
                              {pageType == "TeamChannel" ? "Target Team Name" : "Target Site Name"}
                              {gridData?.length ? <span
                                className="ms-1 cursor-pointer"
                                onClick={() => handleSort('targetTeam')}
                              >
                                <img
                                  src={
                                    sortState.column === 'targetTeam'
                                      ? sortState.direction === 'asc'
                                        ? "/images/sort-arrow-up.svg"
                                        : "/images/sort-arrow-down.svg"
                                      : "/images/sort-arrow-down.svg"
                                  }
                                  alt="sort-arrow"
                                />
                              </span> : null}
                            </th>
                            <th className="text-nowrap">
                              {pageType == "TeamChannel" ? " Target Channel Name" : "Target Site URL"}
                              {gridData?.length ? <span
                                className="ms-1 cursor-pointer"
                                onClick={() => handleSort('targetChannel')}
                              >
                                <img
                                  src={
                                    sortState.column === 'targetChannel'
                                      ? sortState.direction === 'asc'
                                        ? "/images/sort-arrow-up.svg"
                                        : "/images/sort-arrow-down.svg"
                                      : "/images/sort-arrow-down.svg"
                                  }
                                  alt="sort-arrow"
                                />
                              </span> : null}
                            </th>
                            {!disableActions && <th className="text-nowrap text-center">
                              Action
                            </th>}
                          </tr>
                        </thead>
                        <tbody>
                          <GridBodyData></GridBodyData>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="d-md-flex d-block mx-3 px-0 px-md-4 mt-4 justify-content-md-end justify-content-unset">
                    <button
                      type="button"
                      className="secondary-btn btn mb-2 me-0 me-md-3 w-md-100 font-semibold font-14 d-flex justify-content-center align-items-center"
                      onClick={() => {
                        if (originalData.length > 0) {
                          setCancelPopUp(true)
                        }
                        else {
                          history.goBack();
                        }
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="primary-btn btn mb-2 px-3 w-md-100 font-semibold font-14 d-flex justify-content-center align-items-center"
                      onClick={() => {
                        onSaveBtnClick(pageType)
                      }}
                      disabled={gridData?.length > 0 ? false : true}
                    >
                      Save
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Success Pop Up starts here */}
      {successPopUp === true ? (
        <div className="modal fade show overlay overlay-front"
          aria-labelledby="Success-popupLabel" aria-modal="true" role="dialog" style={{ opacity: '1', display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/success-popup-icon.svg" alt="success-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5 className="modal-title mb-3 primary-header" id="Success-popupLabel">
                  Success !
                </h5>
                <p className="primary-data">{successPopupMessage}</p>
              </div>
              <div className="modal-footer border-0 justify-content-center pb-4">
                <button
                  type="button"
                  className="btn primary-btn font-14 px-4 font-semibold"
                  onClick={() => {
                    setSuccessPopup(false);
                    if (navigateTo == 'migration') {
                      setNavigateTo('');
                      history.push("/Migration", {
                        state: {
                          ...location?.state,
                        },
                      });
                    }
                  }}                            >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* Success Popup ends here */}

      {/* failure Pop Up starts here */}
      {alert ? <div
        className="modal fade show overlay overlay-front"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)", opacity: '1' }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                {'Alert'}
              </h5>
              <p className="primary-data">
                {alertMessage}
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => {
                  if (isSessionEpired) {
                    history.push("/Login")
                  }
                  setAlert(false)
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> : null}
      {/*Failure Popup ends here */}

      {actualAlert && (
        <div className="modal fade show overlay overlay-front" tabIndex={-1} style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)", opacity: '1' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0">
                <h5 className="modal-title mb-3 primary-header text-center" id="Failure-popupLabel">
                  {actualAlertMessage.title}
                </h5>
                <div className="primary-data text-left" dangerouslySetInnerHTML={{ __html: actualAlertMessage.content }}></div>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button type="button" className="btn primary-btn font-14 px-4 font-semibold" onClick={() => setActualAlert(false)}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



      {/* cancel Pop Up starts here */}
      {cancelPopUp ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                { }
              </h5>
              <p className="primary-data">
                Are you sure want to cancel the <span>{pageType.toLowerCase() == 'site' ? "Site" : null}</span> mapping?
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={async () => {
                  cancelMapping()
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => {
                  setCancelPopUp(false)
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div> : null}
      {/*Failure Popup ends here */}

      {/* cancel Pop Up starts here */}
      {cancelPopUp ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                { }
              </h5>
              <p className="primary-data">
                Are you sure want to cancel the <span>{pageType.toLowerCase() == 'site' ? "Site" : null}</span> mapping?
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={async () => {
                  cancelMapping()
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => {
                  setCancelPopUp(false)
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div> : null}
      {/*Failure Popup ends here */}

      {/* <!-- TeamsChannelCreation popup starts here --> */}

      {openTeamCreation ?
        <div className="modal fade show" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }} id="TeamsChannelCreation" data-bs-backdrop="static" data-bs-keyboard="false"
          tabIndex={-1} aria-labelledby="TeamsChannelCreationLabel" >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-4">
              <div className="modal-header border-0 p-0">
                <h1 className="font-20 font-bold custom-header-color m-0" id="TeamsChannelCreationLabel">Teams & Channel
                  Creation
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                  teamCreationRef.current.value = ''
                  ChannelCreationRef.current.value = ''
                  setteamCreationBrowseFileName('Choose a File')
                  setChannelCreationFileName('Choose a File')
                  setTeamCreationData([])
                  setChannelCreationData([])
                  setOpenTeamCreation(false);
                }}></button>
              </div>
              <div className="modal-body border-0 p-0 pb-2">
                <div className="row">
                  <span className="d-block font-14 font-regular color-gray-v4 mt-2 mb-3">{location?.state?.MigrationMode == 'standard' ? 'MS Teams Channels created here will have Public, Private and Shared visibility.' : 'MS Teams Channels created here will only have public visibility.'}</span>
                  <div className="col-md-12 mt-3">

                    <div className="mb-1">
                      <label className="font-15 font-semibold label-color w-100 mb-2 pb-1">Import Teams Creation Details <span className="mandatory-color">*</span></label>
                      <div className="upload-btn-wrapper me-2 d-block cursor-pointer">
                        <div className="input-group mb-3">
                          <span className="cust-filter-btn font-14  font-medium primary-color upload-btn">Browse
                            <input className="form-control" type="file" id="TeamCreation" ref={teamCreationRef}
                              onChange={onChangeBrowse}
                            />
                          </span>

                          <input type="text" className="form-control file-input-field"
                            placeholder={teamCreationbrowseFileName} aria-label="Example text with button addon" />
                        </div>

                      </div>

                    </div>
                    <button
                      className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0"
                      onClick={() => {
                        let localTemplateType = ""
                        if (pageType.toLowerCase() == "teamchannel") {
                          setTemplateType("teamCreation")
                          localTemplateType = "teamCreation"

                          downloadTemplate(localTemplateType)
                        }

                      }}
                    >
                      <img src="/images/download-icon.svg" alt="back" width="12" /><span className="ms-1">Download Team
                        Creation Template</span>
                    </button>

                    <p className="d-block font-14 font-regular color-gray-v4 mt-3">It is recommended to create up to 15
                      teams at once</p>
                    <div className="d-flex justify-content-end mt-4">
                      <button type="button" className="btn primary-btn" disabled={disableActions ? true : teamCreationData.length == 0 ? true : false} onClick={() => {
                        debugger
                        createTeamsAndChannelsDetails("CreateTeams")
                      }}>Create Teams</button>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">

                    <div className="mb-1">
                      <label className="font-15 font-semibold label-color w-100 mb-2 pb-1">Import Channel Creation Details <span className="mandatory-color">*</span></label>
                      <div className="upload-btn-wrapper me-2 d-block cursor-pointer">
                        <div className="input-group mb-3">
                          <span className="cust-filter-btn font-14  font-medium primary-color upload-btn">Browse
                            <input className="form-control" type="file" id="ChannelCreation" ref={ChannelCreationRef}
                              onChange={onChangeBrowse}
                            />
                          </span>

                          <input type="text" className="form-control file-input-field"
                            placeholder={channelCreationFileName} aria-label="Example text with button addon" />
                        </div>

                      </div>

                    </div>
                    <button
                      className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0"
                      onClick={() => {
                        let localTemplateType = ""
                        if (pageType.toLowerCase() == "teamchannel") {
                          setTemplateType("teamCreation")
                          localTemplateType = "ChannelCreation"

                          downloadTemplate(localTemplateType)
                        }

                      }}
                    >
                      <img src="/images/download-icon.svg" alt="back" width="12" /><span className="ms-1">Download Channel
                        Creation Template</span>
                    </button>

                    <p className="d-block font-14 font-regular color-gray-v4 mt-3">It is recommended to create up to 15
                      channel at once</p>
                    <div className="d-flex justify-content-end mt-4">
                      <button type="button" className="btn primary-btn" disabled={channelCreationData.length == 0 ? true : false} onClick={() => {
                        debugger
                        createTeamsAndChannelsDetails("CreateChannels")
                      }}>Create Channel</button>
                    </div>

                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
        : null}

      {/* <!-- TeamsChannelCreation popup ends here --> */}

      {/* <!--site popup starts here --> */}
      {openSiteCreation ?
        <div className="modal fade show" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }} id="siteCreation" data-bs-keyboard="false" tabIndex={-1}
          aria-labelledby="siteCreationLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-4">
              <div className="modal-header border-0 p-0">
                <h1 className="font-20 font-bold custom-header-color m-0" id="siteCreationLabel">Site
                  Creation
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                  siteCreationRef.current.value = ''
                  setSiteCreationName("Choose a File")
                  setSiteCreationData([])
                  setOpenSiteCreation(false)
                }}></button>
              </div>
              <div className="modal-body border-0 p-0 pt-2 pb-2">
                <div className="row">
                  <div className="col-md-12 mt-3">

                    <div className="mb-1">
                      <label className="font-15 font-semibold label-color w-100 mb-2 pb-1">Import site creation Details <span className="mandatory-color">*</span></label>
                      <div className="upload-btn-wrapper me-2 d-block cursor-pointer">
                        <div className="input-group mb-3">
                          <span className="cust-filter-btn font-14  font-medium primary-color upload-btn">Browse
                            <input className="form-control" type="file" id="SiteCreation" ref={siteCreationRef}
                              onChange={onChangeBrowse}
                            />
                          </span>

                          <input type="text" className="form-control file-input-field"
                            placeholder={siteCreationName} aria-label="Example text with button addon" />
                        </div>

                      </div>

                    </div>
                    <button
                      className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0"
                      onClick={() => {
                        downloadTemplate("siteCreation")

                      }}
                    >
                      <img src="/images/download-icon.svg" alt="back" width="12" /><span className="ms-1">Download Site
                        Creation Template</span>
                    </button>


                    <div className="d-flex justify-content-end mt-4">
                      {//console.log("the team and sharepoint",teamCreationData,siteCreationData)
                      }
                      <button type="button" className="btn primary-btn"
                        disabled={!siteCreationData?.length}
                        onClick={() => {
                          createTeamsAndChannelsDetails("createsite")
                        }}
                      > Create Site</button>
                    </div>

                  </div>

                </div>
              </div>

            </div>
          </div>
        </div> : null}
      {/* <!--site creation popup ends here --> */}


      {/* footer ends here */}

      {/* loader modal */}
      {loader ?
        <div className="container-fluid">
          <div className="overlay overlay-front">
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border Loader loader-color align-center "
                role="status"
              >
                <span>
                  <span className="loader-inner-circle"></span>
                </span>
              </div>
              <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                Loading..
              </p>
            </div>
          </div>
        </div>
        : <></>}
    </>

  )
}