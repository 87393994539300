import React, { useState, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { fetchGoLiveOptions, postExchangeGoLiveConfig } from '../../Services/GolIveConfigServices';
import { decodeSessionDetails } from '../../Services/CommonServices';
import objCryptoJS from "crypto-js";
// import commonService from '/commonServices';
import { useHistory } from 'react-router-dom';
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';

interface Props {
    isEdit: any;
    taskId: string;
    isOpen: any;
    onToggle: () => void;
    updateGoLiveSaveStatus: (value: any) => void;
    applicationName: string;
    disabled: any;
    onToggleAccordian: () => void;
    isEditCheck: any;
    roleCheck: string
}

const GoliveConfigurations: React.FC<Props> = ({ onToggleAccordian, taskId, isOpen, onToggle, updateGoLiveSaveStatus, applicationName, isEdit, isEditCheck, disabled, roleCheck }) => {

    // PS_01, PS_02, PS_03, PS_04, PS_05, PS_06, PS_07, PS_08, PS_09, PS_10, PS_12
    const [loading, setLoading] = useState(false);
    const [isSharedLinks, setIsSharedLinks] = useState(false);
    const [isUniquePermission, setIsUniquePermission] = useState(false);
    const [isDirectPermission, setIsDirectPermission] = useState(true);
    const [goliveSAC, setGoliveSAC] = useState(true);
    const [sendConfirmation, setSendConfirmation] = useState(true);
    const [isMemberMigration, setIsMemberMigration] = useState(true);
    const [isEmailForwarding, setIsEmailForwarding] = useState(false);
    const [isPermission, setisPermission] = useState(false);
    const [isRules, setisRules] = useState(false);
    let [role, setRole] = useState('');
    const [disableActions, setDisableActions] = useState(roleCheck.toLowerCase().includes('viewer'))
    const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
    const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
    const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
    const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;
    const history = useHistory();

    //console.log(applicationName, ": Application name");

    // PS_13
    useEffect(() => {
        sessionTokenProperties();
        resetTimer();
        fetchData();
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);

        return () => {
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };

    }, []);


    let resetTimer = async () => {
        try {
            // ;
            ////console.log("Reset Timer Start");

            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            ////console.log("sessionResponse value :", sessionResponse);

            // //
            if (sessionResponse == 'expired') {

                sessionStorage.removeItem("c_at");
                setisSessionEpired(true)
                setAlert(true)
                setAlertMessage('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {
                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }

                let token = await getToken()
                let payload = {
                    mailId: emailAddress,
                    isAdmin: false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', updatedToken);
                let updatedobjClient: any = jwt(c_decryptedValue);
                SessionEpiredDateTime = updatedobjClient.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);
            }
            ////console.log("Reset Timer End");
        }
        catch (error) {
            ////console.log("Reset Timer Catch");
            setAlert(true)
            setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            setLoading(false);
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            // 
            ////console.log("Reset Session Timer Start");
            // Assuming InActiveTimeout is a number representing the ID of the current timeout

            if (typeof window !== 'undefined') {
                ////console.log("Reset Session Timer Inside");
                clearTimeout(InActiveTimeout);
                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout = setTimeout(() => {
                    ////console.log("Expired");
                    sessionStorage.removeItem("c_at");
                    ////console.log("removed session");
                    setisSessionEpired(true)
                    setAlert(true)
                    setAlertMessage('Your Session has Expired please login again to continue');
                    // return 'expired'
                }, tokenExpiryCheckInterval);

                ////console.log("Reset Session Timer checking");

                const currentDateTime = new Date().toISOString();
                const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

                const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
                ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                ////console.log("minutes : ", minutes);

                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
                ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

                if (minutes > sessionUpdateTimeGap) {

                    ////console.log("Reset Session Timer need to update");
                    return 'updateToken'
                }

                ////console.log("Reset Session Timer End");
                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            ////console.log("Reset Session Timer Catch");
            throw ex
        }
    };



    // PS_14, PS_15, PS_16, PS_17, PS_18, PS_19, PS_20, PS_21, PS_22, PS_23, PS_24, PS_25, PS_26, PS_27, PS_28, PS_29, PS_30, PS_31
    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: any = await decodeSessionDetails();

            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                else {
                    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
                        history.push("/Admin/Login")
                    }
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            setAlert(true);

            // console.error('Failed in main method', error);
        }
    };


    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};

        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin !== undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            setAlert(true);

            throw error
        }
    }


    const [goLiveSelectedOptions, setGoLiveOptions] = useState({
        isEmailForwarding: isEmailForwarding,
        sendConfirmation: sendConfirmation,
        isMemberMigration: isMemberMigration,
        isUniquePermission: isUniquePermission,
        isDirectPermission: isDirectPermission,
        isSharedLinks: isSharedLinks,
        isPermission: isPermission,
        isRules: isRules,
        taskId: taskId,
    });

    // PS_32, PS_43, PS_44, PS_45, PS_46, PS_47
    const fetchData = async () => {
        setLoading(true);
        {
            try {

                setLoading(true);
                let token = await getToken()

                const goliveOptionsResponse = await fetchGoLiveOptions({
                    taskId: taskId,
                    token: token,
                });
                //console.log(goliveOptionsResponse.data, "golivedata");
                //console.log(goliveOptionsResponse.data.data?.sendConfirmation, "sendConfirmation");


                if (goliveOptionsResponse?.data?.data) {
                    setIsEmailForwarding(goliveOptionsResponse.data?.data?.isEmailForwarding)
                    setSendConfirmation(goliveOptionsResponse.data?.data?.sendConfirmation)
                    setisPermission(goliveOptionsResponse.data?.data?.isPermission)
                    setIsMemberMigration(goliveOptionsResponse.data?.data?.isMemberMigration)
                    setisRules(goliveOptionsResponse.data?.data?.isRules)
                    setIsSharedLinks(goliveOptionsResponse.data?.data?.isSharedLinks)
                    setIsUniquePermission(goliveOptionsResponse.data?.data?.isUniquePermission)
                    setIsDirectPermission(goliveOptionsResponse.data?.data?.isDirectPermission)

                    setGoLiveOptions((prevState: any) => ({
                        ...prevState,
                        isEmailForwarding: goliveOptionsResponse.data?.data?.isEmailForwarding,
                    }));

                    setGoLiveOptions((prevState: any) => ({
                        ...prevState,
                        sendConfirmation: goliveOptionsResponse.data?.data?.sendConfirmation,
                    }));

                    setGoLiveOptions((prevState: any) => ({
                        ...prevState,
                        isPermission: goliveOptionsResponse.data?.data?.isPermission,
                    }));

                    setGoLiveOptions((prevState: any) => ({
                        ...prevState,
                        isMemberMigration: goliveOptionsResponse.data?.data?.isMemberMigration,
                    }));

                    setGoLiveOptions((prevState: any) => ({
                        ...prevState,
                        isRules: goliveOptionsResponse.data?.data?.isRules,
                    }));

                    setGoLiveOptions((prevState: any) => ({
                        ...prevState,
                        isSharedLinks: goliveOptionsResponse.data?.data?.isSharedLinks,
                    }));

                    setGoLiveOptions((prevState: any) => ({
                        ...prevState,
                        isUniquePermission: goliveOptionsResponse.data?.data?.isUniquePermission,
                    }));

                    setGoLiveOptions((prevState: any) => ({
                        ...prevState,
                        isDirectPermission: goliveOptionsResponse.data?.data?.isDirectPermission,
                    }));

                }
            } catch (error) {
                console.error("Error fetching go live options:", error);
            } finally {
                setLoading(false);
            }
        }

    };

    //console.log(goLiveSelectedOptions, ":Before goLiveSelectedOptions");
    // PS_54, PS_55, PS_56, PS_57, PS_58
    // PS_59, PS_60, PS_61, PS_62, PS_63, PS_64
    const mbxHandleCheckboxChange = (option: any) => {

        setGoliveSAC(false)

        if (option === "sendConfirmation") {
            setSendConfirmation((prevValue) => !prevValue);
        }
        if (option === "isMemberMigration") {
            setIsMemberMigration((prevValue) => !prevValue);
        }
        if (option === "isPermission") {
            setisPermission((prevValue) => !prevValue);
        }
        if (option === "isRules") {
            setisRules((prevValue) => !prevValue);
        }
        if (option === "isEmailForwarding") {
            setIsEmailForwarding((prevValue) => !prevValue);
        }
        if (option === "isDirectPermission") {
            setIsDirectPermission((prevValue) => !prevValue)
        }
        if (option === "isUniquePermission") {
            setIsUniquePermission((prevValue) => !prevValue)
        }

        // setGoLiveOptionsChange(option);
        setGoLiveOptions((prevState: any) => ({
            ...prevState,
            [option]: !prevState[option],
        }));

    };

    // const setGoLiveOptionsChange = (option : any) => {
    //     setGoLiveOptions((prevState: any) => ({
    //         ...prevState,
    //         [option]: !prevState[option],
    //     }));
    // }


    // //console.log(setIsEmailForwarding)
    // PS_47, PS_48, PS_49, PS_50, PS_51
    const mbxHandleSave = async () => {
        try {
            debugger
            setLoading(true);
            let token = await getToken()

            if (!disableActions) {

                const mbxUpdatedOptions = { ...goLiveSelectedOptions, token: token };
                await postExchangeGoLiveConfig(mbxUpdatedOptions);
                // //console.log(response , "response")
                //console.log(mbxUpdatedOptions, ": Payload");


            }

            // Send a response to the parent component
            updateGoLiveSaveStatus(true);
            // Handle the result as needed
        } catch (error) {
            // console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isEdit === true) {
            setGoliveSAC(true)
        }
    }, [])

    return (
        <div className="col-md-12">
            <div className="table-responsive theme-table bg-white mb-3">
                <table className="table table-borderless table-shadow mb-0">
                    <thead>
                        <tr>
                            <th className="text-nowrap table-accordion-head">
                                <span className="d-flex align-items-center gap-3">
                                    <img
                                        src="/images/table-accordion-arrow.svg"
                                        alt="table-accordion-arrow"
                                        className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"}`}
                                        data-bs-toggle="collapse"
                                        data-bs-target={disabled === true || isEditCheck === true ? '#goLiveConfig' : ''}
                                        onClick={() => {
                                            if (disabled === true || isEditCheck === true) {
                                                onToggle()
                                            }
                                            else if (disabled === false) {
                                                ////console.log(disabled, "KKKKKK");
                                            }
                                        }}
                                    />
                                    <span
                                        className="ac-heading cursor-pointer"
                                        data-bs-toggle="collapse"
                                        data-bs-target={disabled === true || isEditCheck === true ? '#goLiveConfig' : ''}
                                        onClick={() => {
                                            if (disabled === true || isEditCheck === true) {
                                                onToggle()
                                            }
                                            else if (disabled === false) {
                                                ////console.log(disabled, "KKKKKK");
                                            }
                                        }}
                                    >
                                        Go-Live Configuration
                                    </span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={`collapse ${isOpen ? "show" : ""}`} id="goLiveConfig" >
                            <td className="p-4">

                                {/**Sharepoint Go Live Configurations Starts here */}
                                {(applicationName == 'sharepoint') &&
                                    <>
                                        <div className="d-flex align-items-center gap-2 mt-2 pt-2">
                                            <label className="font-semibold font-18 label-color lh-1">Member Migration
                                            </label>
                                                <Tippy
                                                    delay={[500, 0]}
                                                    arrow={true}
                                                    // followCursor={'horizontal'}
                                                    // plugins={[followCursor]}
                                                    placement='top'
                                                    content="Enable toggle button for seamless migration of necessary members to the new system during the go-live process, enhancing continuity of operations."
                                                >
                                                    <img
                                                        src="/images/tooltip.svg"
                                                        alt="tooltip"
                                                        width={11}
                                                    />
                                                </Tippy>
                                            
                                        </div>
                                        <label className="font-14 mt-4 lh-1 fs-6">
                                            Do we need to proceed with member migration or not?
                                        </label>
                                        <div className="custom-switch green-switch d-flex align-items-center position-relative mt-2">
                                            <input type="checkbox" id="sendConfirmation" checked={isMemberMigration} disabled={disableActions}
                                                onChange={() => mbxHandleCheckboxChange('isMemberMigration')} />
                                            <label
                                                className="font-14 font-regular cust-role-color"
                                                htmlFor="sendConfirmation"
                                            >
                                                <span className="ms-1">{isMemberMigration ? "Yes" : "No"}</span>
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 mt-2 pt-2">
                                            <label className="font-semibold font-18 label-color lh-1">
                                                Email Configuration
                                            </label>
                                            <Tippy
                                                delay={[500, 0]}
                                                arrow={true}
                                                // followCursor={'horizontal'}
                                                // plugins={[followCursor]}
                                                placement='top'
                                                content="Enabling Email Configuration notifies the user via email about the go-live status."
                                            >
                                                <img
                                                    src="/images/tooltip.svg"
                                                    alt="tooltip"
                                                    width={11}
                                                />
                                            </Tippy>
                                        </div>
                                        <label className="font-14 mt-4 lh-1 fs-6">
                                            Would you like to enable settings to receive email communications
                                            and other notifications?
                                        </label>
                                        <div className="custom-switch green-switch d-flex align-items-center position-relative mt-2">
                                            <input type="checkbox" id="sendConfirmation" checked={sendConfirmation} disabled={disableActions}
                                                // disabled={disableActions} 
                                                onChange={() => mbxHandleCheckboxChange('sendConfirmation')} />
                                            <label
                                                className="font-14 font-regular cust-role-color"
                                                htmlFor="sendConfirmation"
                                            >
                                                <span className="ms-1">{sendConfirmation ? "Yes" : "No"}</span>
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 mt-4 pt-2">
                                            <label className="font-semibold font-18 label-color lh-1">
                                                Permissions
                                            </label>
                                            <Tippy
                                                delay={[500, 0]}
                                                arrow={true}
                                                // followCursor={'horizontal'}
                                                // plugins={[followCursor]}
                                                placement='top'
                                                content="Permissions feature configurations within the SharePoint Application will undergo migration."
                                            >
                                                <img
                                                    src="/images/tooltip.svg"
                                                    alt="tooltip"
                                                    width={11}
                                                />
                                            </Tippy>
                                        </div>
                                        <div className="mt-3 d-flex align-items-center">
                                            <input
                                                className="form-check-input cus-check-input mt-0"
                                                type="checkbox"
                                                id="Permission"
                                                checked={isUniquePermission}
                                                onChange={() => mbxHandleCheckboxChange('isUniquePermission')}
                                                disabled={disableActions}
                                            />
                                            <label htmlFor="Permission" className="lh-1 ms-2 fs-6 font-14">
                                                Migrate Unique Permissions
                                            </label>
                                        </div>
                                    </>
                                }
                                {/**share point Go Live Configurations ends here  */}
                                {/* Outlook Go Live Configurations Starts here */}
                                {(applicationName == 'outlook') &&
                                    <>
                                        <div className="d-flex align-items-center gap-2 mt-4 pt-2">
                                            <label className="font-semibold font-18 label-color lh-1">
                                                Email Configuration
                                            </label>
                                            <Tippy
                                                delay={500}
                                                arrow={true}
                                                // followCursor={'horizontal'}
                                                // plugins={[followCursor]}
                                                placement='top'
                                                content="Enabling Email Configuration notifies the user via email about the go-live status."
                                            >
                                                <img
                                                    src="/images/tooltip.svg"
                                                    alt="tooltip"
                                                    width={11}
                                                />
                                            </Tippy>
                                        </div>
                                        <label className="font-14 mt-4 lh-1 fs-6">
                                            Would you like to enable settings to receive email communications
                                            and other notifications?
                                        </label>
                                        <div className="custom-switch green-switch d-flex align-items-center position-relative mt-2">
                                            <input type="checkbox" id="sendConfirmation" checked={sendConfirmation} disabled={disableActions}
                                                // disabled={disableActions} 
                                                onChange={() => mbxHandleCheckboxChange('sendConfirmation')} />
                                            <label
                                                className="font-14 font-regular cust-role-color"
                                                htmlFor="sendConfirmation"
                                            >
                                                <span className="ms-1">{sendConfirmation ? "Yes" : "No"}</span>
                                            </label>
                                        </div>


                                        <div className="d-flex align-items-center gap-2 mt-4 pt-2">
                                            <label className="font-semibold font-18 label-color lh-1">Objects</label>
                                            <Tippy
                                                delay={500}
                                                arrow={true}
                                                // followCursor={false}
                                                // plugins={[followCursor]}
                                                placement='top'
                                                content="This configuration is common to Outlook, One Drive, Shared Mailbox and Groups"
                                            >
                                                <img
                                                    src="/images/tooltip.svg"
                                                    alt="tooltip"
                                                    width={11}
                                                />
                                            </Tippy>
                                        </div>
                                        <div className="col-md-11 mt-3">                                       
                                             <div className="row">
                                            <div className="col-md-3 label-color font-14">
                                                <input
                                                    className="form-check-input cus-check-input mt-0"
                                                    type="checkbox"
                                                    id="permissionMigration"
                                                    checked={isPermission}
                                                    onChange={() => mbxHandleCheckboxChange('isPermission')}
                                                    disabled={disableActions}
                                                />
                                                <label htmlFor="permissionMigration" className="lh-1 ms-2 fs-6 font-14">
                                                    Permission Migration
                                                </label>
                                            </div>
                                            <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                                                <input
                                                    className="form-check-input cus-check-input mt-0"
                                                    type="checkbox"
                                                    id="rules"
                                                    checked={isRules}
                                                    onChange={() => mbxHandleCheckboxChange('isRules')}
                                                    disabled={disableActions}
                                                />
                                                <label htmlFor="rules" className="lh-1 ms-2 fs-6 font-14">
                                                    Rules
                                                </label>
                                            </div>

                                            <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                                                <input
                                                    className="form-check-input cus-check-input mt-0"
                                                    type="checkbox"
                                                    id="emailForwarding"
                                                    checked={isEmailForwarding}
                                                    onChange={() => mbxHandleCheckboxChange('isEmailForwarding')}
                                                    disabled={disableActions}
                                                />
                                                <label htmlFor="emailForwarding" className="lh-1 ms-2 fs-6 font-14">
                                                    Email Forwarding
                                                </label>
                                            </div></div>

                                        </div>

                                    </>
                                }
                                {/* Outlook Go Live Configurations Ends here */}

                                {/* OneDrive Go Live Configurations Starts here */}

                                {applicationName == 'onedrive' &&
                                    <>
                                        <div className="d-flex align-items-center gap-2 mt-4 pt-2">
                                            <label className="font-semibold font-18 label-color lh-1">
                                                Email Configuration
                                            </label>
                                            <Tippy
                                                delay={500}
                                                arrow={true}
                                                // followCursor={'horizontal'}
                                                // plugins={[followCursor]}
                                                placement='top'
                                                content="Enabling Email Configuration notifies the user via email about the go-live status."
                                            >
                                                <img
                                                    src="/images/tooltip.svg"
                                                    alt="tooltip"
                                                    width={11}
                                                />
                                            </Tippy>
                                        </div>
                                        <label className="font-14 mt-4 lh-1 fs-6">
                                            Would you like to enable settings to receive email communications
                                            and other notifications?
                                        </label>
                                        <div className="custom-switch green-switch d-flex align-items-center position-relative mt-2">
                                            <input type="checkbox" id="sendConfirmation" checked={sendConfirmation} disabled={disableActions}
                                                // disabled={disableActions} 
                                                onChange={() => mbxHandleCheckboxChange('sendConfirmation')} />
                                            <label
                                                className="font-14 font-regular cust-role-color"
                                                htmlFor="sendConfirmation"
                                            >
                                                <span className="ms-1">{sendConfirmation ? "Yes" : "No"}</span>
                                            </label>
                                        </div>
                                    </>
                                }

                                {/* OneDrive Go Live Configurations Ends here */}

                                {/* Shared Mailbox, Rooms & Equipments, Groups, Teams Chat and Teams Channel Configurations starts here */}

                                {(applicationName == 'roomsandequipments' || applicationName == 'sharedmailbox' || applicationName == 'groups' || applicationName == 'teamschat' || applicationName == 'teamschannel') &&
                                    <>
                                        <div className="d-flex align-items-center gap-2 mt-2 pt-2">
                                            <label className="font-semibold font-18 label-color lh-1">
                                                Member Migration
                                            </label>
                                            <Tippy
                                                delay={500}
                                                arrow={true}
                                                // followCursor={'horizontal'}
                                                // plugins={[followCursor]}
                                                placement='top'
                                                content="Enable toggle button for seamless migration of necessary members to the new system during the go-live process, enhancing continuity of operations."
                                            >
                                                <img
                                                    src="/images/tooltip.svg"
                                                    alt="tooltip"
                                                    width={11}
                                                />
                                            </Tippy>
                                        </div>
                                        <label className="font-14 mt-4 lh-1 fs-6">
                                            Do we need to proceed with member migration or not?
                                        </label>
                                        <div className="custom-switch green-switch d-flex align-items-center position-relative mt-2">
                                            <input type="checkbox" id="sendConfirmation" checked={isMemberMigration} disabled={disableActions}
                                                onChange={() => mbxHandleCheckboxChange('isMemberMigration')} />
                                            <label
                                                className="font-14 font-regular cust-role-color"
                                                htmlFor="sendConfirmation"
                                            >
                                                <span className="ms-1">{isMemberMigration ? "Yes" : "No"}</span>
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 mt-4 pt-2">
                                            <label className="font-semibold font-18 label-color lh-1">
                                                Email Configuration
                                            </label>
                                            <Tippy
                                                delay={500}
                                                arrow={true}
                                                // followCursor={'horizontal'}
                                                // plugins={[followCursor]}
                                                placement='top'
                                                content="Enabling Email Configuration notifies the user via email about the go-live status."
                                            >
                                                <img
                                                    src="/images/tooltip.svg"
                                                    alt="tooltip"
                                                    width={11}
                                                />
                                            </Tippy>
                                        </div>
                                        <label className="font-14 mt-4 lh-1 fs-6">
                                            Would you like to enable settings to receive email communications
                                            and other notifications?
                                        </label>
                                        <div className="custom-switch green-switch d-flex align-items-center position-relative mt-2">
                                            <input type="checkbox" id="sendConfirmation" checked={sendConfirmation} disabled={disableActions}
                                                onChange={() => mbxHandleCheckboxChange('sendConfirmation')} />
                                            <label
                                                className="font-14 font-regular cust-role-color"
                                                htmlFor="sendConfirmation"
                                            >
                                                <span className="ms-1">{sendConfirmation ? "Yes" : "No"}</span>
                                            </label>
                                        </div>
                                    </>
                                }

                                {/* Shared Mailbox, Rooms & Equipments, Groups, Teams Chat and Teams Channel Configurations Ends here */}

                                {/**Gmail & Mydrive Configurations Ends here  */}

                                {(applicationName == 'gmail' || applicationName == 'mydrive') &&

                                    <>
                                        <div className="d-flex align-items-center gap-2 mt-2 pt-2">
                                            <label className="font-semibold font-18 label-color lh-1">
                                                Email Configuration
                                            </label>
                                            <Tippy
                                                delay={500}
                                                arrow={true}
                                                // followCursor={'horizontal'}
                                                // plugins={[followCursor]}
                                                placement='top'
                                                content="Enabling Email Configuration notifies the user via email about the go-live status."
                                            >
                                                <img
                                                    src="/images/tooltip.svg"
                                                    alt="tooltip"
                                                    width={11}
                                                />
                                            </Tippy>
                                        </div>
                                        <label className="font-14 mt-4 lh-1 fs-6">
                                            Would you like to enable settings to receive email communications
                                            and other notifications?
                                        </label>
                                        <div className="custom-switch green-switch d-flex align-items-center position-relative mt-2">
                                            <input type="checkbox" id="sendConfirmation" checked={sendConfirmation} disabled={disableActions}
                                                onChange={() => mbxHandleCheckboxChange('sendConfirmation')} />
                                            <label
                                                className="font-14 font-regular cust-role-color"
                                                htmlFor="sendConfirmation"
                                            >
                                                <span className="ms-1">{sendConfirmation ? "Yes" : "No"}</span>
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 mt-4 pt-2">
                                            <label className="font-semibold font-18 label-color lh-1">
                                                {applicationName == 'gmail' ? "Mailbox" : "Permissions"}
                                            </label>
                                            <Tippy
                                                delay={500}
                                                arrow={true}
                                                // followCursor={'horizontal'}
                                                // plugins={[followCursor]}
                                                placement='top'
                                                content={applicationName == 'gmail' ? "Activating Email Forwarding directs all incoming emails from the source tenant to the target tenant." : "Selecting the Permissions checkbox to Migrate the File and Folder permissions"}
                                            >
                                                <img
                                                    src="/images/tooltip.svg"
                                                    alt="tooltip"
                                                    width={11}
                                                />
                                            </Tippy>
                                        </div>
                                        {applicationName == 'gmail' &&
                                            <div className="mt-3 d-flex align-items-center">
                                                <input
                                                    className="form-check-input cus-check-input mt-0"
                                                    type="checkbox"
                                                    id="isPermission"
                                                    checked={isPermission}
                                                    onChange={() => mbxHandleCheckboxChange('isPermission')}
                                                    disabled={disableActions}
                                                />
                                                <label htmlFor="isPermission" className="lh-1 ms-2 fs-6 font-14">
                                                    Permission
                                                </label>
                                            </div>
                                        }
                                        {applicationName == 'gmail' &&
                                            <div className="mt-3 d-flex align-items-center">
                                                <input
                                                    className="form-check-input cus-check-input mt-0"
                                                    type="checkbox"
                                                    id="emailForwarding"
                                                    checked={isEmailForwarding}
                                                    onChange={() => mbxHandleCheckboxChange('isEmailForwarding')}
                                                    disabled={disableActions}
                                                />
                                                <label htmlFor="emailForwarding" className="lh-1 ms-2 fs-6 font-14">
                                                    Email Forwarding
                                                </label>
                                            </div>
                                        }
                                        {applicationName == 'gmail' &&
                                            <div className="mt-3 d-flex align-items-center">
                                                <input
                                                    className="form-check-input cus-check-input mt-0"
                                                    type="checkbox"
                                                    id="rules"
                                                    checked={isRules}
                                                    onChange={() => mbxHandleCheckboxChange('isRules')}
                                                    disabled={disableActions}
                                                />
                                                <label htmlFor="rules" className="lh-1 ms-2 fs-6 font-14">
                                                    Rules
                                                </label>
                                            </div>
                                        }
                                        {applicationName == 'mydrive' &&
                                            <div className="mt-3 d-flex align-items-center">
                                                <input
                                                    className="form-check-input cus-check-input mt-0"
                                                    type="checkbox"
                                                    id="permission"
                                                    checked={isPermission}
                                                    onChange={() => mbxHandleCheckboxChange('isPermission')}
                                                    disabled={disableActions}
                                                />
                                                <label htmlFor="permission" className="lh-1 ms-2 fs-6 font-14">
                                                    Permissions
                                                </label>
                                            </div>
                                        }
                                    </>
                                }

                                <div className="d-flex justify-content-end mt-5 pt-md-4">
                                    <button
                                        className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                                        onClick={mbxHandleSave} disabled={disableActions}
                                    // disabled={disableActions || goliveSAC === true}
                                    >
                                        Save &amp; Continue
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {loading && (
                <div className="container-fluid">
                    <div className="overlay">
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <div
                                className="spinner-border Loader loader-color align-center "
                                role="status"
                            >
                                <span>
                                    <span className="loader-inner-circle"></span>
                                </span>
                            </div>
                            <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                                Loading..
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {alert ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                {alertMessage}
                            </h5>
                            <p className="primary-data">
                                {alert}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => {
                                    if (isSessionEpired) {
                                        history.push('/Login')
                                    }
                                    setAlertMessage('')
                                    setAlert(false)
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
            {/*Failure Popup ends here */}

        </div>
    );
};

export default GoliveConfigurations;