// TaskGrid.tsx
import React, { useEffect, useState, useRef } from 'react';

import jwt from 'jwt-decode';
import moment from "moment";
import { useHistory, useLocation } from 'react-router-dom';
import { ActionInterface, Filters, SortCriteria, TaskPayloadInterface } from '../../Common/Interface';
import { decodeSessionDetails } from '../../Services/CommonServices';
import { getSession } from '../../Services/manageClientAPI';
import { deltaMigrationService, fetchTaskData, goLiveService, taskActionApi } from '../../Services/taskService';
import Loader from '../Common/Loader';

// PS_08: Import interfaces providing types such as Task, Filters, SortCriteria.
// Assuming these types are imported from a file named 'interfaces'.

import objCryptoJS from "crypto-js";

// PS_09 to PS_37: Initializing states using useState hooks

export default function TaskGridComponent() {
  // Assuming session token retrieval function
  //   const tokenFromSession = () => {
  //     // Logic to get token from session
  //     return '';
  //   };

  const history = useHistory();
  //debugger
  const location: any = useLocation().state
  //const navigate = useNavigate();
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})

  let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
  let [clientName, setclientName] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');
  let [isAdmin, setIsAdmin] = useState(false);
  let [adminName, setAdminName] = useState('');
  let [adminEemailAddress, setAdminEmailAddress] = useState('');
  let [adminrole, setAdminRole] = useState('');




  const emptyFilters: Filters = {
    status: location?.state?.status ? location?.state?.status : '',
    goLiveStatus: '',
    from: '',
    to: '',
    Modifiedfrom: '',
    Modifiedto: '',
    MigrationType: location?.state?.type ? location?.state?.type : ''
  };

  const [task, setTask] = useState(false);

  // PS_10: Initialize role state using useState
  const [role, setRole] = useState<string>('');

  // PS_11: Initialize taskPresence state using useState
  const [taskPresence, setTaskPresence] = useState<boolean>(false);

  // PS_12: Initialize connectionCount state using useState
  const [connectionCount, setConnectionCount] = useState<number>(0);

  // PS_13: Initialize cancelTaskId state using useState
  const [cancelTaskId, setCancelTaskId] = useState<number>(0);
  const [cancelTaskType, setCancelTaskType] = useState<string>('');


  // PS_14: Initialize taskType state using useState
  const [taskType, setTaskType] = useState<string>("migration");

  // PS_15: Initialize taskName state using useState
  const [taskName, settaskName] = useState<string>('');

  // PS_16: Initialize tasks state using useState
  const [tasks, setTasks] = useState<object>({});
  const [totalTaskInScreen, setTotalTaskInScreen] = useState<number>(0)
  // PS_17: Initialize searchValue state using useState
  const [searchValue, setSearchValue] = useState<string>('');

  // PS_18: Initialize statusDropdown state using useState
  const [statusDropdown, setStatusDropdown] = useState<any[]>([]); // Replace 'any' with concrete dropdown item type

  // PS_19: Initialize goStatus state using useState
  const [goStatus, setGoStatus] = useState<any[]>(['Success', 'Failure', 'In progress', 'Yet to start']); // Replace 'any' with concrete status item type

  // PS_20: Initialize count state using useState
  const [count, setCount] = useState<number>(0);

  // PS_21: Initialize assessmentCount state using useState
  // const [assessmentCount, setAssessmentCount] = useState<boolean>(false);

  // // PS_22: Initialize assessmentErr state using useState
  // const [assessmentErr, setAssessmentErr] = useState<boolean>(false);

  // PS_23: Initialize checktaskName state using useState
  const [checktaskName, setCheckTaskName] = useState<any[]>([]); // Replace 'any' with concrete type

  // PS_24: Initialize checktaskNameError state using useState
  const [checktaskNameError, setCheckTaskNameError] = useState<string>('');

  // PS_25: Initialize taskNameError state using useState
  const [taskNameError, settaskNameError] = useState<boolean>(false);

  // PS_26: Initialize noData state using useState
  const [noData, setNoData] = useState<boolean>(false);

  // PS_27: Initialize isEdit state using useState
  const [isEdit, setIsEdit] = useState<boolean>(false);
  
  // PS_30: Initialize filteredTasks state using useState
  const [filteredTasks, setFilteredTasks] = useState<Filters>(emptyFilters); // Assuming null as default, replace as needed

  // PS_33: Initialize sortCriteria state using useState
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>({ field: '', order: 'asc', key: "", }); // Replace with default field and order

  // PS_34: Initialize taskFilter state using useState
  const [taskFilter, setTaskFilter] = useState<boolean>(false);

  // PS_35: Initialize cancelFilter state using useState
  const [cancelFilter, setCancelFilter] = useState<boolean>(false);

  // PS_36: Initialize loader state using useState
  const [loader, setLoader] = useState<boolean>(false);

  // PS_37: Initialize offset state using useState
  const [offset, setOffset] = useState<number>(10);

  const [asscount, setAssessmentCount] = useState(false);
  const [errCount, setAssessmentErr] = useState(false);

  const [totalCount, setTotalCount] = useState<number>(0);

  const [alert, setAlert] = useState<string>('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  const [environmentType, setEnvironmentType] = useState<any>("");
  const [IsTenantPlanExpired, setIsTenantPlanExpired] = useState<any>();
  const [IsGSuitePlanExpired, setIsGSuitePlanExpired] = useState<any>();
  const [IsTenantPurchased, setIsTenantPurchased] = useState<any>();
  const [IsGSuitePurchased, setIsGSuitePurchased] = useState<any>();
  // //debugger
  let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);;

  // PS_38 is implicit as all initializations are already complete

  // Component's render logic and methods go here

  useEffect(() => {
    // //debugger;
    sessionTokenProperties()
    resetTimer();
    fetchData(filteredTasks, true); // Call with empty object for filter and isNew set to true

    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);
    // PS_140
    if (IsTenantPurchased && IsGSuitePurchased) {
      setEnvironmentType(""); // Reset or keep empty if both are present
    } else if (IsTenantPurchased && !IsGSuitePurchased) {
      setEnvironmentType("tenant");
    } else if (IsGSuitePurchased && !IsTenantPurchased) {
      setEnvironmentType("googleworkspace");
    }
    return () => {
      debugger
      if (InActiveTimeout.current) {
        console.log("Task Unmount Time Clear");  
        clearTimeout(InActiveTimeout.current);
      }
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, [offset, IsTenantPurchased, IsGSuitePurchased]);

  let resetTimer = async () => {
    try {
      // debugger;
      ////console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      ////console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      ////console.log("Reset Timer End");
    }
    catch (error) {
      ////console.log("Reset Timer Catch");
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoader(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      ////console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        ////console.log("Reset Session Timer Inside");
        if (InActiveTimeout.current) {
          clearTimeout(InActiveTimeout.current);
        }
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout.current = setTimeout(() => {
          ////console.log("Expired");
          sessionStorage.removeItem("c_at");
          ////console.log("removed session");
          setisSessionEpired(true)
          setAlert('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        ////console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        ////console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          ////console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        ////console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      ////console.log("Reset Session Timer Catch");
      throw ex
    }
  };

  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      // //debugger
      if (tokenResponse.clientDetails) {
        setObjClient(tokenResponse.clientDetails)
        setclientName(tokenResponse.clientDetails.Name);
        setCompanyName(tokenResponse.clientDetails.CompanyName)
        emailAddress = tokenResponse.clientDetails.Email;
        setEmailAddress(emailAddress);
        setRole(tokenResponse.clientDetails.Role);
        setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
        setIsGSuitePurchased(tokenResponse.clientDetails.IsGSuitePurchased);
        setIsTenantPurchased(tokenResponse.clientDetails.IsTenantPurchased);
      }
      else {
        history.push("/Login")
      }
      //#region only for required Modules
      if (tokenResponse.adminDetails) {
        setObjAdmin(tokenResponse.adminDetails)
        setAdminName(tokenResponse.adminDetails.Name);
        setAdminEmailAddress(tokenResponse.adminDetails.Email);
        setRole(tokenResponse.adminDetails.Role);
      }
      // else {
      //     history.push("/Admin/Login")
      // }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };

  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    //debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }

  //ps 55 to 72
  const fetchData = async (filter: any, isNew: boolean = false) => {
    try {
      // Replace any with the actual type for filter
      // PS_43
      let token = await getToken()
      setLoader(true);

      // PS_44: Assuming role and token are part of the component's state and are set correctly earlier
      let payload: TaskPayloadInterface = {
        token, // assuming this is from component state or props
        searchValue: searchValue, // assuming component state
        filter,
        offset // assuming component state
      };
      payload.filter.status = (payload?.filter?.status == "In Progress") ? "InProgress" : payload.filter.status
      // PS_45 and PS_46
      debugger
      let response: any = await fetchTaskData(payload);
      //////console.log(response.data.data?.taskDetails)
      response = response.data
      ////console.log("------", response, "  data we need to check")
      // PS_55 to PS_71: Handle response based on statusCode and isNew values
      if (response.statusCode === 200) {

        if (response.statusMessage === 'No content') { // PS_55 to PS_59
          ////console.log("no data")
          setTasks([]);
          setCount(0);
          setTotalTaskInScreen(0)
          setLoader(false);
          setNoData(true);
          setIsEdit(false);

        } else { // PS_60 to PS_71: Include necessary data checks
          //response = response.data
          ////console.log(response, "rrrr")
          if (isNew) { // PS_63 to PS_67
            setTasks(response.data?.taskDetails || []);
            setCount(response.data?.taskCount || 0);
            setConnectionCount(response.data?.connectionCount || 0);
            setNoData(false);
            let totalTask = 0;
            // ////console.log(Object.keys(a));
            for (const key of Object.keys(response?.data?.taskDetails)) {
              for (const task of response?.data?.taskDetails[key]) {
                totalTask += task.length;
              }
            }
            ////console.log(totalTask);
            setTotalTaskInScreen(totalTask)
            // Assume there are other states to set based on response.data
          } else { // PS_68 to PS_71
            let existingTask = tasks;
            existingTask = { ...existingTask, ...response.data.taskDetails };
            setTasks(existingTask); setCount(response.data?.taskCount || 0);
            setConnectionCount(response.data?.connectionCount || 0);
            setNoData(false);
            // And so on for other relevant states
          }

        }

      } else if (response.statusCode === 400) { // PS_60
        setAlert(response.statusMessage); // PS_60: Replace with proper error handling
        setNoData(true); // PS_61
        if(response.statusMessage == "User token is invalid"){
          setisSessionEpired(true);
        }
      }
      let arr: string[] = [];
      response.data?.taskNames?.map((value: any) => {
        //////console.log(value.taskName.toLowerCase(), "sandy");
        arr.push(value.taskName.toLowerCase());
      });
      setCheckTaskName(arr);

      setTotalCount(response.data?.taskNames.length)

      let  statuses = response.data?.categorys
        ?.map((item: any) => item.Category)
        .sort((a: string, b: string) => a.localeCompare(b)); // Sort in ascending order

      statuses = statuses.map((item:string) => item?.toLowerCase()?.trim() === 'inprogress' ? 'In Progress' : item);
      setStatusDropdown(statuses);

      setLoader(false);
    } catch (error) {
      // PS_60: Proper error handling here
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.'); // PS_60: Replace with proper error handling
      setLoader(false);
    }
  };
  //PS_118
  const onSearch = async () => {
    const result = await fetchData(emptyFilters, true);
    return result;
  };
  //PS_115 to 116
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };
  //PS_120 to 127
  const handleFilter = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { value, name } = event.target;

    const updatedFilter: any = { ...filteredTasks };

    updatedFilter[name] = value;

    setFilteredTasks((prev: any) => ({
      ...prev,
      [name]: value
    }));
  };
  //PS_125 to 126
  const handleApplyFilter = async () => {
    setTaskFilter(false);
    fetchData(filteredTasks, true);
  };
  //PS_128 to 139
  const handleSort = (
    field: string,
    currentOrder: "asc" | "desc",
    connectionName: string
  ) => {

    if (field == 'type') {
      // Preserve the existing order when values are equal
      return 0;
    }

    const order = currentOrder === "asc" ? "desc" : "asc";

    // Deep copy the tasks object
    const sortedData = JSON.parse(JSON.stringify(tasks));

    // Find the correct array of tasks based on connectionName
    const tasksArray = sortedData[connectionName][0];

    ////console.log(tasksArray, "tasksArray");

    // Sort the tasks array
    tasksArray.sort((a: any, b: any) => {
      const fieldValueA = typeof a[field] === "string" ? a[field].toLowerCase() : a[field];
      const fieldValueB = typeof b[field] === "string" ? b[field].toLowerCase() : b[field];

      //   if (fieldValueA === fieldValueB) {
      //     // Preserve the existing order when values are equal
      //     return 0;
      //   }

      if (order === "asc") {
        ////console.log(fieldValueA, "kdkdkd");
        return fieldValueA > fieldValueB ? 1 : -1;
      } else {
        return fieldValueA < fieldValueB ? 1 : -1;
      }
    });

    // Update the original tasks object with the sorted data
    const updatedTasks = {
      ...tasks,
      [connectionName]: [tasksArray]
    };

    // Update state with sort criteria
    setSortCriteria({ field, order, key: connectionName });
    // Set tasks state with original tasks object
    setTasks(updatedTasks);
  };

  const getStatusCSS = (status: string) => {
    switch (status) {
      case "failed":
        return "danger";
      case "queued":
        return "draft";
      case "draft":
        return "draft";
      case "cancelled":
        return "danger";
      case "scheduled":
        return "scheduled";
      case "inprogress":
        return "inprogress";
      case "in progress":
        return "warning";
      case "completed":
        return "success";
      case "paused":
        return "paused";
      default:
        return "draft";
    }
  };

  //PS_81 to 93
  const getLastVisited = (task: any) => {
    setLoader(true); // PS_81

    if (task?.applicationName?.includes(', ')) {
      task.applicationName = task?.applicationName?.split(', ');
    }

    const page = task.lastVisited; // PS_82
    const taskType = task?.taskType; // PS_83
    // //console.log(page, "pageeeeeeeeeeeeeeeeee");
    if (page?.toLowerCase().includes("generalconfigurations")) { // New condition
      history.push('/Configurations', {
        state: {
          taskId: task.taskId,
          taskName: task.taskName,
          taskType: task.taskType,
          applicationName: task.applicationName,
          stage: 4,
          isEdit: true,
          lastVisited: task.lastVisited,
          lastVisitedStage: task.lastVisitedStage,
          environment: task.environmentType,
          isDirectMigration: task?.isDirectMigration,
          MigrationMode: task?.MigrationMode
        }
      });
      setLoader(false); // PS_93
      return; // Exit the function early
    }
    if (taskType?.toLowerCase() === "assessment") { // PS_84
      switch (page?.toLowerCase()) {
        case "assessmentconfigurations": // PS_85
          history.push("/assessmentConfigurations", {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 2,
              isEdit: true,
              lastVisited: task.lastVisited,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            },
          });

          break;
        case "assessment": // PS_86
          history.push('/Assessment', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 3,
              isEdit: true,
              lastVisited: task.lastVisited,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            },
          });
          break;
        case "summary": // PS_87
          history.push('/Summary', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            },
          });
          break;
        case "inprogress": // PS_87
          history.push('/Inprogress', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              // selectedEntity: task.selectedEntity,
              lastVisited: task.lastVisited,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            },
          });
          break;
        default: // PS_88
          history.push('/assessmentConfigurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 0,
              isEdit: true,
              lastVisited: task.lastVisited,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            },
          });
          break;
      }
    } else { // PS_89
      switch (page?.toLowerCase()) {
        case "configurations": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited,
              lastVisitedStage: task.lastVisitedStage,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            }
          });
          break;
        case "configurations": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited,
              lastVisitedStage: task.lastVisitedStage,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            }
          });
          break;
        case "usermapping": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited,
              lastVisitedStage: task.lastVisitedStage,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            }
          });
          break;
        case "appspecificconfigurations": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited,
              lastVisitedStage: task.lastVisitedStage,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            }
          });
          break;
        case "goliveconfigurations": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited,
              lastVisitedStage: task.lastVisitedStage,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            }
          });
          break;
        case "psserviceconfigurations": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited,
              lastVisitedStage: task.lastVisitedStage,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            }
          });
          break;
        case "selectmigration": // PS_91
          history.push('/migration', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited,
              lastVisitedStage: task.lastVisitedStage,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            }
          });
          break;
        default: // PS_92
          history.push('/configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              environment: task.environmentType,
              isDirectMigration: task?.isDirectMigration,
              MigrationMode: task?.MigrationMode
            }
          });
          break;
      }
    }
    setLoader(false); // PS_93
  };



  //PS_103 to 112
  const userTaskAction = async (taskId: number, status: string) => {
    const token = await getToken()
    try {
      setLoader(true);
      const payload: ActionInterface = {
        token: token,
        taskId: taskId,
        status: status,
      };

      const data: any = await taskActionApi(payload);
      ////console.log(data, "action res")
      if (data.data.statusCode == 200) {
        ////console.log("task cancelled successfully");
        fetchData(filteredTasks, true);
      } else {
        ////console.log("chhh", data.data)
        setAlert(data.data.statusMessage);

        setLoader(false);
      }
    } catch (error) {
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.'); // PS_60: Replace with proper error handling
      setLoader(false);
    }

  };

  //PS_75 to 78
  const deltamigration = async (task: any) => {
    try {
      setLoader(true); // Set loader before API operation
      let token = await getToken()
      const payload: any = {
        token: token,
        taskId: task.taskId,
        applicationName: task.appName
      };
      const response: any = await deltaMigrationService(payload);
      if (response.data.statusCode == 200) {
        setLoader(true);
        fetchData(filteredTasks, true);
      } 
      else {
        setAlert(response.data.statusMessage);
        setLoader(false);
      }
    } catch (error) {
      console.error('Error during delta migration:', error); // Handle error appropriately
    } finally {
      // Set the loader state to false (PS_78)
      setLoader(false);
    }
  };

  //PS_159 to PS_171
  async function goLive(task: any) {
    try {
      setLoader(true)
      let token = await getToken()
      debugger
      //console.log("Inside Go live function");
      //console.log(task);
      let payload
      switch (task.appName) {
        case "outlook":
          payload = {
            token: token,
            taskId: task.taskId,
            environmentType:'Tenant'
          }
          break
        case "onedrive":
        case "outlook,onedrive":
          payload = {
            token: token,
            taskId: task.taskId,
            dbName: "",
            manageClientId: "",
            userMigrationMappingId: "",
            applicationName: task.appName
          }
          break
        default:
          payload = {
            token: token,
            taskId: task.taskId
          }
      }
      
      let response:any = await goLiveService(payload, task.appName)
      if(response.data.statusCode==200){
        setAlert(response.data.statusMessage);
        let updatepayload: ActionInterface = {
          token: token,
          taskId: task.taskId,
          status: 'InProgress',
          column: 'goLiveStatus'
        };
        await taskActionApi(updatepayload)        
      }
      else{
        setAlert(response.data.statusMessage);
      }      
      fetchData(filteredTasks, true)
    } catch (error) {
        setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');       
      console.error('Error during Go Live migration:', error); // Handle error appropriately
    }
    finally {
      setLoader(false)
    }
  }

  return (
    <div className="container-fluid container-height">
      <div className="card px-4 py-4 mt-2 mb-2 border-0 rounded-3 container-fluid container-height">
        <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
          <h5 className="font-20 font-semibold mb-0">Task ({location?.state?.count?location?.state?.count:count})</h5>
          <div className="d-md-flex d-block  gap-3 w-md-100">
            <div className="d-flex justify-content-end justify-content-between gap-3 mb-3 mb-md-0">
            <div className="input-group input-search">
              <input
                type="search"
                className="form-control custom-form font-14 font-regular"
                placeholder="Search"
                name="search"
                onKeyDown={(event) => {
                  if (event.key == "Enter") {
                    fetchData(filteredTasks, true);
                  }
                }}
                onChange={handleSearch}
              />
              <button
                className="btn border d-flex align-items-center"
                type="button"
                onClick={onSearch}
              >
                <img src="/images/search.svg" alt="search" />
              </button>
            </div>
            <div className="d-flex gap-3 flex-row-reverse flex-md-row ms-2">
              <div className="dropdown">
                <button
                  className="btn icon-swap secondary-outline-btn"
                  onClick={() => {
                    setTaskFilter(true);
                  }}
                >
                  <img
                    src="/images/filter-active-icon.svg"
                    alt="filter-active-icon"
                  />
                  <img src="/images/filter-icon.svg" alt="filter-icon" />
                </button>

                {cancelFilter ? (
                  <div
                    className="modal fade show"
                    style={{
                      display: "block",
                      backgroundColor: "rgba(0,0,0,0.6)",
                    }}
                    id="Alert-popup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="Alert-popupLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                          <span className="position-absolute">
                            <img
                              src="/images/failure-popup-icon.svg"
                              alt="Alert-popup-icon"
                            />
                          </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                          <h5
                            className="modal-title mb-3 primary-header"
                            id="Alert-popupLabel"
                          >
                            Alert
                          </h5>
                          {
                            cancelTaskType === 'migration' ?
                              <p className="primary-data font-16">
                                The progress initiated in migration will not be
                                saved. Are you sure you want to cancel?
                              </p> :
                              <p className="primary-data font-16">
                                The progress initiated in assessment will not be
                                saved. Are you sure you want to cancel?
                              </p>
                          }
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                          <button
                            onClick={() => {
                              setCancelFilter(false);
                            }}
                            type="button"
                            className="btn secondary-btn font-14 px-4 font-semibold me-2"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => {
                              setCancelFilter(false);
                              userTaskAction(cancelTaskId, 'Cancelled');
                            }}
                            type="button"
                            className="btn primary-btn font-14 px-4 font-semibold"
                            data-bs-dismiss="modal"
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/*filter*/}
                {taskFilter ? (
                  <div
                    className="dropdown-menu dropdown-menu-end custom-filter-width p-3 end-0 show"
                    style={{ display: "block" }}
                  >
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h5 className="font-bold font-20 mb-0 custom-header-color">
                        Advanced Filter
                      </h5>
                      <span>
                        <img
                          className="cursor-pointer"
                          src="/images/close-icon.svg"
                          alt="close"
                          onClick={() => {
                            setFilteredTasks(emptyFilters);
                            setTaskFilter(false);
                          }}
                        />
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color "
                          htmlFor="status"
                        >
                          Status
                        </label>
                        <select
                          className="form-select custom-form"
                          aria-label="Default select example"
                          id="status"
                          name="status"
                          value={filteredTasks?.status}
                          onChange={handleFilter}
                        >
                          <option value="">Select</option>
                          {statusDropdown.map((data, index) => (
                            <option value={data} key={index}>
                              {data}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color "
                          htmlFor="Go-Live-Status"
                        >
                          Go-Live Status
                        </label>
                        <select
                          className="form-select custom-form"
                          aria-label="Default select example"
                          id="goLiveStatus"
                          name="goLiveStatus"
                          aria-disabled={true}
                          onChange={handleFilter}
                          value={filteredTasks?.goLiveStatus}
                        >
                          <option value="">Select</option>
                          {goStatus.map((data, index) => (
                            <option value={data} key={index}>
                              {data}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="font-16 font-semibold custom-header-color">
                          Created Date
                        </label>
                      </div>
                      <div className="col-md-6 position-relative mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color"
                          htmlFor="from"
                        >
                          From
                        </label>
                        <input
                          className="form-control custom-form"
                          type="date"
                          id="from"
                          name="from"
                          value={filteredTasks.from}
                          placeholder="MM/DD/YYYY"
                          onChange={handleFilter}
                          max={filteredTasks.to || new Date().toISOString().split('T')[0]}
                        />
                        <img
                          src="/images/date-icon.svg"
                          alt="date-icon"
                          className="position-absolute custom-input-icon"
                        />
                      </div>
                      <div className="col-md-6 position-relative mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color"
                          htmlFor="to"
                        >
                          To
                        </label>
                        <input
                          className="form-control custom-form"
                          type="date"
                          id="to"
                          name="to"
                          value={filteredTasks?.to}
                          placeholder="MM/DD/YYYY"
                          onChange={handleFilter}
                          min={filteredTasks?.from}
                          max={new Date().toISOString().split('T')[0]}
                        />
                        <img
                          src="/images/date-icon.svg"
                          alt="date-icon"
                          className="position-absolute custom-input-icon"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="font-16 font-semibold custom-header-color">
                          Modified Date
                        </label>
                      </div>
                      <div className="col-md-6 position-relative">
                        <label
                          className="form-label font-14 font-semibold label-color"
                          htmlFor="Modifiedfrom"
                        >
                          From
                        </label>
                        <input
                          className="form-control custom-form"
                          type="date"
                          id="Modifiedfrom"
                          name="Modifiedfrom"
                          value={filteredTasks?.Modifiedfrom}
                          placeholder="MM/DD/YYYY"
                          onChange={handleFilter}
                          max={filteredTasks?.Modifiedto || new Date().toISOString().split('T')[0]}
                        />
                        <img
                          src="/images/date-icon.svg"
                          alt="date-icon"
                          className="position-absolute custom-input-icon"
                        />
                      </div>
                      <div className="col-md-6 position-relative">
                        <label
                          className="form-label font-14 font-semibold label-color"
                          htmlFor="Modifiedto"
                        >
                          To
                        </label>
                        <input
                          className="form-control custom-form"
                          type="date"
                          id="Modifiedto"
                          name="Modifiedto"
                          value={filteredTasks?.Modifiedto}
                          placeholder="MM/DD/YYYY"
                          onChange={handleFilter}
                          min={filteredTasks?.Modifiedfrom}
                          max={filteredTasks?.Modifiedto || new Date().toISOString().split('T')[0]}
                        />
                        <img
                          src="/images/date-icon.svg"
                          alt="date-icon"
                          className="position-absolute custom-input-icon"
                        />
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex justify-content-end align-items-center py-3">
                          <button
                            className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4"
                            onClick={() => {
                              const clientid = sessionStorage.getItem("m_c_i");
                              fetchData(emptyFilters);
                              setFilteredTasks(emptyFilters);
                            }}
                          >
                            Clear
                          </button>
                          <button
                            disabled={filteredTasks
                              ? Object.values(filteredTasks).every(value => (value?.trim() ?? "") === "")
                              : true}
                            className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                            onClick={handleApplyFilter}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/*filter*/}
              </div>

            </div>
            </div>
            
            {(role?.toLowerCase() !== "viewer") && (
              <button
                className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                onClick={() => {
                  setTask(true);
                }}
              >
                <img src="/images/add-plus-icon.svg" alt="add-plus-icon" />
                New Task
              </button>
            )}

            {task ? (
              <div
                className="modal fade show"
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content p-4">
                    <div className="modal-header border-0 p-0 cursor-pointer">
                      <h1
                        className="font-20 font-bold custom-header-color m-0"
                        id="newTaskLabel"
                      >
                        New Task
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setTask(false);
                          settaskName("");
                          setTaskType("migration");
                          settaskNameError(false)
                        }}
                      />
                    </div>
                    <div className="modal-body border-0 p-0 pt-2 pb-5">
                      <div className="row">
                        {IsTenantPurchased && IsGSuitePurchased ? (
                          <div className="col-md-12">
                            <label className="font-14 font-semibold label-color w-100 mb-2 pb-1 mt-3">
                              Application <span className="mandatory-color">*</span>
                            </label>

                            <select
                              className="form-select custom-form"
                              defaultValue="select"
                              onChange={(e) => {
                                const selectedOption = e.target.value;
                                if (selectedOption === "Tenant") {
                                  setEnvironmentType("tenant");
                                } else if (selectedOption === "G-Workspace") {
                                  setEnvironmentType("googleworkspace");
                                } else {
                                  setEnvironmentType(""); // Reset environmentType if "Select" is chosen
                                }
                              }}
                            >
                              <option value="select" disabled>Select</option>
                              <option value="Tenant">Tenant</option>
                              <option value="G-Workspace">Google Workspace</option>
                            </select>
                          </div>
                        ) : null}
                        <div className="col-md-12">
                          <label className="font-14 font-semibold label-color w-100 mb-2 pb-1 mt-3">
                            Task Type <span className="mandatory-color">*</span>
                          </label>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input custom-radio-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inactive"
                              onClick={() => {
                                setAssessmentErr(false);
                                setTaskType("migration");
                              }}
                              //disabled={count}
                              checked={taskType == "migration"}
                            />
                            <label
                              className="form-check-label font-14 font-regular"
                              htmlFor="inactive"
                            >
                              Migration
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input custom-radio-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="active"
                              onClick={() => {
                                asscount
                                  ? setAssessmentErr(true)
                                  : setAssessmentErr(false);
                                setTaskType("assessment");
                              }}
                              checked={taskType == "assessment"}
                            />
                            <label
                              className="form-check-label font-14 font-regular"
                              htmlFor="active"
                            >
                              Assessment
                            </label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <label
                            className="font-14 font-semibold label-color w-100 my-2 pt-3"
                            htmlFor="taskName"
                          >
                            Task Name <span className="mandatory-color">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control custom-form font-14 font-regular"
                            id="taskName"
                            placeholder="Enter Task Name"
                            value={taskName}
                            aria-disabled={errCount}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (inputValue.length <= 80) {
                                settaskName(inputValue);
                              } else {
                                // If the input exceeds 255 characters, truncate it
                                settaskName(inputValue.slice(0, 80));
                              }
                            }}
                          />
                          {taskNameError == true ? <span style={{ color: 'red', fontSize: '13px' }}>{checktaskNameError}</span> : <span></span>}
                        </div>
                      </div>
                      {errCount && <span className="mandatory-color">Please update your plan to take the assessment</span>}

                    </div>

                    <div className="modal-footer border-0 p-0">
                      <button
                        type="button"
                        style={{ padding: '5px 22px' }}
                        className="btn secondary-btn"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          settaskName("");
                          setTaskType("migration");
                          setTask(false);
                          settaskNameError(false)
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        type="button"
                        className="btn primary-btn"
                        disabled={environmentType.trim().length === 0 || taskName.trim().length === 0}
                        aria-disabled={environmentType.trim() === "" || taskType === "assessment" ? errCount : taskName?.length <= 0}
                        onClick={() => {
                          const lowerCasetaskName = taskName.toLowerCase();

                          if (regex.test(taskName) || taskName.trim() === "") {
                            settaskNameError(true);
                            setCheckTaskNameError("Provide valid task name")
                          }
                          else if (checktaskName.includes(lowerCasetaskName)) {
                            settaskNameError(true);
                            setCheckTaskNameError("Task Name Already Exist")
                          }
                          else {
                            settaskNameError(false);

                            history.push("/SelectApps", {
                              state: {
                                taskName: taskName,
                                taskType: taskType,
                                environment: environmentType,
                                pageType: 'selectapps',
                              },
                            });
                          }

                        }}

                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {Object.entries(tasks)
          .filter(([title, tasksList]) => tasksList[0]?.length > 0)
          .map(([title, tasksList], index) => (
            <div
              key={title}
              className="theme-table bg-white mb-3 table-responsive"
            >
              <table className="table table-borderless table-withborder mb-0">
                <thead>
                  <tr>
                    <th className="text-nowrap table-accordion-head">
                      <span className="d-flex align-items-center gap-3 ">
                        <img
                          src="/images/table-accordion-arrow.svg"
                          alt="table-accordion-arrow"
                          className="cursor-pointer table-accordion-arrow collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target={`#accordion-${index}`} // Unique id for each accordion
                        />
                        <span className="ac-heading custom-table-header-width text-truncate d-inline-block">{title}</span>{" "}
                      </span>
                    </th>
                    <th className="text-nowrap">
                      # Task :{" "}
                      <span className="font-regular">{tasksList[0]?.length}</span>
                    </th>
                    <th className="text-nowrap text-end">Application:
                      {tasksList[0][0]?.environmentType?.toLowerCase() === 'tenant' ?
                        <img src="/images/tenant.svg" alt="microsoft teams logo" className="ms-1" /> :
                        <img src="/images/tenant.svg" alt="slack logo" className="ms-2" />
                      }

                      {tasksList[0][0]?.taskType?.toLowerCase() === 'migration' ?
                        <>
                          <img src="/images/arrow-left.svg" alt="left arrow" className="ms-1" />
                          <img
                            src="/images/tenant.svg"
                            alt="microsoft teams logo"
                            className="ms-1"
                          />
                        </>
                        : <></>}

                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="collapse" id={`accordion-${index}`}>
                    {" "}
                    {/* Unique id for each accordion */}
                    <td colSpan={3}>
                      <span className="theme-table bg-white d-inline-block w-100 ">
                        <table className="table table-borderless table-withborder mb-0">
                          <thead>
                            <tr>
                              <th
                                className="text-nowrap"
                                onClick={() => handleSort("taskName", sortCriteria.order, title)}
                              >
                                Task Name
                                <span className="ms-1 cursor-pointer">
                                  <img
                                    src={`/images/sort-arrow-${title === sortCriteria.key &&
                                      sortCriteria.field === "taskName"
                                      ? sortCriteria.order === "asc"
                                        ? "up"
                                        : "down"
                                      : "down"
                                      }.svg`}
                                    alt="sort-arrow"
                                  />
                                </span>
                              </th>

                              <th
                                className="text-nowrap"
                                onClick={() =>
                                  handleSort("type", sortCriteria.order, title)
                                }
                              >
                                Type

                              </th>
                              {tasksList[0][0]?.taskType?.toLowerCase() ===
                                "migration" && (
                                  <th
                                    className="text-nowrap"
                                    onClick={() =>
                                      handleSort(
                                        "application",
                                        sortCriteria.order,
                                        title
                                      )
                                    }
                                  >
                                    Application
                                    <span className="ms-1 cursor-pointer">
                                      <img
                                        src={`/images/sort-arrow-${title == sortCriteria.key &&
                                          sortCriteria.field === "application"
                                          ? sortCriteria.order === "asc"
                                            ? "up"
                                            : "down"
                                          : "down"
                                          }.svg`}
                                        alt="sort-arrow"
                                      />
                                    </span>
                                  </th>
                                )}

                              <th
                                className="text-nowrap"
                                onClick={() =>
                                  handleSort(
                                    "createdDate",
                                    sortCriteria.order,
                                    title
                                  )
                                }
                              >
                                Created Date
                                <span className="ms-1 cursor-pointer">
                                  <img
                                    src={`/images/sort-arrow-${title == sortCriteria.key &&
                                      sortCriteria.field === "createdDate"
                                      ? sortCriteria.order === "asc"
                                        ? "up"
                                        : "down"
                                      : "down"
                                      }.svg`}
                                    alt="sort-arrow"
                                  />
                                </span>
                              </th>
                              <th
                                className="text-nowrap"
                                onClick={() =>
                                  handleSort(
                                    "modifiedDate",
                                    sortCriteria.order,
                                    title
                                  )
                                }
                              >
                                Modified Date
                                <span className="ms-1 cursor-pointer">
                                  <img
                                    src={`/images/sort-arrow-${title == sortCriteria.key &&
                                      sortCriteria.field === "modifiedDate"
                                      ? sortCriteria.order === "asc"
                                        ? "up"
                                        : "down"
                                      : "down"
                                      }.svg`}
                                    alt="sort-arrow-down"
                                  />
                                </span>
                              </th>
                              <th
                                className="text-nowrap"
                                onClick={() =>
                                  handleSort(
                                    "status",
                                    sortCriteria.order,
                                    title
                                  )
                                }
                              >
                                Status
                                <span className="ms-1 cursor-pointer">
                                  <img
                                    src={`/images/sort-arrow-${title == sortCriteria.key &&
                                      sortCriteria.field === "status"
                                      ? sortCriteria.order === "asc"
                                        ? "up"
                                        : "down"
                                      : "down"
                                      }.svg`}
                                    alt="sort-arrow-down"
                                  />
                                </span>
                              </th>

                              {(tasksList[0][0]?.taskType?.toLowerCase() !==
                                "assessment") &&
                                <th className="text-nowrap">
                                  Go-Live Status

                                </th>}


                              <th className="text-nowrap text-center">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {tasksList[0].map(
                              (task: any, index: any) =>
                                task && (
                                  <tr key={index}>
                                    <td >
                                      {task.status.toLowerCase() == 'draft' ?
                                        <p
                                          className=" task-name font-semibold"
                                        >
                                          {(task.taskName?.length > 21) ? (

                                            <span
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="right"
                                              data-bs-title="Type Team Site"
                                              data-bs-original-title=""
                                              title={task.taskName}
                                            >
                                              {task.taskName?.slice(0, 21) + '...'}
                                            </span>

                                          ) : task.taskName}
                                        </p> :
                                        <span className=" task-name font-semibold">
                                          <a
                                          href=""
                                          onClick={() => {
                                            if (
                                              task?.taskType?.toLowerCase() ==
                                              "assessment"
                                            ) {
                                              history.push("/Viewassessment", {
                                                state: { taskId: task.taskId, taskName: task.taskName },
                                              });
                                            }
                                            if (
                                              task?.taskType?.toLowerCase() ==
                                              "migration"
                                            ) {
                                              history.push("/ViewMigration", {
                                                state: {
                                                  taskId: task.taskId,
                                                  taskName: task.taskName,
                                                  applicationName: task.applicationName

                                                },
                                              });
                                              ////console.log(task.taskId);
                                            }

                                          }}
                                          className="font-semibold"
                                        >
                                          {(task.taskName?.length > 21) ? (

                                            <span
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="right"
                                              data-bs-title="Type Team Site"
                                              data-bs-original-title=""
                                              title={task.taskName}
                                            >
                                              {task.taskName?.slice(0, 21) + '...'}
                                            </span>

                                            ) : task.taskName}
                                        </a>
                                        </span>
                                        
                                      }

                                    </td>
                                    <td>{task.taskType.charAt(0).toUpperCase() + task.taskType.slice(1)}</td>
                                    {task?.taskType?.toLowerCase() ==
                                      "migration" && (
                                        <td>{task.applicationName === 'outlook' ? 'Entity' : task.applicationName}</td>
                                      )}
                                    <td>
                                      {moment(task.createdDate).format(
                                        "MM/DD/YYYY hh:mm"
                                      )}
                                    </td>
                                    <td>
                                      {task.modifiedDate == null ? (
                                        <span className="align-items-center">
                                          -
                                        </span>
                                      ) : (
                                        moment(task.modifiedDate).format(
                                          "MM/DD/YYYY hh:mm"
                                        )
                                      )}
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      <span className="text-nowrap">
                                        <span
                                          className={`table-status ${getStatusCSS(
                                            task.status.toLowerCase()
                                          )} d-inline-block me-2 `}
                                        ></span>
                                        {task?.status?.toLowerCase()?.includes('progress') ? 'Inprogress' : task?.status}
                                      </span>
                                    </td>

                                    {(task?.taskType?.toLowerCase() !==
                                      "assessment") &&
                                      <td>
                                        <span className="d-flex align-items-center gap-4 thread">
                                          <span
                                            className="table-status success completed d-inline-block me-2"
                                            data-bs-toggle="tooltip"
                                            title="Member migration"
                                          />
                                          <span
                                          className={task?.goLiveStatus?.toLowerCase()=='completed'? `table-status success completed d-inline-block me-2`:`table-status danger  d-inline-block me-2`}
                                          data-bs-toggle="tooltip"
                                          title="Member migration"
                                          />
                                        </span>
                                      </td>
                                    }

                                    <td className="text-center" >

                                     {/* Task's Action Dropdown starts here  */}
                                    <div className="btn-group dropup">
                                      <button type="button" className="border-0 p-0 bg-transparent" data-bs-toggle="dropdown" aria-expanded="true">
                                        <img src="/images/keb-menu-icon.svg" alt="keb-menu-icon" className="p-2 action-icon rounded-3 show" aria-expanded="true"/>
                                      </button>
                                      
                                      <ul className="dropdown-menu action-drop-down font-regular font-14 p-1 " style={{position:'absolute',inset:'auto auto 0px 0px', margin:'0px', transform:'translate(-129px, -21.8px)'}} data-popper-placement="top-end">
                                      {(task.status.replace(/\s+/g, '').toLowerCase() == 'inprogress' || task.status.toLowerCase() == 'draft' || task.status.toLowerCase() == 'paused' || task.status.toLowerCase() == 'warning' ||task.status.toLowerCase() == 'failed') &&
                                      <li className="d-flex list">
                                          <span 
                                            aria-disabled={task.status !== "draft" && task.status.replace(/\s+/g, '').toLowerCase() !== "inprogress" && task.status.toLowerCase() !== "failed"} 
                                            className="d-flex gap-2 px-3 py-2" 
                                            style={{border:'none'}}  
                                            onClick={() => { getLastVisited(task)}}>
                                            <img src="/images/edit-icon.svg" alt="Edit"/>Edit
                                          </span>
                                      </li>}
                                        
                                      {task?.taskType?.toLowerCase() !== "assessment"?
                                        task?.status?.toLowerCase() == "completed" && task?.goLiveStatus?.toLowerCase() == 'draft'?
                                        
                                        <li className="d-flex gap-2 px-3 py-2 list" 
                                            aria-disabled={false}
                                            style={{cursor: "default", backgroundColor: "#F2F2EE"}} 
                                            onClick={() => {
                                                goLive(task); 
                                            }}>
                                                <span className="d-flex gap-2 text-nowrap" aria-disabled={false}>
                                                  <img src="/images/go-live-action-icon.svg"/>Go-Live
                                                </span>
                                        </li>:

                                        <li className="d-flex gap-2 px-3 py-2 list" 
                                            aria-disabled={true}
                                            style={{cursor:'not-allowed'}}>
                                                <span className="d-flex gap-2" aria-disabled={true}>
                                                  <img src="/images/go-live-action-icon.svg"/>Go-Live
                                                </span>
                                        </li>
                                        :
                                        <></>}
                                        
                                        
                                        {task?.taskType?.toLowerCase() !== "assessment" ?
                                          task.status.toLowerCase() == "completed" && role !== 'viewer'?
                                            <li 
                                              className="d-flex gap-2 px-3 py-2 list" 
                                              aria-disabled={false}
                                              onClick={() => { 
                                                  deltamigration(task)
                                                }}>
                                          <span className="d-flex gap-2  text-nowrap">
                                            <img src="/images/delta-mig-icon.svg"/>Delta migration
                                          </span>
                                        </li>
                                        :
                                        <li 
                                          className="d-flex gap-2 px-3 py-2 list" 
                                          aria-disabled={true}
                                          style={{cursor:'not-allowed'}}
                                        >
                                          <span className="d-flex gap-2  text-nowrap">
                                            <img src="/images/delta-mig-icon.svg"/>Delta migration
                                          </span>
                                        </li>
                                        :<></>}

                                        {
                                          (task?.taskType?.toLowerCase() ==
                                          "assessment" && (task?.status?.toLowerCase() == "paused" || task.status.replace(/\s+/g, '').toLowerCase() == "inprogress")) &&
                                          <>
                                          {task.status.replace(/\s+/g, '').toLowerCase() == "inprogress" ? 
                                          <li 
                                            className="d-flex gap-2 px-3 py-2 list" 
                                            aria-disabled="true" 
                                            onClick={() => {
                                              if (role.toLowerCase() !== "viewer")
                                                userTaskAction(task.taskId, 'Paused')
                                            }
                                            }>
                                          <span className="d-flex gap-2  text-nowrap" 
                                            style={
                                              role.toLowerCase() !== "viewer" ? 
                                              { border: "none" } : {
                                                    border: "none",
                                                    cursor: "default",
                                                    backgroundColor: "#F2F2EE"
                                                  }}>
                                            <img src="/images/pause-icon.svg"/>Pause
                                          </span>
                                        </li>:
                                        
                                        <li 
                                          className="d-flex gap-2 px-3 py-2 list" 
                                          aria-disabled="true" 
                                          onClick={() => {
                                            if (role.toLowerCase() !== "viewer")
                                              userTaskAction(task.taskId, 'Inprogress');
                                          }
                                          }> 
                                        
                                        <span className="d-flex gap-2  text-nowrap" 
                                          style={
                                            role.toLowerCase() !== "viewer" ? 
                                            { border: "none" } : {
                                                border: "none",
                                                cursor: "default",
                                                backgroundColor: "#F2F2EE"
                                              }}>
                                            <img src="/images/pause-icon-2.svg" alt='in-progress'/>Resume
                                        </span>
                                      </li>
                                        }
                                          </>
                                        }
                                        
                                        {
                                          !(task.status.replace(/\s+/g, '').toLowerCase() == "inprogress" || task.status.toLowerCase() == "paused") || role.toLowerCase() == "viewer" ?
                                          <></>
                                          : 
                                          <li className="d-flex gap-2 px-3 py-2 list" 
                                          aria-disabled="true" 
                                          onClick={() => {
                                            setCancelFilter(true);
                                            setCancelTaskId(task.taskId);
                                            setCancelTaskType(task.taskType)
                                          }}>
                                          <span className="d-flex gap-2  text-nowrap">
                                            <img src="/images/cancel-action-icon.svg"/>Cancel
                                          </span>
                                        </li>
                                        }
                                        
                                        
                                        
                                        {
                                        (task?.taskType?.toLowerCase() == "assessment" && (task?.status?.toLowerCase() !== 'draft' && task?.status?.toLowerCase() !== 'inprogress')) &&
                                          (<li 
                                            className="d-flex gap-2 px-3 py-2 list" 
                                            aria-disabled="true" 
                                            onClick = {() =>{
                                              history.push("/AssessmentSummary", {
                                                state: { 
                                                  taskId: task.taskId, 
                                                  taskName: task.taskName, 
                                                  taskType: task.taskType 
                                                },
                                              });
                                            }}
                                             >
                                              <span className="d-flex gap-2  text-nowrap">
                                                <img src="/images/delta-mig-icon.svg"/>Summary
                                              </span>

                                          </li>)}
                                        
                                      
                                        
                                        
                                      </ul>
                                    </div>
                                     {/* Task's Action Dropdown ends here  */}
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                      </span>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          ))}


        {totalTaskInScreen == count || connectionCount <= 10 ? null : (
          <div className="d-flex justify-content-center mt-5">
            <button
              className="btn primary-outline-btn font-14 font-semibold"
              onClick={() => {
                // let count1 = offset
                let count = offset;
                // if(offset % 11 || 10 == 0)
                //   {
                count += 10;
                setOffset(count);
                // }
                // else{
                //   count1 += 10
                //   setoffset(count1);
                // }
              }}
            >
              Load More
            </button>
          </div>
        )}

        {noData == true ? (
          <div className="pt-4 pb-3 text-center">
            <img
              src="/images/no-data-found-img.svg"
              alt="no-data-found-img"
              className="mb-5"
            />
            <h5 className="font-12 font-bold color-no-rec">{totalCount > 0 ? 'No Record' : 'No Data'}</h5>
            <p className="font-10 font-regular color-no-rec">
              {totalCount > 0 ? 'There is no Record to show you right now' : 'There is no data to show you right now'}
            </p>
          </div>
        ) : (
          <div></div>
        )}
        {loader && <Loader />}

        {/*Failure Popup starts here */}
        {alert ? <div
          className="modal fade show"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  {alert.includes('success')?<img src="/images/success-popup-icon.svg" alt="success-popup-icon" />:
                  <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                  }
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5
                  className="modal-title mb-3 primary-header"
                  id="Failure-popupLabel"
                >
                  { }
                </h5>
                <p className="primary-data">
                  {alert}
                </p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button
                  type="button"
                  className="btn primary-btn font-14 px-4 font-semibold"
                  onClick={() => {
                    if (isSessionEpired) {
                      history.push('/Login')
                    }
                    setAlert('')
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div> : null}
        {/*Failure Popup ends here */}
        

        {/* Task grid Action dropdown - Older version below*/}
        {/* <span className="action-drop-down"
      >
        <img
          src="/images/keb-menu-icon.svg"
          alt="keb-menu-icon"
          data-bs-toggle="dropdown"
          className="p-2 action-icon rounded-3"

          aria-disabled={task.status.toLowerCase() == 'cancelled' || (task?.taskType?.toLowerCase() ==
            "assessment" && task.status.toLowerCase() == 'completed')}
        />
        <span className="dropdown-menu font-regular font-14 p-1">
        {(task.status.replace(/\s+/g, '').toLowerCase() == 'inprogress' || task.status.toLowerCase() == 'draft' || task.status.toLowerCase() == 'paused' || task.status.toLowerCase() == 'warning' ||task.status.toLowerCase() == 'failed') &&                                            <span
              // style={role.toLowerCase() !== "viewer" ? {border: "none"} :{
              //   border: "none",
              //   cursor:"default",                                                    
              //   backgroundColor:"#F2F2EE"
              // }}

              onClick={() => {

                // if (task.status === "inprogress") {
                //   navigate("/table");
                // } else if (task.status === "draft") {
                //if(role.toLowerCase() !== "viewer")
                getLastVisited(task);
                // }
              }}
              className="d-flex list"
            >
              <span
                aria-disabled={task.status !== "draft" && task.status.replace(/\s+/g, '').toLowerCase() !== "inprogress" && task.status.toLowerCase() !== "failed"}
                style={{ border: "none" }}
                className="d-flex gap-2 px-3 py-2"
              >
                <img src="/images/edit-icon.svg" alt="Edit" />
                Edit
              </span>
            </span>

          }


          {task?.taskType?.toLowerCase() !==
            "assessment" ? (
            <>
              {(task?.status?.toLowerCase() == "completed" && task?.goLiveStatus?.toLowerCase() == 'draft') ?
                <>
                  <span onClick={() => {
                    goLive(task); //console.log(task,"1");
                  }}
                    className="d-flex gap-2 px-3 py-2 list"
                  // style={{
                  //   cursor: "default",

                  //   backgroundColor: "#F2F2EE",
                  // }}
                  >     <span
                    className="d-flex gap-2"
                    aria-disabled={true}

                    // aria-disabled={task.status.toLowerCase() !==
                    //   "completed" ||
                    //   role == "viewer"}
                    style={{
                      cursor: "default",

                      backgroundColor: "#F2F2EE",
                    }}
                  > <img src="/images/go-live-action-icon.svg" />
                      Go-Live
                    </span>
                  </span>
                </>
                :
                <>
                  <span
                    className="d-flex gap-2 px-3 py-2 list"
                    style={{cursor:'not-allowed'}}
                  >
                    <span
                      className="d-flex gap-2"
                      aria-disabled={true}
                    >
                      <img src="/images/go-live-action-icon.svg" />
                      Go-Live
                    </span>
                  </span>
                </>
              }

            </>) : <></>}


          {task?.taskType?.toLowerCase() !==
            "assessment" ? (
            <>
              {task.status.toLowerCase() ==
                "completed" && role !== 'viewer' ? (
                <span onClick={() => { deltamigration(task) }}
                  className="d-flex gap-2 px-3 py-2 list"
                >
                  <img src="/images/delta-mig-icon.svg" />
                  Delta migration
                </span>
              ) : (
                <span className="d-flex gap-2 px-3 py-2 list" style={{
                  cursor: "not-allowed"
                }}
                  aria-disabled={true}
                >
                  <span
                    className="d-flex gap-2 "
                    onClick={() => {
                      if (task.status.toLowerCase() !== "completed" || role === 'viewer') {
                        return; // Do nothing if task is not completed or role is viewer
                      }
                      else if (task?.taskType?.toLowerCase() === "migration") {
                        // deltamigration(task);
                      }
                    }}
                  >
                    <img src="/images/delta-mig-icon.svg" />
                    Delta migration
                  </span>
                </span>
              )}

            </>) : <></>}

          {(task?.taskType?.toLowerCase() ==
            "assessment" && (task?.status?.toLowerCase() == "paused" || task.status.replace(/\s+/g, '').toLowerCase() == "inprogress")) &&
            <>
              {task.status.replace(/\s+/g, '').toLowerCase() == "inprogress" ? (
                <span className="d-flex gap-2 px-3 py-2 list"
                  style={role.toLowerCase() !== "viewer" ? { border: "none" } : {
                    border: "none",
                    cursor: "default",
                    backgroundColor: "#F2F2EE"
                  }}

                >
                  <span

                    onClick={() => {
                      if (role.toLowerCase() !== "viewer")
                        userTaskAction(task.taskId, 'Paused')
                    }
                    }
                  >
                    <img
                      src="/images/pause-icon.svg"
                      alt="pause"
                      style={{ marginRight: "10px" }}
                    />
                    Pause
                  </span>
                </span>
              ) : (
                <span className="d-flex gap-2 px-3 py-2 list"
                  style={role.toLowerCase() !== "viewer" ? { border: "none" } : {
                    border: "none",
                    cursor: "default",
                    backgroundColor: "#F2F2EE"
                  }}

                >
                  <span

                    onClick={() => {
                      if (role.toLowerCase() !== "viewer")
                        userTaskAction(task.taskId, 'Inprogress');
                    }}
                  >
                    <img src="/images/pause-icon-2.svg"
                      alt="in-progress" />
                    Resume
                  </span>
                </span>

              )}
            </>
          }

          {!(task.status.replace(/\s+/g, '').toLowerCase() == "inprogress" || task.status.toLowerCase() == "paused") || role.toLowerCase() == "viewer" ?
            <></>
            : (
              <span
                onClick={() => {
                  setCancelFilter(true);
                  setCancelTaskId(task.taskId);
                  setCancelTaskType(task.taskType)
                }}
                className="d-flex gap-2 px-3 py-2 list"
              >
                <img src="/images/cancel-action-icon.svg" />
                Cancel
              </span>
            )}

          {task?.taskType?.toLowerCase() ==
            "assessment" && (task?.status?.toLowerCase() !== 'draft' && task?.status?.toLowerCase() !== 'inprogress') ? (
            <span className="d-flex gap-2 px-3 py-2 list">
              <span
              className="d-flex gap-2 "
                onClick={() => {

                  history.push("/AssessmentSummary", {
                    state: { taskId: task.taskId, taskName: task.taskName, taskType: task.taskType },
                  });

                }
                }

              >
                <img src="/images/delta-mig-icon.svg" />
                Summary
              </span>
            </span>)
            : <></>}

        </span>
      </span> */}
      </div>
    </div>

      
  );
}

