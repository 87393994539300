// PS_01, PS_02, PS_03, PS_04, PS_05, PS_08, PS_09, PS_10, PS_11, PS_12, PS_13, PS_14, PS_15, PS_16, and PS_17.

import React from "react";
import { createContext, useRef } from 'react';
import Loader from "../Common/Loader";
import { getFeedbacks, insertFeedbackData } from "../../Services/feedbackServices";
import { decodeSessionDetails } from "../../Services/CommonServices";
import { getSession } from "../../Services/manageClientAPI";
import { useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

import objCryptoJS from "crypto-js";

export const MyContext: any = createContext("");

import jwt from 'jwt-decode'

interface Props {
    loading: boolean;
}

const Feedback: React.FC<Props> = ({ loading }) => {
    const location = useLocation();

    let token: { clientToken?: string; adminToken?: string } = {};

    let history = useHistory()

    const [feedbacks, setFeedbacks] = useState<any>([]);//PS_AD-05

    const [totalFeedbacks, settotalFeedbacks] = useState<any>("");//PS_AD-05

    const [loader, setLoader] = useState<boolean>(false);

    const [showFullFeedback, setShowFullFeedback] = useState(new Array(feedbacks[0]?.length).fill(false));

    const [rating, setRating] = useState<string>("");//PS_AD-17

    const [review, setReview] = useState<string>("");//PS_AD-17

    const [feedbackPopUp, setFeedbackPopUp] = useState<boolean>(true);//PS_AD-17

    const [successPopUp, setSuccessPopUp] = useState<boolean>(false);//PS_AD-17

    const [errorMessage, setErrorMessage] = useState<string>("");//PS_AD-17

    const clientId = "1";//PS_AD-17

    const [showPopup, setShowPopup] = useState(MyContext);//PS_AD-17

    const [alterPopUp, setalterPopUp] = useState<boolean>(false);//PS_AD-17
    const [alterPopMessage, setalterPopMessage] = useState<string>('Action blocked. Please try again or reach AVAMIGRATRON Support.');//PS_AD-17

    let [adminUI, setAdminUI] = useState<boolean>(false);

    const [alert, setAlert] = useState<boolean>(false);

    const [alertMessage, setAlertMessage] = useState<string>('')

    const MAX_CHARACTERS = 1000;

    const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');

    const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));

    const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');

    const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');

    const [objAdmin, setObjAdmin] = useState({})

    const [objClient, setObjClient] = useState({})

    let [adminName, setAdminName] = useState('');

    let [adminEemailAddress, setAdminEmailAddress] = useState('');

    let [adminrole, setAdminRole] = useState('');

    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }

    let [clientName, setclientName] = useState('');

    let [companyName, setCompanyName] = useState('');

    let [emailAddress, setEmailAddress] = useState('');

    let [role, setRole] = useState('');

    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';

    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';

    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());

    const [isSessionEpired, setisSessionEpired] = useState(false);

    // //debugger

    let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);

    // PS_31, PS_32, PS_33, PS_34, PS_35, PS_36, PS_37, PS_38, PS_39, and PS_40

    useEffect(() => {
        if (window.location.pathname.toString().toLowerCase().includes("admin")) {
            setAdminUI(true);
            getFeedbackData();
        }

        sessionTokenProperties();

        resetTimer();

        window.addEventListener('keydown', resetTimer);

        window.addEventListener('click', resetTimer);

        return () => {

            if (InActiveTimeout.current) {
                console.log("Feedback Unmount Time Clear");
                clearTimeout(InActiveTimeout.current);
            }
            window.removeEventListener('click', resetTimer);

            window.removeEventListener('keydown', resetTimer);

        };
    }, []);

    let resetTimer = async () => {

        try {

            // debugger;

            ////console.log("Reset Timer Start");



            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);

            ////console.log("sessionResponse value :", sessionResponse);



            // //debugger

            if (sessionResponse == 'expired') {

                debugger

                sessionStorage.removeItem("c_at");

                setisSessionEpired(true)

                setAlert(true)

                setAlertMessage('Your Session has Expired please login again to continue');

            }

            else if (sessionResponse == 'updateToken') {

                if (emailAddress == "" || emailAddress == undefined) {

                    await sessionTokenProperties();

                }

                debugger

                let token = await getToken()

                let payload = {

                    mailId: emailAddress,

                    isAdmin: objClient == undefined ? true : false,

                    token: token

                };

                let getsessionToken = await getSession(payload);

                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY

                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;



                // const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);

                // const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);

                // let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();

                await sessionStorage.removeItem("c_at");

                await sessionStorage.setItem('c_at', getsessionToken?.data?.data.sessionToken);
                await sessionTokenProperties();
                // let updatedobjClient: any = jwt(c_decryptedValue);

                // SessionEpiredDateTime = updatedobjClient.CreatedAt;

                // setSessionEpiredDateTime(SessionEpiredDateTime);

            }

            ////console.log("Reset Timer End");

        }

        catch (error) {

            ////console.log("Reset Timer Catch");

            setAlert(true)

            setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');

            setLoader(false);

        }

    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {

        try {

            // debugger

            ////console.log("Reset Session Timer Start");

            // Assuming InActiveTimeout is a number representing the ID of the current timeout



            if (typeof window !== 'undefined') {

                ////console.log("Reset Session Timer Inside");

                if (InActiveTimeout.current) {
                    clearTimeout(InActiveTimeout.current);
                }

                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);



                InActiveTimeout.current = setTimeout(() => {

                    ////console.log("Expired");

                    sessionStorage.removeItem("c_at");

                    ////console.log("removed session");

                    setisSessionEpired(true)

                    setAlert(true)

                    setAlertMessage('Your Session has Expired please login again to continue');

                    // return 'expired'

                }, tokenExpiryCheckInterval);



                ////console.log("Reset Session Timer checking");



                const currentDateTime = new Date().toISOString();

                //const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();

                ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);



                const diffInMilliseconds = Math.abs(new Date(currentDateTime).getTime() - new Date(SessionEpiredDateTime).getTime());

                ////console.log("diffInMilliSeconds values:", diffInMilliseconds);



                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

                ////console.log("minutes : ", minutes);



                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);

                ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);



                if (minutes > sessionUpdateTimeGap) {



                    ////console.log("Reset Session Timer need to update");

                    return 'updateToken'

                }



                ////console.log("Reset Session Timer End");

                return 'NoActionRequired'

            }

            return 'NoActionRequired'

        }

        catch (ex) {

            ////console.log("Reset Session Timer Catch");

            throw ex

        }

    };

    let sessionTokenProperties = async () => {

        try {

            const tokenResponse: any = await decodeSessionDetails();

            debugger

            if (tokenResponse.adminDetails) {

                if (tokenResponse.adminDetails) {

                    setObjAdmin(tokenResponse.adminDetails)

                    setAdminName(tokenResponse.adminDetails.Name);

                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    // emailAddress = tokenResponse.adminDetails.Email;
                    // setEmailAddress(emailAddress);
                    // setSessionEpiredDateTime(tokenResponse.adminDetails.CreatedAt);
                    setAdminRole(tokenResponse.adminDetails.Role);

                }

                else {

                    if (window.location.pathname.toString().toLowerCase().includes("admin")) {

                        history.push("/Admin/Login")

                    }

                }

            }

            if (!window.location.pathname.toString().toLowerCase().includes("admin")) {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    SessionEpiredDateTime = tokenResponse.clientDetails.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                }
                else {
                    history.push("/Login")
                }

            }

            //#endregion

        } catch (error) {

            setAlert(true);

            setLoader(false)



            console.error('Failed in main method', error);

        }

    };

    const getToken = async () => {

        let clientToken: string;

        let adminToken: string;

        let token: { clientToken?: string; adminToken?: string } = {};

        debugger

        try {

            if (objClient != undefined) {

                let sessionToken: any = sessionStorage.getItem('c_at')

                if (sessionToken) {

                    // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)

                    // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);

                    // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['clientToken'] = sessionToken



                }





            }

            if (objAdmin !== undefined) {

                let sessionToken: any = sessionStorage.getItem('a_at')

                if (sessionToken) {

                    // sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)

                    // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);

                    // adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();



                    token['adminToken'] = sessionToken



                }

            }

            return token

        } catch (error) {

            setAlert(true);

            setLoader(false)



            throw error

        }

    }

    // PS_42, PS_43, PS_44, PS_65, PS_66, PS_67, PS_68, PS_69, PS_70, PS_71, PS_72, PS_73, PS_74, PS_75 and PS_76.

    const getFeedbackData = async () => {//PS_AD-08

        try {

            setLoader(true)

            let token = await getToken();



            const result: any = await getFeedbacks({ token: token });//PS_AD-09

            ////console.log("feedback get result daw", result.data.data[0]);

            setFeedbacks(result?.data.data[0]);//PS_AD-13

            settotalFeedbacks(result?.data.data[0][0]?.totalFeedbacks);

            setLoader(false)

        } catch (error) {

            setLoader(false)

            setAlert(true);



        }

    };

    return (

        <div>
            <>

                <div className="container-fluid container-height">

                    <div className="row">

                        <div className="col-md-12">

                            <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">

                                <div className="row">

                                    <div className="col-md-12 mb-4">

                                        <h5 className="font-20 font-bold mb-0">

                                            Feedbacks ({totalFeedbacks})

                                        </h5>

                                    </div>



                                    {feedbacks?.length > 0 ? (

                                        feedbacks?.map((data: any, index: any) => {
                                            const checkRating = data?.rating;

                                            return (

                                                <div

                                                    key={index}

                                                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4"

                                                >

                                                    <div className="card rounded-3 h-100 px-3 py-3">

                                                        <img

                                                            className="custom-quotes-width mb-3"

                                                            src="/images/double-quotes-icon.svg"

                                                            alt="double-quotes-icon"

                                                        />

                                                        <p className="feedback-content font-regular feedback-content-hig custom-parah-color font-12" style={{ marginBottom: '10px' }}>

                                                            {data?.review && (

                                                                showFullFeedback[index]

                                                                    ? data?.review

                                                                    : data?.review.slice(0, 130)

                                                            )}

                                                            {data?.review && data?.review.length > 150 && (

                                                                <span

                                                                    className="feedback-toggle"

                                                                    title={data?.review}

                                                                    onClick={() =>

                                                                        setShowFullFeedback((prevState) =>

                                                                            prevState.map((item, i) => i === index ? !item : item)

                                                                        )

                                                                    }

                                                                >

                                                                    {showFullFeedback[index] ? 'Show Less' : '...'}

                                                                </span>

                                                            )}

                                                        </p>

                                                        <div className="mt-auto d-flex justify-content-between align-items-center">

                                                            <div>

                                                                <div className="d-flex">

                                                                    <div className="d-block me-2">

                                                                        <img

                                                                            src="/images/boy-animated1-icon.svg"

                                                                            alt="animated-emoji"

                                                                        />

                                                                    </div>

                                                                    <div className="d-block">

                                                                        <span className="font-regular d-block w-100 font-12 w-100 cust-name-color">

                                                                            {data?.userName}

                                                                        </span>
                                                                        <span className="font-regular d-block font-10 w-100 cust-role-color">{data.roleType}</span>


                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <div>

                                                                <div className="d-flex align-items-center">

                                                                    <span>

                                                                        <img

                                                                            src={

                                                                                checkRating === "Sad"

                                                                                    ? "/images/sad-emoji-icon.svg"

                                                                                    : checkRating === "Happy"

                                                                                        ? "/images/happy-emoji-icon.svg"

                                                                                        : "/images/netural-emoji-icon.svg"

                                                                            }

                                                                            alt="emoji"

                                                                        />

                                                                    </span>



                                                                    <span className="font-10 font-regular cust-role-color ml-2">

                                                                        &nbsp;

                                                                        {data?.rating}

                                                                    </span>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            );

                                        })

                                    ) : (

                                        <div className="row align-items-center">

                                            <div className="pt-4 pb-3 text-center">

                                                <img

                                                    src="/images/no-data-found-img.svg"

                                                    alt="no-data-found-img"

                                                    className="mb-3"

                                                />

                                                <h5 className="font-12 font-bold color-no-rec">No Data</h5>

                                                <p className="font-10 font-regular color-no-rec">

                                                    There is no data to show you right now

                                                </p>

                                            </div>

                                        </div>

                                    )}



                                </div>

                                {alert ? <div

                                    className="modal fade show"

                                    tabIndex={-1}

                                    style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}

                                >

                                    <div className="modal-dialog modal-dialog-centered">

                                        <div className="modal-content popup-brd-radius">

                                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                                <span className="position-absolute">

                                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                                </span>

                                            </div>

                                            <div className="modal-body border-0 text-center">

                                                <h5

                                                    className="modal-title mb-3 primary-header"

                                                    id="Failure-popupLabel"

                                                >

                                                    {"Something went wrong! Please try again or reach AVAMIGRATRON Support."}

                                                </h5>

                                                <p className="primary-data">

                                                    {alert}

                                                </p>

                                            </div>

                                            <div className="modal-footer border-0 pb-4 justify-content-center">

                                                <button

                                                    type="button"

                                                    className="btn primary-btn font-14 px-4 font-semibold"

                                                    onClick={() => {

                                                        if (isSessionEpired) {

                                                            history.push('/Login')

                                                        }



                                                        setAlert(false)

                                                    }}

                                                >

                                                    Ok

                                                </button>

                                            </div>

                                        </div>

                                    </div>

                                </div> : null}

                            </div>

                        </div>

                    </div>

                    {loader &&

                        <div className="container-fluid">

                            <div className="overlay">

                                <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center">

                                    <div

                                        className="spinner-border Loader loader-color align-center"

                                        role="status"

                                    >

                                        <span>

                                            <span className="loader-inner-circle" />

                                        </span>

                                    </div>

                                    <p className="loading-text font-16 font-semibold color-white-v2 mt-2 text-center">

                                        It will take time to load. Please wait until it loads

                                    </p>

                                </div>

                            </div>

                        </div>}

                </div>

            </>

        </div>

    );

};



export default Feedback;