// PS_01, PS_02, PS_03, PS_04, PS_05, PS_08, PS_09, PS_10, PS_11, PS_12, PS_13, PS_14, PS_15, PS_16, and PS_17.

import React from "react";
import { createContext } from 'react';
import Loader from "../Common/Loader";
import { getFeedbacks, insertFeedbackData } from "../../Services/feedbackServices";
import { decodeSessionDetails } from "../../Services/CommonServices";
import { getSession } from "../../Services/manageClientAPI";
import { useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

import objCryptoJS from "crypto-js";

export const MyContext: any = createContext("");

import jwt from 'jwt-decode'

interface Props {

    loading: boolean;

}





const Feedback: React.FC<Props> = ({ loading }) => {

    const location = useLocation();



    let token: { clientToken?: string; adminToken?: string } = {};



    // const clientToken = sessionStorage?.getItem("c_at");

    // const adminToken = sessionStorage?.getItem("a_at");



    // if (clientToken && adminToken) {

    //     token = { clientToken, adminToken };

    // } else if (clientToken) {

    //     token = { clientToken };

    // } else if (adminToken) {

    //     token = { adminToken };

    // }





    let history = useHistory()

    const [feedbacks, setFeedbacks] = useState<any>([]);//PS_AD-05

    ////console.log(feedbacks, "hushwuhswuhswu");



    const [totalFeedbacks, settotalFeedbacks] = useState<any>("");//PS_AD-05

    const [loader, setLoader] = useState<boolean>(false);

    const [showFullFeedback, setShowFullFeedback] = useState(new Array(feedbacks[0]?.length).fill(false));

    ////console.log("FEEDBACKS", totalFeedbacks);

    const [rating, setRating] = useState<string>("");//PS_AD-17

    const [review, setReview] = useState<string>("");//PS_AD-17

    const [feedbackPopUp, setFeedbackPopUp] = useState<boolean>(true);//PS_AD-17

    const [successPopUp, setSuccessPopUp] = useState<boolean>(false);//PS_AD-17

    const [errorMessage, setErrorMessage] = useState<string>("");//PS_AD-17

    const clientId = "1";//PS_AD-17

    const [showPopup, setShowPopup] = useState(MyContext);//PS_AD-17

    const [alterPopUp, setalterPopUp] = useState<boolean>(false);//PS_AD-17
    const [alterPopMessage, setalterPopMessage] = useState<string>('Action blocked. Please try again or reach AVAMIGRATRON Support.');//PS_AD-17


    let [adminUI, setAdminUI] = useState<boolean>(false);

    const [alert, setAlert] = useState<boolean>(false);

    const [alertMessage, setAlertMessage] = useState<string>('')



    // const [alert, setAlert] = useState<string>('');



    const MAX_CHARACTERS = 1000;

    const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');

    const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));

    const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');

    const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');

    const [objAdmin, setObjAdmin] = useState({})

    const [objClient, setObjClient] = useState({})

    let [adminName, setAdminName] = useState('');

    let [adminEemailAddress, setAdminEmailAddress] = useState('');

    let [adminrole, setAdminRole] = useState('');

    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }

    let [clientName, setclientName] = useState('');

    let [companyName, setCompanyName] = useState('');

    let [emailAddress, setEmailAddress] = useState('');

    let [role, setRole] = useState('');

    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';

    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';

    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());

    const [isSessionEpired, setisSessionEpired] = useState(false);

    // //debugger

    let InActiveTimeout: NodeJS.Timeout;







    // PS_31, PS_32, PS_33, PS_34, PS_35, PS_36, PS_37, PS_38, PS_39, and PS_40

    useEffect(() => {



        if (window.location.pathname.toString().toLowerCase().includes("admin")) {

            setAdminUI(true);

            getFeedbackData();

        }



        sessionTokenProperties();

        resetTimer();

        window.addEventListener('keydown', resetTimer);

        window.addEventListener('click', resetTimer);



        return () => {

            window.removeEventListener('click', resetTimer);

            window.removeEventListener('keydown', resetTimer);

        };



    }, []);



    let resetTimer = async () => {

        try {

            // debugger;

            ////console.log("Reset Timer Start");



            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);

            ////console.log("sessionResponse value :", sessionResponse);



            // //debugger

            if (sessionResponse == 'expired') {

                debugger

                sessionStorage.removeItem("c_at");

                setisSessionEpired(true)

                setAlert(true)

                setAlertMessage('Your Session has Expired please login again to continue');

            }

            else if (sessionResponse == 'updateToken') {

                if (emailAddress == "" || emailAddress == undefined) {

                    await sessionTokenProperties();

                }

                debugger

                let token = await getToken()

                let payload = {

                    mailId: emailAddress,

                    isAdmin: objClient == undefined ? true : false,

                    token: token

                };

                let getsessionToken = await getSession(payload);

                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY

                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;



                const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);

                const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);

                let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();

                await sessionStorage.removeItem("c_at");

                await sessionStorage.setItem('c_at', updatedToken);

                let updatedobjClient: any = jwt(c_decryptedValue);

                SessionEpiredDateTime = updatedobjClient.CreatedAt;

                setSessionEpiredDateTime(SessionEpiredDateTime);

            }

            ////console.log("Reset Timer End");

        }

        catch (error) {

            ////console.log("Reset Timer Catch");

            setAlert(true)

            setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');

            setLoader(false);

        }

    }



    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {

        try {

            // debugger

            ////console.log("Reset Session Timer Start");

            // Assuming InActiveTimeout is a number representing the ID of the current timeout



            if (typeof window !== 'undefined') {

                ////console.log("Reset Session Timer Inside");

                clearTimeout(InActiveTimeout);

                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);



                InActiveTimeout = setTimeout(() => {

                    ////console.log("Expired");

                    sessionStorage.removeItem("c_at");

                    ////console.log("removed session");

                    setisSessionEpired(true)

                    setAlert(true)

                    setAlertMessage('Your Session has Expired please login again to continue');

                    // return 'expired'

                }, tokenExpiryCheckInterval);



                ////console.log("Reset Session Timer checking");



                const currentDateTime = new Date().toISOString();

                const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();

                ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);



                const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());

                ////console.log("diffInMilliSeconds values:", diffInMilliseconds);



                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

                ////console.log("minutes : ", minutes);



                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);

                ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);



                if (minutes > sessionUpdateTimeGap) {



                    ////console.log("Reset Session Timer need to update");

                    return 'updateToken'

                }



                ////console.log("Reset Session Timer End");

                return 'NoActionRequired'

            }

            return 'NoActionRequired'

        }

        catch (ex) {

            ////console.log("Reset Session Timer Catch");

            throw ex

        }

    };

    let sessionTokenProperties = async () => {

        try {

            const tokenResponse: any = await decodeSessionDetails();

            debugger

            if (tokenResponse.adminDetails) {

                if (tokenResponse.adminDetails) {

                    setObjAdmin(tokenResponse.adminDetails)

                    setAdminName(tokenResponse.adminDetails.Name);

                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    emailAddress = tokenResponse.adminDetails.Email;
                    setEmailAddress(emailAddress);
                    setSessionEpiredDateTime(tokenResponse.adminDetails.CreatedAt);
                    setAdminRole(tokenResponse.adminDetails.Role);

                }

                else {

                    if (window.location.pathname.toString().toLowerCase().includes("admin")) {

                        history.push("/Admin/Login")

                    }

                }

            }

            else {

                if (tokenResponse.clientDetails) {

                    setObjClient(tokenResponse.clientDetails)

                    setclientName(tokenResponse.clientDetails.Name);

                    setCompanyName(tokenResponse.clientDetails.CompanyName)

                    emailAddress = tokenResponse.clientDetails.Email;

                    setEmailAddress(emailAddress);

                    setRole(tokenResponse.clientDetails.Role);

                    setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);

                }

                else {

                    history.push("/Login")

                }

            }

            //#endregion

        } catch (error) {

            setAlert(true);

            setLoader(false)



            console.error('Failed in main method', error);

        }

    };





    const getToken = async () => {

        let clientToken: string;

        let adminToken: string;

        let token: { clientToken?: string; adminToken?: string } = {};

        debugger

        try {

            if (objClient != undefined) {

                let sessionToken: any = sessionStorage.getItem('c_at')

                if (sessionToken) {

                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)

                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);

                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['clientToken'] = clientToken



                }





            }

            if (objAdmin !== undefined) {

                let sessionToken: any = sessionStorage.getItem('a_at')

                if (sessionToken) {

                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)

                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);

                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();



                    token['adminToken'] = adminToken



                }

            }

            return token

        } catch (error) {

            setAlert(true);

            setLoader(false)



            throw error

        }

    }

    // PS_42, PS_43, PS_44, PS_65, PS_66, PS_67, PS_68, PS_69, PS_70, PS_71, PS_72, PS_73, PS_74, PS_75 and PS_76.

    const getFeedbackData = async () => {//PS_AD-08

        try {

            setLoader(true)

            let token = await getToken();



            const result: any = await getFeedbacks({ token: token });//PS_AD-09

            ////console.log("feedback get result daw", result.data.data[0]);

            setFeedbacks(result?.data.data[0]);//PS_AD-13

            settotalFeedbacks(result?.data.data[0][0]?.totalFeedbacks);

            setLoader(false)

        } catch (error) {

            setLoader(false)

            setAlert(true);



        }

    };







    // PS_78, PS_79, PS_80.

    const handleRatingClick: any = (selectedRating: any) => {

        try {

            ////console.log(selectedRating, "huhuhuhdueh");



            setRating(selectedRating);

            setErrorMessage("");

        } catch (error) {



        }

    };



    // PS_81, PS_82, PS_83, PS_84, PS_85, PS_86 and PS_87.

    const handleReviewChange: any = (e: any) => {

        try {

            let inputText = e.target.value;



            if (inputText.length > MAX_CHARACTERS) {

                inputText = inputText.slice(0, MAX_CHARACTERS);

                setErrorMessage("Maximum character limit reached");

            } else {

                setErrorMessage(""); // Clear error message if within limit

            }



            setReview(inputText);

        } catch (error) {



        }

    };



    // PS_88, PS_89, PS_90, PS_91, PS_100, PS_101, PS_102 and PS_103.

    const handleSubmit: any = async () => {

        try {

            let token = await getToken();

            setLoader(true)

            if (rating === "" || review === "") {

                setErrorMessage('Please select a rating and write a review!');

                return;

            } ``

            const data = {

                token: token,

                rating: rating,

                review: review,

            };

            debugger

            const result: any = await insertFeedbackData(data);//PS_AD-18

            ////console.log("RESULT", result);

            setLoader(false)

            ////console.log(result.data.statusCode, "statusssss");



            if (result.data.statusCode === 200) {//PS_AD-22

                setFeedbackPopUp(false);
                setSuccessPopUp(true);
            }

            if (result?.data?.statusCode === 400) {
                setalterPopUp(true)
                setalterPopMessage(result?.data?.statusMessage)
                sessionStorage.clear()
            }
        } catch (error) {

            ////console.log("Error submitting feedback:", error);

        }

    };



    const renderAdminUI: any = () => (

        <>

            <div className="container-fluid container-height">

                <div className="row">

                    <div className="col-md-12">

                        <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">

                            <div className="row">

                                <div className="col-md-12 mb-4">

                                    <h5 className="font-20 font-bold mb-0">

                                        Feedbacks ({totalFeedbacks})

                                    </h5>

                                </div>



                                {feedbacks?.length > 0 ? (

                                    feedbacks?.map((data: any, index: any) => {

                                        ////console.log(data, "dataaaaaaaaaa");



                                        const checkRating = data?.rating;

                                        ////console.log(checkRating, "ratingcheckkkkk");





                                        return (

                                            <div

                                                key={index}

                                                className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4"

                                            >

                                                <div className="card rounded-3 h-100 px-3 py-3">

                                                    <img

                                                        className="custom-quotes-width mb-3"

                                                        src="/images/double-quotes-icon.svg"

                                                        alt="double-quotes-icon"

                                                    />

                                                    <p className="feedback-content font-regular feedback-content-hig custom-parah-color font-12" style={{ marginBottom: '10px' }}>

                                                        {data?.review && (

                                                            showFullFeedback[index]

                                                                ? data?.review

                                                                : data?.review.slice(0, 130)

                                                        )}

                                                        {data?.review && data?.review.length > 150 && (

                                                            <span

                                                                className="feedback-toggle"

                                                                title={data?.review}

                                                                onClick={() =>

                                                                    setShowFullFeedback((prevState) =>

                                                                        prevState.map((item, i) => i === index ? !item : item)

                                                                    )

                                                                }

                                                            >

                                                                {showFullFeedback[index] ? 'Show Less' : '...'}

                                                            </span>

                                                        )}

                                                    </p>

                                                    <div className="mt-auto d-flex justify-content-between align-items-center">

                                                        <div>

                                                            <div className="d-flex">

                                                                <div className="d-block me-2">

                                                                    <img

                                                                        src="/images/boy-animated1-icon.svg"

                                                                        alt="animated-emoji"

                                                                    />

                                                                </div>

                                                                <div className="d-block">

                                                                    <span className="font-regular d-block w-100 font-12 w-100 cust-name-color">

                                                                        {data?.userName}

                                                                    </span>
                                                                    <span className="font-regular d-block font-10 w-100 cust-role-color">{data.roleType}</span>


                                                                </div>

                                                            </div>




                                                        </div>

                                                        <div>

                                                            <div className="d-flex align-items-center">

                                                                <span>

                                                                    <img

                                                                        src={

                                                                            checkRating === "Sad"

                                                                                ? "/images/sad-emoji-icon.svg"

                                                                                : checkRating === "Happy"

                                                                                    ? "/images/happy-emoji-icon.svg"

                                                                                    : "/images/netural-emoji-icon.svg"

                                                                        }

                                                                        alt="emoji"

                                                                    />

                                                                </span>



                                                                <span className="font-10 font-regular cust-role-color ml-2">

                                                                    &nbsp;

                                                                    {data?.rating}

                                                                </span>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        );

                                    })

                                ) : (

                                    <div className="row align-items-center">

                                        <div className="pt-4 pb-3 text-center">

                                            <img

                                                src="/images/no-data-found-img.svg"

                                                alt="no-data-found-img"

                                                className="mb-3"

                                            />

                                            <h5 className="font-12 font-bold color-no-rec">No Data</h5>

                                            <p className="font-10 font-regular color-no-rec">

                                                There is no data to show you right now

                                            </p>

                                        </div>

                                    </div>

                                )}



                            </div>

                            {alert ? <div

                                className="modal fade show"

                                tabIndex={-1}

                                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}

                            >

                                <div className="modal-dialog modal-dialog-centered">

                                    <div className="modal-content popup-brd-radius">

                                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                            <span className="position-absolute">

                                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                            </span>

                                        </div>

                                        <div className="modal-body border-0 text-center">

                                            <h5

                                                className="modal-title mb-3 primary-header"

                                                id="Failure-popupLabel"

                                            >

                                                {"Something went wrong! Please try again or reach AVAMIGRATRON Support."}

                                            </h5>

                                            <p className="primary-data">

                                                {alert}

                                            </p>

                                        </div>

                                        <div className="modal-footer border-0 pb-4 justify-content-center">

                                            <button

                                                type="button"

                                                className="btn primary-btn font-14 px-4 font-semibold"

                                                onClick={() => {

                                                    if (isSessionEpired) {

                                                        history.push('/Login')

                                                    }



                                                    setAlert(false)

                                                }}

                                            >

                                                Ok

                                            </button>

                                        </div>

                                    </div>

                                </div>

                            </div> : null}

                        </div>

                    </div>

                </div>

                {loader &&

                    <div className="container-fluid">

                        <div className="overlay">

                            <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center">

                                <div

                                    className="spinner-border Loader loader-color align-center"

                                    role="status"

                                >

                                    <span>

                                        <span className="loader-inner-circle" />

                                    </span>

                                </div>

                                <p className="loading-text font-16 font-semibold color-white-v2 mt-2 text-center">

                                    It will take time to load. Please wait until it loads

                                </p>

                            </div>

                        </div>

                    </div>}

            </div>

        </>

    );



    // UI #2 (User View)

    const renderUserUI: any = () => (

        <>

            {feedbackPopUp && (



                <div

                    className="modal fade show"

                    style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}

                    id="Telluswhatyouthink"

                    data-bs-backdrop="static"

                    data-bs-keyboard="false"

                    tabIndex={-1}

                    aria-labelledby="TelluswhatyouthinkLabel"

                    aria-hidden="true"

                >

                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content p-4">

                            <div className="modal-header border-0 p-0">

                                <h1

                                    className="font-20 font-bold custom-header-color m-0"

                                    id="TelluswhatyouthinkLabel"

                                >

                                    Tell us what you think

                                </h1>

                                <button

                                    type="button"

                                    onClick={() => {

                                        setFeedbackPopUp(false);

                                        setShowPopup(false);

                                        history.push("/task");

                                    }}

                                    className="btn-close shadow-none"

                                    data-bs-dismiss="modal"

                                    aria-label="Close"

                                />

                            </div>

                            <div className="modal-body border-0 p-0 pt-2 pb-3">

                                <div className="row">

                                    <div className="col-md-12">

                                        <label

                                            className="font-14 font-semibold label-color w-100 my-2 pt-3"

                                            htmlFor="MailTo"

                                        >

                                            What do you think of this tool ?

                                        </label>

                                        <div className="btn-group" role="group" aria-label="Basic example">

                                            <button

                                                onClick={() => handleRatingClick("Sad")}

                                                type="button"

                                                className={`emoji-active d-flex align-items-center me-4 ${rating === "Sad" ? 'selected' : ''

                                                    }`}

                                            >

                                                <img

                                                    src="/images/sad-emoji-icon.svg"

                                                    className="popup-emoji"

                                                    alt="sad-emoji"

                                                />

                                            </button>

                                            <button

                                                onClick={() => handleRatingClick("Neutral")}

                                                type="button"

                                                className={`emoji-active d-flex align-items-center me-4 ${rating === "Neutral" ? 'selected' : ''

                                                    }`}

                                            >

                                                <img

                                                    src="/images/emoji-neutral-icon.png"

                                                    className="popup-emoji neutral-emoji"

                                                    alt="emoji"

                                                />

                                            </button>

                                            <button

                                                onClick={() => handleRatingClick("Happy")}

                                                type="button"

                                                className={`emoji-active d-flex align-items-center me-4 ${rating === "Happy" ? 'selected' : ''

                                                    }`}

                                            >

                                                <img

                                                    src="/images/happy-emoji-icon.svg"

                                                    className="popup-emoji"

                                                    alt="happy-emoji"
                                                    style={{ width: "46px" }}
                                                />

                                            </button>

                                        </div>

                                    </div>

                                    <div className="col-md-12">

                                        <label

                                            htmlFor="mailComments"

                                            className="font-14 font-semibold label-color w-100 my-2 pt-3"

                                        >

                                            Do you have any thoughts you’d like to share?

                                        </label>

                                        <textarea

                                            className="form-control custom-textarea resize-none custom-form"

                                            id="mailComments"

                                            rows={3}

                                            placeholder="Enter Your Thoughts"

                                            maxLength={1000}

                                            defaultValue={""}

                                            onChange={handleReviewChange}

                                        ></textarea>

                                        {errorMessage && (

                                            <div className="col-md-12">

                                                <p className="text-danger">{errorMessage}</p>

                                            </div>

                                        )}

                                    </div>

                                </div>

                            </div>

                            <div className="modal-footer border-0 p-0">

                                <button

                                    onClick={() => {

                                        setFeedbackPopUp(false);

                                        setShowPopup(false);

                                        // window.location.reload();

                                        history.push("/task");

                                    }}

                                    type="button"

                                    className="btn secondary-btn px-3"

                                    data-bs-dismiss="modal"

                                >

                                    Cancel

                                </button>

                                <button

                                    onClick={(e) => {

                                        e.preventDefault();

                                        handleSubmit(e);

                                    }}

                                    type="button"

                                    className={`btn primary-btn px-3 ${rating && review ? '' : 'disabled-btn'}`}

                                    disabled={!rating || !review}



                                >

                                    Send

                                </button>

                            </div>

                        </div>

                    </div>



                    <div>



                    </div>







                    {loader && <Loader />}

                </div>

            )}



            {successPopUp && (

                <div>

                    <div

                        className="modal fade show"

                        id="Success-popup"

                        tabIndex={-1}

                        aria-labelledby="Success-popupLabel"

                        aria-modal="true"

                        role="dialog"

                        style={{ display: "block", backgroundColor: "rgba(0,0,0,1)" }}

                    >

                        <div className="modal-dialog modal-dialog-centered">

                            <div className="modal-content popup-brd-radius">

                                <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                    <span className="position-absolute">

                                        <img src="/images/success-popup-icon.svg" alt="success-popup-icon" />

                                    </span>

                                </div>

                                <div className="modal-body border-0 text-center">

                                    <h5 className="modal-title mb-3 primary-header" id="Success-popupLabel">

                                        Success!

                                    </h5>

                                    <p className="primary-data">Feedback Submitted Successfully.</p>

                                </div>

                                <div className="modal-footer border-0 justify-content-center pb-4">

                                    <button

                                        type="button"

                                        className="btn primary-btn font-14 px-4 font-semibold"

                                        data-bs-dismiss="modal"

                                        onClick={() => {

                                            // setSuccessPopUp(false);

                                            setShowPopup(false);

                                            //window.location.reload();

                                            history.push("/task");

                                        }}



                                    >

                                        Ok

                                    </button>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            )}

            {alterPopUp == true && (
                <div className="modal fade show" id="Failure-popup" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="Failure-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>

                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content popup-brd-radius">

                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                <span className="position-absolute">

                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                </span>

                            </div>

                            <div className="modal-body border-0 text-center">

                                <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Failure</h5>

                                <p className="primary-data">{alterPopMessage}</p>

                            </div>

                            <div className="modal-footer border-0 pb-4 justify-content-center">

                                <button type="button" onClick={() => setShowPopup(false)} className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">OK</button>

                            </div>

                        </div>


                    </div>

                </div>) }


        </>

    );





    return (

        <div>

            {adminUI ? renderAdminUI() : renderUserUI()}

        </div>

    );

};



export default Feedback;