//PS_01, PS_02, PS_03, PS_04, PS_05, PS_06, PS_07, PS_08, PS_09, PS_10
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { Onlymail, PasswordRegex } from "../../Common/Constant";
import CryptoJS from 'crypto-js';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { fetchLoginDetails, resetPasskey, accountVerification, sendOtp } from "../../Services/RegistrationService";
import { MsalConfig, ControlData, LoginErrorData } from "../../Common/Interface";
import jwtDecode from "jwt-decode";
import { clientTaskCount } from "../../Services/taskService";
import { commonErrorMsgs } from "../../Common/Constant";


//PS_16, PS_17, PS_18, PS_19, PS_20, PS_21, PS_22, PS_23, PS_24, PS_25, PS_26, PS_27, PS_28, PS_29, PS_30, PS_31, PS_32, PS_33, PS_34, PS_35, PS_36, PS_37, PS_38
export default function Login() {
    const location = useLocation();
    
    const history = useHistory()
    const passValue = useRef()
    let loginDataObj: ControlData = {
        mailId: "",
        password: "",
        confirmPassword: "",
        isAdmin: false
    }
    let errorObj: LoginErrorData = {
        errorEmailAddress: "",
        errorPassword: "",
        errorConfirmPassword: "",
    }
    let rn_secretKey: string = `${process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY}`
    let cs_secretKey: string = `${process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY}`
    let as_secretKey: string = `${process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY}`
    let [controlData, setControlData] = useState<ControlData>(loginDataObj)
    let [errorData, setErrorData] = useState<LoginErrorData>(errorObj)
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [permanentPasswordVisibility, setPermanentPasswordVisibility] = useState<boolean>(false);

    const [page, setPage] = useState<any>(false);
    const [disableEmail, setdisableEmail] = useState<boolean>(false)
    const [otp, setOTP] = useState<any>("");
    const [otpValues, setOTPValues] = useState<any>(["", "", "", "", "", ""]);
    const [otpPopUp, setOTPPopUp] = useState<boolean>(false);
    const [otpError, setOtpError] = useState<boolean>(false);
    const [timer, setTimer] = useState<number>(45);
    const [clientData, setClientData] = useState<any>();
    let [emailId, setEmailId] = useState<string>();
    const [showField, setShowField] = useState<boolean>(false);
    const [buttonDisable, setButtonDisable] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false)
    const [ShowPopup, setShowPopup] = useState<boolean>(false)
    const [PopupMessage, setPopupMessage] = useState<string>('Action blocked. Please try again or reach AVAMIGRATRON Support.')
const[emailDisable,setEmailDisable]=useState<boolean>(false)
    const [invitedExpired, setinvitedExpired] = useState<boolean>(false)
    const [invitedMessage, setinvitedMessage] = useState<string>('')
    const [alert, setAlert] = useState<boolean>(false)
    const [isResetPswdPg, setIsResetPswdPg] = useState<boolean>(false);
    const [expirationTime, setExpirationTime] = useState<string>();
    const [decryptedOTP,setDecryptedOTP]=useState<string>('');

    const msalConfig: MsalConfig = {
        auth: {
            clientId: `${process.env.REACT_APP_Azure_Client_ID}`,
            redirectUri: `${process.env.REACT_APP_FE_URL}`,
        },
    };

    const msalConfigAdmin: MsalConfig = {
        auth: {
            clientId: `${process.env.REACT_APP_Azure_Client_ID}`,
            redirectUri: `${process.env.REACT_APP_ADMIN_FE_URL}`,
        },
    };

    // PS_39, PS_40, PS_41, PS_42, PS_43, PS_44, PS_45, PS_46, PS_47, PS_48, PS_49, PS_50, PS_51, PS_52, PS_53, PS_54
    useEffect(() => {
        if (location.pathname.toLowerCase().includes('admin')) {
            setControlData({
                ...controlData,
                isAdmin: true
            });
        }
        debugger
        if (location.pathname.toLowerCase().includes("/resetpassword")) {
            debugger
            
            setShowField(true)
            const queryParams = new URLSearchParams(window.location.search);
            const R_Password: any = queryParams.get('Resetpassword');
            
            //console.log(decodedToken);
            try {
                const decodedToken: any = jwtDecode(R_Password);
                if (decodedToken.exp < Date.now() / 1000) {
                    setinvitedExpired(true)
                    setinvitedMessage('Your verification link has expired Please reach your Admin')
                }

                else {
                    debugger
                    setIsResetPswdPg(true)
                    setdisableEmail(true)
                    emailId = decodedToken.emailAddress;
                    setEmailId(emailId);
                    const isAdminR = decodedToken.isAdmin
                    setControlData({
                        ...controlData,
                        mailId: emailId || '',
                        isAdmin: isAdminR
                    })
                     userVerification({ mailId: emailId, isAdmin: isAdminR, secretLink: R_Password })
                }
            }
            catch (error) {

            }
        }
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        let
            params: any = new
                URLSearchParams
                (window.location.search);
        let invited = params.get('invited');
        const resetPassword = params.get('ResetPassword')
        //console.log(invited, "invited.............");
        //console.log(emailId, "test");
        if (invited) {
            debugger
            setEmailDisable(true)
            setShowField(true);
            try {
                const decodedToken: any = jwtDecode(invited);
                if (decodedToken.exp < Date.now() / 1000) {
                    setinvitedExpired(true)
                    setinvitedMessage('Your verification link has expired Please reach your Admin')
                }
                else {
                    debugger
                    if (decodedToken && decodedToken.emailAddress) {
                        const emailAddress = decodedToken.emailAddress;
                        const isAdmin = decodedToken.isAdmin
                        ////console.log(emailAddress, isAdmin, "emailAddress");
                        setdisableEmail(true)
                        setEmailId(emailAddress)
                        //invited
                        setControlData({
                            ...controlData,
                            mailId: emailAddress,
                            isAdmin: isAdmin
                        })
                        userVerification({ mailId: emailAddress, isAdmin: isAdmin, secretLink: invited })

                    }
                }
            }
            catch (e: any) {
                ////console.log(e.message);

            }

        }
        if (token) {
            debugger
            const decodedToken: any = jwtDecode(token);
            ////console.log(decodedToken);
            if (decodedToken && decodedToken.emailAddress) {
                const emailAddress = decodedToken.emailAddress;
                ////console.log(emailAddress, "emailAddress");
                setdisableEmail(true)
                setEmailId(emailAddress)
                setControlData({
                    ...controlData,
                    mailId: emailAddress

                })
                userVerification(emailAddress)
            }
        }
    }, []);

    // PS_59, PS_60, PS_61, PS_62, PS_63, PS_64, PS_65
    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            }
            if (timer == 0) {
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);

    // PS_55,PS_56,PS_66PS_67,PS_68,PS_69
    async function userVerification(data: object) {
        try {
            setLoader(true)
            ////console.log(data, "data");

            const verifyAccount: any = await accountVerification(data)
            setLoader(false)

            ////console.log(verifyAccount, "account verification");
            if (verifyAccount.data.statusCode === 201) {
                return true
            }
            else if (verifyAccount.data.statusCode === 200) {
                return true
            }
            else if (verifyAccount.data.statusCode === 400) {
                setinvitedExpired(true)
                setinvitedMessage('Invalid URL')
            }
        }
        catch {
            setAlert(true)
        }
    }

    const handleLoginClick = (e: any) => {
        try {
            debugger
            if (showField) {
                resetPassword(e);
            }
            else {
                loginDetails(e);
            }
        }
        catch {
            setAlert(true)
        }

    };
    const handleBackToLogin = () => {
       

        setControlData(controlData)
        setIsResetPswdPg(false);
        setShowField(false);
          setEmailId("")
        controlData = {
            mailId: "",
            password: "",
            confirmPassword: "",
            isAdmin: false} 
            setControlData(controlData)
        // Resetting the email ID
        history.push('/Login'); // Navigating to the Login page
      };
    const encryptPassword = (password: any) => {
        ////console.log("inside encryptPass function");

        // Replace with your own secret key
        const secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';

        // Encrypt the password using AES algorithm
        const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
        ////console.log(encryptPassword, "encryptedPasssssss");

        return encryptedPassword;
    };

    const setExpirationTimer=(time:string):void=>{
        try{
            setExpirationTime(new Date((new Date(time)).getTime() + 30* 60 * 1000).toISOString());
        }catch(err:any){
            console.error('Error in setExpirationTimer fn',err);
        }
    }
    //PS_107.,PS_108,-,PS_109,PS_110,PS_111,PS_112,-,PS_113,PS_114,PS_115,PS_116,-,PS_117,PS_118,PS_119,PS_120,PS_121
    const fieldValidation = (e: React.ChangeEvent<HTMLInputElement>): any => {
        try {
            let validObj: LoginErrorData = {
                errorEmailAddress: '',
                errorPassword: '',
                errorConfirmPassword: '',
            };
            let flag = true;
            controlData = { ...controlData, [e.target.name]: e.target.value }
            setControlData(controlData);
            errorData = { ...errorData, [e.target.name]: '' }
            setErrorData(errorData);

            // Email validation and error setting/clearing
            if (e.target.name === 'mailId') {
                   emailId=e.target.value
                   setEmailId(emailId)
                if (e.target.value === '') {
                    validObj.errorEmailAddress = 'Email Address cannot be empty';
                    setErrorData(validObj);
                    flag = false;

                }

                else if (e.target.name === 'mailId' && Onlymail(e.target.value)) {
                    validObj.errorEmailAddress = 'The Entered Email ID is invalid';
                    setErrorData(validObj);
                    flag = false;
                }
            }

            // Password validation and error setting/clearing
            if (e.target.name === 'password') {
                if (e.target.value == '') {
                    validObj.errorPassword = 'Password cannot be empty';
                    setErrorData(validObj);
                    flag = false;
                }
                
                else if (PasswordRegex(e.target.value) || e.target.value.length <= 8) {
                    validObj.errorPassword = 'Please enter a valid password';
                    setErrorData(validObj);
                    flag = false;
                }
                else if (controlData.confirmPassword != '' && e.target.value !== controlData.confirmPassword) {
                    validObj.errorPassword = 'Passwords do not match';
                    setErrorData(validObj);
                    flag = false;
                }
                else if (controlData.confirmPassword != '' && e.target.value === controlData.confirmPassword) {
                    validObj.errorPassword = '';
                    setErrorData(validObj);
                    flag = true;
                }
                else if (controlData.confirmPassword == '' && showField) {
                    validObj.errorPassword = '';
                    setErrorData(validObj);
                    flag = false;
                }
            }

            // Confirm Password validation and error setting/clearing
            if (showField) {
                debugger
                if (e.target.name === 'confirmPassword') {
                    if (e.target.value === '') {
                        validObj.errorConfirmPassword = 'Confirm Password cannot be empty';
                        setErrorData(validObj);
                        flag = false;
                    }
                    else if (e.target.value.includes(' ')) {
                        setControlData({ ...controlData, confirmPassword: controlData.confirmPassword.trim() });
                    }
                    else if (PasswordRegex(e.target.value)) {
                        validObj.errorConfirmPassword = 'Please enter a valid password';
                        setErrorData(validObj);
                        flag = false;
                    }
                    else if (controlData.password !== e.target.value) {
                        validObj.errorConfirmPassword = 'Passwords does not match';
                        setErrorData(validObj);
                        flag = false;
                    }
                    else if (controlData.password === e.target.value) {
                        validObj.errorConfirmPassword = '';
                        setErrorData(validObj);
                        flag = true;
                    }

                }
            }
            setErrorData(validObj);
            setButtonDisable(flag)
            return flag

        }

        catch {
            setAlert(true)
        }

    };


    //PS_91,-,PS_92,-,PS_93,-,PS_94,PS_95,PS_96,PS_97,-,PS_98,PS_99,PS_100,PS_101,PS_102,-,PS_103,PS_104,PS_105
    async function resetPassword(e: React.FormEvent<HTMLFormElement>) {
        try {
            const
                params: any = new
                    URLSearchParams
                    (window.location.search);
          //  console.log("controlled data 1", controlData);

            e.preventDefault();
            if (fieldValidation(e as any)) {
                setLoader(true)
                let result: any;
               // console.log("came here",controlData.isAdmin);
                
                if (controlData.isAdmin === "NewRegistration" || controlData.isAdmin === false) {
                    const invited = params.get('invited');
                    const decodedToken: any = jwtDecode(invited);
                    const isAdmin = decodedToken.isAdmin
                    const payload = {
                        mailId: decodedToken.emailAddress,
                        isAdmin: isAdmin,
                        secretLink: invited,
                        password: controlData.password,
                        confirmPassword: controlData.confirmPassword,
                        newManageUser: "newManageUser"
                    }
                   // console.log("the payload",payload);
                    
                    result = await resetPasskey(payload)
                    console.log(result, "result");
                    if (result.data.statusCode === 200) {
                        setOTPPopUp(true);
                        setLoader(false)
                        setOTP(result?.data?.data?.OTP);
                        setExpirationTimer(result?.data?.data?.tokenGenaratedAt)
                        decryptOTP(result?.data?.data?.OTP)
                        setClientData(result)
                        setOTPPopUp(true); // Show the OTP popup
                    }
                    else if (result.data.statusCode === 400) {
                        setLoader(false)
                        setShowPopup(true)
                        
                        setinvitedMessage('Invalid URL')
                    }
                    else {
                        setLoader(false)
                    }
                }
                else {
                    // Show the OTP popup
                    const queryParams = new URLSearchParams(window.location.search);
                    const R_Password: any = queryParams.get('Resetpassword');
                    let resetPassword;
                    const payload = {
                        mailId: controlData.mailId,
                        secretLink: R_Password,
                        password: controlData.password,
                        confirmPassword: controlData.confirmPassword,
                        isAdmin: controlData.isAdmin,
                        resetPassword:"resetPassword"
                    }

                    resetPassword = await resetPasskey(payload)
                    debugger
                    console.log("the reset password", resetPassword);

                    debugger
                    if (resetPassword.data.statusCode === 200) {
                        setLoader(false)
                        setOTPPopUp(true);
                        setOTP(resetPassword?.data?.data?.OTP);
                        setExpirationTimer(resetPassword?.data?.data?.tokenGenaratedAt);
                        setClientData(resetPassword)
                        decryptOTP(resetPassword?.data?.data?.OTP)

                    } else if (resetPassword.data.statusCode === 400) {
                        setLoader(false)
                        setShowPopup(true)
                        setPopupMessage('Invalid URL')
                       
                    } else {
                        setLoader(false)
                    }
                }

                ////console.log(result, "result");



                ////console.log(result, "result");

                // setLoader(false)
                // history.push('/Login')
                // window.location.reload()
            }
        }
        catch (error) {
            setLoader(false)
            console.error('Error:', error);
            setAlert(true)
        }
    }
    // PS_70,PS_71,PS_72,PS_73,PS_74,PS_79,PS_80,-,PS_81,-,PS_82,-,PS_83,-,PS_84,-,PS_85,-,PS_86,-,PS_87,-,PS_88,PS_89,PS_90 
    async function loginDetails(e: React.FormEvent<HTMLFormElement>) {
        debugger;
        try {
            setLoader(true);
            e.preventDefault();

            debugger
            if (fieldValidation(e as any)) {
                let payload = {
                    mailId: controlData.mailId,
                    password: await encryptPassword(controlData.password),
                    isAdmin: controlData.isAdmin,
                    
                }
                let result = await fetchLoginDetails(payload);
                ////console.log(result, 'result');
                debugger
                if (result.data.statusCode !== 200) {
                    if (result.data.statusMessage === 'Incorrect Password') {
                        setErrorData({ ...errorObj, errorPassword: 'The entered Email ID or Password is invalid' });
                        sessionStorage.clear();
                    }
                    if (result.data.statusCode === 429) {
                        setShowPopup(true);
                    }

                    if (result.data.statusMessage === 'Please Verify Your Account') {
                        setErrorData({ ...errorObj, errorPassword: 'Please Verify Your Account' });
                    }

                    if (result.data.statusMessage === 'Please Use SSO') {
                        setErrorData({ ...errorObj, errorPassword: 'Please Use SSO' });
                    }
                    if (result.data.statusMessage === 'User Not Found') {
                        setErrorData({ ...errorObj, errorPassword: 'Please enter Valid Email ID' });
                    }
                    if (result.data.statusMessage === 'User Inactive') {
                        setPopupMessage("Your AVAMIGRATRON account is currently inactive. To regain access to your account and our services, please contact the AVAMIGRATRON support.")
                        setShowPopup(true);                     
                    }
                    if (result.data.statusMessage === 'Please Use Email') {
                        setErrorData({ ...errorObj, errorPassword: 'Please Use Email' });
                    }
                    if (result?.data?.statusCode == 400) {
                        setPopupMessage(result?.data?.statusMessage)
                        setShowPopup(true);
                    }
                } else {
                    if (result.data.statusMessage == 'navigate to plan') {
                        setPage(true)
                    }
                    setClientData(result);
                    //console.log(result.data.data.OTP, "hello.....hai....");
                    setEmailId(controlData.mailId)
                    setOTP(result.data.data.OTP);
                    decryptOTP(result.data.data.OTP);
                    setExpirationTimer(result?.data?.data?.tokenGenaratedAt)
                    setTimer(45);
                    setOTPPopUp(true); // Show the OTP popup
                    let passphrase = `${process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY}`;
                    ////console.log("otp", otp)
                    ////console.log("React Node Ecryption : ", process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY);
                    const decryptedBytes = CryptoJS.AES.decrypt(result.data.data.OTP, passphrase);
                    ////console.log("Decrypted Bytes : ", decryptedBytes);
                    const decryptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
                    console.log("Decrypted Value : ", decryptedValue);
                }
            }
        } catch (error) {
            setAlert(true)
        } finally {
            setLoader(false);
        }
    }
    const msalInstance = new PublicClientApplication(msalConfig);
    msalInstance.initialize();

    const msalInstanceAdmin = new PublicClientApplication(msalConfigAdmin);
    msalInstanceAdmin.initialize();

    //PS_123,PS_124,PS_125,PS_126,-,PS_127,PS_128,PS_129,-,PS_130,PS_131,PS_132,PS_133,PS_134,PS_135,-,PS_136,PS_137,PS_138,PS_139,PS_140,PS_141,PS_142,PS_143,PS_144
    const handleSSOLogin = async () => {
        try {
            debugger
            let loginResponse: any;
            if(controlData.isAdmin == true){
                loginResponse = await msalInstanceAdmin.loginPopup({
                    scopes: ["User.Read"],
                    prompt: "select_account"
                })
            }
            else{
                loginResponse = await msalInstance.loginPopup({
                    scopes: ["User.Read"],
                    prompt: "select_account"
                });
            }

            if (loginResponse.account) {
                const userEmail: any = loginResponse.account.username; // Assuming 'username' is the email

                // Update controldata with the user's email address
                const updatedControldata: object = { ...controlData, mailId: userEmail, password: null };
                setLoader(true)
                let result: any = await fetchLoginDetails(updatedControldata);
                setLoader(false)
                debugger
                ////console.log(result, 'hhhhh');
                if (result.data.statusCode != 200) {

                    if (result.data.statusMessage === "User Not Found") {
                        setErrorData({ ...errorObj, errorPassword: "Please enter Valid Email ID" })
                        sessionStorage.clear()
                    }
                    if (result.data.statusMessage === "Please Verify Your Account") {
                        setErrorData({ ...errorObj, errorPassword: "Please Verify Your Account" })
                        sessionStorage.clear()
                    }
                    if (result.data.statusMessage === "Please Use Email") {
                        setErrorData({ ...errorObj, errorPassword: "Please Use Email" })
                        sessionStorage.clear()
                    }

                    if (result?.data?.statusCode == 400) {
                        setPopupMessage(result?.data?.statusMessage)
                        setShowPopup(true);
                    }
                }
                else {
                    if (controlData.isAdmin === false) {
                        let deccryptedToken = CryptoJS.AES.decrypt(result.data.data.token, rn_secretKey);
                        let encryptedToken: any = CryptoJS.AES.encrypt(deccryptedToken, cs_secretKey);
                        sessionStorage.setItem("c_at", encryptedToken)
                        setLoader(false)
                        setOTPPopUp(false);
                        //console.log(deccryptedToken);

                        // setSuccessPopup(true);
                        setOTPValues(['', '', '', '', '', '']);;
                        setTimer(45);
                        let payload = {
                            token:
                                { clientToken: result.data.data.token }
                        }
                        setLoader(true)
                        if (result.data.statusMessage == 'navigate to plan') {
                            window.location.href = '/NewPlan'
                        }
                        else {
                            const taskCount: any = await clientTaskCount(payload)
                            ////console.log(taskCount?.data?.data?.count, "task");

                            setLoader(false)
                            if (taskCount.data.statusCode === 200) {


                                if (taskCount?.data?.data > 0) {
                                    window.location.href = '/Dashboard'
                                }
                                else {
                                    window.location.href = '/Task'
                                }

                            }
                            else {
                                setinvitedExpired(true)
                                setinvitedMessage(commonErrorMsgs.generalError)

                            }
                        }

                    }
                    else {
                        let deccryptedToken = CryptoJS.AES.decrypt(result.data.data.token, rn_secretKey).toString(CryptoJS.enc.Utf8);
                        let encryptedToken: any = CryptoJS.AES.encrypt(deccryptedToken, as_secretKey);
                        sessionStorage.setItem("a_at", encryptedToken)
                        setLoader(false)
                        setOTPPopUp(false);
                        // setSuccessPopup(true);
                        setOTPValues(['', '', '', '', '', '']);;
                        setTimer(45);
                        window.location.href = '/Admin/Dashboard'
                    }
                }

            } else {
                ////console.log("Login was successful, but could not retrieve user details.");
            }
        } catch (error) {
            // console.error("Login failed", error);
        }
    };

    const decryptOTP = (otp:string)=> {
        let passphrase = `${process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY}`;
        const decryptedBytes = CryptoJS.AES.decrypt(otp, passphrase);
        const decryptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
        setDecryptedOTP(decryptedValue)
    };

    const handleVerifyOtp = async () => {
        debugger
        const userOtp = otpValues.join("");
        ////console.log(otp, otpValues, userOtp, "mdkd");
        //console.log(decryptedValue, "value of decryption");


        //console.log(decryptedValue, 'fhghjj');

        const currentDateTime = new Date().toISOString();

        if (!(decryptedOTP === userOtp) || !expirationTime || expirationTime < currentDateTime ) {
            setOtpError(true);
        } else {

            if (controlData.isAdmin == true) {
                let deccryptedToken = CryptoJS.AES.decrypt(clientData.data.data.token, rn_secretKey);
                let encryptedToken: any = CryptoJS.AES.encrypt(deccryptedToken, as_secretKey);
                sessionStorage.setItem("a_at", encryptedToken)
                setLoader(false)
                setOTPPopUp(false);
                // setSuccessPopup(true);
                setOTPValues(['', '', '', '', '', '']);;
                setTimer(45);
                window.location.href = '/Admin/Dashboard'
            }
            else if (controlData.isAdmin == "NewRegistration") {
                debugger
                let deccryptedToken = CryptoJS.AES.decrypt(clientData.data.data.token, rn_secretKey).toString(CryptoJS.enc.Utf8);
                let encryptedToken: any = CryptoJS.AES.encrypt(deccryptedToken, cs_secretKey);
                sessionStorage.setItem("c_at", encryptedToken)
                setOTPPopUp(false);
                // setSuccessPopup(true);
                setOTPValues(['', '', '', '', '', '']);;
                setTimer(45);
                let payload = {
                    token:
                        { clientToken: clientData.data.data.token }
                }
                window.location.href = '/NewPlan'
            }
            else {
                debugger
                let deccryptedToken = CryptoJS.AES.decrypt(clientData.data.data.token, rn_secretKey).toString(CryptoJS.enc.Utf8);
                let encryptedToken: any = CryptoJS.AES.encrypt(deccryptedToken, cs_secretKey);
                sessionStorage.setItem("c_at", encryptedToken)
                setOTPPopUp(false);
                // setSuccessPopup(true);
                setOTPValues(['', '', '', '', '', '']);;
                setTimer(45);
                let payload = {
                    token:
                        { clientToken: clientData.data.data.token }
                }
                setLoader(true)
                if (page) {
                    window.location.href = '/NewPlan'
                }
                else {
                    const taskCount: any = await clientTaskCount(payload)
                    ////console.log(taskCount?.data?.data?.count, "task");
                    setLoader(false)
                    if (taskCount.data.statusCode === 200) {

                        if (taskCount?.data?.data > 0) {
                            window.location.href = '/Dashboard'
                        }
                        else {
                            window.location.href = '/Task'
                        }

                    }
                    else {
                        setinvitedExpired(true)
                        setinvitedMessage(commonErrorMsgs.generalError)

                    }
                }

            }

        }
    };
    const sendMail = async () => {
        ////console.log("trigger....................");
        debugger
        setOTPPopUp(false)
        setOTPValues(['', '', '', '', '', ''])
        setOtpError(false)
        let payload = {
            mailId: controlData.mailId,
        };

        setLoader(true)
        const response: any = await sendOtp(payload);
        setLoader(false)
        setOTPPopUp(true)

      console.log(response, "shgdgchsgdvhgsdchsdch");

        if (response?.data?.statusCode == 200) {
            setOTPPopUp(true)
            setTimer(45)
            ////console.log(response.data, "otp");
            setExpirationTimer(response?.data?.data?.tokenGenaratedAt)
            decryptOTP(response?.data?.data?.OTP)
            debugger
            setOTP(response.data.data.OTP)

        }
        else if(response?.data?.data == "Action blocked. Please try again or reach AVAMIGRATRON Support."){
            setOTPPopUp(false)
            setShowPopup(true)
            setPopupMessage("Max limits reached, please try again after an hour or email us at migrationsupport@avatest.in.")
        }
        else{
            setOTPPopUp(false)
            setAlert(true)
            setShowPopup(true)
            setPopupMessage(commonErrorMsgs.generalError)
        }
    };
    //PS_172,PS_173,PS_174,PS_175,-,PS_176,-,PS_177,PS_178,PS_179,PS_180
    const handleChange = (index: any, value: any) => {
        // Allow only digits
        debugger
        const newDigit = value.replace(/\D/g, "").slice(-1);

        // Update OTP value at the specified index
        const newOtpValues = [...otpValues];
        newOtpValues[index] = newDigit;
        setOTPValues(newOtpValues);

        // Move focus to the next input if a digit is entered
        if (newDigit && index < otpValues.length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };
    const handleKeyDown = (e: any, index: any) => {
        if (e.keyCode === 8) { // Check if the backspace key is pressed
            // Clear the value of the current input
            const newOtpValues = [...otpValues];
            newOtpValues[index] = ''; // Clear the value of the current input
            setOTPValues(newOtpValues);

            if (index > 0) {
                // If current input is not the first one
                // Move focus to the previous input
                const prevInput = document.getElementById(`otp-input-${index - 1}`);
                if (prevInput) {
                    prevInput.focus();
                }
            }

            // Prevent default behavior to avoid any additional clearing
            e.preventDefault();
            e.stopPropagation();
        }
    };
    //PS_163,PS_164,PS_165,PS_166,PS_167,-,PS_168,PS_169,PS_170,PS_171
    const handlePaste = (event: any, index: any) => {
        event.preventDefault();
        const pastedData = event.clipboardData.getData("Text");
        const pastedDigits = pastedData.split("").slice(0, otpValues.length);
        const newOtpValues = [...otpValues];
        pastedDigits.forEach((digit: any, i: any) => {
            if (digit.match(/\d/)) {
                newOtpValues[index + i] = digit;
            }
        });
        setOTPValues(newOtpValues);
    };

    const renderOTPpopup = () => {
        return (
            <div
                className="modal fade show"
                id="otp-popup"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="Success-popupLabel"
                aria-hidden="true"
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-3">
                        <div className="modal-header border-0 justify-content-center py-2 mb-5 position-relative">
                            <span className="position-absolute">
                                <img
                                    src="/images/verification-icon.svg"
                                    alt="verification-icon"
                                />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center pt-0">
                            <div className="row justify-content-center">
                                <h5
                                    className="modal-title mb-2 primary-header"
                                    id="Success-popupLabel"
                                >
                                    Enter OTP
                                </h5>
                                <p className="primary-data">
                                    Please enter the 6-digit OTP that was sent to your email.
                                </p>
                                <div className="col-md-9">
                                    <div className="d-flex justify-content-between gap-2 mb-2" style={{ marginTop: "20px" }}>
                                        {otpValues.map((value: any, index: any) => (
                                            <div className="d-block me-3" key={index}>
                                                <input
                                                    id={`otp-input-${index}`}
                                                    className="form-control custom-form"
                                                    type="text"
                                                    value={value}
                                                    onChange={(e) => handleChange(index, e.target.value)}
                                                    onPaste={(e) => handlePaste(e, index)}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                                    maxLength={1}
                                                    onKeyDownCapture={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleVerifyOtp()
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ))}

                                    </div>
                                    <div className="d-flex justify-content-end font-12 font-semibold">
                                        {timer > 0 ? (
                                            <span>
                                                Resend OTP in {timer} seconds
                                            </span>
                                        ) : (
                                            <a className="font-semibold link-color cursor-pointer" onClick={() => { sendMail() }}>
                                                Resend OTP
                                            </a>
                                        )}
                                    </div>
                                    <div>
                                        <span className="mandatory-color">
                                            {otpError == true ? "Invalid OTP" : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold mb-2"
                                data-bs-dismiss="modal"
                                onClick={handleVerifyOtp}
                            >
                                Verify
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {/* Login page starts here */}
            <div className="bg-white">
                <div className="container-fluid">
                    <div className="row ">
                        {/*Left container starts here */}
                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 login-brd-left w-60">
                            <div className="row justify-content-center">
                                <div className="col-md-12 px-md-4 px-2 pt-4">
                                    <span>
                                        <a href="https://www.avamigratron.com" className='anchorAsPointer'>
                                            <img
                                                src="/images/brand-logo.svg"
                                                alt="brand-logo"
                                                className="login-logo-sty mb-3"
                                            />
                                        </a>
                                    </span>
                                </div>
                                <div className="col-xl-8 col-lg-9 col-md-9 col-sm-12 pt-0 pb-0 ">
                                    <div className="login-content-height w-100 d-flex">
                                        <div className="form-signup my-auto w-100">
                                            <h5 className="mb-4 font-20 login-header font-bold">
                                                AVAMIGRATRON
                                            </h5> {isResetPswdPg ? (<><p className="tertiary-header mb-3">Reset Password</p><p className="mt-2 mb-4 font-14 font-regular text-gray">
                                                Please enter new password to reset your password</p></>) :
                                                <p className="tertiary-header mb-3">Welcome!</p>}
                                            {isResetPswdPg ? <></> :
                                                <>
                                                    <button
                                                        type="button"
                                                        className="btn w-100 mb-3 font-14 font-semibold px-4 secondary-btn"
                                                        onClick={handleSSOLogin}
                                                    >
                                                        <span>
                                                            <img
                                                                src="/images/windows-icon.svg"
                                                                alt="windows-icon"
                                                                className="me-2"
                                                            />
                                                        </span>{" "}
                                                        Login with Microsoft
                                                    </button>
                                                    {otpPopUp && renderOTPpopup()} {/* Render the OTP popup */}

                                                    <p className="mt-3 mb-4 font-14 font-regular text-gray horz-line-pos">
                                                        <span className="horz-line">Or</span>
                                                    </p>
                                                </>
                                            }
                                            {isResetPswdPg ? <></> :
                                                <div className="col-md-12 mb-3">

                                                    <label
                                                        className="form-label font-14 font-semibold"
                                                        htmlFor="emailAddress"
                                                    >
                                                        Email Address{" "}
                                                    </label>
                                                    <input
                                                        className="form-control custom-form"
                                                        type="text"
                                                        name="mailId"
                                                        id="emailAddress"
                                                        value={emailId}
                                                        disabled={emailDisable}
                                                        placeholder="Enter Email Address"
                                                        onChange={(e) => { fieldValidation(e) }}
                                                        maxLength={320}
                                                        

                                                    />
                                                    {errorData.errorEmailAddress && (<p className="font-12 font-semibold mandatory-color mt-0 mb-0 ">{errorData.errorEmailAddress}</p>)}
                                                </div>}
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label font-14 font-semibold" htmlFor="password" placeholder="New Password">
                                                {isResetPswdPg ? <>New Password </> :  <>Password &nbsp;</>  }
                                                
                                                <Tippy
                                                        arrow={true}
                                                        followCursor={'horizontal'}
                                                        placement='top'
                                                        content="Password must be 8-20 characters, including uppercase, lowercase, number, and special character."
                                                    >
                                                        <img
                                                            src="/images/tooltip.svg"
                                                            alt="tooltip"
                                                            width={11}

                                                        />
                                                    </Tippy>

                                                </label>
                                                <div className="position-relative">
                                                    <input
                                                        type={passwordVisible ? "text" : "password"}
                                                        className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                                        id="password"
                                                        name="password"
                                                        placeholder={isResetPswdPg ? "New password" : "Enter Password"}
                                                        value={controlData.password}
                                                        onKeyDownCapture={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleLoginClick(e)
                                                            }
                                                        }}
                                                        onChange={(e) => { fieldValidation(e) }}
                                                        maxLength={20}
                                                        onPaste={(e)=>{e.preventDefault()}}
                                                    />

                                                    <img
                                                        className={`position-absolute ${passwordVisible ? 'custom-top' : ''} custom-postion top-50 cursor-pointer`}
                                                        src={`/images/${passwordVisible ? `password-eye-open.svg` : `password-icon.svg`}`}
                                                        alt="password-icon"
                                                        onClick={() => { setPasswordVisible(!passwordVisible); }}

                                                    />
                                                </div>
                                                {errorData.errorPassword && (
                                                    <p className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{errorData.errorPassword}</p>)}
                                            </div>
                                            {showField && (
                                                <div className="col-md-12 mb-3">
                                                    <label className="form-label font-14 font-semibold" htmlFor="confirmPassword" placeholder="Confirm Password">
                                                        Confirm Password                           <Tippy
                                                            // delay={[500]}
                                                            arrow={true}
                                                            followCursor={'horizontal'}
                                                            plugins={[followCursor]}
                                                            placement='top'
                                                            content="Please re-enter your password to confirm. Ensure it matches the password you entered above."
                                                        >
                                                            <img
                                                                src="/images/tooltip.svg"
                                                                alt="tooltip"
                                                                width={11}

                                                            />
                                                        </Tippy>

                                                    </label>
                                                    <div className="position-relative">
                                                        <input
                                                            type={permanentPasswordVisibility ? "text" : "password"}
                                                            className="form-control password-eye-hide custom-form font-14 font-regular py-2 "
                                                            id="confirmPassword"
                                                            name="confirmPassword"
                                                            placeholder="Confirm password"

                                                            onKeyDownCapture={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    handleLoginClick(e);
                                                                }
                                                            }}
                                                            onChange={(e) => { fieldValidation(e); }}
                                                            maxLength={20}
                                                            onPaste={(e)=>{e.preventDefault()}}
                                                        />

                                                        <img
                                                            className={`position-absolute ${permanentPasswordVisibility ? 'custom-top' : ''} custom-postion top-50 cursor-pointer`}
                                                            src={`/images/${permanentPasswordVisibility ? `password-eye-open.svg` : `password-icon.svg`}`}
                                                            alt="password-icon"
                                                            onClick={() => { setPermanentPasswordVisibility(!permanentPasswordVisibility) }}

                                                        />
                                                    </div>
                                                    {errorData.errorConfirmPassword && (
                                                        <span className="font-12 font-semibold mandatory-color mt-2 mb-0">{errorData.errorConfirmPassword}</span>
                                                    )}
                                                </div>
                                            )}
                                            {isResetPswdPg ? <></> :
                                                <div className="text-end mt-2 mb-5">
                                                    <a href="/Forgotpassword" className="font-14 font-semibold link-color" >
                                                        Forgot Password
                                                    </a>
                                                </div>
                                            }
                                            {isResetPswdPg ?
                                                <> <button
                                                    type="button"
                                                    className="btn w-100 mb-3 font-14 font-semibold px-4 primary-btn"
                                                    onClick={(e) => { handleLoginClick(e) }}
                                                    disabled={!buttonDisable || controlData.password.length < 7 || controlData.mailId.length < 7} // Set disabled attribute based on disableButton state
                                                >
                                                    Reset Password
                                                </button> <p className="mt-0 mb-5 font-14 font-regular text-gray text-center">
                                                        <a className="cursor-pointer font-semibold link-color" onClick={handleBackToLogin}>
                                                            Back to Login
                                                        </a>
                                                    </p>
                                                </>
                                                :
                                                <>
                                                    <button
                                                        type="button"
                                                        className="btn w-100 mb-3 font-14 font-semibold px-4 primary-btn"
                                                        onClick={(e) => { handleLoginClick(e) }}
                                                        disabled={!buttonDisable || controlData.password.length < 7 || controlData.mailId.length < 7} // Set disabled attribute based on disableButton state

                                                    >
                                                        Login
                                                    </button>
                                                    <p className="mt-0 mb-5 font-14 font-regular text-gray" onClick={() => { history.push('/Registration') }} >
                                                        Don't have an Account? <a className="cursor-pointer font-semibold link-color">
                                                            Create Account
                                                        </a>
                                                    </p>
                                                </>
                                            }
                                            {otpPopUp && renderOTPpopup()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Left container ends here */}
                        {/*Right container starts here */}
                        <div className=" col-xl-5 d-xl-block d-none p-0 w-40">
                            <div className="login-bg-height position-relative my-0">
                                <div className="login-bg-height login-bg-img text-center">
                                    <h5 className="login-header font-bold font-32 pt-5 mb-4">
                                        World's No 1 Enterprise-ready
                                    </h5>
                                    <p className="font-24 font-semibold primary-color">
                                        Migration Platform!
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*Right container ends here */}
                    </div>
                    {ShowPopup == true ? (
                        <div className="modal fade show" id="Failure-popup" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="Failure-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>

                            <div className="modal-dialog modal-dialog-centered">

                                <div className="modal-content popup-brd-radius">

                                    <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                        <span className="position-absolute">

                                            <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                        </span>

                                    </div>

                                    <div className="modal-body border-0 text-center">

                                        <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Failure</h5>

                                        <p className="primary-data">{PopupMessage}</p>

                                    </div>

                                    <div className="modal-footer border-0 pb-4 justify-content-center">

                                        <button type="button" onClick={() => setShowPopup(false)} className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">OK</button>

                                    </div>

                                </div>


                            </div>

                        </div>) : null}
                </div>
                {/* Login page ends here */}



            </div>
            {invitedExpired ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                {invitedMessage}
                            </h5>
                            <p className="primary-data">
                                {invitedExpired}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => { window.location.href = '/Login' }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
            {loader && (
                <div className="overlay">
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div
                            className="spinner-border Loader loader-color align-center "
                            role="status"
                        >
                            <span>
                                <span className="loader-inner-circle" />
                            </span>
                        </div>
                        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                            Verifying...
                        </p>
                    </div>
                </div>
            )}
        </>
    );
}