import { useEffect, useState, useRef } from "react";
import { AppSpecificConfigurationsProps, entityConfig, sessionResponse } from "../../Common/Interface";
import objCryptoJS from "crypto-js";
import { followCursor } from 'tippy.js';
import Tippy from "@tippyjs/react";

import { decodeSessionDetails } from "../../Services/CommonServices";
import { useHistory } from "react-router";
import { fetchUserOptionsFromDatabase, postOutlookCustomization, fetchOptionsFromDatabase, postCustomization } from "../../Services/AppSpecificServices";
import { updateModifiedDate } from "../../Services/taskService";
import jwt from 'jwt-decode'
import { getSession } from "../../Services/manageClientAPI";
import { insertLastVisitedPageValueApi } from "../../Services/MigrationAndManageMigrationServices";

const AppSpecificConfigurations: React.FC<AppSpecificConfigurationsProps> = ({
  taskId,
  applicationName,
  isOpen,
  disabled,
  isEdit,
  isEditCheck,
  roleCheck,
  onToggle,
  onAppSpecificConfigSave,
  updateAppSpecificConfigSaveStatus,
  isDirect,
  setIsDirect,
  migrationMode,
  setMigrationMode,
  lastVisitedStage,
  setLastVisitedStage
}) => {
  /*PS_01 to PS_09 will be executed*/
  const history = useHistory()
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [endDateMin, setEndDateMin] = useState<string>("");
  const [endDateMax, setEndDateMax] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  let [clientName, setclientName] = useState<string>('');
  let [companyName, setCompanyName] = useState<string>('');
  let [emailAddress, setEmailAddress] = useState<string>('');
  let [role, setRole] = useState<string>('');
  let [isAdmin, setIsAdmin] = useState<boolean>(false);
  let [adminName, setAdminName] = useState<string>('');
  let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
  let [adminrole, setAdminRole] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  const [versionNotPrefered,setVersionNotPrefered]=useState<boolean>(false);
  // //debugger
const lastStage : number = 3;


  let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);
  //  const isOpen= true
  // const [role, setRole] = useState<string>(sessionStorage.getItem("role") || "");
  const [disableActions, setDisableActions] = useState<boolean>(
    roleCheck.toLowerCase() === "viewer"
  );

  // Options object for OUTLOOK
  const [mbxSelectedOptions, setMbxSelectedOptions] = useState<entityConfig>({
    isMailMigrationSelected: true,
    isContactsMigrationSelected: true,
    isCalendarMigrationSelected: true,
    isTasksMigrationSelected: true,
    isFileMigrationSelected: true,
    isVersionSelected: true,
    fromDate: "",
    toDate: "",
    taskId: Number(taskId),
    applicationName: "outlook",
  });

  const outlookOptions:Array<string> = ['isMailMigrationSelected','isContactsMigrationSelected', 'isCalendarMigrationSelected', 'isTasksMigrationSelected', 'isFileMigrationSelected'];

  // Options object for Other Applications
  const [selectedOptions, setSelectedOptions] = useState<any>({
    isActualConversation: false,
    isHtmlMigration: false,
    isServiceAccount: false,
    isActualUser: false,
    isDirectMigration: false,
    isCustomMigration: false,
    isSiteContentSelected: false,
    isCreatedSelected: true,
    isPublicViewSelected: false,
    isUserAlertSelected: false,
    isPageCommentsSelected: false,
    isMeetingRecordingSelected: false,
    isFileAttachmentsSelected: false,
    isTasksSelected: false,
    isCalendarsSelected: false,
    isContactsSelected: false,
    isMailsSelected: true,
    isVersionsSelected: true,
    taskId: Number(taskId),
    fromDate: "",
    toDate: "",
    isSharedWithMeSelected: false,
    isTrashSelected: false,
    applicationName: applicationName,
    isListMigration: true,
    isDocLibraryMigration: true,
    isPageMigration: true,
    isSitePermission: true,
    isListLibPermission: true,
    isShareLinkPermission: true,
  
  });
  //console.log(selectedOptions, "optionsssss");

  const [basedonApplicationSAC, setBasedonApplicationSAC] = useState<boolean>(false);
  /**PS_31 to PS_37 will be executed
*/
  // Date Change Handling for Outlook
  const mbxHandleDateChange = (date: any, field: any) => {
    setBasedonApplicationSAC(false)
    setMbxSelectedOptions((prevState: any) => ({ ...prevState, [field]: date }));

    if (field === "fromDate") {
      setEndDateMin(date);
    }
    if (field === "toDate") {
      setEndDateMax(date);
    }
  };

  // Date Change Handling for other Applications
  const handleDateChange = (date: any, field: any) => {
    setBasedonApplicationSAC(false)
    setSelectedOptions((prevState: any) => ({ ...prevState, [field]: date }));

    if (field === "fromDate") {
      setEndDateMin(date);
    }
    if (field === "toDate") {
      setEndDateMax(date);
    }
  };

  const [fileTypes, setFileTypes] = useState<string[]>([]);
  useEffect(() => {
    sessionTokenProperties();
    // resetTimer();

    // Assuming you have access to the applicationName variable
    if (applicationName === 'outlook') {
      fetchData();
    } else {
      fetchAppSpecificData();
    }

    // window.addEventListener('keydown', resetTimer);
    // window.addEventListener('click', resetTimer);

    // return () => {
    //   window.removeEventListener('click', resetTimer);
    //   window.removeEventListener('keydown', resetTimer);
    // };

  }, [applicationName]);

  let resetTimer = async () => {
    try {
      // debugger;
      ////console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      ////console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      ////console.log("Reset Timer End");
    }
    catch (error) {
      ////console.log("Reset Timer Catch");
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoading(false)
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      ////console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        ////console.log("Reset Session Timer Inside");
        if (InActiveTimeout.current) {
          clearTimeout(InActiveTimeout.current);
        }
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout.current = setTimeout(() => {
          ////console.log("Expired");
          sessionStorage.removeItem("c_at");
          ////console.log("removed session");
          setisSessionEpired(true)
          setAlert('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        ////console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        console.log("minutes appspec : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          ////console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        ////console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      ////console.log("Reset Session Timer Catch");
      throw ex
    }
  };

  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.adminDetails) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
        }
        else {
          history.push("/Admin/Login")
        }
      }
      else {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setclientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          emailAddress = tokenResponse.clientDetails.Email;
          setEmailAddress(emailAddress);
          setRole(tokenResponse.clientDetails.Role);
          setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
        }
        else {
          history.push("/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };

  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  /**PS_24 to PS_30 will be executed
*/
  // Handling CheckBox change for Outlook
  const mbxHandleCheckboxChange = (option: string): void => {
    setBasedonApplicationSAC(false);
    setMbxSelectedOptions((prevState: any) => ({
      ...prevState,
      [option]: !prevState[option],
    }));
  }

  const handleOnedriveOptions=(option:string):void=>{
    try{

      if(option=='isVersionSelected'){
        //When Version alone toggled
        if(mbxSelectedOptions['isFileMigrationSelected']){
          //When the File is chosen previously

          if(mbxSelectedOptions['isVersionSelected']==true){
            setVersionNotPrefered(true);
          }else{
            setVersionNotPrefered(false);
          }

          setMbxSelectedOptions((prevState: any) => ({
            ...prevState,
            [option]: !prevState[option],
          }));

        }else{
          //When the File is not chosen
          if(mbxSelectedOptions['isVersionSelected']==false){
            //When the Version was selected
            setVersionNotPrefered(false);
          }
          setMbxSelectedOptions((prevState: any) => ({
            ...prevState,
            [option]: !prevState[option],
            'isFileMigrationSelected': true
          }));
        }
      }else{

        if(versionNotPrefered && mbxSelectedOptions['isFileMigrationSelected']==false){
          setMbxSelectedOptions((prevState: any) => ({
            ...prevState,
            [option]: true,
            'isVersionSelected':false
          }));
        }
        else if(mbxSelectedOptions['isFileMigrationSelected']==true){
          setMbxSelectedOptions((prevState: any) => ({
            ...prevState,
            [option]: false,
            'isVersionSelected':false
          }));
        }else{
            setMbxSelectedOptions((prevState: any) => ({
              ...prevState,
              [option]: true,
              'isVersionSelected':true
            }));
        }
          
        
      }
    }catch(error:any){
      console.error('Error in handleOnedriveOptions',error)
    }
  };

  // Handling CheckBox change for other Applications
  const appSpecificHandleCheckboxChange = (option: string): void => {
    setBasedonApplicationSAC(false);
    setSelectedOptions((prevState: any) => ({
      ...prevState,
      [option]: !prevState[option],
    }));
  }
  /**PS_10 to PS_17 will be executed
*/
  // Fetchdata function to fetch outlook data from DB.
  const fetchData = async () => {
    try {
      let token = await getToken()
      let payload = {
        token: token,
        taskId: Number(taskId),
        applicationName: applicationName
      }
      // Fetch data from the API
      const userOptions = await fetchUserOptionsFromDatabase(payload);
      ////console.log(userOptions, "aaaaaaaa");
      /*PS_18 to PS_23 will be eexcuted */

      // Update the state with the fetched data
      if (userOptions?.data?.data[0]) {
        const data = userOptions?.data?.data[0] // Extract the data object
        ////console.log(userOptions?.data?.data[0], "test");

        // Update mbxSelectedOptions state based on fetched values
        ////console.log(data, "Testmelbin");
        
        setMbxSelectedOptions((prevState: any) => ({
          ...prevState,
          archiveMailbox: data?.isArcheivedMailboxSelected,
          recoverableItems: data?.isRecoverableItemsSelected,
          notes: data?.isNotesSelected,
          rules: data?.isRulesSelected,
          permissions: data?.isPermissionsSelected,
          isMailMigrationSelected: data.isMailMigrationSelected,
          isContactsMigrationSelected: data.isContactsMigrationSelected,
          isCalendarMigrationSelected: data.isCalendarMigrationSelected,
          isTasksMigrationSelected: data.isTasksMigrationSelected,
          isFileMigrationSelected: data.isFileMigrationSelected,
          isVersionSelected: data.isVersionSelected,
          fromDate: data.customStartDate?new Date(data.customStartDate)?.toISOString().split('T')[0]:"", // Convert date to ISO format
          toDate: data.customEndDate? new Date(data.customEndDate)?.toISOString().split('T')[0]: "", // Convert date to ISO format

        }));
      }
    } catch (error) {
      console.error("Error fetching user options:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetchdata function to fetch other applications data from DB.
  const fetchAppSpecificData = async () => {
    try {
      setLoading(true)
      let token = await getToken()
      let payload = {
        token: token,
        taskId: Number(taskId),
        applicationName: applicationName
      }
      // Fetch data from the API
      const userOptions = await fetchOptionsFromDatabase(payload);
      //console.log(userOptions, "aaaaaaaa");
      /*PS_18 to PS_23 will be eexcuted */
      //console.log(userOptions?.data?.data, "huuuuuuuuuuuuuuuuuu");

      // Update the state with the fetched data
      if (userOptions?.data?.data) {
        const data = userOptions?.data?.data // Extract the data object
        const isMigration = data?.Task?.channelMigrationType === "Migration";
        const isActual = data?.isActualConversation;
        const isDirectType = data?.Task?.isDirectMigration        
        setSelectedOptions((prevState: any) => ({
          ...prevState,
          isActualConversation: isActual,
          isHtmlMigration: !isActual,
          isActualUser: isMigration,
          isServiceAccount: !isMigration,
          isDirectMigration: isDirectType,
          isCustomMigration: !isDirectType,
          isTasksSelected: data?.isTasksSelected,
          isCalendarsSelected: data?.isCalendarsSelected,
          isContactsSelected: data?.isContactsSelected,
          isMailsSelected: data?.isMailsSelected,
          isSiteContentSelected: data?.isSiteContentSelected,
          isPublicViewSelected: data?.isPublicViewSelected,
          isUserAlertSelected: data?.isUserAlertSelected,
          isPageCommentsSelected: data?.isPageCommentsSelected,
          isMeetingRecordingSelected: data?.isMeetingRecordingSelected,
          isFileAttachmentsSelected: data?.isFileAttachmentsSelected,
          isSharedWithMeSelected: data?.isSharedWithMeSelected,
          isTrashSelected: data?.isTrashSelected,
          isVersionsSelected: data?.isVersionsSelected,
          fromDate: data?.fromDate ? data.fromDate.split('T')[0] : null,
          toDate: data?.toDate ? data.toDate.split('T')[0] : null,
          isListMigration: data?.isListMigration,
          isDocLibraryMigration: data?.isDocLibraryMigration,
          isPageMigration: data?.isPageMigration,
          isSitePermission: data?.isSitePermission,
          isListLibPermission: data?.isListLibPermission,
          isShareLinkPermission: data?.isShareLinkPermission,
          isModifiedSelected: data?.isCreatedSelected==false?true:false,
          isCreatedSelected: data?.isCreatedSelected
          


        }));
        //console.log(selectedOptions, "selectedoptions fetch");

      }
    } catch (error) {
      console.error("Error fetching user options:", error);
    } finally {
      setLoading(false);
    }
  };


  const [errors, setErrors] = useState({
    startDateError: "",
    endDateError: "",
  });
  const validateStartDate = (startDate: any, endDate: any) => {
    const currentDate = new Date().toISOString().split("T")[0];
    if (!endDate) {
      return startDate <= currentDate;
    }
    return startDate <= endDate && startDate <= currentDate;
  };

  const validateEndDate = (startDate: any, endDate: any) => {
    const currentDate = new Date().toISOString().split("T")[0];
    return !endDate || (startDate <= endDate && endDate <= currentDate);
  };


  const handleMutuallyExclusiveCheckbox = (selectedOption: string, oppositeOption: string) => {
    setSelectedOptions((prevState: { [x: string]: any; }) => ({
      ...prevState,
      [selectedOption]: !prevState[selectedOption],
      [oppositeOption]: false
    }));
  };
  const handleSiteContentPermissionCB = (e:any) => {
    debugger
    const{name, value, target, type, checked}=e.target
    setSelectedOptions((prevState: { [x: string]: any; }) => ({
      ...prevState,
      [name]: !prevState[name]      
    }));
  };
  

  /**PS_38 to PS_60 will be executed
*/
  // Handle save function for outlook.
  const mbxHandleSave = async () => {
    try {
      setLoading(true);

      if (!disableActions) {
        let { fromDate, toDate } = mbxSelectedOptions;
        if (!validateStartDate(fromDate, toDate)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            startDateError:
              "*Start date cannot be greater than today's date or less than the end date.",
          }));
          return;
        }
        if (!validateEndDate(fromDate, toDate)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            endDateError:
              "*End date cannot be greater than today's date or less than the start date.",
          }));
          return;
        }

        setErrors({
          startDateError: "",
          endDateError: "",
        });
        const token = await getToken()
        const mbxUpdatedOptions = {
          token: token,
          ...mbxSelectedOptions,
          fromDate,
          toDate,
          applicationName
        }
        debugger
        await postOutlookCustomization(mbxUpdatedOptions);
        // await postOutlookCustomization(mbxUpdatedOptions);
        setLoading(false);
        const token1 = await getToken()
        if (isEdit) {
          let payload = {
            token: token1,
            taskId: Number(taskId)
          }
          const result = await updateModifiedDate(payload)
        }

      }

      // Close the accordion
      onToggle();

      updateAppSpecificConfigSaveStatus(true);

      onAppSpecificConfigSave();

      // Handle the result as needed
    } catch (error: any) {
      return error.message;
    } finally {
      setLoading(false);
    }
  };

  // Handle save function for other applications.
  const handleSave = async () => {
    try {
      setLoading(true);
      debugger
      if (!disableActions) {
        let { fromDate, toDate } = selectedOptions;
        if ((fromDate != null && toDate != null)) {
          if (!validateStartDate(fromDate, toDate)) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              startDateError:
                "*Start date cannot be greater than today's date or less than the end date.",
            }));
            return;
          }
          if (!validateEndDate(fromDate, toDate)) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              endDateError:
                "*End date cannot be greater than today's date or less than the start date.",
            }));
            return;
          }
        }

        setErrors({
          startDateError: "",
          endDateError: "",
        });
        const token = await getToken()

        const updateOptions = {
          token: token,
          ...selectedOptions,
          customStartDate: fromDate,
          customEndDate: toDate,
          applicationName
        }
        await postCustomization(updateOptions);
        setLoading(false);
        const token1 = await getToken()
        if (isEdit) {
          let payload = {
            token: token1,
            taskId: Number(taskId)
          }
          const result = await updateModifiedDate(payload)
        }

      }

      // Update the isDirect state in the parent component
      setIsDirect(selectedOptions.isDirectMigration);

      // Set migrationMode based on isServiceAccount and isActualUser
      if (selectedOptions.isServiceAccount) {
        setMigrationMode('standard');
      } else if (selectedOptions.isActualUser) {
        setMigrationMode('migration');
      }
      // Close the accordion
      onToggle();
      onAppSpecificConfigSave();
      updateAppSpecificConfigSaveStatus(true);

      onAppSpecificConfigSave();

      // Handle the result as needed
    } catch (error: any) {
      return error.message;
    } finally {
      setLoading(false);
    }
  };
  //console.log(isDirect, "state of direct in child comp 222");

  const insertLastVisitedPageValue = async (page: string) => {
    try {
      ////console.log(page, "This is the last inserted pageeeee");
      if(lastStage >= lastVisitedStage){
        setLastVisitedStage (lastStage)
      }
      let token = await getToken()

      const payload = {
        token: token,
        taskId: taskId,
        lastVisitedValue: page,
        lastVisitedStage: lastVisitedStage,
      };

      ////console.log(payload, 'last visited');

      // Insert only newly entered records into the database
      const response: any = await insertLastVisitedPageValueApi(payload);

      if (!response) {
        ////console.log('The current page value failed to insert');
      } else {
        ////console.log('The current page value inserted successfully');

      }
    } catch (error) {
      console.error('Error inserting records:', error);
    }
  };

  const handleSaveDisability=(propertiesArray:any[],selectedOptObject:any):boolean=>{
    try{
      let disableBtn:boolean=true;
      const isAllFalse=propertiesArray.every((option)=>selectedOptObject[option]==false);

      if(!isAllFalse){
        disableBtn= false
      }
      return disableBtn;
    }catch(error:any){
      console.error('Error in handleOutlookSaveDisability',error);
      return true;
    }
  }
  return (<>
    {applicationName === "outlook" ? (
      <div className="col-md-12">
        <div className="table-responsive theme-table bg-white mb-3">
          <table className="table table-borderless table-shadow mb-0">
            <thead>
              <tr>
                <th className="text-nowrap table-accordion-head">
                  <span className="d-flex align-items-center gap-3">

                    <img
                      src="/images/table-accordion-arrow.svg"
                      alt="table-accordion-arrow"
                      className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"
                        }`}
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true ? "#userMailboxConfiguration" : ''}
                      onClick={() => {
                        if (
                          disabled == true || 
                          lastVisitedStage>=2 || isEditCheck == true) {
                          onToggle()
                        }
                        else if (disabled == false) {
                          ////console.log(disabled, "KKKKKK");
                        }
                      }}

                    />
                    <span
                      className="ac-heading cursor-pointer"
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true? "#userMailboxConfiguration" : ''}
                      onClick={() => {
                        if (
                           disabled == true ||
                           lastVisitedStage>=2 || isEditCheck == true) {
                          onToggle()
                        }
                        // else if (disabled == false) {
                        //   ////console.log(disabled, "KKKKKK");
                        // }
                      }}
                    >
                      App Specific Configuration
                    </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                className={`collapse ${isOpen ? "show" : ""}`}
                id="outlook_Configuration"
              >
                <td className="p-4">
                  <label className="font-16 font-bold label-color">
                    What do you want to migrate ?
                  </label>
                  <div className="row">
                    <label className="font-semibold font-15 label-color w-100 mt-4">
                      Mailbox
                      {/* <img
                    src="/images/tooltip.svg"
                    alt="info"
                    className="ms-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-title="tooltip content"
                  /> */}
                      <></>
                      <Tippy
                        delay={500}
                        arrow={true}
                        // followCursor={'horizontal'}
                        // plugins={[followCursor]}
                        placement='top'
                        content="This mailbox configuration is common to Outlook, Shared Mailbox"
                      >
                        <img className="ms-2"
                          src="/images/tooltip.svg"
                          alt="tooltip"
                          width={11}

                        />
                      </Tippy>
                    </label>
                    <div className="col-md-11 mt-3">
                      <div className="row">
                        <div className="col-md-3 label-color font-14">
                          <input
                            className="form-check-input cus-check-input"
                            type="checkbox"
                            id="userMailboxMails"
                            checked={mbxSelectedOptions.isMailMigrationSelected}
                            onChange={() =>
                              mbxHandleCheckboxChange("isMailMigrationSelected")
                            }
                            disabled={disableActions}
                          />
                          <label
                            className="ms-2 mt-md-1"
                            htmlFor="userMailboxMails"
                          >
                            Mails
                          </label>
                        </div>
                        <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                          <input
                            className="form-check-input cus-check-input"
                            type="checkbox"
                            id="userMailboxCalender"
                            checked={mbxSelectedOptions.isCalendarMigrationSelected}
                            onChange={() =>
                              mbxHandleCheckboxChange("isCalendarMigrationSelected")
                            }
                            disabled={disableActions}
                          />
                          <label
                            className="ms-2 mt-md-1"
                            htmlFor="userMailboxCalender"
                          >
                            Calendars
                          </label>
                        </div>
                        <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                          <input
                            className="form-check-input cus-check-input"
                            type="checkbox"
                            id="userMailboxContacts"
                            checked={mbxSelectedOptions.isContactsMigrationSelected}
                            onChange={() =>
                              mbxHandleCheckboxChange("isContactsMigrationSelected")
                            }
                            disabled={disableActions}
                          />
                          <label
                            className="ms-2 mt-md-1"
                            htmlFor="userMailboxContacts"
                          >
                            Contacts
                          </label>
                        </div>
                        <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                          <input
                            className="form-check-input cus-check-input"
                            type="checkbox"
                            id="mailboxtask"
                            checked={mbxSelectedOptions.isTasksMigrationSelected}
                            onChange={() =>
                              mbxHandleCheckboxChange("isTasksMigrationSelected")
                            }
                            disabled={disableActions}
                          />
                          <label
                            className="ms-2 mt-md-1"
                            htmlFor="mailboxtask"
                          >
                            Task
                          </label>
                        </div>
                        <label className="font-semibold font-15 label-color w-100 mt-4">
                          OneDrive
                          {/* <img
                    src="/images/tooltip.svg"
                    alt="info"
                    className="ms-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-title="tooltip content"
                  /> */}
                          <></>
                          <Tippy
                            delay={500}
                            arrow={true}
                            // followCursor={'horizontal'}
                            // plugins={[followCursor]}
                            placement='top'
                            content="This configuration allows the user to Migrate the one drive files and its versions"
                          >
                            <img className="ms-2"
                              src="/images/tooltip.svg"
                              alt="tooltip"
                              width={11}

                            />
                          </Tippy>
                        </label>
                        <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                          <div className="form-check mt-2 mt-md-4">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="oneDriveFiles"
                              checked={mbxSelectedOptions.isFileMigrationSelected}
                              onChange={() =>
                                handleOnedriveOptions("isFileMigrationSelected")
                              }
                              disabled={disableActions}
                            />
                            <label
                              className="ms-2 mt-md-1"
                              htmlFor="oneDriveFiles"
                            >
                              Files
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                          <div className="mt-2 mt-md-4 form-check d-flex align-items-center mt-0">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="version"
                              checked={mbxSelectedOptions.isVersionSelected}
                              onChange={() =>
                                handleOnedriveOptions("isVersionSelected")
                              }
                              disabled={disableActions}
                            />
                            <label
                              className="ms-2 mt-md-1"
                              htmlFor="version"
                            >
                              Versions
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label className="font-semibold font-15 label-color w-100 mt-4 pt-2">
                      Migration Customization Dates
                    </label>
                    <div className="col-md-6 mt-3">
                      <div className="row">
                        <div className="col-md-4 pb-1 pb-md-0 position-relative">
                          <label
                            htmlFor="userMailbox-fromDate"
                            className="form-label w-100 font-semibold font-14 label-color"
                          >
                            From
                          </label>

                          <input
                            type="date"
                            id="userMailbox-fromDate"
                            className="form-control custom-form"
                            value={mbxSelectedOptions?.fromDate}
                            max={endDateMax || new Date().toISOString().split("T")[0]}
                            onChange={(e) =>
                              mbxHandleDateChange(e.target.value, "fromDate")
                            }
                            disabled={disableActions}
                          />
                          <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                        </div>
                        <div className="col-md-4 mt-2 mt-md-0 position-relative">
                          <label
                            htmlFor="userMailbox-toDate"
                            className="form-label w-100 font-semibold font-14 label-color"
                          >
                            To
                          </label>

                          <input
                            type="date"
                            id="userMailbox-toDate"
                            className="form-control custom-form"
                            value={mbxSelectedOptions.toDate}
                            min={endDateMin}
                            max={new Date().toISOString().split("T")[0]}
                            onChange={(e) =>
                              mbxHandleDateChange(e.target.value, "toDate")
                            }
                            disabled={disableActions}
                          />
                          <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                        </div>
                      </div>
                    </div>
                    {errors.endDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.endDateError}
                      </span>
                    )}
                    {errors.startDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.startDateError}
                      </span>
                    )}
                  </div>
                  <span className="d-flex justify-content-end mt-5 pt-md-4">
                    <button
                      className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                      // disabled={loading || !(mbxSelectedOptions.archiveMailbox || mbxSelectedOptions.fromDate != "" || mbxSelectedOptions.toDate != "" || mbxSelectedOptions.notes || mbxSelectedOptions.recoverableItems || mbxSelectedOptions.permissions || mbxSelectedOptions.rules) || disableActions}
                      disabled={handleSaveDisability(outlookOptions,mbxSelectedOptions)}
                      onClick={() => {
                        mbxHandleSave();
                        insertLastVisitedPageValue('goLiveConfigurations');
                      }}
                    >
                      Save &amp; Continue
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ) : applicationName === "sharepoint" ? (
      <div className="col-md-12">
        <div className="table-responsive theme-table bg-white mb-3">
          <table className="table table-borderless mb-0">
            <thead>
              <tr>
                <th className="text-nowrap table-accordion-head">
                  <span className="d-flex align-items-center gap-3">
                    <img
                      src="/images/table-accordion-arrow.svg"
                      alt="table-accordion-arrow"
                      className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"
                        }`}
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true ? "#sharePointConfiguration" : ''}
                      onClick={() => {
                        if (disabled == true || lastVisitedStage>=2) {
                          onToggle()
                        }
                        else if (disabled == false) {
                          ////console.log(disabled, "KKKKKK");
                        }
                      }}
                    />
                    <span
                      className="ac-heading cursor-pointer"
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true? "#sharePointConfiguration" : ''}
                      onClick={() => {
                        if (disabled == true || lastVisitedStage>=2 || isEditCheck == true) {
                          onToggle()
                        }
                        else if (disabled == false) {
                          ////console.log(disabled, "KKKKKK");
                        }
                      }}
                    >
                      SharePoint Configuration
                    </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={`collapse ${isOpen ? "show" : ""}`} id="sharePointConfiguration">
                <td className="p-4">
                  <label className="font-16 font-bold label-color">What do you want to migrate ?</label>

                  <div className="p-3 mb-2 mt-3 d-flex align-items-start gap-2 border-radius-8 info-div-bg">
                    <img
                      src="/images/tooltip.svg"
                      alt="tooltip"
                      width={11}
                    />
                    <span className="lh-1 font-12 cust-role-color">
                      Click <a
                        className="primary-color font-bold cursor-pointer"
                        style={{ textDecoration: 'none' }}
                        href="https://www.avamigratron.com/Product/fast-forward-your-office-365-tenant-to-tenant-migration-with-avamigratron/"
                        target="_blank"
                        rel="noopener noreferrer" >
                        here
                      </a > to know more about the type of migration which you are going to perform.
                    </span>
                  </div>
                  <label className="font-semibold font-15 label-color w-100 mt-4">What kind of migration do you need?</label>
                  <div className="col-md-7 mt-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                          <input
                            className="form-check-input cus-check-input"
                            type="checkbox"
                            id="teamsChat-directMigration"
                            name="accountType"
                            checked={selectedOptions.isDirectMigration}
                            onChange={() => handleMutuallyExclusiveCheckbox("isDirectMigration", "isCustomMigration")}
                            disabled={disableActions }
                          />
                          <label className="ms-2 mt-1" htmlFor="teamsChat-directMigration">Direct</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                          <input
                            className="form-check-input cus-check-input"
                            type="checkbox"
                            id="teamsChat-customMigration"
                            name="accountType"
                            checked={selectedOptions.isCustomMigration}
                            onChange={() => handleMutuallyExclusiveCheckbox("isCustomMigration", "isDirectMigration")}
                            disabled={disableActions}
                          />
                          <label className="ms-2 mt-1" htmlFor="teamsChat-customMigration">Custom</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="font-semibold font-15 label-color w-100 mt-4">Site Contents</label>
                    <div className="col-md-7 mt-3">
                      <div className="row">
                        <div className="col-md-3 label-color font-14">
                        <input
                            className="form-check-input cus-check-input my-0"
                            type="checkbox"
                            id="ListCheckbox"
                            name="isListMigration"
                            checked={selectedOptions.isListMigration}
                            onChange={handleSiteContentPermissionCB}
                          />
                          {/* <img src="/images/tick-symbol.svg" alt="tick" className="me-2" /> */}                          
                          <span className="ms-2 mt-md-1">List</span>
                        </div>
                        <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <input
                            className="form-check-input cus-check-input my-0"
                            type="checkbox"
                            id="DocLibraryCheckbox"
                            name="isDocLibraryMigration"
                            checked={selectedOptions.isDocLibraryMigration}
                            onChange={handleSiteContentPermissionCB}
                          />
                          {/* <img src="/images/tick-symbol.svg" alt="tick" className="me-2" /> */}
                          <span className="ms-2 mt-md-1">Document Library</span>
                        </div>
                        <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <input
                            className="form-check-input cus-check-input my-0"
                            type="checkbox"
                            id="SietPgCheckbox"
                            name="isPageMigration"
                            checked={selectedOptions.isPageMigration}
                            onChange={handleSiteContentPermissionCB}
                          />
                          {/* <img src="/images/tick-symbol.svg" alt="tick" className="me-2" /> */}
                          <span className="ms-2 mt-md-1">Site Pages</span>
                        </div>
                      </div>
                    </div>
                    <label className="font-semibold font-15 label-color w-100 mt-4 pt-2">Permissions</label>
                    <div className="col-md-7 mt-3">
                      <div className="row">
                        <div className="col-md-3 label-color font-14">
                        <input
                            className="form-check-input cus-check-input my-0"
                            type="checkbox"
                            id="SietPermissionCheckbox"
                            name="isSitePermission"
                            checked={selectedOptions.isSitePermission}
                            onChange={handleSiteContentPermissionCB}
                          />
                          {/* <img src="/images/tick-symbol.svg" alt="tick" className="me-2" /> */}
                          <span className="ms-2 mt-md-1">Site</span>
                        </div>
                        <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <input
                            className="form-check-input cus-check-input my-0"
                            type="checkbox"
                            id="ListLibCheckbox"
                            name="isListLibPermission"
                            checked={selectedOptions.isListLibPermission}
                            onChange={handleSiteContentPermissionCB}
                          />
                          {/* <img src="/images/tick-symbol.svg" alt="tick" className="me-2" /> */}
                          <span className="ms-2 mt-md-1">List & Library</span>
                        </div>
                        <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <input
                            className="form-check-input cus-check-input my-0"
                            type="checkbox"
                            id="SharedLinkCheckbox"
                            name="isShareLinkPermission"
                            checked={selectedOptions.isShareLinkPermission}
                            onChange={handleSiteContentPermissionCB}
                          />
                          {/* <img src="/images/tick-symbol.svg" alt="tick" className="me-2" /> */}
                          <span className="ms-2 mt-md-1">Shared Links</span>
                        </div>
                      </div>
                    </div>
                    <label className="font-semibold font-15 label-color w-100 mt-4 pt-2">SharePoint Customization Dates</label>
                    <div className="col-md-3 mt-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-check">
                            <input
                              className="form-check-input custom-radio-input" 
                              type="radio"
                              name="sharePointDateOption"
                              id="createdDate"
                              value="createdDate"
                              checked={selectedOptions.isCreatedSelected}
                              onChange={() => handleMutuallyExclusiveCheckbox("isCreatedSelected", "isModifiedSelected")}
                              disabled={disableActions}
                            />
                            <label className="form-check-label font-14 font-regular" htmlFor="createdDate">
                              Created Date
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-check">
                            <input
                              className="form-check-input custom-radio-input"
                              type="radio"
                              name="sharePointDateOption"
                              id="modifiedDate"
                              value="modifiedDate"
                              checked={selectedOptions.isModifiedSelected}
                              onChange={() => handleMutuallyExclusiveCheckbox("isModifiedSelected", "isCreatedSelected")}
                              disabled={disableActions}
                            />
                            <label className="form-check-label font-14 font-regular" htmlFor="modifiedDate">
                              Modified Date
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label></label>
                    <div className="col-md-6 mt-3">
                      <div className="row">
                        <div className="col-md-4 pb-1 pb-md-0 position-relative">
                          <label
                            htmlFor="sp-fromDate"
                            className="form-label w-100 font-semibold font-14 label-color"
                          >
                            From
                          </label>

                          <input
                            type="date"
                            id="sp-fromDate"
                            className="form-control custom-form"
                            value={selectedOptions?.fromDate || ''}
                            max={selectedOptions?.toDate || new Date().toISOString().split("T")[0]}
                            onChange={(e) => handleDateChange(e.target.value, "fromDate")}
                            disabled={disableActions}
                          />
                          <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                        </div>
                        <div className="col-md-4 mt-2 mt-md-0 position-relative">
                          <label
                            htmlFor="sp-toDate"
                            className="form-label w-100 font-semibold font-14 label-color"
                          >
                            To
                          </label>

                          <input
                            type="date"
                            id="sp-toDate"
                            className="form-control custom-form"
                            value={selectedOptions?.toDate || ''}
                            min={selectedOptions?.fromDate || ''}
                            max={new Date().toISOString().split("T")[0]}
                            onChange={(e) => handleDateChange(e.target.value, "toDate")}
                            disabled={disableActions}
                          />
                          <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                        </div>
                      </div>
                    </div>
                    {errors.endDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.endDateError}
                      </span>
                    )}
                    {errors.startDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.startDateError}
                      </span>
                    )}
                    <label className="font-semibold font-15 label-color w-100 mt-4">Other Configurations</label>
                    <div className="col-md-3 mt-3">
                      <div className="row">
                        <div className="d-md-flex d-block">

                          <div className="col-md-6">
                            <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                              <input
                                className="form-check-input cus-check-input"
                                type="checkbox"
                                id="sp-publicViews"
                                checked={selectedOptions.isPublicViewSelected}
                                onChange={() =>
                                  appSpecificHandleCheckboxChange("isPublicViewSelected")
                                }
                                disabled={disableActions}
                              />
                              <label className="ms-2 mt-md-1" htmlFor="sp-publicViews">Public Views</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                              <input
                                className="form-check-input cus-check-input"
                                type="checkbox"
                                id="sp-userAlerts"
                                checked={selectedOptions.isUserAlertSelected}
                                onChange={() =>
                                  appSpecificHandleCheckboxChange("isUserAlertSelected")
                                }
                                disabled={disableActions}
                              />
                              <label className="ms-2 mt-md-1" htmlFor="sp-userAlerts">User Alerts</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                              <input
                                className="form-check-input cus-check-input"
                                type="checkbox"
                                id="sp-pageComments"
                                checked={selectedOptions.isPageCommentsSelected}
                                onChange={() =>
                                  appSpecificHandleCheckboxChange("isPageCommentsSelected")
                                }
                                disabled={disableActions}
                              />
                              <label className="ms-2 mt-md-1" htmlFor="sp-pageComments">Page Comments</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="d-flex justify-content-end mt-5 pt-md-4">
                    <button
                      className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                      onClick={() => {
                        handleSave();
                        insertLastVisitedPageValue('goLiveConfigurations');
                      }}
                      disabled={(!selectedOptions.isDirectMigration && !selectedOptions.isCustomMigration) || disableActions ||(!selectedOptions.isShareLinkPermission && !selectedOptions.isListLibPermission && !selectedOptions.isSitePermission) || (!selectedOptions.isPageMigration && !selectedOptions.isDocLibraryMigration && !selectedOptions.isListMigration)}
                    >
                      Save &amp; Continue
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ) : applicationName === "teamschannel" ? (
      <div className="col-md-12">
        <div className="table-responsive theme-table bg-white mb-3">
          <table className="table table-borderless mb-0">
            <thead>
              <tr>
                <th className="text-nowrap table-accordion-head">
                  <span className="d-flex align-items-center gap-3">

                  <img
                      src="/images/table-accordion-arrow.svg"
                      alt="table-accordion-arrow"
                      className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"
                        }`}
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true ? "#teamsChannelConfiguration" : ''}
                      onClick={() => {
                        if (
                           disabled == true || 
                           lastVisitedStage>=2 || isEditCheck == true) {
                          onToggle()
                        }
                        else if (disabled == false) {
                          ////console.log(disabled, "KKKKKK");
                        }
                      }}

                    />
                    <span
                      className="ac-heading cursor-pointer"
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true  ? "#teamsChannelConfiguration" : ''}
                      onClick={() => {
                        if (
                          disabled == true || 
                          lastVisitedStage>=2 || isEditCheck == true) {
                          onToggle()
                        }
                        // else if (disabled == false) {
                        //   ////console.log(disabled, "KKKKKK");
                        // }
                      }}
                    >
                      Teams Channel Configuration
                    </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={`collapse ${isOpen ? "show" : ""}`} id="teamsChannelConfiguration">
                <td className="p-4">
                  <label className="font-16 font-bold label-color">What do you want to migrate ?</label>
                  <div className="p-3 mb-2 mt-3 d-flex align-items-start gap-2 border-radius-8 info-div-bg">
                    <img
                      src="/images/tooltip.svg"
                      alt="tooltip"
                      width={11}
                    />
                    <span className="lh-1 font-12 cust-role-color">
                      Click <a
                        className="primary-color font-bold cursor-pointer"
                        style={{ textDecoration: 'none' }}
                        href="https://www.avamigratron.com/Product/the-only-enterprise-ready-tenant-to-tenant-migration-platform/"
                        target="_blank"
                        rel="noopener noreferrer" >
                        here
                      </a > to know more about the type of migration which you are going to perform.
                    </span>
                  </div>
                  <div className="row">
                    <label className="font-semibold font-15 label-color w-100 mt-4">Channel Scope</label>
                    <div className="col-md-7 mt-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="teamsChat-migrateActual"
                              checked={selectedOptions.isActualConversation}
                              onChange={() => handleMutuallyExclusiveCheckbox("isActualConversation", "isHtmlMigration")}
                              disabled={disableActions}
                            />
                            <label className="ms-2 mt-1" htmlFor="teamsChat-migrateActual">Migrate as Actual Conversations</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="teamsChat-migrateHtml"
                              checked={selectedOptions.isHtmlMigration}
                              onChange={() => handleMutuallyExclusiveCheckbox("isHtmlMigration", "isActualConversation")}
                              disabled={disableActions}
                            />
                            <label className="ms-2 mt-1" htmlFor="teamsChat-migrateHtml">Migrate as HTML Files in OneDrive</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {selectedOptions.isActualConversation && (
                      <>
                        <label className="font-semibold font-15 label-color w-100 mt-4">For teams application, what kind of migration do you like to proceed with?</label>
                        <div className="col-md-7 mt-3">
                          <div className="row">
                                <div className="col-md-6">
                                  <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                                    <input
                                      className="form-check-input cus-check-input"
                                      type="checkbox"
                                      id="teamsChat-serviceAccount"
                                      name="accountType"
                                      checked={selectedOptions.isServiceAccount}
                                      onChange={() => handleMutuallyExclusiveCheckbox("isServiceAccount", "isActualUser")}
                                      disabled={disableActions}
                                    />
                                    <label className="ms-2 mt-1" htmlFor="teamsChat-serviceAccount">
                                      Service Account{" "}
                                      <Tippy
                                        delay={[500, 0]}
                                        arrow={true}
                                        placement='top'
                                        content="Migrate Channels from Teams on behalf of service account as MS Teams public or Private Channels."
                                      >
                                        <img
                                          src="/images/tooltip.svg"
                                          alt="tooltip"
                                          width={11}
                                        />
                                      </Tippy>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                                    <input
                                      className="form-check-input cus-check-input"
                                      type="checkbox"
                                      id="teamsChat-actualUser"
                                      name="accountType"
                                      checked={selectedOptions.isActualUser}
                                      onChange={() => handleMutuallyExclusiveCheckbox("isActualUser", "isServiceAccount")}
                                      disabled={disableActions}
                                    />
                                    <label className="ms-2 mt-1" htmlFor="teamsChat-actualUser">
                                      Actual User{" "}
                                      <Tippy
                                        delay={[500, 0]}
                                        arrow={true}
                                        placement='top'
                                        content="Migrate Channels from Teams on behalf of Actual users as MS Teams Public channels."
                                      >
                                        <img
                                          src="/images/tooltip.svg"
                                          alt="tooltip"
                                          width={11}
                                        />
                                      </Tippy>
                                    </label>
                                  </div>
                                </div>
                          </div>
                        </div>
                      </>
                    )}
                    {selectedOptions.isActualConversation && (selectedOptions.isServiceAccount || selectedOptions.isActualUser) && (
                      <>
                        <label className="font-semibold font-15 label-color w-100 mt-4">What kind of migration do you need?</label>
                        <div className="col-md-7 mt-3">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                                <input
                                  className="form-check-input cus-check-input"
                                  type="checkbox"
                                  id="teamsChat-directMigration"
                                  name="accountType"
                                  checked={selectedOptions.isDirectMigration}
                                  onChange={() => handleMutuallyExclusiveCheckbox("isDirectMigration", "isCustomMigration")}
                                  disabled={disableActions}
                                />
                                <label className="ms-2 mt-1" htmlFor="teamsChat-directMigration">Direct</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                                <input
                                  className="form-check-input cus-check-input"
                                  type="checkbox"
                                  id="teamsChat-customMigration"
                                  name="accountType"
                                  checked={selectedOptions.isCustomMigration}
                                  onChange={() => handleMutuallyExclusiveCheckbox("isCustomMigration", "isDirectMigration")}
                                  disabled={disableActions}
                                />
                                <label className="ms-2 mt-1" htmlFor="teamsChat-customMigration">Custom</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <label className="font-semibold font-15 label-color w-100 mt-4 pt-2">Objects</label>
                    <div className="col-md-8 mt-3">
                      <div className="row">
                        <div className="col-md-4 label-color font-14">
                          <img src="/images/tick-symbol.svg" alt="tick" className="me-2" />
                          <span>Tab</span>
                        </div>
                        <div className="col-md-4 label-color font-14 mt-2 mt-md-0">
                          <img src="/images/tick-symbol.svg" alt="tick" className="me-2" />
                          <span>Tags</span>
                        </div>
                        <div className="col-md-4 label-color font-14 mt-2 mt-md-0">
                          <img src="/images/tick-symbol.svg" alt="tick" className="me-2" />
                          <span>Planner</span>
                        </div>
                        <div className="col-md-4 label-color font-14">
                          <img src="/images/tick-symbol.svg" alt="tick" className="me-2" />
                          <span>Apps</span>
                        </div>
                      </div>
                    </div>
                    <label className="font-semibold font-15 label-color w-100 mt-4 pt-2">Group Mailbox</label>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-6 label-color font-14">
                          <img src="/images/tick-symbol.svg" alt="tick" className="me-2" />
                          <span>Mailbox</span>
                        </div>
                      </div>
                    </div>
                    <label className="font-semibold font-15 label-color w-100 mt-4">SharePoint Teams Site</label>
                    <div className="col-md-7 mt-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="teamsChat-migrateFiles"
                              checked={selectedOptions.isSiteContentSelected}
                              onChange={() =>
                                appSpecificHandleCheckboxChange("isSiteContentSelected")
                              }
                              disabled={disableActions}
                            />
                            <label className="ms-2  font-14 " htmlFor="teamsChat-migrateFiles">
                              Sharepoint Site Content</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label className="font-semibold font-15 label-color w-100 mt-4 pt-2">Team Channel Customization Dates</label>
                    <div className="col-md-6 mt-3">
                      <div className="row">
                        <div className="col-md-4 pb-1 pb-md-0 position-relative">
                          <label
                            htmlFor="teamsChannel-fromDate"
                            className="form-label w-100 font-semibold font-14 label-color"
                          >
                            From
                          </label>

                          <input
                            type="date"
                            id="teamsChannel-fromDate"
                            className="form-control custom-form"
                            value={selectedOptions?.fromDate}
                            max={endDateMax || new Date().toISOString().split("T")[0]}
                            onChange={(e) =>
                              handleDateChange(e.target.value, "fromDate")
                            }
                            disabled={disableActions}
                          />
                          <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                        </div>
                        <div className="col-md-4 mt-2 mt-md-0 position-relative">
                          <label
                            htmlFor="teamsChannel-toDate"
                            className="form-label w-100 font-semibold font-14 label-color"
                          >
                            To
                          </label>

                          <input
                            type="date"
                            id="teamsChannel-toDate"
                            className="form-control custom-form"
                            value={selectedOptions.toDate}
                            min={endDateMin}
                            max={new Date().toISOString().split("T")[0]}
                            onChange={(e) =>
                              handleDateChange(e.target.value, "toDate")
                            }
                            disabled={disableActions}
                          />
                          <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                        </div>
                      </div>
                    </div>
                    {errors.endDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.endDateError}
                      </span>
                    )}
                    {errors.startDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.startDateError}
                      </span>
                    )}
                    <label className="font-semibold font-15 label-color w-100 mt-4">Other Configurations</label>
                    <div className="col-md-7 mt-3">
                      <div className="row">
                        <div className="col-md-12 col-lg-6">
                          <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="teamsChat-externalUsers"
                              checked={selectedOptions.isMeetingRecordingSelected}
                              onChange={() =>
                                appSpecificHandleCheckboxChange("isMeetingRecordingSelected")
                              }
                              disabled={disableActions}
                            />
                            <label className="ms-2 mt-1" htmlFor="teamsChat-externalUsers">Meeting Recordings</label>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                          <div className="form-check mt-2 mt-md-0 d-flex align-items-center">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="teamsChat-userChat"
                              checked={selectedOptions.isFileAttachmentsSelected}
                              onChange={() =>
                                appSpecificHandleCheckboxChange("isFileAttachmentsSelected")
                              }
                              disabled={disableActions}
                            />
                            <label className="ms-2 mt-1" htmlFor="teamsChat-userChat">File Attachments</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="d-flex justify-content-end mt-5 pt-md-4">
                    <button
                      className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                      onClick={() => {
                        handleSave();
                        insertLastVisitedPageValue('goLiveConfigurations');
                      }}
                      disabled={
                        disableActions ||
                        (selectedOptions.isActualConversation &&
                          (!(selectedOptions.isServiceAccount || selectedOptions.isActualUser) ||
                            !(selectedOptions.isDirectMigration || selectedOptions.isCustomMigration))) ||
                        (!selectedOptions.isActualConversation && !selectedOptions.isHtmlMigration)
                      }
                    >
                      Save &amp; Continue
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ) : applicationName === 'gmail' ? (
      <div className="col-md-12">
        <div className="table-responsive theme-table bg-white mb-3">
          <table className="table table-borderless table-shadow mb-0">
            <thead>
              <tr>
                <th className="text-nowrap table-accordion-head">
                  <span className="d-flex align-items-center gap-3">
                    <img
                      src="/images/table-accordion-arrow.svg"
                      alt="table-accordion-arrow"
                      className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"
                        }`}
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true? "#userMailboxConfiguration" : ''}
                      onClick={() => {
                        if (disabled == true || lastVisitedStage>=2 || isEditCheck == true) {
                          onToggle()
                        }
                        else if (disabled == false) {
                          ////console.log(disabled, "KKKKKK");
                        }
                      }}
                    />
                    <span
                      className="ac-heading cursor-pointer"
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true ? "#userMailboxConfiguration" : ''}
                      onClick={() => {
                        if (disabled == true || lastVisitedStage>=2) {
                          onToggle()
                        }
                        else if (disabled == false) {
                          ////console.log(disabled, "KKKKKK");
                        }
                      }}
                    >
                      Mail Configuration
                    </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                className={`collapse ${isOpen ? "show" : ""}`}
                id="outlook_Configuration"
              >
                <td className="p-4">
                  <label className="font-16 font-bold label-color">
                    What do you want to migrate ?
                  </label>
                  <div className="row">
                    <label className="font-semibold font-15 label-color w-100 mt-4">
                      Objects
                    </label>
                    <div className="col-md-8 mt-3">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-check d-flex align-items-center mt-0">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="isMailsSelected"
                              checked={selectedOptions.isMailsSelected}
                              onChange={() => appSpecificHandleCheckboxChange("isMailsSelected")}
                              disabled={disableActions}
                            />
                            <label className="ms-2 mt-md-1" htmlFor="isMailsSelected">
                              Mails
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check d-flex align-items-center mt-0">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="isContactsSelected"
                              checked={selectedOptions.isContactsSelected}
                              onChange={() => appSpecificHandleCheckboxChange("isContactsSelected")}
                              disabled={disableActions}
                            />
                            <label className="ms-2 mt-md-1" htmlFor="isContactsSelected">
                              Contacts
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check d-flex align-items-center mt-0">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="isCalendarsSelected"
                              checked={selectedOptions.isCalendarsSelected}
                              onChange={() => appSpecificHandleCheckboxChange("isCalendarsSelected")}
                              disabled={disableActions}
                            />
                            <label className="ms-2 mt-md-1" htmlFor="isCalendarsSelected">
                              Calendars
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check d-flex align-items-center mt-0">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="isTasksSelected"
                              checked={selectedOptions.isTasksSelected}
                              onChange={() => appSpecificHandleCheckboxChange("isTasksSelected")}
                              disabled={disableActions}
                            />
                            <label className="ms-2 mt-md-1" htmlFor="isTasksSelected">
                              Tasks
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label className="font-semibold font-15 label-color w-100 mt-4 pt-2">GMail Customization Dates</label>
                    <div className="col-md-3 mt-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-check">
                            <input
                              className="form-check-input custom-radio-input"
                              type="radio"
                              name="sharePointDateOption"
                              id="createdDate"
                              value="createdDate"
                              checked={selectedOptions.isCreatedSelected}
                              onChange={() => handleMutuallyExclusiveCheckbox("isCreatedSelected", "isModifiedSelected")}
                              disabled={disableActions}
                            />
                            <label className="form-check-label font-14 font-regular" htmlFor="createdDate">
                              Created Date
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-check">
                            <input
                              className="form-check-input custom-radio-input"
                              type="radio"
                              name="sharePointDateOption"
                              id="modifiedDate"
                              value="modifiedDate"
                              checked={selectedOptions.isModifiedSelected}
                              onChange={() => handleMutuallyExclusiveCheckbox("isModifiedSelected", "isCreatedSelected")}
                              disabled={disableActions}
                            />
                            <label className="form-check-label font-14 font-regular" htmlFor="modifiedDate">
                              Modified Date
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label></label>
                    <div className="col-md-6 mt-3">
                      <div className="row">
                        <div className="col-md-4 pb-1 pb-md-0 position-relative">
                          <label
                            htmlFor="sp-fromDate"
                            className="form-label w-100 font-semibold font-14 label-color"
                          >
                            From
                          </label>

                          <input
                            type="date"
                            id="sp-fromDate"
                            className="form-control custom-form"
                            value={selectedOptions?.fromDate}
                            max={endDateMax || new Date().toISOString().split("T")[0]}
                            onChange={(e) =>
                              handleDateChange(e.target.value, "fromDate")
                            }
                            disabled={disableActions}
                          />
                          <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                        </div>
                        <div className="col-md-4 mt-2 mt-md-0 position-relative">
                          <label
                            htmlFor="sp-toDate"
                            className="form-label w-100 font-semibold font-14 label-color"
                          >
                            To
                          </label>

                          <input
                            type="date"
                            id="sp-toDate"
                            className="form-control custom-form"
                            value={selectedOptions.toDate}
                            min={endDateMin}
                            max={new Date().toISOString().split("T")[0]}
                            onChange={(e) =>
                              handleDateChange(e.target.value, "toDate")
                            }
                            disabled={disableActions}
                          />
                          <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                        </div>
                      </div>
                    </div>
                    {errors.endDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.endDateError}
                      </span>
                    )}
                    {errors.startDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.startDateError}
                      </span>
                    )}
                    {errors.endDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.endDateError}
                      </span>
                    )}
                    {errors.startDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.startDateError}
                      </span>
                    )}
                  </div>
                  <span className="d-flex justify-content-end mt-5 pt-md-4">
                    <button
                      className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                      disabled={loading || !(selectedOptions.isContactsSelected || selectedOptions.isTaskssSelected || selectedOptions.isCalendarssSelected || selectedOptions.isMailsSelected) || disableActions}
                      onClick={() => {
                        handleSave();
                        insertLastVisitedPageValue('goLiveConfigurations');
                      }}
                    >
                      Save &amp; Continue
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ) : applicationName === 'mydrive' ? (
      <div className="col-md-12">
        <div className="table-responsive theme-table bg-white mb-3">
          <table className="table table-borderless table-shadow mb-0">
            <thead>
              <tr>
                <th className="text-nowrap table-accordion-head">
                  <span className="d-flex align-items-center gap-3">
                    <img
                      src="/images/table-accordion-arrow.svg"
                      alt="table-accordion-arrow"
                      className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"
                        }`}
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true? "#userMailboxConfiguration" : ''}
                      onClick={() => {
                        if (disabled == true || lastVisitedStage>=2 || isEditCheck == true) {
                          onToggle()
                        }
                        else if (disabled == false) {
                          ////console.log(disabled, "KKKKKK");
                        }
                      }}
                    />
                    <span
                      className="ac-heading cursor-pointer"
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true ? "#userMailboxConfiguration" : ''}
                      onClick={() => {
                        if (disabled == true || lastVisitedStage>=2 || isEditCheck == true) {
                          onToggle()
                        }
                        else if (disabled == false) {
                          ////console.log(disabled, "KKKKKK");
                        }
                      }}
                    >
                      My Drive Configuration
                    </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                className={`collapse ${isOpen ? "show" : ""}`}
                id="outlook_Configuration"
              >
                <td className="p-4">
                  <label className="font-16 font-bold label-color">
                    What do you want to migrate ?
                  </label>
                  <div className="row">
                    <label className="font-semibold font-15 label-color w-100 mt-4">
                      Objects
                    </label>

                    <div className="col-md-8 mt-3">
                      <div className="row">
                        <div className="col-md-3 label-color font-14">
                          <img src="/images/tick-symbol.svg" alt="tick" className="me-2" />
                          <span>Files</span>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check d-flex align-items-center mt-0">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="isTrashSelected"
                              checked={selectedOptions.isTrashSelected}
                              onChange={() => appSpecificHandleCheckboxChange("isTrashSelected")}
                              disabled={disableActions}
                            />
                            <label className="ms-2 mt-md-1" htmlFor="isTrashSelected">
                              Trash
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check d-flex align-items-center mt-0">
                            <input
                              className="form-check-input cus-check-input"
                              type="checkbox"
                              id="isSharedWithMeSelected"
                              checked={selectedOptions.isSharedWithMeSelected}
                              onChange={() => appSpecificHandleCheckboxChange("isSharedWithMeSelected")}
                              disabled={disableActions}
                            />
                            <label className="ms-2 mt-md-1" htmlFor="isSharedWithMeSelected">
                              Shared with me
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label className="font-semibold font-15 label-color w-100 mt-4 pt-2">GDrive Customization Dates</label>
                    <div className="col-md-3 mt-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-check">
                            <input
                              className="form-check-input custom-radio-input"
                              type="radio"
                              name="sharePointDateOption"
                              id="createdDate"
                              value="createdDate"
                              checked={selectedOptions.isCreatedSelected}
                              onChange={() => handleMutuallyExclusiveCheckbox("isCreatedSelected", "isModifiedSelected")}
                              disabled={disableActions}
                            />
                            <label className="form-check-label font-14 font-regular" htmlFor="createdDate">
                              Created Date
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-check">
                            <input
                              className="form-check-input custom-radio-input"
                              type="radio"
                              name="sharePointDateOption"
                              id="modifiedDate"
                              value="modifiedDate"
                              checked={selectedOptions.isModifiedSelected}
                              onChange={() => handleMutuallyExclusiveCheckbox("isModifiedSelected", "isCreatedSelected")}
                              disabled={disableActions}
                            />
                            <label className="form-check-label font-14 font-regular" htmlFor="modifiedDate">
                              Modified Date
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label></label>
                    <div className="col-md-6 mt-3">
                      <div className="row">
                        <div className="col-md-4 pb-1 pb-md-0 position-relative">
                          <label
                            htmlFor="sp-fromDate"
                            className="form-label w-100 font-semibold font-14 label-color"
                          >
                            From
                          </label>

                          <input
                            type="date"
                            id="sp-fromDate"
                            className="form-control custom-form"
                            value={selectedOptions?.fromDate}
                            max={endDateMax || new Date().toISOString().split("T")[0]}
                            onChange={(e) =>
                              handleDateChange(e.target.value, "fromDate")
                            }
                            disabled={disableActions}
                          />
                          <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                        </div>
                        <div className="col-md-4 mt-2 mt-md-0 position-relative">
                          <label
                            htmlFor="sp-toDate"
                            className="form-label w-100 font-semibold font-14 label-color"
                          >
                            To
                          </label>

                          <input
                            type="date"
                            id="sp-toDate"
                            className="form-control custom-form"
                            value={selectedOptions.toDate}
                            min={endDateMin}
                            max={new Date().toISOString().split("T")[0]}
                            onChange={(e) =>
                              handleDateChange(e.target.value, "toDate")
                            }
                            disabled={disableActions}
                          />
                          <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                        </div>
                      </div>
                    </div>
                    {errors.endDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.endDateError}
                      </span>
                    )}
                    {errors.startDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.startDateError}
                      </span>
                    )}
                    {errors.endDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.endDateError}
                      </span>
                    )}
                    {errors.startDateError && (
                      <span style={{ color: "red", fontSize: "12.5px" }}>
                        {errors.startDateError}
                      </span>
                    )}
                  </div>
                  <span className="d-flex justify-content-end mt-5 pt-md-4">
                    <button
                      className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                      // disabled={loading || !(mbxSelectedOptions.archiveMailbox || mbxSelectedOptions.fromDate != "" || mbxSelectedOptions.toDate != "" || mbxSelectedOptions.notes || mbxSelectedOptions.recoverableItems || mbxSelectedOptions.permissions || mbxSelectedOptions.rules) || disableActions}
                      onClick={() => {
                        handleSave();
                        insertLastVisitedPageValue('goLiveConfigurations');
                      }}
                    >
                      Save &amp; Continue
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ) : null}
    {loading && (
      <div className="overlay">
        <div className="position-absolute top-50 start-50 translate-middle">
          <div
            className="spinner-border Loader loader-color align-center "
            role="status"
          >
            <span>
              <span className="loader-inner-circle" />
            </span>
          </div>
          <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
            Loading..
          </p>
        </div>
      </div>

    )}
    {alert ? <div
      className="modal fade show"
      tabIndex={-1}
      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content popup-brd-radius">
          <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
            <span className="position-absolute">
              <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
            </span>
          </div>
          <div className="modal-body border-0 text-center">
            <h5
              className="modal-title mb-3 primary-header"
              id="Failure-popupLabel"
            >
              { }
            </h5>
            <p className="primary-data">
              {alert}
            </p>
          </div>
          <div className="modal-footer border-0 pb-4 justify-content-center">
            <button
              type="button"
              className="btn primary-btn font-14 px-4 font-semibold"
              onClick={() => {
                if (isSessionEpired) {
                  history.push('/Login')
                }
                setAlert('')
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div> : null}
    {/*Failure Popup ends here */}
  </>
  )


}

export default AppSpecificConfigurations;