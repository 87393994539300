import React, { useRef, useState, useEffect, createContext } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import { decodeSessionDetails } from "../../Services/CommonServices";
import { updateUserMapping } from "../../Services/UserMapping";
import { commonErrorMsgs } from "../../Common/Constant";
import { insertFeedbackData } from "../../Services/feedbackServices";

export const MyContext: any = createContext("");

export default function Header() {

    const { pathname } = useLocation();
    const history = useHistory();
    const hamburgerChildElement = useRef<HTMLDivElement>(null);
    const [tab, setTab] = useState<string>("");
    const [alterPopUp, setAlterPopUp] = useState<boolean>(false)
    const [userName, setUserName] = useState<string>("");
    const [role, setRole] = useState<string>("");
    let userLocation: string;
    let c_local: any = sessionStorage.getItem("c_at");
    let cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

    let [objClient, setObjClient] = useState<any>();
    let [objAdmin, setObjAdmin] = useState<any>();

    let a_local: any = sessionStorage.getItem("a_at");
    let as_secretKey: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;

    let [alterMessage, setAlterMessage] = useState<any>("Are you sure you want to Logout?");
    let [alterMessage2, setAlterMessage2] = useState<any>("");
    const [loader, setLoader] = useState<boolean>(false)
    const location: any = useLocation().state
    let [pathType, setPathType] = useState<any>("");

    const [rating, setRating] = useState<string>("");//PS_AD-17
    const [review, setReview] = useState<string>("");//PS_AD-17
    const MAX_CHARACTERS = 1000;
    const [errorMessage, setErrorMessage] = useState<string>("");//PS_AD-17
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [feedbackPopUp, setFeedbackPopUp] = useState<boolean>(false);//PS_AD-17
    const [successPopUp, setSuccessPopUp] = useState<boolean>(false);//PS_AD-17
    const [showPopup, setShowPopup] = useState(MyContext);//PS_AD-17

    const decodeSession = async () => {
        try {
            debugger;
            let response: any = await decodeSessionDetails();
            if (response?.adminDetails && a_local) {
                setObjAdmin(response.adminDetails);
                setUserName(response.adminDetails.Name);
                setRole(response.adminDetails.Role);
                setEmailAddress(response?.adminDetails?.Email)
            }

            if (response?.clientDetails && c_local) {
                setObjClient(response.clientDetails)
                setUserName(response.clientDetails.Name);
                setRole(response.clientDetails.Role);
                setEmailAddress(response?.clientDetails?.Email)
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    // if (c_local != undefined) {
    //     let c_byteValue = objCryptoJS.AES.decrypt(c_local, cs_secretKey);
    //     var c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
    //     objClient = jwt(c_decryptedValue);
    //     userName = objClient.Name;
    //     role = objClient.Role;
    // }

    // if (a_local != undefined) {
    //     let a_byteValue = objCryptoJS.AES.decrypt(a_local, as_secretKey);
    //     var a_decryptedValue = a_byteValue.toString(objCryptoJS.enc.Utf8);
    //     objAdmin = jwt(a_decryptedValue);
    //     if (objClient == undefined) {
    //         userName = objAdmin.Name;
    //         role = objAdmin.Role;
    //     }
    // }

    let taskHighlight: string[] = ['task', 'configurations', 'feedbacks', 'viewmigration', 'migrationreport', 'usermapping', 'mappedusers', 'connectors', 'migration', 'selectapps', 'assessmentconfigurations', 'assessment', 'assessmentsummary', 'viewassessment', 'inprogress', 'customcreationandmapping']

    useEffect(() => {
        debugger
        decodeSession();
        if (pathname.toLowerCase().includes("dashboard")) {
            userLocation = "/dashboard";
        }
        else if (pathname.toLowerCase().includes("/admin/licenserenewal")) {
            userLocation = "/dashboard";
        }
        else if (taskHighlight.some(item => pathname.toLowerCase().includes(item))) {
            userLocation = '/task';
        }
        else if (pathname.toLowerCase().includes('manageclient') || pathname.toLowerCase().includes('newclient') || pathname.toLowerCase().includes('updateclient')) {
            userLocation = 'admin';
        }
        else if (taskHighlight.some(item => pathname.toLowerCase().includes(item)) || pathname.toLowerCase().includes('/admin/feedback')) {
            userLocation = '/feedback';
            ////console.log(userLocation, "existing location :");
        }
        else if (pathname.includes("/admin/configuration")) {
            userLocation = "/admin/configuration"
        }
        if (pathname.toString().toLowerCase().includes("admin")) {
            document.title = `AVAMIGRATRON - Admin`
        }
        else if (pathname.toString().toLowerCase().includes("view")) {
            const path = pathname.toString().split('/')
            const pathSplit1 = path[1].toString().substring(0, 4);
            const pathSplit2 = path[1].toString().substring(4);
            document.title = `AVAMIGRATRON - ${pathSplit1.charAt(0).toUpperCase() + pathSplit1.slice(1) + ' ' + pathSplit2.charAt(0).toUpperCase() + pathSplit2.slice(1)}`
            console.log(pathSplit1[0].charAt(0).toUpperCase() + pathSplit1[0].slice(1));
            // console.log(pathSplit1[1].charAt(0).toUpperCase() + pathSplit1[1].slice(1));            
        }
        else if (pathname.toString().toLowerCase().includes("report")) {
            const path = pathname.toString().split('/')
            const pathSplit1 = path[1].toString().substring(0, 9);
            const pathSplit2 = path[1].toString().substring(9);
            document.title = `AVAMIGRATRON - ${pathSplit1.charAt(0).toUpperCase() + pathSplit1.slice(1) + ' ' + pathSplit2.charAt(0).toUpperCase() + pathSplit2.slice(1)}`
        }
        else if (pathname.toString().toLowerCase().includes("summary")) {
            const path = pathname.toString().split('/')
            const pathSplit1 = path[1].toString().substring(0, 10);
            const pathSplit2 = path[1].toString().substring(10);
            document.title = `AVAMIGRATRON - ${pathSplit1.charAt(0).toUpperCase() + pathSplit1.slice(1) + ' ' + pathSplit2.charAt(0).toUpperCase() + pathSplit2.slice(1)}`
        }
        else {
            const path = pathname.toString().split('/')
            document.title = `AVAMIGRATRON - ${path[1].charAt(0).toUpperCase() + path[1].slice(1)}`
        }
        ////console.log(userLocation, "current location : ");
        setTab(userLocation);
    }, [pathname]);

    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        //debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    // sessionToken = CryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    // var dePass = sessionToken.toString(CryptoJS.enc.Utf8);
                    // clientToken = CryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = sessionToken

                }


            }
            if (objAdmin !== undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    // sessionToken = CryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    // var dePass = sessionToken.toString(CryptoJS.enc.Utf8);
                    // adminToken = CryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = sessionToken

                }
            }
            return token
        } catch (error) {
            setAlterPopUp(true);
            setAlterMessage(commonErrorMsgs?.generalError)

            throw error
        }
    }

    async function updateUserMappingStatus(action: string) {
        try {
            if (!role.toLowerCase().includes('viewer') || !objAdmin?.Role?.toLowerCase()?.includes('viewer'))
                setLoader(true)
            let token = await getToken()
            let payload = {
                taskId: location?.state?.taskId,
                token: token,
                action: action,
                userMappingName: location?.state?.userMappingName
            }
            let response: any = await updateUserMapping(payload)
            //debugger
            if (response.data.statusCode == 200) {
                history.push(pathType, {
                    state: {
                        ...location.state,
                        lastVisited: 'userMapping'

                    }
                })
            }
            else {
                setAlterPopUp(true)
                setAlterMessage(response.data.statusMessage)
            }
        } catch (error) {
            //setAlert(true)
            setAlterPopUp(true)
            setAlterMessage(commonErrorMsgs?.generalError)
        }
        finally {
            setLoader(false)
        }
    }

    const handleRatingClick: any = (selectedRating: any) => {
        try {
            setRating(selectedRating);
            setErrorMessage("")
        } catch (error) {

        }
    };

    // PS_81, PS_82, PS_83, PS_84, PS_85, PS_86 and PS_87.

    const handleReviewChange: any = (e: any) => {

        try {

            let inputText = e.target.value;



            if (inputText.length > MAX_CHARACTERS) {

                inputText = inputText.slice(0, MAX_CHARACTERS);

                setErrorMessage("Maximum character limit reached");

            } else {

                setErrorMessage(""); // Clear error message if within limit

            }

            setReview(inputText);

        } catch (error) {

        }
    };


    // PS_88, PS_89, PS_90, PS_91, PS_100, PS_101, PS_102 and PS_103.

    const handleSubmit: any = async () => {

        try {

            let token = await getToken();

            setLoader(true)

            if (rating === "" || review === "") {

                setErrorMessage('Please select a rating and write a review!');

                return;

            }

            const data = {

                token: token,

                rating: rating,

                review: review,

                emailID: emailAddress

            };

            debugger

            const result: any = await insertFeedbackData(data);//PS_AD-18

            setReview("")
            setRating("")

            ////console.log("RESULT", result);

            setLoader(false)

            ////console.log(result.data.statusCode, "statusssss");



            if (result.data.statusCode === 200) {//PS_AD-22

                setFeedbackPopUp(false);
                setSuccessPopUp(true);
            }

            if (result?.data?.statusCode === 400) {
                setAlterPopUp(true)
                setAlterMessage(result?.data?.statusMessage)
            }
        } catch (error) {
            setAlterMessage(commonErrorMsgs.generalError)
        }
    };

    function renderAlertPopUp() {
        return (
            <div className="modal fade show" id="Failure-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Failure-popupLabel" aria-modal="true" role="dialog" style={{ display: alterPopUp ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.8)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">
                                Alert
                            </h5>
                            <p className="primary-data">{alterMessage}</p>
                            <p className="primary-data">{alterMessage2}</p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                onClick={() => {
                                    setAlterPopUp(false);
                                    setAlterMessage("Are you sure you want to Logout?")
                                    setAlterMessage2("")

                                    if (alterMessage === 'Are you sure you want to Logout?') {
                                        if (objAdmin != undefined && objClient != undefined) {
                                            history.push('/login');
                                            sessionStorage.clear();
                                        }
                                        else if (objAdmin != undefined) {
                                            history.push('/admin/login');
                                            sessionStorage.clear();
                                        }
                                        else {
                                            history.push('/login');
                                            sessionStorage.clear();
                                        }

                                    }
                                    else if (alterMessage === "Changes will not be saved.") {
                                        updateUserMappingStatus('reset')
                                    }
                                }}
                                className="btn primary-btn font-14 px-4 font-semibold"
                                data-bs-dismiss="modal"
                            >
                                OK
                            </button>
                            {!alterMessage.includes("Max limits reached") &&
                                <button
                                    type="button"
                                    onClick={() => {
                                        setAlterPopUp(false);
                                        setAlterMessage("Are you sure you want to Logout?")
                                        setAlterMessage2("")
                                    }}
                                    className="btn primary-btn font-14 px-4 font-semibold"
                                    data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const handleLinkClick = (type: string) => {
        ////console.log(type, "test")
        debugger

        const path = window.location.pathname.toString().toLowerCase();
        if (!path.includes('/admin') && path.includes('/mappedusers')) {
            // Check the role
            if (role.toLowerCase().includes('viewer') || objAdmin?.Role?.toLowerCase()?.includes('viewer')) {
                history.push(type);
            } else {
                setAlterMessage("Changes will not be saved.")
                setAlterMessage2("Are you sure you want to continue?")
                setPathType(type)
                setAlterPopUp(true)
            }
        }
        else {
            const path = window.location.pathname.toString();
            (type == "/Admin/ManageClients" || path == "/Admin/ManageClients/UpdateClient" || path == "/Admin/ManageClients/NewClient") ? setTab("admin") : setTab(type);
            if (hamburgerChildElement.current) {
                hamburgerChildElement.current.className =
                    hamburgerChildElement.current.className.includes("show")
                        ? hamburgerChildElement.current.className.replace("show", "") + " collapsed "
                        : hamburgerChildElement.current.className;
            }
            if (objAdmin && objClient == undefined && !(path.toLowerCase().includes('admin'))) {
                history.push(`/Admin/${type}`);
            }
            else {
                history.push(type);
            }
        }
    };

    // Conditional rendering based on URL
    if (!pathname.toLowerCase().includes('/registration') &&
        !pathname.toLowerCase().includes('/login') &&
        !pathname.toLowerCase().includes('/admin/login') &&
        !pathname.toLowerCase().includes('/forgotpassword')) {
        return (
            <>
                <nav className="navbar navbar-expand-md shadow-sm topnav-z-index navbar-light sticky-top bg-white py-3" style={{ zIndex: '1050' }}>
                    <div className="container-fluid" style={{ cursor: 'pointer' }}>
                        <a
                            className="navbar-brand ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                debugger
                                setTab("/dashboard");
                                if (objAdmin != undefined && objClient != undefined) {
                                    handleLinkClick("/Dashboard")
                                }
                                else if (objAdmin != undefined) {
                                    handleLinkClick("/Admin/Dashboard")
                                }
                                else if (objClient != undefined) {
                                    handleLinkClick("/Dashboard")
                                }
                                else {
                                    window.open('https://www.avamigratron.com/Product', '_blank');
                                }
                            }}
                        >
                            <img src="/images/brand-logo.svg" alt="brand-logo" style={{ cursor: 'pointer' }} />
                        </a>
                        <button
                            className="navbar-toggler collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarsExample04"
                            aria-controls="navbarsExample04"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        {(objClient || objAdmin) ?
                            <div
                                ref={hamburgerChildElement}
                                className="navbar-collapse collapse"
                                id="navbarsExample04"
                            >
                                <ul className="navbar-nav me-auto mb-2 mb-md-0 gap-md-4 ms-md-4 mt-3 mt-md-0 ms-3">
                                    {/*Dashboard*/}
                                    <li className="nav-item">
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                if (objClient == undefined) {
                                                    handleLinkClick("/Admin/Dashboard")
                                                }
                                                else {
                                                    handleLinkClick("/Dashboard")
                                                }
                                            }}
                                            className={`nav-link font-14 top-nav-link cursor-pointer ${tab?.toLowerCase() === "/dashboard" ? "active" : ""}`}
                                        >
                                            Dashboard
                                        </a>

                                    </li>
                                    {(objAdmin && objClient == undefined) ? (
                                        <>

                                            {/*Coniguration*/}
                                            <li className="nav-item">
                                                <a
                                                    onClick={() => handleLinkClick("/Admin/Configurations")}
                                                    className={`nav-link font-14 top-nav-link cursor-pointer${tab?.toLowerCase() === "/admin/configurations" ? "active" : ""}
                                                        `}
                                                >
                                                    Configuration
                                                </a>
                                            </li>
                                            {/*Admin*/}
                                            <li className="nav-item">
                                                <a
                                                    onClick={() => handleLinkClick("/Admin/ManageClients")}
                                                    className={`nav-link font-14 top-nav-link cursor-pointer ${tab?.toLowerCase() === "admin" ? "active" : ""
                                                        }`}
                                                >
                                                    Admin
                                                </a>
                                            </li>
                                            {/*Feedback*/}
                                            <li className="nav-item">
                                                <a
                                                    onClick={() => handleLinkClick("/Admin/Feedback")}
                                                    className={`nav-link font-14 top-nav-link cursor-pointer ${tab?.toLowerCase() === "/feedback" ? "active" : ""}
                                                        `}
                                                >
                                                    Feedbacks
                                                </a>
                                            </li>
                                        </>)
                                        : null}
                                    {objClient ? (
                                        <>
                                            {/*Task*/}
                                            < li className="nav-item">
                                                <a
                                                    onClick={() => handleLinkClick("/Task")}
                                                    className={`nav-link font-14 top-nav-link cursor-pointer ${tab?.toLowerCase() === "/task" ? "active" : ""
                                                        }`}
                                                >
                                                    Task
                                                </a>
                                            </li>
                                        </>
                                    ) : null}
                                </ul>
                                <span className="border-bottom d-block w-100 d-block d-md-none mb-3" />
                                <ul className="list-unstyled mb-0 d-md-flex gap-4 d-grid ms-3 align-items-center">
                                    {/*Contact Us*/}
                                    <li>
                                        <a
                                            onClick={() =>
                                                (objClient == undefined) ? handleLinkClick("/Admin/Contactus")
                                                    : handleLinkClick("/Contactus")}
                                            className="nav-link p-0 cursor-pointer"
                                        >
                                            <img src="/images/headset-icon.svg" alt="headset-icon" />{" "}
                                            <span className="text-nowrap font-semibold font-14 text-dark ms-2 d-inline-block d-md-none">
                                                Contact Us
                                            </span>
                                        </a>
                                    </li>
                                    {/*Help & Doc*/}
                                    <li>
                                        <a
                                            onClick={() => {
                                                (objClient == undefined) ? handleLinkClick("/Admin/HelpandDocumentation")
                                                    : handleLinkClick("/HelpandDocumentation")
                                            }}
                                            className="nav-link p-0 cursor-pointer"
                                        >
                                            <img src="/images/queries-icon.svg" alt="headset-icon" />{" "}
                                            <span className="text-nowrap font-semibold font-14 text-dark ms-2 d-inline-block d-md-none">
                                                Help &amp; Documentation
                                            </span>
                                        </a>
                                    </li>
                                    {/*Raise a Feedback*/}
                                    {!role.toLowerCase().includes('admin') &&
                                        <li className="d-block d-md-none">
                                            <a
                                                onClick={() => setFeedbackPopUp(true)}
                                                className="nav-link p-0 cursor-pointer"
                                            >
                                                <img src="/images/feedback-icon.svg" alt="headset-icon" />{" "}
                                                <span className="text-nowrap font-semibold font-14 text-dark ms-2 d-inline-block d-md-none">
                                                    Raise Feedback
                                                </span>
                                            </a>
                                        </li>
                                    }
                                    {/*Profile Details*/}
                                    {/*User Name*/}
                                    <li className="d-flex align-items-center justify-content-between d-md-none">

                                        <span className="d-flex align-items-center gap-2 pt-2">
                                            <span className="d-flex align-items-center justify-content-center font-14 font-semibold profile-legend-name success" onClick={() => {
                                                objClient == undefined ?
                                                    handleLinkClick("/Admin/Profile") : handleLinkClick("/Profile")
                                            }}>
                                                {userName[0]}</span>
                                            <span className="d-grid"><span className="text-nowrap font-semibold font-14">{userName}</span>
                                                <span className="text-nowrap font-regular font-10"> {role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()} </span>
                                            </span>
                                        </span>

                                        <img
                                            src="/images/logout-icon.svg"
                                            alt="logout-icon"
                                            className="me-3"
                                            onClick={() => (setAlterPopUp(true))}
                                        />
                                    </li>
                                    {/*Profile Info*/}
                                    <li>
                                        <span className="dropdown d-none d-md-block">
                                            <span className="nav-link p-0" data-bs-toggle="dropdown">
                                                <span className="d-flex align-items-center justify-content-center font-14 font-semibold profile-legend-name success">
                                                    {userName[0]}
                                                </span>
                                            </span>
                                            <span className="dropdown-menu dropdown-menu-end border-0 profile-pic-dropdown mt-2 ">
                                                <span className="d-flex align-items-center gap-2 pt-2 px-3">
                                                    <span className="d-flex align-items-center justify-content-center font-14 font-semibold profile-legend-name success">
                                                        {userName[0]}
                                                    </span>
                                                    <span className="d-grid">
                                                        <span className="text-nowrap font-semibold font-14">
                                                            {userName}
                                                        </span>
                                                        <span className="text-nowrap font-regular font-10">
                                                            {role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()}                                            </span>
                                                    </span>
                                                </span>
                                                <ul className="list-unstyled theme-drop-list font-14 font-regular mt-2">
                                                    <li
                                                        onClick={() => {
                                                            objClient == undefined ?
                                                                handleLinkClick("/Admin/Profile") : handleLinkClick("/Profile")
                                                        }}
                                                        className="py-1 px-3 cursor-pointer"
                                                    >
                                                        Profile Update
                                                    </li>

                                                </ul>

                                                {!role.toLowerCase().includes('admin') &&
                                                    <ul className="list-unstyled theme-drop-list font-14 font-regular mt-2">
                                                        <li
                                                            onClick={() => setFeedbackPopUp(true)}
                                                            className="py-1 px-3 cursor-pointer"
                                                        >
                                                            Raise Feedback
                                                        </li>

                                                    </ul>
                                                }

                                                <span className="font-14 font-semibold text-center logout px-2 pt-2 w-100 d-inline-block cursor-pointer">
                                                    <span className="d-flex align-items-center justify-content-center p-2 gap-1" onClick={() => (setAlterPopUp(true))}>
                                                        <img src="/images/logout-icon.svg"
                                                            alt="logout-icon"

                                                        />
                                                        Logout
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            : null}
                        {alterPopUp && renderAlertPopUp()}
                    </div>

                    {loader && <div className="container-fluid">
                        <div className="overlay">
                            <div className="position-absolute top-50 start-50 translate-middle">
                                <div
                                    className="spinner-border Loader loader-color align-center "
                                    role="status"
                                >
                                    <span>
                                        <span className="loader-inner-circle" />
                                    </span>
                                </div>
                                <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                                    Loading..
                                </p>
                            </div>
                        </div>
                    </div>}

                    {objClient !== undefined && feedbackPopUp && (



                        <div

                            className="modal fade show"

                            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}

                            id="Telluswhatyouthink"

                            data-bs-backdrop="static"

                            data-bs-keyboard="false"

                            tabIndex={-1}

                            aria-labelledby="TelluswhatyouthinkLabel"

                            aria-hidden="true"

                        >

                            <div className="modal-dialog modal-dialog-centered">

                                <div className="modal-content p-4">

                                    <div className="modal-header border-0 p-0">

                                        <h1

                                            className="font-20 font-bold custom-header-color m-0"

                                            id="TelluswhatyouthinkLabel"

                                        >

                                            Tell us what you think

                                        </h1>

                                        <button

                                            type="button"

                                            onClick={() => {

                                                setFeedbackPopUp(false);

                                                setShowPopup(false);

                                                setRating("")
                                                setReview("")

                                            }}

                                            className="btn-close shadow-none"

                                            data-bs-dismiss="modal"

                                            aria-label="Close"

                                        />

                                    </div>

                                    <div className="modal-body border-0 p-0 pt-2 pb-3">

                                        <div className="row">

                                            <div className="col-md-12">

                                                <label

                                                    className="font-14 font-semibold label-color w-100 my-2 pt-3"

                                                    htmlFor="MailTo"

                                                >

                                                    What do you think of this tool ?

                                                </label>

                                                <div className="btn-group" role="group" aria-label="Basic example">

                                                    <button

                                                        onClick={() => handleRatingClick("Sad")}

                                                        type="button"

                                                        className={`emoji-active d-flex align-items-center me-4 ${rating === "Sad" ? 'selected' : ''

                                                            }`}

                                                    >

                                                        <img

                                                            src="/images/sad-emoji-icon.svg"

                                                            className="popup-emoji"

                                                            alt="sad-emoji"

                                                        />

                                                    </button>

                                                    <button

                                                        onClick={() => handleRatingClick("Neutral")}

                                                        type="button"

                                                        className={`emoji-active d-flex align-items-center me-4 ${rating === "Neutral" ? 'selected' : ''

                                                            }`}

                                                    >

                                                        <img

                                                            src="/images/emoji-neutral-icon.png"

                                                            className="popup-emoji neutral-emoji"

                                                            alt="emoji"

                                                        />

                                                    </button>

                                                    <button

                                                        onClick={() => handleRatingClick("Happy")}

                                                        type="button"

                                                        className={`emoji-active d-flex align-items-center me-4 ${rating === "Happy" ? 'selected' : ''

                                                            }`}

                                                    >

                                                        <img

                                                            src="/images/happy-emoji-icon.svg"

                                                            className="popup-emoji"

                                                            alt="happy-emoji"
                                                            style={{ width: "46px" }}
                                                        />

                                                    </button>

                                                </div>

                                            </div>

                                            <div className="col-md-12">

                                                <label

                                                    htmlFor="mailComments"

                                                    className="font-14 font-semibold label-color w-100 my-2 pt-3"

                                                >

                                                    Do you have any thoughts you’d like to share?

                                                </label>

                                                <textarea

                                                    className="form-control custom-textarea resize-none custom-form"

                                                    id="mailComments"

                                                    rows={3}

                                                    placeholder="Enter Your Thoughts"

                                                    maxLength={1000}

                                                    defaultValue={""}

                                                    onChange={handleReviewChange}

                                                ></textarea>

                                                {errorMessage && (

                                                    <div className="col-md-12">

                                                        <p className="text-danger">{errorMessage}</p>

                                                    </div>

                                                )}

                                            </div>

                                        </div>

                                    </div>

                                    <div className="modal-footer border-0 p-0">

                                        <button

                                            onClick={() => {

                                                setFeedbackPopUp(false);

                                                setShowPopup(false);
                                                setRating("")
                                                setReview("")

                                                // window.location.reload();
                                            }}

                                            type="button"

                                            className="btn secondary-btn px-3"

                                            data-bs-dismiss="modal"

                                        >

                                            Cancel

                                        </button>

                                        <button

                                            onClick={(e) => {
                                                setFeedbackPopUp(false)
                                                e.preventDefault();

                                                handleSubmit(e);

                                            }}

                                            type="button"

                                            className={`btn primary-btn px-3 ${rating && review ? '' : 'disabled-btn'}`}

                                            disabled={!rating || !review}



                                        >

                                            Send

                                        </button>

                                    </div>

                                </div>

                            </div>



                            <div>



                            </div>

                        </div>

                    )}



                    {successPopUp && (

                        <div>

                            <div

                                className="modal fade show"

                                id="Success-popup"

                                tabIndex={-1}

                                aria-labelledby="Success-popupLabel"

                                aria-modal="true"

                                role="dialog"

                                style={{ display: "block", backgroundColor: "rgba(0,0,0,1)" }}

                            >

                                <div className="modal-dialog modal-dialog-centered">

                                    <div className="modal-content popup-brd-radius">

                                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                            <span className="position-absolute">

                                                <img src="/images/success-popup-icon.svg" alt="success-popup-icon" />

                                            </span>

                                        </div>

                                        <div className="modal-body border-0 text-center">

                                            <h5 className="modal-title mb-3 primary-header" id="Success-popupLabel">

                                                Success!

                                            </h5>

                                            <p className="primary-data">Feedback Submitted Successfully.</p>

                                        </div>

                                        <div className="modal-footer border-0 justify-content-center pb-4">

                                            <button

                                                type="button"

                                                className="btn primary-btn font-14 px-4 font-semibold"

                                                data-bs-dismiss="modal"

                                                onClick={() => {

                                                    // setSuccessPopUp(false);

                                                    setShowPopup(false);
                                                    setFeedbackPopUp(false)
                                                    setSuccessPopUp(false)
                                                    //window.location.reload();

                                                }}



                                            >

                                                Ok

                                            </button>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    )}
                </nav>
            </>

        )
    } else {
        return null;
    }
}
