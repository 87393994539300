export let BE_URL = process.env.REACT_APP_BE_URL;
// export let BE_URL = "http://localhost:8080";

// export let BE_URL_CreationandMapping = `http://localhost:8080`
export let BE_URL_CreationandMapping = `${process.env.REACT_APP_BE_URL}/creation-and-mapping`



export const ADMIN_BE_URL = process.env.REACT_APP_BE_URL;

//export let BE_URL = 'http://localhost:8080';

export const Onlymail = (value: any) => {
    let emailValid = value.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);
    let checkValid = emailValid ? true : false
    return !checkValid
}

export const isValidPhoneNumber = (phoneNumber: any) => {
    // Define the universal minimum length for phone numbers
    const minPhoneNumberLength: number = 5;
    const maxPhoneNumberLength: number = 15;
    const isLongEnough = phoneNumber.length >= minPhoneNumberLength;
    const isShortEnough = phoneNumber.length <= maxPhoneNumberLength;
    return isLongEnough && isShortEnough;

};

export const goLiveURL = {
    sharepoint : `/sharepoint-migration/GoLiveMigration/Golive`,
    teamschannel: `/team-channel-migration/teamsChannelMigration/teamsGoLive`,
    gmail: ``,
    mydrive: `/drive-migration/oneDrive/GoLive`,
    outlook: `/team-channel-migration/teamsChannelMigration/teamsGoLive`
}
export const taskGrid = {
    getTaskData: "/task/tasks/taskDetails",
    updateTaskAction: "/task/tasks/updateTaskAction",
    updateLastVisitedPage: "/task/tasks/lastVisited",
    updateModifiedDate: "/task/tasks/updateModifiedDate",
    deltaMigration: '/task/tasks/initiateDelta',
    clientTaskCOunt: '/task/tasks/getTaskCount'
};

export const overallAssessment = {
    getAssessmentSummary: "/task/assessment/getAssessmentSummary",

}

export const appSpecificConfig = {
    postOutlookCustomization: "/configuration/appSpecificConfig/postOutlookCustomization",
    fetchUserOptionsFromDatabase: "/configuration/appSpecificConfig/fetchUserOptionsFromDatabase",
    handleInsertAppConfig: "/configuration/appSpecificConfig/handleInsertAppConfig",
    fetchOptionsFromDatabase: "/configuration/appSpecificConfig/fetchOptionsFromDatabase",
    fetchPreferencesData: "/configuration/appSpecificConfig/fetchPreferencesData",
    postPreferencesData: "/configuration/appSpecificConfig/postPreferencesData"
}


export const assessmentGrid = {
    fetchApplicationNames: "/task/viewAssesment/fetchApplicationNames",
    getUserMailboxData: "/task/viewAssesment/getUserMailboxData",
    getUserAppDetailsData: "/task/viewAssesment/getUserAppDetailsData",
    getSummary: "/task/viewAssesment/getSummary",
    cancelAction: "/task/viewAssesment/cancelAction",
    retryAction: "/task/viewAssesment/retryAsssessment",
    
};

export const viewMigration = {
    getlook: '/task/viewMigration/getMigrationTypeForOutlookUser',
    userActions: '/task/Migration/updateAction',
    fetchoutlook: '/task/viewMigration/fetchoutlookData',
    allApps: '/task/viewMigration/getViewMigrationDetails',
    getReport: '/task/viewMigration/generateOutlookMigrationReport'
}


// Minimum 6 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
export const PasswordRegex = (value: any) => {
    let passwordValid: any = value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/)
    let checkValid: boolean = passwordValid ? true : false
    return !checkValid
}

export const profile: any = {
    getClientData: '/mcr-svc-coll/profile/getClientData',
    updateProfile: '/mcr-svc-coll/profile/updateprofile',
    updatePassword: '/mcr-svc-coll/profile/updatepassword',
    handleuser: '/mcr-svc-coll/profile/handlesecondaryuser',
    sendmail: '/mcr-svc-coll/profile/sendInviteMail'
}



export const dashboard = {
    getAdminCards: "/mcr-svc-coll/dashboard/getAdminCards",
    getLicensRenewal: "/mcr-svc-coll/dashboard/getAdminLicenseRenewal",
    getClientDashboard: "/mcr-svc-coll/dashboard/fetchUserDashBoard",
};

export const contactus = {
    getCategory: "/mcr-svc-coll/contactus/getCategory",
    getAllFaq: "/mcr-svc-coll/contactus/getAllFaq",
    addComments: "/mcr-svc-coll/contactus/addComments",
    addQuestions: "/mcr-svc-coll/contactus/addQuestions",
    deleteQuestions: "/mcr-svc-coll/contactus/deleteQuestions",
};


// export const HelpAndDocumentation = {
//     getGuideAndVideoUrl: "/helpAndDocumentation/getGuideAndVideoData",
//     deleteGuideOrVideoUrl: "/helpAndDocumentation/deleteGuideOrVideo",
//     getCategoryUrl: "/helpAndDocumentation/getCategory",
//     uploadGuideToBlobUrl: "/helpAndDocumentation/uploadGuideToBlob",
//     blobTokenUrl: "/helpAndDocumentation/getBlobAccessToken",
//     addVideoOrEditVideoUrl: "/helpAndDocumentation/addVideoOrEditVideo",
// };

export const HelpAndDocumentation = {
    getGuideAndVideoUrl: "/mcr-svc-coll/helpAndDocumentation/getGuideAndVideoData",
    deleteGuideOrVideoUrl: "/mcr-svc-coll/helpAndDocumentation/deleteGuideOrVideo",
    getCategoryUrl: "/mcr-svc-coll/helpAndDocumentation/getCategory",
    uploadGuideToBlobUrl: "/mcr-svc-coll/helpAndDocumentation/uploadGuideToBlob",
    addVideoOrEditVideoUrl: "/mcr-svc-coll/helpAndDocumentation/addVideoOrEditVideo",
    blobTokenUrl: "/mcr-svc-coll/helpAndDocumentation/getBlobAccessToken",
};

export const commonErrorMsgs = {
    serverDownError: "Server is down",
    invalidTokenError: "User token is invalid",
    invalidPayloadError: "Invalid payload provided",
    generalError: "Something went wrong! Please try again or reach AVAMIGRATRON Support.",
    noRecords: "No Records Found",
    noData: "No Data Found"
};

export const fileErrors = {
    formatError: "Please upload a PDF file",
    duplicateError: "File already exist",
    sizeError: "Please ensure that the file size is less than 5 MB.",
};

export const videoErrors = {
    invalidUrlFormat: "Enter valid URL",
    mandatory: "This field is mandatory",
    maxLength: "This field accepts only 255 characters",
    characterLimit: "Character limit shouldn't exceed 256 characters",
    duplicateError: "Video already exist",
};




// export const HelpAndDocumentation = {
//     getGuideAndVideoUrl: "/common/helpAndDocumentation/getGuideAndVideoData",
//     deleteGuideOrVideoUrl: "/common/helpAndDocumentation/deleteGuideOrVideo",
//     getCategoryUrl: "/common/helpAndDocumentation/getCategory",
//     uploadGuideToBlobUrl: "/common/helpAndDocumentation/uploadGuideToBlob",
//     addVideoOrEditVideoUrl: "/common/helpAndDocumentation/addVideoOrEditVideo",
// };
export const feedbackEndpoint = {
    getFeedbacks: "/mcr-svc-coll/feedback/getFeedback",
    postFeedback: "/mcr-svc-coll/feedback/postFeedback",
};



// PS_EX_02: Declare a property for SelectApps called getAppsUrl with interface string and assign it with the value "/task/SelectApps/getApps"
// PS_EX_03: Declare a property for SelectApps called getSelectedAppsUrl with interface string and assign it with the value "/task/SelectApps/getSelectedApps"
// PS_EX_04: Declare a property for SelectApps called startAssessmentUrl with interface string and assign it with the value "/task/SelectApps/startAssessment"
// PS_EX_05: Declare a property for SelectApps called customExportAndImportUrl with interface string and assign it with the value "/task/SelectApps/customExportAndImport"
// export const SelectAppsUrls = {
//     getAppsUrl: "/SelectApps/getApps",
//     getSelectedAppsUrl: "/SelectApps/getSelectedAppsForAssessment",
//     startAssessmentUrl: "/SelectApps/startAssessment",
//     customExportAndImportUrl: "/SelectApps/customExportAndImport",
// };

export const SelectAppsUrls = {
    getAppsUrl: "/mcr-svc-coll/SelectApps/getApps",
    getSelectedAppsUrl: "/mcr-svc-coll/SelectApps/getSelectedAppsForAssessment",
    startAssessmentUrl: "/mcr-svc-coll/SelectApps/startAssessment",
    customExportAndImportUrl: "/mcr-svc-coll/SelectApps/customExportANdImport",
};


// PS_EX_06: Declare a variable called outlookExportTemplate with properties like {"User Name": "", "User Email": ""}
export const exportTemplate: { [key: string]: string } = {
    "Entity Name": "",
    "Entity Email Address": "",
    "Entity Type":""
};
export const sharePointExportTemplate: { [key: string]: string } = {
    "Site Name": "",
    "Site Url": "",
};
export const teamschannelExportTemplate: { [key: string]: string } = {
    "Team Name": "",
    "Team ID": "",
    "Channel Name":"",
    "Channel ID":""
};

export const userMappingExportTemplate: { [key: string]: string } = {
    'Source User Name': "",
    'Source Email Address': "",
    'Source Type': "",
    'Target User Name': "",
    'Target Email Address': "",
    'Target Type': ""
};

export const entityMappingExportTemplate: { [key: string]: string } = {
    'Source Entity Name': "",
    'Source Email Address': "",
    'Source Type': "",
    'Target Entity Name': "",
    'Target Email Address': "",
    'Target Type': ""
};

// PS_EX_07: Declare a variable called importMsgs and have the following properties:
// invalidColumns: "Please enter vaild column details",
// duplicateValues: "Contains duplicate data",
// emptyValues: "Empty values are not accepted"
export const importMsgs = {
    invalidColumns: "Please enter vaild column details",
    duplicateValues: "Contains duplicate data",
    emptyValues: "Empty values are not accepted",
    invalidFile: "Please Upload a valid csv file"
};



export const connection = {
    getConnections: "/task/tasks/getConnections",
    getConnectionEdit: "/task/tasks/getConnectionEdit",
    checkAndCreateTask: "/task/tasks/checkAndCreateTask"
};

export const Admin: object | any = {
    getAdminClientpath: '/adminbe/admin/getAdminClientData',
    handleClient: '/adminbe/admin/handleClient',
    sendMail: '/adminbe/admin/sendMail',
    update: '/adminbe/admin/updateSeverdown',
    getSession: '/adminbe/admin/redirecttoClient',
    updateServerdown: '/adminbe/admin/updateServerdown',
    fetchApplications:'/adminbe/admin/fetchEnvironmentApplications',
    assignLicense:'/adminbe/admin/assignLicense',
    revokeLicense:'/adminbe/admin/revokeLicense',



}

export const defaultAddress = "Avasoft, Beta Block, Alpha City It Park, Navalur, Tamil Nadu 600130"

export const appDescripions: any = {
    groups: 'The groups application in Microsoft 365 allows users to manage, and collaborate within groups, enabling streamlined communication, file sharing and task management across teams and projects.',
    outlook: 'Integrated communication and collaboration tools encompassing email management, cloud storage, team-based workspaces, and shared email resources for collective access.',
    sharedmailbox: 'Microsoft 365 shared mailbox enables multiple users to collectively access and manage emails for collaborative work or specialized tasks like customer support.',
    roomsandequipments: 'Rooms & Equipment in Microsoft 365 are resources for booking meetings or events, like conference rooms or projectors , aiding efficient resource management and scheduling.',
    teamschannel: 'A Teams channel is a dedicated space within a Microsoft Teams workspace where team members can collaborate, communicate and share files on specific topics or projects.',
    teamschat: 'Real-time messaging for one-on-one or group conversations within Microsoft Teams, facilitating text-based communication, file sharing and collaboration.',
    onedrive: 'Cloud storage service by Microsoft for storing, sharing and collaborating on files and documents securely from anywhere.',
    sharepoint: 'Collaboration platform by Microsoft for creating websites, managing content and sharing information with organization.',
    gmail:'Gmail, is a free, web-based email service provided by Google. It offers a user-friendly interface, large storage capacity, powerful search capabilities, and seamless integration with other Google services, making it one of the most popular email platforms worldwide',
    mydrive:'Google Drive allows users to store files, share them with others, and collaborate on documents in real-time, while also providing seamless integration with other Google services and third-party applications',
    shareddrive:'Shared drives are special folders in Google Drive that you can use to store, search, and access files with a team. Shared drive files belong to the team instead of an individual. Even if members leave, the files stay in the shared drive so your team can keep sharing information and work anywhere, from any device.',
    sites:'Google Sites is a structured wiki and web page creation tool included as part of the free, web-based Google Docs Editors suite offered by Google.',
    chats:'Google Chat is a secure communications tool designed to provide easy business communications within the Google Workspace ecosystem. With Google Chat, teams collaborate via text, build collaborative chat rooms, share documents, deliver presentations, and establish web conferences.',
    ggroups:'Google Groups is an online discussion and collaboration platform provided by Google. It allows users to create and participate in email-based mailing lists, forums, and Q&A communities, making it easy to share information and engage in group conversations on various topics.',
    entity: 'Integrated communication and collaboration tools encompassing email management, cloud storage, team-based workspaces, and shared email resources for collective access.'
}


export const helpAndDocumentation = {
    saasToken: "/admin/adminHelpAndDocumentation/getAccessData",
}


export function isValidAppID(id: any) {
    const regex =/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return regex.test(id);
}

export function isvalidSecret(inputString: any) {
    let regexItem =  /^[a-zA-Z0-9~._-]{40}$/;
    return regexItem.test(inputString);
}

export function isValidTenantID(id: any) {
    const regex = /^[a-zA-Z0-9_-]+$/;
    return regex.test(id);
}

export function isValidEmail(email: any) {
    let emailValid = email.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);
    let checkValid = emailValid ? true : false
    return !checkValid
}

export let serverDownContent :any=`


Hi ,
Our server is scheduled for essential upgrades and maintenance.

Maintenance Details:
Start Date and Time: [Insert Start Date and Time] 
End Date and Time: [Insert End Date and Time] 
Duration: [Insert Duration] 
Purpose: [Briefly describe the purpose of the maintenance]

During this period, our server will be temporarily unavailable. We apologize for any inconvenience and appreciate your understanding.
Please plan your activities accordingly and refrain from accessing the server during maintenance to avoid potential data loss or disruption.

For urgent inquiries or concerns, please contact our support team at Migrationsupport@avasoft.com

Thanks,
Team AVAMIGRATRON`

export let announcemnetHTMLContent :any =`
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <title>Announcement</title>
</head>

<body style="font-family: Arial, Helvetica, sans-serif;">

    <table style="width: 100%;">
        <tr>
            <td style="width : 10%; color: white"></td>
            <td style="width : 80%; ">
                <div
                    style="display: flex; margin-left: auto; margin-right: auto; margin-top: 48px; border-top: 4px solid #596FCE; background-color: white; color: black; position: relative; ">
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td style="width: 45%;"></td>
                                <td style="width: 10%;">
                                    <img src="${process.env.Brand_Logo_For_Mail}" alt="logo" />
                                </td>
                                <td style="width: 45%;"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </td>
            <td style="width : 10%; color: white"></td>
        </tr>
        <tr>
            <td style="width : 10%; color: white"></td>
            <td style="width: 80%; align-content: center; background-color: #ebecff;">
                <table style="width: 100%;">
                    <tbody>
                        <tr>
                            <td style="width: 15%;"></td>
                            <td style="width: 70%; ">
                                <div
                                    style="padding-top:10px; margin-left: auto; margin-right: auto; margin-top: 0px; background-color: #ebecff;  color: #464775; ">
                                    <div style="color: black; padding-right: 10%; padding-left: 10%;">
                                        <h2 style="color: #464775;">{subject}</h2>
                                        <p><span style="font-weight: bold;">Hi</span> 
                                        <p>{comments}</p>
                                        <p>For urgent inquiries or concerns, please contact our support team at <a href="mailto:Migrationsupport@avasoft.com" style="color: #464775; text-decoration: none;">Migrationsupport@avasoft.com</a>.</p>
                                        <p><span style="font-weight: bold;">Thanks,</span><br><span style="font-weight: bold; color: #464775;">Team AVAMIGRATRON</span></p>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 15%;"></td>
                        </tr>
                    </tbody>
                </table>
            </td>
            <td style="width : 10%; color: white"></td>
        </tr>
    </table>

</body>

</html>`

export let serverDownHTMLContent :any= `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <title>Server Maintenance</title>
</head>

<body style="font-family: Arial, Helvetica, sans-serif;">

    <table style="width: 100%;">
        <tr>
            <td style="width : 10%; color: white"></td>
            <td style="width : 80%; ">
                <div
                    style="display: flex; margin-left: auto; margin-right: auto; margin-top: 48px; border-top: 4px solid #596FCE; background-color: white; color: black; position: relative; ">
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td style="width: 45%;"></td>
                                <td style="width: 10%;">
                                    <img src=${process.env.Brand_Logo_For_Mail} alt="logo" />
                                </td>
                                <td style="width: 45%;"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </td>
            <td style="width : 10%; color: white"></td>
        </tr>
        <tr>
            <td style="width : 10%; color: white"></td>
            <td style="width: 80%; align-content: center; background-color: #ebecff;">
                <table style="width: 100%;">
                    <tbody>
                        <tr>
                            <td style="width: 15%;"></td>
                            <td style="width: 70%; ">
                                <div
                                    style="padding-top:10px; margin-left: auto; margin-right: auto; margin-top: 0px; background-color: #ebecff;  color: #464775; ">
                                    <div style="color: black; padding-right: 10%; padding-left: 10%;">
                                        <h2 style="color: #464775;"> Server Maintenance</h2>
                                        <p><span style="font-weight: bold;">Hi,</span></p>
                                        <p>Our server is scheduled for essential upgrades and maintenance.</p>
                                        <h3 style="color: #464775;">Maintenance Details:</h3>
                                        <ul>
                                            <li><strong>Start Date and Time:</strong> [Insert Start Date and Time]</li>
                                            <li><strong>End Date and Time:</strong> [Insert End Date and Time]</li>
                                            <li><strong>Duration:</strong> [Insert Duration]</li>
                                            <li><strong>Purpose:</strong> [Briefly describe the purpose of the maintenance]</li>
                                        </ul>
                                        <p>During this period, our server will be temporarily unavailable. We apologize for any inconvenience and appreciate your understanding.</p>
                                        <p>Please plan your activities accordingly and refrain from accessing the server during maintenance to avoid potential data loss or disruption.</p>
                                        <p>For urgent inquiries or concerns, please contact our support team at <a href="mailto:Migrationsupport@avasoft.com" style="color: #464775; text-decoration: none;">Migrationsupport@avasoft.com</a>.</p>
                                        <p><span style="font-weight: bold;">Thanks,</span><br><span style="font-weight: bold; color: #464775;">Team AVAMIGRATRON</span></p>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 15%;"></td>
                        </tr>
                    </tbody>
                </table>

            </td>
            <td style="width : 10%; color: white"></td>
        </tr>
    </table>

</body>

</html>`

export const ALPHABETS_MUST_REGEX : RegExp = /^(?=.*[a-zA-Z]).*$/;
export const ONLY_ALPHABETS_REGEX :RegExp = /^[a-zA-Z]+$/;
